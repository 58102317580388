import React, { useState, useEffect, useRef } from "react";

export const useOutside = (initialIsVisible) => {
  // State and setter for storing whether element is visible
  const [isShow, setIsShow] = useState(initialIsVisible);

  const ref = useRef(null);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsShow(false);
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.addEventListener("click", handleClickOutside, true);
    };
  }); // Empty array ensures that effect is only run on mount and unmount

  return { ref, isShow, setIsShow };
};
