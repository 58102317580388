// APPROVED +
import { z } from "zod";
import { elementTypes } from "../../../controllersFieldsHookForm/index";
import {
	getFieldsSchema,
	getFieldsDefaultValue,
} from "../../../controllersFieldsHookForm/helpers";

const code = {
	id: 111,
	key: "code",
	placeholder: "common.forms.code",
	fieldName: "code",
	errKeyNameBackend: "code",
	elementType: elementTypes.input,
	defaultValue: "",
	schema: z.string(),
};

export const fieldsObj = {
	code,
};

export const fields = Object.values(fieldsObj);
export const fieldsDefaultValue = getFieldsDefaultValue(fields);
export const schema = getFieldsSchema(fields);
