import { combineReducers, configureStore } from "@reduxjs/toolkit";

import { useDispatch } from "react-redux";

import app from "./App/app";

//main
import mainDiscountProducts from "./Main/MainDiscountProducts/MainDiscountProducts";
// favourite
import favourite from "./Favourite/favourite";
// brands
import brands from "./Brands/brands";
import brandsAlphabet from "./Brands/BrandsAlphabet";
// subscription
import subscription from "./Subscription/subscription";
// productCard
import productCard from "./ProductCard/productCard";
// categories
import categories from "./Categories/categories";

import categoriesAll from "./Generals/Categories/CategoriesAll";
//brand
import brandsCategory from "./BrandsCategory/brandsCategory";
//basket
import basket from "./Basket/basket";
//checkout
import checkoutOrder from "./Checkout/CheckoutOrder";
//SearchCatalog
import searchCatalog from "./SearchCatalog/SearchCatalog";

//generals
import categoriesTop from "./Generals/CategoriesTop/CategoriesTop";
import recommendedProducts from "./Generals/RecommendedProducts/RecommendedProducts";
import categoryOrBrandProducts from "./Generals/CategoryOrBrandProducts/CategoryOrBrandProducts";
import question from "./Generals/Question/question";
import staticInformation from "./Generals/Static_information/Static_information";
import productsFilers from "./Generals/ProductsFilers/ProductsFilers";
import messages from "./Generals/Messages/messages";
import favouriteWishList from "./FavouriteWishList/favouriteWishList";
import reviewProduct from "./Generals/Reviews/reviewProduct";
import rawCategories from "./Generals/Categories/RawCategories";
import products from "./Generals/Products/Products";

//seller

//sellerOrder
import sellerOrder from "./Seller/SellerOrder/sellerOrder";
//sellerOrders
import sellerOrders from "./Seller/SellerOrders/sellerOrders";
//sellerSettings
import sellerSettingsDataReturn from "./Seller/SellerSettings/SellerSettingsDataReturn/SellerSettingsDataReturn";
import sellerSettingsCompany from "./Seller/SellerSettings/SellerSettingsCompany/SellerSettingsCompany";
import sellerSettingsPay from "./Seller/SellerSettings/SellerSettingsPay/SellerSettingsPay";
import sellerSettingsDocuments from "./Seller/SellerSettings/SellerSettingsDocuments/SellerSettingsDocuments";
import sellerSettingsDelivery from "./Seller/SellerSettings/SellerSettingsDelivery/SellerSettingsDelivery";
// sellerProducts
import sellerProducts from "./Seller/SellerProducts/sellerProducts";
import sellerProduct from "./Seller/SellerProducts/sellerProduct";
import sellerAddEditProduct from "./Seller/SellerProducts/sellerAddEditProduct";
// sellerMessages
import sellerMessagesDialogues from "./Seller/SellerMessages/SellerMessagesDialogues";
import sellerMessagesProductsReviews from "./Seller/SellerMessages/SellerMessagesProductsReviews";
import sellerMessagesSellerReviews from "./Seller/SellerMessages/SellerMessagesSellerReviews";
import sellerMessagesQuestions from "./Seller/SellerMessages/SellerMessagesQuestions";
// sellerProfile
import sellerProfileMoney from "./Seller/SellerProfile/SellerProfileMoney";
// sellerFinance
import sellerFinance from "./Seller/SellerFinance/sellerFinance";

// user
import user from "./User/user";
import userRegister from "./User/userRegister";
import userForgotPassword from "./User/userForgotPassword";
import userResetPassword from "./User/userResetPassword";

import { appApi } from "./api";

export const rootReducer = combineReducers({
	[appApi.reducerPath]: appApi.reducer,

	app: app,

	// main
	mainDiscountProducts: mainDiscountProducts,
	// favourite
	favourite: favourite,
	// brands
	brands: brands,
	brandsAlphabet: brandsAlphabet,
	// subscription
	subscription: subscription,
	// productCard
	productCard: productCard,
	// categories
	categories: categories,

	categoriesAll: categoriesAll,
	// brand
	brandsCategory: brandsCategory,
	//basket
	basket: basket,
	//checkout
	checkoutOrder: checkoutOrder,
	//SearchCatalog
	searchCatalog: searchCatalog,
	//generals
	categoriesTop: categoriesTop,
	recommendedProducts: recommendedProducts,
	categoryOrBrandProducts: categoryOrBrandProducts,
	question: question,
	staticInformation: staticInformation,
	productsFilers: productsFilers,
	messages: messages,
	favouriteWishList: favouriteWishList,
	reviewProduct: reviewProduct,
	rawCategories: rawCategories,
	products,
	//seller
	// sellerOrder
	sellerOrder: sellerOrder,
	// sellerOrders
	sellerOrders: sellerOrders,
	// sellerSettings
	sellerSettingsDataReturn: sellerSettingsDataReturn,
	sellerSettingsCompany: sellerSettingsCompany,
	sellerSettingsPay: sellerSettingsPay,
	sellerSettingsDocuments: sellerSettingsDocuments,
	sellerSettingsDelivery: sellerSettingsDelivery,
	// sellerProducts
	sellerProducts: sellerProducts,
	sellerProduct: sellerProduct,
	sellerAddEditProduct: sellerAddEditProduct,
	// sellerMessages
	sellerMessagesDialogues: sellerMessagesDialogues,
	sellerMessagesProductsReviews: sellerMessagesProductsReviews,
	sellerMessagesSellerReviews: sellerMessagesSellerReviews,
	sellerMessagesQuestions: sellerMessagesQuestions,
	sellerProfileMoney: sellerProfileMoney,
	//sellerFinance
	sellerFinance: sellerFinance,
	// user
	user: user,
	userRegister: userRegister,
	userForgotPassword: userForgotPassword,
	userResetPassword: userResetPassword,
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();

export const store = configureStore({
	reducer: rootReducer,
	devTools: process.env.NODE_ENV !== "production",
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: false,
		}).concat(appApi.middleware),
});

store.subscribe(() => {
	if (store.getState().favouriteWishList.wishListProducts.length > 0) {
		localStorage.setItem(
			"favouriteProducts",
			JSON.stringify(store.getState().favouriteWishList.wishListProducts),
		);
	}
	if (store.getState().basket.basket?.length > 0) {
		localStorage.setItem(
			"basketProducts",
			JSON.stringify(store.getState().basket.basket),
		);
	}
	if (store.getState().user.recentlyProducts.length > 0) {
		localStorage.setItem(
			"recentlyProducts",
			JSON.stringify(store.getState().user.recentlyProducts),
		);
	}
});
