export const getDataSendQuery = ({ url, args }) => {
	let urlCopy = url;
	const urlPagination = args?.urlPagination || "";
	const paramsString = args?.paramsString || "";
	const params = args?.params || {};
	const headers = args?.headers || {};

	if (urlPagination) {
		urlCopy = urlPagination;
	}

	if (paramsString) {
		urlCopy += paramsString;
	}

	return {
		url: urlCopy,
		params,
		headers,
	};
};
