import React from "react";
import { useTranslation } from "react-i18next";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useHistory } from "react-router-dom";

// Assets
import {
	sellFormManImg,
	sellFormManMobileImg,
	sellFormWomanImg,
	sellFormWomanMobileImg,
} from "../../assets/images/imagesConstants";

// UI
import MainBtn from "../../ui/buttons/mainBtn";

// Hooks
import { useAppSelector } from "../../hooks/redux";

// Paths
import { paths, pathFC } from "../../App/Routes/paths";

// DB
import { bottomBannerDiscount } from "../../db/generals/generalsData";
import { formatedCallbackUrl } from "../../utils/formatedCallbackUrl";
import { BREAKPOINTS_NAME } from "../../theme/breakpoints/index";

// Styles
import {
	Banner,
	Images,
	WrapperContent,
	Title,
	TitleSpan,
	TitleParagraph,
	sx,
} from "./styles";

const BottomBanner: React.FC = () => {
	const { t } = useTranslation();
	const history = useHistory();
	const isUpLaptop = useMediaQuery((theme: any) =>
		theme.breakpoints.up(BREAKPOINTS_NAME.laptop),
	);

	// SELECTORS
	const isLogged = useAppSelector((state) => state.user.isLogged);

	// FUNCTIONS
	const handleButtonClick = () => {
		history.push(
			pathFC(
				formatedCallbackUrl({
					includesArray: [paths.registration],
					path: paths.registration,
				}),
			),
		);
	};

	if (isLogged) return null;

	return (
		<Banner>
			<Images>
				{!isUpLaptop ? (
					<>
						<img
							src={sellFormManMobileImg}
							alt="man_mobile"
						/>
						<img
							src={sellFormWomanMobileImg}
							alt="woman_mobile"
						/>
					</>
				) : (
					<>
						<img
							src={sellFormManImg}
							alt="man"
						/>
						<img
							src={sellFormWomanImg}
							alt="woman"
						/>
					</>
				)}
			</Images>
			<WrapperContent>
				<Title>
					<TitleSpan>
						{t("bottomDiscount.discount")} {bottomBannerDiscount}
					</TitleSpan>
					<TitleParagraph>
						{t("bottomDiscount.firstPart")}
						<br />
						{t("bottomDiscount.secondPart")}
					</TitleParagraph>
				</Title>
				<MainBtn
					text={t("login.createAcc.1")}
					onClick={handleButtonClick}
					optionsTags={{
						wrapper: {
							sx: sx.pMainBtn.wrapper,
						},
						btn: {
							variant: "contained_ee4a2e_ee4a2e_ffffff",
							sx: sx.pMainBtn.btn,
						},
					}}
				/>
			</WrapperContent>
		</Banner>
	);
};

export default BottomBanner;
