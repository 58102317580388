import { createSlice } from "@reduxjs/toolkit";

//api
import { getRequest, putRequest } from "../../../../API/_api";

//redux
import {
  setModalWindowChildrenType,
  setModalWindowStatus,
  setModalWindowInfo,
} from "../../../App/app";

export const getSellerSettingsPayBack = () => (dispatch: any) => {
  dispatch(setLoadingGetSellerSettingsPayBack(true));

  const success = (data: any) => {
    dispatch(setDataGetSellerSettingsPayBack(data?.data));
  };

  const setError = (error: any) => {
    dispatch(setErrorGetSellerSettingsPayBack(error));
  };

  getRequest({
    url: `seller/payment-data`,
    success,
    setError,
  });
};

type TPutSellerSettingsPayBackProps = {
  obj: any;
};

export const putSellerSettingsPayBack =
  (sendData: TPutSellerSettingsPayBackProps) => (dispatch: any) => {
    const { obj } = sendData;
    dispatch(setLoadingPutSellerSettingsPayBack(true));

    const success = (data: any) => {
      dispatch(setDataPutSellerSettingsPayBack(data?.data));
      dispatch(setModalWindowChildrenType("formSuccess"));
      dispatch(
        setModalWindowInfo({
          title: "common.modals",
        })
      );
      dispatch(setModalWindowStatus(true));
    };

    const setError = (error: any) => {
      dispatch(setErrorPutSellerSettingsPayBack(error?.response?.data));
    };

    putRequest({
      url: `seller/payment-data`,
      success,
      setError,
      obj,
    });
  };

const initialState: any = {
  dataGetSellerSettingsPayBack: null,
  loadingGetSellerSettingsPayBack: false,
  errorGetSellerSettingsPayBack: null,
  dataPutSellerSettingsPayBack: null,
  loadingPutSellerSettingsPayBack: false,
  errorPutSellerSettingsPayBack: null,
};

export const sellerSettingsPay = createSlice({
  name: "sellerSettingsPay",
  initialState,
  reducers: {
    setDataGetSellerSettingsPayBack(state, action) {
      state.dataGetSellerSettingsPayBack = action.payload;
      state.loadingGetSellerSettingsPayBack = false;
      state.errorPutSellerSettingsPayBack = null;
    },
    setErrorGetSellerSettingsPayBack(state, action) {
      state.errorGetSellerSettingsPayBack = action.payload;
      state.loadingGetSellerSettingsPayBack = false;
    },
    setLoadingGetSellerSettingsPayBack(state, action) {
      state.loadingGetSellerSettingsPayBack = action.payload;
      state.errorGetSellerSettingsPayBack = null;
    },
    setDataPutSellerSettingsPayBack(state, action) {
      state.dataPutSellerSettingsPayBack = action.payload;
      state.loadingPutSellerSettingsPayBack = false;
    },
    setErrorPutSellerSettingsPayBack(state, action) {
      state.errorPutSellerSettingsPayBack = action.payload;
      state.loadingPutSellerSettingsPayBack = false;
    },
    setLoadingPutSellerSettingsPayBack(state, action) {
      state.loadingPutSellerSettingsPayBack = action.payload;
      state.errorPutSellerSettingsPayBack = null;
    },
  },
});

export const {
  setLoadingGetSellerSettingsPayBack,
  setErrorGetSellerSettingsPayBack,
  setDataGetSellerSettingsPayBack,
  setDataPutSellerSettingsPayBack,
  setErrorPutSellerSettingsPayBack,
  setLoadingPutSellerSettingsPayBack,
} = sellerSettingsPay.actions;

export default sellerSettingsPay.reducer;
