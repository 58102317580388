import { paths, pathFC } from "../../App/Routes/paths";

//svg
import { promoCodeSvg } from "../../components/ui/Svgs/promoCodeSvg";
import { profileSvg } from "../../components/ui/Svgs/profileSvg";
import { favoritesSvg } from "../../components/ui/Svgs/favoritesSvg";
import { basketSvg } from "../../components/ui/Svgs/basketSvg";
import { basketBlackSvg } from "../../components/ui/Svgs/basketBlackSvg";
import { profileBlackSvg } from "../../components/ui/Svgs/profileBlackSvg";

import { sellerSlugs } from "../Seller/sellerMenuData";

type TGenderCategoryLink = {
  id: number;
  key: string;
  path: string;
  title: string;
  slug: string;
};

type TGendersCategoryLink = Array<TGenderCategoryLink>;

export const gendersCategoryLink: TGendersCategoryLink = [
  {
    id: 1,
    key: "women",
    path: pathFC(paths.mainWomen),
    title: "header.genders.women",
    slug: "women",
  },
  {
    id: 2,
    key: "men",
    path: pathFC(paths.mainMen),
    title: "header.genders.men",
    slug: "men",
  },
  {
    id: 3,
    key: "kids",
    path: pathFC(paths.mainKids),
    title: "header.genders.kids",
    slug: "kids",
  },
];

type THeaderBodybuttonLinkk = {
  id: number;
  path: string;
  title: string;
  svg?: string;
  subLinks?: any;
};

type THeaderBodybuttonsLinkk = Array<THeaderBodybuttonLinkk>;

export const headerBodybuttonsLink: THeaderBodybuttonsLinkk = [
  {
    id: 0,
    path: pathFC(paths.profilePromoCodesCertificates),
    title: "header.promoCode.title",
    svg: promoCodeSvg,
  },
  {
    id: 1,
    path: pathFC(paths.profilePersonal),
    title: "header.personal.profile",
    svg: profileSvg,
    subLinks: [
      {
        id: 0,
        path: pathFC(paths.profilePersonal),
        title: "profilePersonal.title",
      },
      {
        id: 1,
        path: pathFC(paths.profileOrders),
        title: "profile.orders.title",
      },
      {
        id: 2,
        path: pathFC(paths.profileQuestionReviews),
        title: "profile.questions.title",
      },
    ],
  },
  {
    id: 2,
    path: pathFC(paths.favorites),
    title: "header.favourite.title",
    svg: favoritesSvg,
  },
  {
    id: 3,
    path: pathFC(paths.basket),
    title: "header.basket.title",
    svg: basketSvg,
  },
];

export const headerBodyButtonsLinkSeller: THeaderBodybuttonLinkk = {
  id: 0,
  path: pathFC(sellerSlugs.sellerProfile),
  title: "seller.lists.title",
  subLinks: [
    {
      id: 0,
      path: pathFC(sellerSlugs.sellerProfile),
      title: "seller.lists.menu.panel",
    },
    {
      id: 1,
      path: pathFC(sellerSlugs.sellerOrdersAll),
      title: "seller.lists.menu.orders",
    },
    {
      id: 2,
      path: pathFC(sellerSlugs.sellerMessages),
      title: "seller.lists.menu.messages",
    },
    {
      id: 3,
      path: pathFC(sellerSlugs.sellerProductsOnSale),
      title: "seller.lists.menu.products",
    },
    {
      id: 4,
      path: pathFC(sellerSlugs.sellerSettingsCompany),
      title: "seller.lists.menu.settings",
    },
  ],
};

export const headerBodybuttonsLinkAdaptive: THeaderBodybuttonsLinkk = [
  {
    id: 0,
    path: pathFC(paths.profilePersonal),
    title: "header.personal.profile",
    svg: profileBlackSvg,
  },
  {
    id: 1,
    path: pathFC(paths.basket),
    title: "header.basket.title",
    svg: basketBlackSvg,
  },
];

type TheaderMenubuttonLink = {
  path: string;
  title: string;
  news?: boolean;
  action?: boolean;
  subLink: [];
};

type TheaderMenubuttonsLink = Array<TheaderMenubuttonLink>;

export const headerMenubuttonsLink: TheaderMenubuttonsLink = [
  {
    path: pathFC(paths.mainWomen),
    title: "header.footerItem.promotions",
    action: true,
    subLink: [],
  },
  {
    path: pathFC(paths.mainWomen),
    title: "header.menubuttonsLink.closses",
    subLink: [],
  },
  {
    path: pathFC(paths.mainWomen),
    title: "header.menubuttonsLink.shoes",
    subLink: [],
  },
  {
    path: pathFC(paths.mainWomen),
    title: "header.menubuttonsLink.accessories",
    subLink: [],
  },
  {
    path: pathFC(paths.mainWomen),
    title: "header.menubuttonsLink.sport",
    subLink: [],
  },
  {
    path: pathFC(paths.mainWomen),
    title: "header.menubuttonsLink.beauty",
    subLink: [],
  },
  {
    path: pathFC(paths.mainWomen),
    title: "header.footerItem.brands",
    subLink: [],
  },
  {
    path: pathFC(paths.mainWomen),
    title: "header.footerItem.discount",
    subLink: [],
  },
  {
    path: pathFC(paths.mainWomen),
    title: "header.footerItem.new",
    news: true,
    subLink: [],
  },
  {
    path: pathFC(paths.mainWomen),
    title: "header.footerItem.blog",
    subLink: [],
  },
];

export const genderChildren = (gender: string) => {
  const obj: any = {
    women: [
      {
        id: 1,
        name: "header.womenOdyg",
        slug: "2-zenskaya-odezda",
      },
      {
        id: 2,
        name: "header.womenObuv",
        slug: "145-zenskaya-obuv",
      },
      {
        id: 3,
        name: "header.acsesuars",
        slug: "547-aksessuary",
      },
      {
        id: 100,
        name: "brands.title",
        path: pathFC(paths.brands),
      },
    ],
    men: [
      {
        id: 1,
        name: "header.menOdyg",
        slug: "177-muzskaya-odezda",
      },
      {
        id: 2,
        name: "header.menObuv",
        slug: "284-muzskaya-obuv",
      },
      {
        id: 3,
        name: "header.acsesuars",
        slug: "322-aksessuary",
      },
      {
        id: 100,
        name: "brands.title",
        path: pathFC(paths.brands),
      },
    ],
    kids: [
      {
        id: 1,
        name: "header.girls",
        slug: "418-devockam",
      },
      {
        id: 2,
        name: "header.boys",
        slug: "472-malcikam",
      },
      {
        id: 3,
        name: "header.child",
        slug: "520-novorozdennym",
      },
      {
        id: 100,
        name: "brands.title",
        path: pathFC(paths.brands),
      },
    ],
  };
  return obj[gender];
};
