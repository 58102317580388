import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

//styles
import s from "./ErrorBoundaryComponent.module.scss";

//ui
import Container from "../ui/Containers/container/Contaiter";
import ButtonFrontBack from "../ui/Buttons/ButtonFrontBack/ButtonFrontBack";

//routes
import { paths, pathFC } from "../../App/Routes/paths";

type TErrorComponentProps = {
  error: any;
  resetErrorBoundary: any;
};

const ErrorComponent = ({
  error,
  resetErrorBoundary,
}: TErrorComponentProps) => {
  const { t } = useTranslation();

  const history = useHistory();

  return (
    <main role="alert" className={`${s.main}`}>
      <Container>
        <p>Something went wrong:</p>
        <p>{error.message}</p>
        <div className={s.groupBtn}>
          <ButtonFrontBack
            width={{ xl: "230px" }}
            marginTop={{ smPlus: "25px" }}
            marginRight={{ smPlus: "auto" }}
            marginLeft={{ smPlus: "auto" }}
            mode={"grayWhite"}
            isButton
            onClick={() => resetErrorBoundary()}
            className={s.tryAgain}
          >
            <span className={s.contentBtn}>Try again</span>
          </ButtonFrontBack>
          <ButtonFrontBack
            width={{ xl: "230px" }}
            marginTop={{ smPlus: "25px" }}
            marginRight={{ smPlus: "auto" }}
            marginLeft={{ smPlus: "auto" }}
            mode={"grayWhite"}
            isButton
            onClick={() => history.push(pathFC(paths.main))}
          >
            <span>{t("btnTitle.homePage")}</span>
          </ButtonFrontBack>
        </div>
      </Container>
    </main>
  );
};

export default ErrorComponent;
