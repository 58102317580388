import { useTranslation } from "react-i18next";

//styles
import s from "./ModalBasketOrder.module.scss";

//ui
import BasketOrderItem from "./ModalBasketOrderItem/ModalBasketOrderItem";
import TitleWithLine from "../../../TitleWithLine/TitleWithLine";

type TModalBasketOrderProps = {
  num: number;
  order?: any;
  basket?: any;
};

const ModalBasketOrder = ({ num, order, basket }: TModalBasketOrderProps) => {
  const { t } = useTranslation();

  return (
    <div className={s.root}>
      <TitleWithLine
        title={`${t("basket.order.title")} ${num}`}
        fontSize={{ xl: "16px", lg: "14px" }}
        widthLine={{ xl: "30px" }}
        heightLine={{ xl: "2px" }}
      />

      <div className={s.body}>
        {order?.products?.length > 0 &&
          order.products.map((data: any) => (
            <BasketOrderItem basket={basket} data={data} key={data.id} />
          ))}
      </div>
      <div className={s.totalSum}>
        <span>{t("basket.modals.total")}</span>
        <p>
          {order?.products?.length > 0 &&
            (order.products.reduce(
              (i: number, item: any) => i + item.final_price * item.quantity,
              0
            ) || 0).toFixed(0)}{" "}
          грн
        </p>
      </div>
    </div>
  );
};

export default ModalBasketOrder;
