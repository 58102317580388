import useNativeLazyLoading from "@charlietango/use-native-lazy-loading";
import { useInView } from "react-intersection-observer";

//styles
import s from "./ImageComponentUI.module.scss";

//assets
import noImageAvailable from "../../../assets/images/generals/NoImageAvailable.png";

const LazyImage = ({ width, height, src, alt, className }) => {
  const supportsLazyLoading = useNativeLazyLoading();
  const { ref, inView } = useInView({
    triggerOnce: true,
    // rootMargin: "100% 100%",
    skip: supportsLazyLoading !== false,
  });

  function imgError(image) {
    image.onerror = "";
    image.src = noImageAvailable;
    return true;
  }

  return (
    <div ref={ref} className={s.wrapperImg}>
      {inView || supportsLazyLoading ? (
        <img
          src={src}
          alt={alt}
          width={width}
          height={height}
          loading="lazy"
          className={className}
          onError={({ currentTarget }) => imgError(currentTarget)}
        />
      ) : null}
    </div>
  );
};

export default LazyImage;
