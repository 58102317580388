import { createSlice } from "@reduxjs/toolkit";

//api
import { postRequest } from "../../../API/_api";

//redux
import { setModalWindowStatus } from "../../../redux/App/app";

type TPostQuestionBackProps = {
  obj: any;
  getMethodReloadData: any;
};

export const postQuestionBack =
  (sendData: TPostQuestionBackProps) => (dispatch: any) => {
    const { obj, getMethodReloadData } = sendData;
    dispatch(setLoadingPostQuestionBack(true));

    const success = (data: any) => {
      dispatch(setDataPostQuestionBack(data?.data));
      dispatch(getMethodReloadData);
      dispatch(setModalWindowStatus(false));
    };

    const setError = (error: any) => {
      console.dir(error?.response?.data, "error?.response?.data?.message");
      dispatch(setErrorPostQuestionBack(error?.response?.data));
    };

    postRequest({
      url: `asks`,
      success,
      setError,
      obj,
    });
  };

const initialState: any = {
  dataPostQuestionBack: null,
  loadingPostQuestionBack: false,
  errorPostQuestionBack: null,
};

export const question = createSlice({
  name: "question",
  initialState,
  reducers: {
    setDataPostQuestionBack(state, action) {
      state.dataPostQuestionBack = action.payload;
      state.loadingPostQuestionBack = false;
    },
    setErrorPostQuestionBack(state, action) {
      state.errorPostQuestionBack = action.payload;
      state.loadingPostQuestionBack = false;
    },
    setLoadingPostQuestionBack(state, action) {
      state.loadingPosttQuestionBack = action.payload;
      state.errorPosttQuestionBack = null;
    },
  },
});

export const {
  setDataPostQuestionBack,
  setErrorPostQuestionBack,
  setLoadingPostQuestionBack,
} = question.actions;

export default question.reducer;
