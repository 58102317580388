import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//styles
import s from "./ModalUserSellerChat.module.scss";

//components
import UserSellerChatMessage from "./ModalUserSellerChatMessage/ModalUserSellerChatMessage";
import ModalClose from "../ModalComponents/ModalClose/ModalClose";

//hooks
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux";

//ui
import ButtonFrontBack from "../../Buttons/ButtonFrontBack/ButtonFrontBack";
import TitleWithLine from "../../TitleWithLine/TitleWithLine";
import ErrorText from "../../ErrorText/ErrorText";
import { postMessagesBack } from "../../../../redux/Generals/Messages/messages";
import {
  getMessagesBack,
  setDialogIdMessagesBack,
  setErrorPostMessagesBack,
} from "../../../../redux/Generals/Messages/messages";

const ModalUserSellerChat = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const { modalWindowInfo: chatId } = useAppSelector((state) => state.app);

  const { dataGetMessagesBack, errorPostMessagesBack } = useAppSelector(
    (state) => state.messages
  );

  const [message, setMessage] = useState("");

  useEffect(() => {
    chatId &&
      dispatch(
        setDialogIdMessagesBack({
          order_id: chatId,
        })
      );
    chatId &&
      dispatch(
        getMessagesBack({
          id: chatId,
        })
      );
  }, [chatId]);

  const sendMessage = () => {
    dispatch(
      postMessagesBack({
        obj: {
          recipient_id: 1,
          order_id: chatId,
          text: message.trim(),
        },
        id: chatId,
      })
    );
  };

  return (
    <div className={s.root}>
      <ModalClose />
      <div className={s.wrapperTitle}>
        <TitleWithLine
          title={t("modals.chat.title")}
          fontSize={{ xl: "18px", lg: "14px" }}
          widthLine={{ xl: "60px", lg: "28px" }}
          heightLine={{ xl: "3px", lg: "2px" }}
        />
      </div>
      {errorPostMessagesBack?.message && (
        <ErrorText text={errorPostMessagesBack?.message} />
      )}
      <div className={s.content}>
        <div className={s.back}>
          <FontAwesomeIcon icon={faChevronUp} />
        </div>
        <div className={s.chat}>
          {dataGetMessagesBack?.map((data: any, i: number) => (
            <UserSellerChatMessage data={data} key={i} />
          ))}
        </div>
      </div>

      <div className={s.wrapper}>
        <textarea
          className={s.textarea}
          name=""
          cols={30}
          rows={10}
          placeholder={t("seller.messages.dialogues.text")}
          onChange={(e) => setMessage(e.target.value)}
          onKeyDown={(e) => {
            if (e.code === "Enter") {
              if (message.trim().length > 0) {
                sendMessage();
                setMessage("");
              }
            }
          }}
          value={message}
        />

        <ButtonFrontBack
          width={{ xl: "230px" }}
          marginTop={{ xl: "20px" }}
          marginLeft={{ xl: "auto" }}
          marginRight={{ xl: "auto" }}
          mode={"red"}
          isButton
          onClick={() => {
            if (message.trim().length > 0) {
              sendMessage();
              setMessage("");
            }
          }}
        >
          <span>{t("common.button")}</span>
        </ButtonFrontBack>
      </div>
    </div>
  );
};

export default ModalUserSellerChat;
