//styles
import styles from "./Button.module.scss";

//ui
import StyledDiv from "../../StyledDiv/StyledDiv";

type TButtonFrontBackProps = {
  onClick?: any;
  icon?: string;
  mode?: string;
  className?: string;
  isButton?: boolean;
  marginTop?: any;
  marginRight?: any;
  marginLeft?: any;
  width?: any;
  children?: any;
  disabledLoading?: any;
  maxWidth?: any;
};

const ButtonFrontBack = ({
  onClick,
  className = "",
  icon,
  mode,
  isButton,
  marginTop,
  marginRight,
  marginLeft,
  width,
  children,
  disabledLoading,
  maxWidth,
}: TButtonFrontBackProps) => {
  return (
    <StyledDiv
      maxWidth={maxWidth ? maxWidth : ""}
      width={width ? width : ""}
      marginTop={marginTop ? marginTop : ""}
      marginRight={marginRight ? marginRight : ""}
      marginLeft={marginLeft ? marginLeft : ""}
    >
      {isButton ? (
        <button
          className={`${mode && styles[mode]}  ${styles.button} ${
            disabledLoading ? styles.disabledLoading : ""
          }`}
          onClick={onClick}
          disabled={disabledLoading}
        >
          <ButtonFrontBackBody
            className={className}
            icon={icon}
            children={children}
          />
        </button>
      ) : (
        <div
          className={`${mode && styles[mode]}  ${styles.button}`}
          onClick={onClick}
        >
          <ButtonFrontBackBody
            className={className}
            icon={icon}
            children={children}
          />
        </div>
      )}
    </StyledDiv>
  );
};

const ButtonFrontBackBody = ({
  className = "",
  icon,
  children,
}: TButtonFrontBackProps) => {
  return (
    <>
      <div className={`${styles.front} ${className}`}>
        {icon}
        {children}
      </div>
      {/* <div className={`${styles.back} ${className}`}>
        {icon}
        {children}
      </div> */}
    </>
  );
};

export default ButtonFrontBack;
