import { createSlice, PayloadAction } from "@reduxjs/toolkit";

//api
import { getRequest } from "../../../API/_api";

type sendData = {
  name: string;
  params: { type: string; gender?: string };
};

type TdiscountImagesInitialState = {
  categoriesGendersBack: any;
  loadingCategoriesGendersBack: boolean;
  errorCategoriesGendersBack: any;
};

const initialState: TdiscountImagesInitialState = {
  categoriesGendersBack: {
    women: null,
    men: null,
    kids: null,
  },
  loadingCategoriesGendersBack: false,
  errorCategoriesGendersBack: null,
};

export const getCategoriesGendersBack =
  (sendData: sendData) => (dispatch: any) => {
    const { name, params } = sendData;
    dispatch(setLoadingCategoriesGendersBack(true));
    const success = (data: any) => {
      dispatch(
        successCategoriesGendersBack({
          name: name,
          data: data.data,
        })
      );
    };

    const setError = (error: any) => {
      dispatch(setErrorCategoriesGendersBack(error));
    };

    getRequest({
      url: `categories`,
      success,
      setError,
      params,
    });
  };

export const categoriesAll = createSlice({
  name: "categoriesAll",
  initialState,
  reducers: {
    successCategoriesGendersBack(state, action) {
      state.categoriesGendersBack = {
        women: action.payload.data?.[0],
        men: action.payload.data?.[1],
        kids: action.payload.data?.[2],
      };
      state.loadingCategoriesGendersBack = false;
    },
    setLoadingCategoriesGendersBack(state, action) {
      state.loadingCategoriesGendersBack = action.payload;
      state.errorCategoriesGendersBack = null;
    },
    setErrorCategoriesGendersBack(state, action) {
      state.errorCategoriesGendersBack = action.payload;
      state.loadingCategoriesGendersBack = false;
    },
  },
});
export const {
  successCategoriesGendersBack,
  setLoadingCategoriesGendersBack,
  setErrorCategoriesGendersBack,
} = categoriesAll.actions;

export default categoriesAll.reducer;
