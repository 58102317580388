import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

//styles
import s from "./ModalForgotPassword.module.scss";

//components
import ModalClose from "../ModalComponents/ModalClose/ModalClose";

//ui
import ButtonFrontBack from "../../Buttons/ButtonFrontBack/ButtonFrontBack";
import TitleWithLine from "../../TitleWithLine/TitleWithLine";
import Input from "../../inputs/Input/Input";
import ErrorText from "../../ErrorText/ErrorText";

//hooks
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux";

//utils
import { validationFormSchema } from "../../../../utils/forms/validationFormSchema";

///redux
import { postPostUserForgotPasswordBack } from "../../../../redux/User/userForgotPassword";

const nameFields = {
  email: "email",
};

const ModalForgotPassword = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const {
    dataPostUserForgotPasswordBack,
    loadingPostUserForgotPasswordBack,
    errorPostUserForgotPasswordBack,
  } = useAppSelector((state) => state.userForgotPassword);

  const [message, setMessage] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(validationFormSchema(t, nameFields)),
  });

  const onSubmit = (data: any) => {
    const cb = () => {
      setSuccess(true);
      setError(false);
    };
    const cbError = () => {
      setError(true);
      setSuccess(false);
    };
    dispatch(
      postPostUserForgotPasswordBack({
        obj: {
          email: data?.email,
        },
        cb,
        cbError,
      })
    );
  };

  return (
    <div className={s.root}>
      <ModalClose />
      <div className={s.wrapperTitle}>
        <TitleWithLine
          title={t("modals.password.title")}
          fontSize={{ xl: "18px", lg: "14px" }}
          widthLine={{ xl: "60px", lg: "28px" }}
          heightLine={{ xl: "3px", lg: "2px" }}
        />
      </div>
      <div className={s.wrapper}>
        {success && (
          <p className={s.done}>
            <span>{t("modals.password.success")}</span>
          </p>
        )}
        {error && <ErrorText text={t("generals.sendInstructionOnEmail")} />}
        <p className={s.description}>{t("modals.password.description")}.</p>
        <form className={s.form} onSubmit={handleSubmit(onSubmit)}>
          <Input
            width={{ xl: "100%" }}
            marginTop={{ xl: "20px" }}
            id={nameFields.email}
            name={nameFields.email}
            type="text"
            placeholder={"E-mail"}
            required
            error={errors?.[nameFields.email]}
            errorFromBack={errorPostUserForgotPasswordBack}
            register={register}
          />
          <div className={s.groupsBtn}>
            <ButtonFrontBack
              width={{ xl: "230px" }}
              marginTop={{ xl: "15px" }}
              mode={"red"}
              isButton
              disabledLoading={loadingPostUserForgotPasswordBack}
            >
              <span>{t("common.button")}</span>
            </ButtonFrontBack>
          </div>
        </form>
        <p
          className="modal-confirm__text"
          style={{ color: "#c42727", textAlign: "center" }}
        >
          {message &&
            "Сервіс тимчасово недоступний. Для зміни пароля зверніться до адміністратора сайту"}
        </p>
      </div>
    </div>
  );
};

export default ModalForgotPassword;
