import React, { ReactNode } from "react";
import { SDContainer } from "./styles";

interface ContainerProps {
	children: ReactNode;
}

const Container: React.FC<ContainerProps> = ({ children }) => {
	return <SDContainer>{children}</SDContainer>;
};

export default Container;
