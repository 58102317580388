import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";

//styles
import s from "./SliderBtns.module.scss";

const SliderBtnPrev = ({
  className,
  classNameSliderBtn,
  ref,
  styles = {}
}: any): JSX.Element => {
  return (
    <div
      className={`${s.sliderButton} ${s.sliderNext} ${className} ${classNameSliderBtn}`}
      style = {styles}
      ref={ref}
    >
      <FontAwesomeIcon icon={faChevronLeft} />
    </div>
  );
};

export default SliderBtnPrev;
