import { z } from "zod";

export const getFieldsDefaultValue = (fields) =>
	fields.reduce((acc, el) => {
		acc[el.key] = el.defaultValue;

		return acc;
	}, {});

export const getFieldsSchema = (fields) =>
	z.object(
		fields.reduce((acc, el) => {
			acc[el.key] = el.schema;

			return acc;
		}, {}),
	);
