import { createSlice } from "@reduxjs/toolkit";

//api
import { getRequest, putRequest, postRequest } from "../../../../API/_api";

//redux
import {
  setModalWindowChildrenType,
  setModalWindowStatus,
  setModalWindowInfo,
} from "../../../App/app";

export const getSellerSettingsDeliveryBack = () => (dispatch: any) => {
  dispatch(setLoadingGetSellerSettingsDeliveryBack(true));

  const success = (data: any) => {
    dispatch(setDataGetSellerSettingsDeliveryBack(data?.data));
  };

  const setError = (error: any) => {
    dispatch(setErrorGetSellerSettingsDeliveryBack(error));
  };

  getRequest({
    url: `seller/delivery-available`,
    success,
    setError,
  });
};

type TPostSellerSettingsDeliveryBackProps = {
  obj: {
    ids: number[];
  };
};

export const postSellerSettingsDeliveryBack =
  (sendData: TPostSellerSettingsDeliveryBackProps) => (dispatch: any) => {
    const { obj } = sendData;
    dispatch(setLoadingPostSellerSettingsDeliveryBack(true));

    const success = (data: any) => {
      dispatch(setDataPostSellerSettingsDeliveryBack(data?.data));
      dispatch(getSellerSettingsDeliveryBack());
    };

    const setError = (error: any) => {
      dispatch(setErrorPostSellerSettingsDeliveryBack(error?.response?.data));
    };

    postRequest({
      url: `seller/delivery-select`,
      success,
      setError,
      obj,
    });
  };

type TPutSellerSettingsDeliveryBackProps = {
  obj: any;
};

export const putSellerSettingsDeliveryBack =
  (sendData: TPutSellerSettingsDeliveryBackProps) => (dispatch: any) => {
    const { obj } = sendData;
    dispatch(setLoadingPutSellerSettingsDeliveryBack(true));

    const success = (data: any) => {
      dispatch(setDataPutSellerSettingsDeliveryBack(data?.data));
      dispatch(setModalWindowChildrenType("formSuccess"));
      dispatch(
        setModalWindowInfo({
          title: "common.modals",
        })
      );
      dispatch(setModalWindowStatus(true));
    };

    const setError = (error: any) => {
      dispatch(setErrorPutSellerSettingsDeliveryBack(error?.response?.data));
    };

    putRequest({
      url: `seller/delivery-data`,
      success,
      setError,
      obj,
    });
  };

const initialState: any = {
  dataGetSellerSettingsDeliveryBack: null,
  loadingGetSellerSettingsDeliveryBack: false,
  errorGetSellerSettingsDeliveryBack: null,

  dataPutSellerSettingsDeliveryBack: null,
  loadingPutSellerSettingsDeliveryBack: false,
  errorPutSellerSettingsDeliveryBack: null,

  dataPostSellerSettingsDeliveryBack: null,
  loadingPostSellerSettingsDeliveryBack: false,
  errorPostSellerSettingsDeliveryBack: null,
};

export const sellerSettingsDelivery = createSlice({
  name: "sellerSettingsDelivery",
  initialState,
  reducers: {
    setDataGetSellerSettingsDeliveryBack(state, action) {
      state.dataGetSellerSettingsDeliveryBack = action.payload;
      state.loadingGetSellerSettingsDeliveryBack = false;
    },
    setErrorGetSellerSettingsDeliveryBack(state, action) {
      state.errorGetSellerSettingsDeliveryBack = action.payload;
      state.loadingGetSellerSettingsDeliveryBack = false;
    },
    setLoadingGetSellerSettingsDeliveryBack(state, action) {
      state.loadingGetSellerSettingsDeliveryBack = action.payload;
      state.errorGetSellerSettingsDeliveryBack = null;
      state.errorPutSellerSettingsDeliveryBack = null;
    },
    setDataPutSellerSettingsDeliveryBack(state, action) {
      state.dataPutSellerSettingsDeliveryBack = action.payload;
      state.loadingPutSellerSettingsDeliveryBack = false;
    },
    setErrorPutSellerSettingsDeliveryBack(state, action) {
      state.errorPutSellerSettingsDeliveryBack = action.payload;
      state.loadingPutSellerSettingsDeliveryBack = false;
    },
    setLoadingPutSellerSettingsDeliveryBack(state, action) {
      state.loadingPutSellerSettingsDeliveryBack = action.payload;
      state.errorPutSellerSettingsDeliveryBack = null;
    },

    setDataPostSellerSettingsDeliveryBack(state, action) {
      state.dataPostSellerSettingsDeliveryBack = action.payload;
      state.loadingPostSellerSettingsDeliveryBack = false;
    },
    setErrorPostSellerSettingsDeliveryBack(state, action) {
      state.errorPostSellerSettingsDeliveryBack = action.payload;
      state.loadingPostSellerSettingsDeliveryBack = false;
    },
    setLoadingPostSellerSettingsDeliveryBack(state, action) {
      state.loadingPostSellerSettingsDeliveryBack = action.payload;
      state.errorPosttSellerSettingsDeliveryBack = null;
    },
  },
});

export const {
  setLoadingGetSellerSettingsDeliveryBack,
  setErrorGetSellerSettingsDeliveryBack,
  setDataGetSellerSettingsDeliveryBack,
  setDataPutSellerSettingsDeliveryBack,
  setErrorPutSellerSettingsDeliveryBack,
  setLoadingPutSellerSettingsDeliveryBack,
  setDataPostSellerSettingsDeliveryBack,
  setErrorPostSellerSettingsDeliveryBack,
  setLoadingPostSellerSettingsDeliveryBack,
} = sellerSettingsDelivery.actions;

export default sellerSettingsDelivery.reducer;
