type TLanguageData = {
	key: string;
	label: string;
	language: string;
	slugLang: null | string;
	mainLang: boolean;
};

type TLanguagesData = Array<TLanguageData>;

const ruLang = {
	key: "ru",
	label: "rus",
	language: "Russian",
	slugLang: "ru",
	mainLang: false,
};

export const ukLang = {
	key: "uk",
	label: "ukr",
	language: "Ukrainian",
	slugLang: "",
	mainLang: true,
};
export const languageData: TLanguagesData = [ruLang, ukLang];

export const mainLanguageData: TLanguageData = ukLang;

export const withoutSlugLanguageData: TLanguageData = ruLang;

export const langsData: any = {
	ua: ukLang,
	uk: ukLang,
	ru: ruLang,
};

export const slugLangObj: any = {
	ua: ukLang,
	uk: ukLang,
	ru: ruLang,
};
