// +
// import CheckIcon from "/public/icons/generals/check.svg";
import { SDCheckbox, SDBGActiveIcon, SDBGNonActiveIcon } from "./styles";

const CheckboxComponent = ({ item, value, onChange, idField }) => (
	<SDCheckbox
		id={idField}
		name={item.fieldName}
		onChange={(e) => onChange({ e })}
		checked={value}
		icon={<SDBGNonActiveIcon />}
		checkedIcon={
			<SDBGActiveIcon>
				{/* <CheckIcon /> */}
			</SDBGActiveIcon>
		}
	/>
);

export default CheckboxComponent;
