export const formatedCallbackUrl = (args) => {
	const callbackUrl = args.callbackUrl || ""
	const includesArray = args.includesArray || []
	const path = args.path || ""

	if (
		includesArray.includes(window.location.pathname)
	) {
		return path;
	}

	return `${path}?callbackUrl=${
		callbackUrl || `${window.location.pathname}${window.location.search}`
	}`;
};
