import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

//styles
import s from "./ModalResetPassword.module.scss";

//components
import ModalClose from "../ModalComponents/ModalClose/ModalClose";

//hooks
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux";

//ui
import ButtonFrontBack from "../../Buttons/ButtonFrontBack/ButtonFrontBack";
import TitleWithLine from "../../TitleWithLine/TitleWithLine";
import Input from "../../inputs/Input/Input";
import ErrorText from "../../ErrorText/ErrorText";

//utils
import { validationFormSchema } from "../../../../utils/forms/validationFormSchema";

//redux
import { postPostUserResetPasswordBack } from "../../../../redux/User/userResetPassword";

const nameFields = {
  email: "email",
  passwordRegister: "passwordRegister",
  passwordRegisterConfirmation: "passwordRegisterConfirmation",
};

const ModalResetPassword = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const { modalWindowInfo } = useAppSelector((state) => state.app);

  const { loadingPostUserResetPasswordBack, errorPostUserResetPasswordBack } =
    useAppSelector((state) => state.userResetPassword);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(validationFormSchema(t, nameFields)),
  });

  const onSubmit = (data: any) => {
    dispatch(
      postPostUserResetPasswordBack({
        obj: {
          token: modalWindowInfo?.token,
          email: data.email,
          password: data.passwordRegister,
          password_confirmation: data.passwordRegisterConfirmation,
        },
      })
    );
  };

  return (
    <div className={s.root}>
      <ModalClose />
      <div className={s.wrapperTitle}>
        <TitleWithLine
          title={t("modals.password.title")}
          fontSize={{ xl: "18px", lg: "14px" }}
          widthLine={{ xl: "60px", lg: "28px" }}
          heightLine={{ xl: "3px", lg: "2px" }}
        />
      </div>
      <p className={s.text}> {t("modals.verify.message")}</p>
      {errorPostUserResetPasswordBack && (
        <ErrorText text={t("password.error.messageCommon")} />
      )}
      <div className={s.wrapper}>
        <form className={s.form} onSubmit={handleSubmit(onSubmit)}>
          <Input
            width={{ xl: "100%" }}
            marginTop={{ xl: "10px" }}
            id={nameFields.email}
            name={nameFields.email}
            type="text"
            placeholder={"E-mail"}
            required
            error={errors?.email}
            register={register}
          />

          <Input
            width={{ xl: "100%" }}
            marginTop={{ xl: "10px" }}
            id={nameFields.passwordRegister}
            name={nameFields.passwordRegister}
            type="text"
            placeholder={t("common.forms.password")}
            required
            error={errors?.passwordRegister}
            register={register}
          />
          <Input
            width={{ xl: "100%" }}
            marginTop={{ xl: "10px" }}
            id={nameFields.passwordRegisterConfirmation}
            name={nameFields.passwordRegisterConfirmation}
            type="text"
            placeholder={t("common.forms.repeatPassword")}
            required
            error={errors?.passwordRegisterConfirmation}
            register={register}
          />
          <ButtonFrontBack
            width={{ xl: "230px" }}
            marginTop={{ xl: "15px" }}
            marginRight={{ xl: "auto" }}
            marginLeft={{ xl: "auto" }}
            mode={"red"}
            isButton
            disabledLoading={loadingPostUserResetPasswordBack}
          >
            <span>{t("modals.verify.button")}</span>
          </ButtonFrontBack>
        </form>
      </div>
    </div>
  );
};

export default ModalResetPassword;
