import React from "react";
import useMediaQuery from "@mui/material/useMediaQuery";

//styles
import s from "./Header.module.scss";
import { BREAKPOINTS_NAME } from "../../../theme/breakpoints/index";

//components
import HeaderTop from "./HeaderTop/HeaderTop";
import HeaderBody from "./HeaderBody/HeaderBody";
import HeaderBottom from "./HeaderBottom/HeaderBottom";

const Header: React.FC = () => {
	const isUpTabletMinus = useMediaQuery((theme: any) =>
		theme.breakpoints.up(BREAKPOINTS_NAME.tabletMinus),
	);
	return (
		<header className={s.root}>
			{!isUpTabletMinus && <div style={{ height: "50px" }}></div>}
			{isUpTabletMinus && <HeaderTop />}

			<HeaderBody />
			<HeaderBottom />
		</header>
	);
};

export default Header;
