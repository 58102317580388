import React from "react";
import { useTranslation } from "react-i18next";
import { faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//styles
import s from "./SelectInput.module.scss";

//components
import StyledDiv from "../../StyledDiv/StyledDiv";

//ts
import { TMediaQuery } from "../../../../TS/types/generals/generals";

type TSelectInputProps = {
  width: TMediaQuery;
  maxWidth?: TMediaQuery;
  marginTop?: TMediaQuery;
  marginRight?: TMediaQuery;
  label?: any;
  name?: string;
  className?: string;
  errorFromBack?: any;
  required?: boolean;
  error?: any;
  register?: any;
  defaultOption?: any;
  options?: any;
  setValue: any;
  value?: any;
  sendTitle?: boolean;
  validation?: any;
};

const SelectInput = ({
  label,
  options,
  maxWidth,
  register,
  required,
  setValue,
  defaultOption,
  width,
  name,
  error,
  value,
  className,
  marginRight,
  marginTop,
  errorFromBack,
  sendTitle,
  validation,
}: TSelectInputProps) => {
  const { t } = useTranslation();

  const [option, setOption] = React.useState<{
    title: string;
    value: any;
  } | null>(() => options?.[0]);

  const [visible, setVisible] = React.useState(false);

  const onSetOptionHandler = (el: any) => {
    setOption(el);
    setVisible(false);
  };
  React.useEffect(() => {
    register && register(name, validation);
  }, []);

  React.useEffect(() => {
    value &&
      setOption(() => {
        const findOption = options.find((item: any) => {
          return t(item?.title) === value;
        });
        return {
          title: t(findOption?.title),
          value: findOption?.value,
        };
      });
  }, [value]);

  React.useEffect(() => {
    if (!!option) {
      const findOption = sendTitle ? option?.title : option?.value;

      setValue &&
        name &&
        setValue(name, findOption, {
          shouldValidate: true,
        });
      setValue && !name && setValue(findOption);
    }
  }, [option]);

  return (
    <StyledDiv
      width={width}
      maxWidth={maxWidth}
      marginRight={marginRight}
      marginTop={marginTop}
      onClick={() => setVisible((prev) => !prev)}
      className={s.styledDiv}
      onMouseEnter={() =>
        setTimeout(() => {
          setVisible(() => true);
        }, 10)
      }
      onMouseLeave={() =>
        setTimeout(() => {
          setVisible(() => false);
        }, 10)
      }
    >
      <div className={`${s.root} ${className}`}>
        <div className={s.label}>
          {label} {required && <span className={s.required}>*</span>}
        </div>
        <div
          className={`${s.select}  ${visible ? s.active : ""} ${
            !!error || errorFromBack ? s.error : ""
          }`}
        >
          <span>
            {t(
              option?.title
                ? option?.title
                : defaultOption
                ? defaultOption
                : "select"
            )}
          </span>
          {options.length > 0 && (
            <FontAwesomeIcon
              icon={faChevronUp}
              className={`${s.arrow}  ${visible ? s.reverse : ""}`}
            />
          )}
        </div>
      </div>
      {visible && options.length > 0 && (
        <div className={s.dropdown}>
          {options &&
            options?.map((el: any, index: number) => (
              <div
                key={index}
                className={s.option}
                onClick={() => {
                  onSetOptionHandler({
                    title: t(el.title),
                    value: el.value,
                  });
                  setVisible((prev) => !prev);
                }}
              >
                {el?.title ? t(el.title) : el.value}
              </div>
            ))}
        </div>
      )}
    </StyledDiv>
  );
};

export default SelectInput;
