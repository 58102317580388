import React from "react";
import useMediaQuery from "@mui/material/useMediaQuery";

//styles
import s from "./HeaderTop.module.scss";
import breakpoints from "../../../../styles/vars";

//components
import HeaderTopAdvantages from "./HeaderTopAdvantages/HeaderTopAdvantages";

//ui common
import { SwitchLanguage } from "../../SwitchLanguage/SwitchLanguage";

//ui
import Container from "../../../ui/Containers/container/Contaiter";

//db
import { soicialsLink } from "../../../../db/generals/generalsData";

import { BREAKPOINTS_NAME } from "../../../../theme/breakpoints/index";

const HeaderTop: React.FC = () => {
	const isUpLaptop = useMediaQuery((theme: any) =>
		theme.breakpoints.up(BREAKPOINTS_NAME.laptop),
	);

	return (
		<div className={s.root}>
			<Container>
				<div className={s.wrapper}>
					<div className={s.socialsWrapper}>
						{soicialsLink?.length > 0 &&
							soicialsLink.map((soicialLink, index: number) => {
								return (
									<a
										href={soicialLink.src}
										target="_blank"
										rel="noreferrer"
										key={index}
										className={s.socialLink}
									>
										<span
											dangerouslySetInnerHTML={{
												__html: soicialLink.svg,
											}}
											className={s.socialSvg}
										/>
									</a>
								);
							})}
					</div>
					{isUpLaptop && <HeaderTopAdvantages />}
					<div className={s.languagesWrapper}>
						<SwitchLanguage
							className={s.languageBtn}
							classNameActive={s.activeBtn}
							filterLang={false}
						/>
					</div>
				</div>
			</Container>
		</div>
	);
};

export default HeaderTop;
