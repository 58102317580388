import { createSlice } from "@reduxjs/toolkit";

//api
import { getRequest, putRequest } from "../../../../API/_api";

//redux
import {
  setModalWindowChildrenType,
  setModalWindowStatus,
  setModalWindowInfo,
} from "../../../App/app";

export const getSellerSettingsDataReturnBack = () => (dispatch: any) => {
  dispatch(setLoadingGetSellerSettingsDataReturnBack(true));

  const success = (data: any) => {
    dispatch(setDataGetSellerSettingsDataReturnBack(data?.data));
  };

  const setError = (error: any) => {
    dispatch(setErrorGetSellerSettingsDataReturnBack(error));
  };

  getRequest({
    url: `seller/refund-data`,
    success,
    setError,
  });
};

type TPutSellerSettingsDataReturnBackProps = {
  obj: any;
};

export const putSellerSettingsDataReturnBack =
  (sendData: TPutSellerSettingsDataReturnBackProps) => (dispatch: any) => {
    const { obj } = sendData;
    dispatch(setLoadingPutSellerSettingsDataReturnBack(true));

    const success = (data: any) => {
      dispatch(setDataPutSellerSettingsDataReturnBack(data?.data));
      dispatch(setModalWindowChildrenType("formSuccess"));
      dispatch(
        setModalWindowInfo({
          title: "common.modals",
        })
      );
      dispatch(setModalWindowStatus(true));
    };

    const setError = (error: any) => {
      dispatch(setErrorPutSellerSettingsDataReturnBack(error?.response?.data));
    };

    putRequest({
      url: `seller/refund-data`,
      success,
      setError,
      obj,
    });
  };

const initialState: any = {
  dataGetSellerSettingsDataReturnBack: null,
  loadingGetSellerSettingsDataReturnBack: false,
  errorGetSellerSettingsDataReturnBack: null,
  dataPutSellerSettingsDataReturnBack: null,
  loadingPutSellerSettingsDataReturnBack: false,
  errorPutSellerSettingsDataReturnBack: null,
};

export const sellerSettingsDataReturn = createSlice({
  name: "sellerSettingsDataReturn",
  initialState,
  reducers: {
    setDataGetSellerSettingsDataReturnBack(state, action) {
      state.dataGetSellerSettingsDataReturnBack = action.payload;
      state.loadingGetSellerSettingsDataReturnBack = false;
    },
    setErrorGetSellerSettingsDataReturnBack(state, action) {
      state.errorGetSellerSettingsDataReturnBack = action.payload;
      state.loadingGetSellerSettingsDataReturnBack = false;
    },
    setLoadingGetSellerSettingsDataReturnBack(state, action) {
      state.loadingGetSellerSettingsDataReturnBack = action.payload;
      state.errorGetSellerSettingsDataReturnBack = null;
      state.errorPutSellerSettingsDataReturnBack = null;
    },
    setDataPutSellerSettingsDataReturnBack(state, action) {
      state.dataPutSellerSettingsDataReturnBack = action.payload;
      state.loadingPutSellerSettingsDataReturnBack = false;
    },
    setErrorPutSellerSettingsDataReturnBack(state, action) {
      state.errorPutSellerSettingsDataReturnBack = action.payload;
      state.loadingPutSellerSettingsDataReturnBack = false;
    },
    setLoadingPutSellerSettingsDataReturnBack(state, action) {
      state.loadingPutSellerSettingsDataReturnBack = action.payload;
      state.errorPutSellerSettingsDataReturnBack = null;
    },
  },
});

export const {
  setLoadingGetSellerSettingsDataReturnBack,
  setErrorGetSellerSettingsDataReturnBack,
  setDataGetSellerSettingsDataReturnBack,
  setDataPutSellerSettingsDataReturnBack,
  setErrorPutSellerSettingsDataReturnBack,
  setLoadingPutSellerSettingsDataReturnBack,
} = sellerSettingsDataReturn.actions;

export default sellerSettingsDataReturn.reducer;
