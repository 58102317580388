import { useMediaQuery } from "react-responsive";

//styles
import s from "./ProductCardSlider.module.scss";
import breakpoints from "../../../styles/vars";

//ui
import ImageComponentUI from "../../ui/ImageComponentUI/ImageComponentUI";

//types
import { TMedia } from "../../../TS/types/generals/generals";

//hooks
import { useAppDispatch } from "../../../hooks/redux";

//utils
import { isBaseURLSingleInImage } from "../../../utils/generals/isBaseURLSingleInImage";

//redux
import {
  setModalWindowChildrenType,
  setModalWindowStatus,
  setModalWindowInfo,
} from "../../../redux/App/app";

type TProductCardSlideProps = {
  data: string;
  media: Array<TMedia> | [] | null;
  i: number;
  images: Array<string>;
  alt: string | null;
  strictSizesImg?: boolean;
};

const ProductCardSlide = ({
  data,
  media,
  i,
  images,
  alt,
  strictSizesImg,
}: TProductCardSlideProps) => {
  const dispatch = useAppDispatch();

  const md = useMediaQuery({ maxWidth: breakpoints.md });

  return (
    <div
      className={`${s.productImgSlider} ${
        strictSizesImg ? s.strictSizesImg : ""
      }`}
      onClick={() => {
        dispatch(setModalWindowStatus(true));
        dispatch(setModalWindowChildrenType("zoomImg"));
        dispatch(
          setModalWindowInfo({
            index: i,
            images: images,
            alt: alt,
            media: media,
          })
        );
      }}
    >
      {data && (
        <ImageComponentUI
          src={isBaseURLSingleInImage(data)}
          media={media || null}
          size={md ? "md" : "lg"}
          index={i}
          images={images}
          alt={alt}
        />
      )}
    </div>
  );
};

export default ProductCardSlide;
