import { useHistory, useLocation } from "react-router-dom";

//hooks
import { useAppSelector, useAppDispatch } from "../../../hooks/redux";

//redux
import { setLanguage } from "../../../redux/App/app";

//db
import { languageData } from "../../../db/generals/languageData";
import { languageInitialization } from "../../../utils/language/languageInitialization";

type TSwitchLanguageProps = {
  className: string;
  classNameActive: string;
  filterLang: boolean;
};

export const SwitchLanguage = ({
  className,
  classNameActive,
  filterLang,
}: TSwitchLanguageProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const location = useLocation();

  const history = useHistory();

  const language = useAppSelector((state) => state.app.language);

  const changeLanguage = (language: string): void => {
    localStorage.setItem("language_sh", language);
    dispatch(setLanguage(language));
    languageInitialization(language, location, history, true);
  };

  return (
    <>
      {languageData.map(({ key = "", label = "" }) => {
        if (filterLang) {
          return key !== language ? (
            <LanguageBtn
              key={key}
              keyLang={key}
              label={label}
              lang={language}
              changeLanguage={changeLanguage}
              className={className}
              classNameActive={classNameActive}
            />
          ) : null;
        } else {
          return (
            <LanguageBtn
              key={key}
              keyLang={key}
              label={label}
              lang={language}
              changeLanguage={changeLanguage}
              className={className}
              classNameActive={classNameActive}
            />
          );
        }
      })}
    </>
  );
};

type TLanguageBtnProps = {
  keyLang: string;
  label: string;
  lang: string;
  changeLanguage: (language: string) => void;
  className: string;
  classNameActive: string;
};

const LanguageBtn = ({
  keyLang,
  label,
  lang,
  changeLanguage,
  className,
  classNameActive,
}: TLanguageBtnProps) => {
  return (
    <div
      className={`${className} ${lang === keyLang && classNameActive}`}
      onClick={() => changeLanguage(keyLang)}
    >
      {label}
    </div>
  );
};
