"use client";

/* eslint-disable @typescript-eslint/no-empty-function */

import { useTranslation } from "react-i18next";
import { FormProvider, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

//assets
import { checkIco, errorIco } from "../../../../assets/icons/iconsConstants";
import { fieldsDefaultValue, fields, schema } from "./config";

import {
	sx,
	SDRoot,
	SDGroupsBtn,
	SDWrapper,
	SDTextDone,
	SDError,
	SDDescription,
	SDRepeat,
} from "./styles";

//hooks
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux";

//utils
import TitleWithLine from "../../../../ui/titleWithLine";
import MainBtn from "../../../../ui/buttons/mainBtn";
import ControllersFieldsHookForm from "../../../controllersFieldsHookForm/index";

//redux
import {
	resendCodeOnPhone,
	profileVerifyPhoneCode,
} from "../../../../redux/User/user";

// loc-text

const PhoneVerify = ({
	optionsTags = {
		root: {},
	},
	handleClose,
	cbProfileVerifyPhoneCode,
}) => {
	const { t } = useTranslation();

	const dispatch = useAppDispatch();

	const { errorVerifyPhoneCode } = useAppSelector((state) => state.user);

	const { token } = useAppSelector((state) => state.app);

	const methodsForm = useForm({
		resolver: zodResolver(schema),
		defaultValues: fieldsDefaultValue,
	});

	const onSubmit = (data) => {
		dispatch(
			profileVerifyPhoneCode({
				obj: {
					phone_verify_code: data.code,
				},
				cb: () => {
					cbProfileVerifyPhoneCode();
					handleClose();
				},
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}),
		);
	};

	return (
		<SDRoot {...optionsTags?.root}>
			<TitleWithLine
				title={t("profilePersonal.verifyNumber")}
				optionsTags={{
					root: {
						sx: {
							justifyContent: "center",
						},
					},
				}}
			/>
			<SDWrapper>
				{errorVerifyPhoneCode && (
					<SDError>
						<img
							src={errorIco}
							alt="error"
						/>
						<span>{t("verify.messageError")}</span>
					</SDError>
				)}
				<SDTextDone>
					<img
						src={checkIco}
						alt="checkIco"
					/>
					<span>{t("modals.verify.success")}</span>
				</SDTextDone>
				<SDDescription>{t("modals.verify.repeat")}</SDDescription>

				<FormProvider {...methodsForm}>
					<ControllersFieldsHookForm
						configFields={fields.map((el) => ({
							...el,
							placeholder: el?.placeholder
								? t(el?.placeholder)
								: "",
						}))}
						isProvider
						handleSubmit={methodsForm.handleSubmit(onSubmit)}
					/>
				</FormProvider>
				<SDGroupsBtn>
					<SDRepeat
						onClick={() =>
							resendCodeOnPhone("phone", {
								Authorization: `Bearer ${token}`,
							})
						}
					>
						{t("modals.password.repeatCode")}
					</SDRepeat>
					<MainBtn
						text={t("common.button")}
						onClick={methodsForm.handleSubmit(onSubmit)}
						optionsTags={{
							wrapper: { sx: sx.pMainBtn.wrapper },
							btn: {
								variant: "contained_ee4a2e_ee4a2e_ffffff",
								sx: sx.pMainBtn.btn,
							},
						}}
					/>
				</SDGroupsBtn>
			</SDWrapper>
		</SDRoot>
	);
};
export default PhoneVerify;
