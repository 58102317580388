import styled from "@mui/system/styled";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { BREAKPOINTS_NAME } from "../../theme/breakpoints/index";

// Define styled components
export const SDRoot = styled("div")(({ theme }) => ({
	display: "flex",
	alignItems: "center",
	position: "relative",
}));

export const SDLine = styled(Divider)(({ theme }) => ({
	backgroundColor: theme.palette.customColors.bg.bg_3,
	borderRadius: "5px",
	width: "60px",
	height: "3px",
	[theme.breakpoints.down(BREAKPOINTS_NAME.laptop)]: {
		width: "28px",
		height: "2px",
	},
}));

export const SDTitle = styled(Typography)(({ theme }) => ({
	fontWeight: "bold",
	letterSpacing: "0.1em",
	textTransform: "uppercase",
	color: theme.palette.customColors.text.t_3,
	marginLeft: "20px",
	fontSize: "18px",
	[theme.breakpoints.down(BREAKPOINTS_NAME.laptop)]: {
		fontSize: "16px",
		marginLeft: "10px",
	},
	[theme.breakpoints.down(BREAKPOINTS_NAME.tabletMinus)]: {
		fontSize: "14px",
	},
}));

export const SDImg = styled("img")(({ theme }) => ({
	position: "absolute",
	left: "-15px",
	top: "9px",
	[theme.breakpoints.down(BREAKPOINTS_NAME.laptop)]: {
		position: "relative",
		left: 0,
		top: 0,
		marginLeft: "20px",
		width: "40px",
		height: "40px",
	},
}));
