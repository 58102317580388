import React, { useEffect, useLayoutEffect, useState, Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ErrorBoundary } from "react-error-boundary";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

//styles
import "./App.scss";

// import "swiper/swiper.scss"; // core Swiper
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import "swiper/css/lazy";

//components
import ErrorComponent from "../components/ErrorBoundaryComponent/ErrorBoundaryComponent";

import Page404 from "../screens/404";

//ui common
import Header from "../components/common/Header/Header";
import Footer from "../components/common/Footer/Footer";
import MinimalHeader from "../components/common/MinimalHeader/MinimalHeader";
import MinimalFooter from "../components/common/MinimalFooter/MinimalFooter";

//ui
import { selectModal } from "../components/ui/ModalWindows/selectModal";
import ModalWrapper from "../components/ui/ModalWindows/ModalWrapper/ModalWrapper";
import MiniPreloader from "../components/ui/MiniPreloader/MiniPreloader";

//app
import {
	publicRoutes,
	privateCustomerRoutes,
	privateSellerRoutes,
} from "./Routes/routes";

//hooks
import { useAppDispatch, useAppSelector } from "../hooks/redux";

//utils
import useScrollBlock from "../utils/generals/useScrollBlock";
import { getSelectedLang } from "../utils/language/getSelectedLang";

//routes
import { paths, pathFC } from "./Routes/paths";

//db
import { ukLang } from "../db/generals/languageData";
import { sellerSlugs } from "../db/Seller/sellerMenuData";

//redux
import { addWishlistProduct } from "../redux/FavouriteWishList/favouriteWishList";

import { getUser } from "../redux/User/user";
import { setLanguage } from "../redux/App/app";
import {
	setModalWindowInfo,
	setModalWindowChildrenType,
	setModalWindowStatus,
} from "../redux/App/app";
import { getMessagesBack } from "../redux/Generals/Messages/messages";
import { getRawCategoriesBack } from "../redux/Generals/Categories/RawCategories";
import ModalCustom from "../models/modal";

const tokenShopping = localStorage.getItem("token_shopping");

Sentry.init({
	dsn: "https://5d638d11933847998d7aac74a21eaca9@o1081602.ingest.sentry.io/6089204",
	integrations: [new Integrations.BrowserTracing()],
	tracesSampleRate: 1.0,
});

const App: React.FC = () => {
	const dispatch = useAppDispatch();
	const [blockScroll, allowScroll] = useScrollBlock();
	const [, i18n] = useTranslation();
	const location = useLocation();
	const history = useHistory();

	const singlePage = [pathFC(paths.checkout), pathFC(paths.basket)];

	//SELECTORS
	const { modalWindowStatus, modalWindowChildrenType } = useAppSelector(
		(state) => state.app,
	);
	const { userData } = useAppSelector((state) => state.user);
	const { dialogIdMessagesBack } = useAppSelector((state) => state.messages);

	// STATES
	const [intervalId, setIntervalId] = useState<any>();

	// FUNCTIONS
	const detectLanguage = () => {
		const selectedLang = getSelectedLang();

		const changeLanguageApp = async (lang: any) => {
			dispatch(setLanguage(lang.key));
			i18n.changeLanguage(lang.key);

			document.documentElement.lang = lang.key;
		};

		if (selectedLang?.slugLang) {
			changeLanguageApp(selectedLang);
		} else {
			changeLanguageApp(ukLang);
		}
	};

	useEffect(() => {
		if (modalWindowStatus) blockScroll();
		if (!modalWindowStatus) allowScroll();
	}, [modalWindowStatus]);

	const logError = (error: any, errorInfo: any) => {
		console.log({ error, errorInfo });
	};

	useEffect(() => {
		if (userData) {
			localStorage.removeItem("favouriteProducts");
			dispatch(addWishlistProduct(userData?.favorite_products));
		}
	}, [userData]);

	useEffect(() => {
		detectLanguage();
		if (tokenShopping && !userData) {
			dispatch(getUser());
		}

		let searchParams = new URLSearchParams(location.search);
		if (searchParams.get("token")) {
			dispatch(
				setModalWindowInfo({
					token: searchParams.get("token"),
				}),
			);
			dispatch(setModalWindowChildrenType("resetPassword"));
			dispatch(setModalWindowStatus(true));
		}
	}, []);

	useEffect(() => {
		if (localStorage.getItem("favouriteProducts")) {
			let favouriteProducts: any =
				localStorage.getItem("favouriteProducts");
			if (favouriteProducts) {
				favouriteProducts = JSON.parse(favouriteProducts);
				dispatch(
					addWishlistProduct(
						favouriteProducts?.filter((item: any) => item),
					),
				);
			}
		}

		dispatch(getRawCategoriesBack());
	}, []);

	// redirect old /ua/ to /
	useLayoutEffect(() => {
		const location = window.location;
		const path = location.pathname;
		const search = location.search;

		const redirectMain = (word: string) => {
			const replacePath = path.replace(word, "/");
			const redirectedPath = `${replacePath}${search}`;

			history.replace(redirectedPath);
		};

		if (path.startsWith("/ua/")) {
			redirectMain("/ua/");
		} else if (path.startsWith("/ua")) {
			redirectMain("/ua");
		}
	}, [history]);

	//messages
	useEffect(() => {
		clearInterval(intervalId);
		dialogIdMessagesBack &&
			dialogIdMessagesBack?.order_id &&
			setIntervalId(
				setInterval(() => {
					dispatch(
						getMessagesBack({
							id: dialogIdMessagesBack?.order_id,
						}),
					);
				}, 10000),
			);
		return () => clearInterval(intervalId);
	}, [dialogIdMessagesBack]);

	useEffect(() => {
		(window.location.pathname.includes(sellerSlugs.sellerDialogues) ||
			window.location.pathname.includes(sellerSlugs.sellerDialogues)) &&
			clearInterval(intervalId);
		!modalWindowStatus && intervalId && clearInterval(intervalId);
	}, [window.location.pathname, modalWindowStatus]);

	return (
		<>
			<ToastContainer />
			{modalWindowStatus && (
				<ModalWrapper>
					{selectModal(modalWindowChildrenType)}
				</ModalWrapper>
			)}
			<ModalCustom />
			{singlePage.indexOf(window.location.pathname) < 0 ? (
				<Header />
			) : (
				<MinimalHeader />
			)}

			<Suspense
				fallback={
					<main className="main__wrapper--loader">
						<MiniPreloader />
					</main>
				}
			>
				<Switch>
					{publicRoutes.map(({ path, component: Component }) => {
						return (
							<Route
								exact
								key={path}
								path={path}
							>
								<ErrorBoundary
									FallbackComponent={ErrorComponent}
									onError={logError}
								>
									<Component />
								</ErrorBoundary>
							</Route>
						);
					})}
					{privateCustomerRoutes?.map(
						({ path, component: Component }) => {
							return (
								<Route
									exact
									key={path}
									path={path}
								>
									<ErrorBoundary
										FallbackComponent={ErrorComponent}
										onError={logError}
									>
										<Component />
									</ErrorBoundary>
								</Route>
							);
						},
					)}
					{privateSellerRoutes?.map(
						({ path, component: Component }) => {
							return (
								<Route
									exact
									key={path}
									path={path}
								>
									<ErrorBoundary
										FallbackComponent={ErrorComponent}
										onError={logError}
									>
										<Component />
									</ErrorBoundary>
								</Route>
							);
						},
					)}

					<Route>
						<Page404 />
					</Route>
				</Switch>
			</Suspense>
			{singlePage.indexOf(window.location.pathname) < 0 ? (
				<Footer />
			) : (
				<MinimalFooter />
			)}
		</>
	);
};

export default App;
