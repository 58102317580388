import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";
import { useMediaQuery } from "react-responsive";

//styles
import s from "./SearchHeaderInput.module.scss";
import breakpoints from "../../../../styles/vars";

//assets
import { lupaSvg } from "../../Svgs/lupaSvg";

// ui
import ImageComponentUI from "../../../ui/ImageComponentUI/ImageComponentUI";

//api
import { baseURLSingle } from "../../../../API/_api";

//hooks
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux";

//routes
import { paths, pathFC } from "../../../../App/Routes/paths";

//redux
import { getSearchCatalogBack } from "../../../../redux/SearchCatalog/SearchCatalog";

type TSearchInputProps = {
  placeholder: string;
  className?: string;
  setActiveSearch?: any;
  activeSearch?: boolean;
  classNameDropdown?: string;
  lupaIsLink?: boolean;
};

const SearchHeaderInput = ({
  placeholder,
  className,
  activeSearch = false,
  setActiveSearch,
  classNameDropdown,
  lupaIsLink,
}: TSearchInputProps): JSX.Element => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const ref = useRef<HTMLDivElement>(null);
  const history = useHistory();

  const [search, setSearch] = useState<string>("");

  const md = useMediaQuery({ maxWidth: breakpoints.md });

  const delay = 400;
  const [find, setFind] = useState<string>();
  const debouncedSetFilter = useDebouncedCallback(
    (find) => setSearch(find),
    delay
  );

  const { dataGetSearchCatalogBack } = useAppSelector(
    (state) => state.searchCatalog
  );

  useEffect(() => {
    const params = {
      search: search,
      page: 1,
      pageSize: 30,
      sortBy: "rating",
      direction: "desc",
    };

    search.length > 2 &&
      dispatch(
        getSearchCatalogBack({
          params: params,
        })
      );
  }, [search]);

  const handleClickOutside = (event: any) => {
    if (ref.current && !ref?.current?.contains(event.target as Node)) {
      setActiveSearch(false);
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.addEventListener("click", handleClickOutside, true);
    };
  });

  return (
    <div
      className={`${s.root} ${className ? className : ""} ${
        className ? (activeSearch ? s.activeSearch : s.notActiveSearch) : ""
      }`}
    >
      <div className={s.wrapperBody}>
        <div className={s.wrapperInput}>
          <input
            type="text"
            placeholder={t(placeholder)}
            value={find}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              debouncedSetFilter(e.target.value);
              setFind(e.target.value);
            }}
            className={`${activeSearch ? "" : s.input}`}
            onKeyPress={(e) => {
              if (e.key === "Enter" && search.length > 2) {
                history.push(pathFC(`${paths?.search}/${search}`));
                setActiveSearch(false);
              }
            }}
          />
        </div>
        {activeSearch && dataGetSearchCatalogBack?.length > 2 && (
          <div
            className={`${s.dropdown} ${
              classNameDropdown ? classNameDropdown : ""
            }`}
            // ref={ref}
          >
            {dataGetSearchCatalogBack.map((item: any, index: number) => {
              return (
                <Link
                  className={s.item}
                  to={pathFC(`${paths.productCart}/${item?.slug}`)}
                  key={index}
                  onClick={() => {
                    setFind("");
                  }}
                >
                  <div className={s.wrapperImgName}>
                    <div className={s.img}>
                      <ImageComponentUI
                        src={`${
                          item?.images[0]?.includes("img") ? baseURLSingle : ""
                        }${item?.images[0]}`}
                        media={item?.media.length ? item?.media : null}
                        images={item?.images}
                        index={0}
                        size={md ? "xs" : "sm"}
                        alt={item?.name}
                      />
                    </div>
                    <div className={s.text}>{item?.name}</div>
                  </div>
                  <div className={s.cost}>
                    <p>{(item?.final_price || 0).toFixed(0)}</p>
                    <span> грн</span>
                  </div>
                </Link>
              );
            })}
          </div>
        )}
      </div>
      <div
        className={s.lupa}
        dangerouslySetInnerHTML={{ __html: lupaSvg }}
        onClick={() => {
          setFind("");
          lupaIsLink && history.push(pathFC(`${paths?.search}/${search}`));
          setActiveSearch((prev: boolean) => !prev);
        }}
      />
    </div>
  );
};

export default SearchHeaderInput;
