/* eslint-disable camelcase */
// +
import NativeSelect from "@mui/material/NativeSelect";
import styled from "@mui/system/styled";


// STYLES
export const SDNativeSelect = styled(NativeSelect)(
	({ theme, is_dark_background_color }) => ({
		"label + &": {
			marginTop: 0,
		},
		"& > select": {
			fontSize: "16px",
			backgroundColor: theme.palette.customColors.bg.bg_23,
			color: theme.palette.customColors.text.t_2,
		},
		"& > svg": {
			fill: theme.palette.customColors.svg.s_2,
		},
	}),
);
