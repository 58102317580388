export enum sellerVariantsSlug {
  profile = "profile",
  order = "order",
  orders = "orders",
  products = "products",
  messages = "messages",
  dialogues = "dialogues",
  settings = "settings",
  finance = "finance",
}

export const sellerVariantsContent = {
  sellerProfile: `/seller/${sellerVariantsSlug.profile}`,
  sellerOrder: `/seller/${sellerVariantsSlug.order}`,
  sellerOrders: `/seller/${sellerVariantsSlug.orders}`,
  sellerProducts: `/seller/${sellerVariantsSlug.products}`,
  sellerMessages: `/seller/${sellerVariantsSlug.messages}`,
  sellerDialogues: `/seller/${sellerVariantsSlug.dialogues}`,
  sellerSettings: `/seller/${sellerVariantsSlug.settings}`,
  sellerFinance: `/seller/${sellerVariantsSlug.finance}`,
};

export enum paymentDataMethodValue {
  is_pickup_available_value = 0,
  is_wayforpay_available_value = 1,
  is_c2c_available_value = 2,
}

export enum deliveryDataMethodValue {
  delivery_NP_value = 0,
  delivery_addresses_value = 1,
}
