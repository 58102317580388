import { useTranslation } from "react-i18next";

//styles
import s from "./ModalClothingSize.module.scss";

//components
import ModalClose from "../ModalComponents/ModalClose/ModalClose";

//ui
import TitleWithLine from "../../TitleWithLine/TitleWithLine";

//db
import { gendersClothesWomen } from "../../../../db/Modals/ModalClothingSize";

const ModalClothingSize = () => {
  const { t } = useTranslation();

  return (
    <div className={s.root}>
      <ModalClose />
      <div className={s.wrapperTitle}>
        <TitleWithLine
          title={t("clothingSize.title")}
          marginTop={{ xl: "20px" }}
          fontSize={{ xl: "18px", lg: "14px" }}
          widthLine={{ xl: "60px", lg: "28px" }}
          heightLine={{ xl: "3px", lg: "2px" }}
        />
      </div>
      {gendersClothesWomen?.title && (
        <TitleWithLine
          title={t(gendersClothesWomen.title)}
          marginTop={{ xl: "20px" }}
          fontSize={{ xl: "16px" }}
          widthLine={{ xl: "30px" }}
          heightLine={{ xl: "2px" }}
        />
      )}
      <div className={s.table}>
        {gendersClothesWomen?.titlesColumns?.length > 0 && (
          <div className={s.titleList}>
            {gendersClothesWomen?.titlesColumns?.map((item: any) => (
              <div className={s.titleListItem}>
                {t(item.text)}
                {item.additional && item.additional}
              </div>
            ))}
          </div>
        )}
        {gendersClothesWomen?.sizesMass?.length > 0 && (
          <ul className={s.list}>
            {gendersClothesWomen?.sizesMass?.map((item: any) => (
              <li className={s.listItem} key={item?.id}>
                {item?.sizes?.map((size: string, index: number) => (
                  <span key={index}>{size}</span>
                ))}
              </li>
            ))}
          </ul>
        )}
      </div>
      <div className={s.wrapperSubtitle}>
        <TitleWithLine
          title={t("clothingSize.hint")}
          fontSize={{ xl: "16px" }}
          widthLine={{ xl: "30px" }}
          heightLine={{ xl: "2px" }}
        />
      </div>

      <div className={s.content}>
        {gendersClothesWomen.text && (
          <div className={s.text}>{gendersClothesWomen.text}</div>
        )}
        {gendersClothesWomen.image && (
          <div className={s.wrapperImg}>
            <img src={gendersClothesWomen.image} alt="+" />
          </div>
        )}
      </div>
    </div>
  );
};

export default ModalClothingSize;
