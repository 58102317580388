import { createSlice } from "@reduxjs/toolkit";
import { useHistory } from "react-router-dom";

//api
import { postRequest, putRequest } from "../../../API/_api";

//TS
import { TSellerOrder } from "../../../TS/types/seller/sellerOrder/sellerOrder";

//redux
import {
  setModalWindowChildrenType,
  setModalWindowStatus,
  setModalWindowInfo,
} from "../../App/app";

type TPostSellerOrderBackProps = {
  obj: any;
};

export const postSellerOrderBack =
  (sendData: TPostSellerOrderBackProps) => (dispatch: any) => {
    const { obj } = sendData;
    dispatch(setLoadingPostSellerOrderBack(true));

    const success = (data: any) => {
      dispatch(setDataPostSellerOrderBack(data?.data));
      dispatch(setModalWindowChildrenType("text"));
      dispatch(
        setModalWindowInfo({
          typeTag: "h2",
          text: "sellerAddProduct.add.success",
        })
      );
    };

    const setError = (error: any) => {
      dispatch(setErrorPostSellerOrderBack(error?.response?.data));
      dispatch(setModalWindowStatus(true));
      dispatch(setModalWindowChildrenType("formError"));
      dispatch(
        setModalWindowInfo({
          title: "common.modals",
          text: error?.response?.data?.message,
        })
      );
    };

    postRequest({
      url: `seller/products`,
      success,
      setError,
      obj,
    });
  };

type TputSellerOrderBackProps = {
  orderId: string;
  obj: any;
};

export const putSellerOrderBack =
  (sendData: TputSellerOrderBackProps) => (dispatch: any) => {
    const { obj, orderId } = sendData;
    dispatch(setLoadingPutSellerOrderBack(true));

    const success = (data: any) => {
      dispatch(setDataPutSellerOrderBack(data?.data));
      dispatch(setModalWindowChildrenType("text"));
      dispatch(
        setModalWindowInfo({
          typeTag: "h2",
          text: "modals.successInfo.text",
        })
      );
      dispatch(setModalWindowStatus(true));
    };

    const setError = (error: any) => {
      dispatch(setErrorPutSellerOrderBack(error?.response?.data));
      dispatch(setModalWindowChildrenType("formError"));
      dispatch(
        setModalWindowInfo({
          title: "common.modals",
          text: error?.response?.data?.message,
        })
      );
      dispatch(setModalWindowStatus(true));
    };

    putRequest({
      url: `seller/products/${orderId}`,
      success,
      setError,
      obj,
    });
  };

type TInitialState = {
  dataPutSellerOrderBack: any;
  loadingPutSellerOrderBack: boolean;
  errorPutSellerOrderBack: any;
};

const initialState: any = {
  dataPostSellerOrderBack: null,
  loadingPostSellerOrderBack: false,
  errorPostSellerOrderBack: null,

  dataPutSellerOrderBack: null,
  loadingPutSellerOrderBack: false,
  errorPutSellerOrderBack: null,
};

export const sellerAddEditProduct = createSlice({
  name: "sellerAddEditProduct",
  initialState,
  reducers: {
    setDataPutSellerOrderBack(state, action) {
      state.dataPutSellerOrderBack = action.payload;
      state.loadingPutSellerOrderBack = false;
    },
    setErrorPutSellerOrderBack(state, action) {
      state.errorPutSellerOrderBack = action.payload;
      state.loadingPutSellerOrderBack = false;
    },
    setLoadingPutSellerOrderBack(state, action) {
      state.loadingPutSellerOrderBack = action.payload;
      state.errorPutSellerOrderBack = null;
    },
    setDataPostSellerOrderBack(state, action) {
      state.dataPostSellerOrderBack = action.payload;
      state.loadingPostSellerOrderBack = false;
    },
    setErrorPostSellerOrderBack(state, action) {
      state.errorPostSellerOrderBack = action.payload;
      state.loadingPostSellerOrderBack = false;
    },
    setLoadingPostSellerOrderBack(state, action) {
      state.loadingPostSellerOrderBack = action.payload;
      state.errorPostSellerOrderBack = null;
    },
  },
});

export const {
  setDataPutSellerOrderBack,
  setErrorPutSellerOrderBack,
  setLoadingPutSellerOrderBack,
  setDataPostSellerOrderBack,
  setErrorPostSellerOrderBack,
  setLoadingPostSellerOrderBack,
} = sellerAddEditProduct.actions;

export default sellerAddEditProduct.reducer;
