import { NavLink, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

//styles
import s from "./ButtonsLinkSeller.module.scss";

//assets
import bag from "../../../../../assets/images/header/headerBody/bag.png";

//routes
import { paths, pathFC } from "../../../../../App/Routes/paths";

//db
import { headerBodyButtonsLinkSeller } from "../../../../../db/Header/headerData";

const ButtonsLinkSeller = () => {
  const { t } = useTranslation();

  const history = useHistory();

  const logout = () => {
    localStorage.removeItem("token_shopping");
    history.push(pathFC(paths.main));
    window.location.reload();
  };

  return (
    <div className={s.wrapperProfile}>
      <NavLink
        to={headerBodyButtonsLinkSeller.path}
        activeClassName={s.activeButtonItem}
        className={s.buttonItemSeller}
      >
        <img src={bag} alt="bag" className={s.buttonItemSvg} />
        <span className={s.buttonItemTitle}>
          {t(headerBodyButtonsLinkSeller.title)}
        </span>
      </NavLink>
      {headerBodyButtonsLinkSeller?.subLinks && (
        <div className={s.subLinks}>
          {headerBodyButtonsLinkSeller?.subLinks?.map((item: any) => {
            return (
              <NavLink to={item.path} className={s.subLinkItem} key={item.id}>
                {t(item.title)}
              </NavLink>
            );
          })}
          <span className={s.subLinkItemOut} onClick={logout}>
            {t("header.logOut")}
          </span>
        </div>
      )}
    </div>
  );
};

export default ButtonsLinkSeller;
