"use client";

// +
import { useTranslation } from "react-i18next";
import { SDLabel, SDWInput } from "./styles";

const WrapperInput = ({
	optionsTags = {},
	idField,
	children,
	el,
	isDarkBackgroundColor,
}) => {
	const { t } = useTranslation();

	// VARIABLES
	const forceRenderLabel = el.forceRenderLabel || false;

	return (
		<SDWInput {...optionsTags.wrapperLabelAndInput}>
			{el.label || forceRenderLabel ? (
				<SDLabel
					htmlFor={idField}
					{...optionsTags.label}
				>
					{t(el.label)} {el?.addintionalTextLabel || ""}
				</SDLabel>
			) : null}
			{children}
		</SDWInput>
	);
};
export default WrapperInput;
