import React from "react";
import { useMediaQuery } from "react-responsive";

//styles
import s from "./FooterMainInfo.module.scss";
import breakpoints from "../../../../styles/vars";

//compinents
import LogoAndLanguage from "./LogoAndLanguage/LogoAndLanguage";
import FooterMenu from "./FooterMenu/FooterMenu";

//ui
import Contaiter from "../../../ui/Containers/container/Contaiter";
import ContainerFooter from "../../../ui/Containers/containerFooter/ContainerFooter";

const FooterMainInfo: React.FC = () => {
  const lg = useMediaQuery({ maxWidth: breakpoints.lg });

  return (
    <div className={s.footerMainInfo}>
      {lg ? (
        <div className={s.wrapper}>
          <LogoAndLanguage />
          <FooterMenu />
        </div>
      ) : (
        <Contaiter>
          <ContainerFooter>
            <div className={s.wrapper}>
              <LogoAndLanguage />
              <FooterMenu />
            </div>
          </ContainerFooter>
        </Contaiter>
      )}
    </div>
  );
};

export default FooterMainInfo;
