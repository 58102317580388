//enums
import { sellerVariantsContent } from "../../TS/enums/generls";
import { getSelectedLang } from "../../utils/language/getSelectedLang";

type TPath = {
  main: string;
  mainMen: string;
  mainWomen: string;
  mainKids: string;
  profilePersonal: string;
  profileOrders: string;
  profileQuestionReviews: string;
  profilePromoCodesCertificates: string;
  brands: string;
  productCart: string;
  categories: string;
  contacts: string;
  cooperation: string;
  about: string;
  delivery: string;
  faq: string;
  warranty: string;
  return: string;
  favorites: string;
  basket: string;
  partnership: string;
  brandsCategory: string;
  checkout: string;
  search: string;
  profile: string;
  payThankCard: string;
  payThankCash: string;
  payThankError: string;
  seller: string;
  sellerProductsProduct: string;
  newProducts: string;
  discountProducts: string;
  registration: string;
};

enum profileVariantsContent {
  profilePersonal = "/profile/personal",
  profileOrders = "/profile/orders",
  profileQuestionReviews = "/profile/question_reviews",
  profilePromoCodesCertificates = "/profile/promo",
}

export const paths: TPath = {
  main: `/`,
  mainWomen: `/women`,
  mainMen: `/men`,
  mainKids: `/kids`,
  productCart: `/product_card`,
  profilePersonal: profileVariantsContent.profilePersonal,
  profileQuestionReviews: profileVariantsContent.profileQuestionReviews,
  profileOrders: profileVariantsContent.profileOrders,
  profilePromoCodesCertificates:
    profileVariantsContent.profilePromoCodesCertificates,
  contacts: `/contacts`,
  cooperation: `/cooperation`,
  about: `/about`,
  faq: `/faq`,
  warranty: `/warranty`,
  partnership: `/partnership`,
  delivery: `/delivery`,
  brands: `/brands`,
  categories: `/categories`,
  brandsCategory: `/brands_category`,
  favorites: `/favorites`,
  basket: `/basket`,
  return: `/return`,
  checkout: `/checkout`,
  search: `/search`,
  profile: `/profile`,
  payThankCard: `/pay_thank_card`,
  payThankCash: `/pay_thank_cash`,
  payThankError: `/pay_thank_error`,
  seller: `/seller`,
  sellerProductsProduct: `${sellerVariantsContent.sellerProducts}/product`,
  newProducts: `/new-products`,
  discountProducts: `/discount-products`,
  registration: `/registration`,
};

export const pathFC = (pathName: string) => {
  const selectedLang = getSelectedLang();

  return `${
    selectedLang?.slugLang 
    ? `/${selectedLang?.slugLang}` 
    : ""
  }${pathName}`;
};
