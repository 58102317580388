import { createSlice, PayloadAction } from "@reduxjs/toolkit";

//db
import { mainLanguageData } from "../../db/generals/languageData";
import { gendersCategoryLink } from "../../db/Header/headerData";

type TInitialState = {
	language: string;
	isLocalizationUrl: string;
	localGenderUKR: string;
	gender: string;
	modalWindowStatus: boolean;
	modalWindowChildrenType: string;
	modalWindowInfo: any;
	generalInfo: any;
	token: string;
	modalConfig: any,
};
const initialState: TInitialState = {
	language: mainLanguageData?.key,
	isLocalizationUrl: "",
	localGenderUKR: "Для жінок",
	gender: (() => {
		return (
			gendersCategoryLink.find((item: any) =>
				window.location.pathname.includes(`/${item?.key}`),
			)?.key || "women"
		);
	})(),
	modalWindowStatus: false,
	modalWindowChildrenType: "",
	modalWindowInfo: null,
	generalInfo: null,
	token: JSON.parse(localStorage.getItem("token_shopping")!) || "",
	modalConfig: {
		open: false,
		type: "",
		props: {},
		// open: true,
		// type: "phoneVerify",
		// props: {},
	},
};

export const app = createSlice({
	name: "app",
	initialState,
	reducers: {
		setLanguage: (state, action: PayloadAction<string>) => {
			state.language = action.payload;
		},
		setIsLocalizationUrl: (state, action: PayloadAction<string>) => {
			state.isLocalizationUrl = action.payload;
		},

		setLocalGenderUKR: (state, action: PayloadAction<string>) => {
			state.localGenderUKR = action.payload;
		},
		setGender: (state, action: PayloadAction<string>) => {
			state.gender = action.payload;
		},
		setModalWindowChildrenType: (state, action: PayloadAction<string>) => {
			state.modalWindowChildrenType = action.payload;
		},
		setModalWindowStatus: (state, action: PayloadAction<boolean>) => {
			state.modalWindowStatus = action.payload;
		},
		setModalWindowInfo: (state, action) => {
			state.modalWindowInfo = action.payload;
		},
		setGeneralInfo: (state, action) => {
			state.generalInfo = action.payload;
		},
		setToken: (state, action) => {
			state.token = action.payload;
		},
		// modalConfig
		setModalConfigAction(state, { payload }) {
			state.modalConfig = payload;
		},
		setModalClearConfigAction(state) {
			state.modalConfig = initialState.modalConfig;
		},
	},
});

export const {
	setLanguage,
	setIsLocalizationUrl,
	setGender,
	setLocalGenderUKR,
	setModalWindowStatus,
	setModalWindowChildrenType,
	setModalWindowInfo,
	setGeneralInfo,
	setToken,
	setModalConfigAction,
	setModalClearConfigAction,
} = app.actions;

export default app.reducer;
