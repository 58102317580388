import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, Link } from "react-router-dom";

//styles
import s from "./HeaderMobile.module.scss";
import breakpoints from "../../../../styles/vars";

//assets
import brands from "../../../../assets/icons/header/NewMenu/brands.svg";

//components
import ButtonsLinkCustomer from "./ButtonsLinkCustomer/ButtonsLinkCustomer";
import HeaderTop from "../HeaderTop/HeaderTop";

//ui
import SearchHeaderInput from "../../../ui/inputs/SearchHeaderInput/SearchHeaderInput";

//db
import { headerBodybuttonsLink } from "../../../../db/Header/headerData";

//svg
import { arrowSvg } from "../../../ui/Svgs/arrowSvg";

//paths
import { paths, pathFC } from "../../../../App/Routes/paths";

//hooks
import { useAppSelector } from "../../../../hooks/redux";

//utils
import { IncludeMassElement } from "../../../../utils/generals/IncludeMassElement";

type THeaderMobileProps = {
	menuIcons: any;
	categories: any;
	setActiveMobileMenu: any;
};
const HeaderMobile = ({
	menuIcons,
	categories,
	setActiveMobileMenu,
}: THeaderMobileProps) => {
	const { t } = useTranslation();

	const { userData } = useAppSelector((state) => state.user);

	const { gender } = useAppSelector((state) => state.app);

	const [local] = useState(() => window.location.pathname);

	const [showMore, setShowMore] = useState<Array<number>>([]);

	const handleonClickShow = (element: number) => {
		IncludeMassElement(element, showMore, setShowMore);
	};

	useEffect(() => {
		local !== window.location.pathname && setActiveMobileMenu(false);
	}, [local]);

	return (
		<div className={s.mobileMenu}>
			<div className={`${s.burgerWrapperTop} `}>
				<div
					className={`${s.burger} ${s.activeBurger}`}
					onClick={() => {
						setActiveMobileMenu((prev: boolean) => !prev);
					}}
				/>
				<SearchHeaderInput
					placeholder={"common.forms.search"}
					className={s.searchAdaptiveMobileMenu}
					activeSearch={true}
				/>
			</div>
			<div className={s.burgerWrapperMenu}>
				<div className={s.wrapperButtons}>
					{headerBodybuttonsLink?.length > 0 &&
						userData?.role?.toLowerCase() !== "seller" &&
						headerBodybuttonsLink.map((item) => {
							return (
								<ButtonsLinkCustomer
									key={item?.id}
									path={item.path}
									svg={item.svg}
									title={item.title}
									subLinks={item?.subLinks}
									onClick={() => {
										setActiveMobileMenu(false);
									}}
								/>
							);
						})}
				</div>
				<div className={s.categoryWrapper}>
					{categories?.length > 0 &&
						categories
							.filter(
								(data: any) =>
									data.slug !== "590-sport" &&
									data.slug !== "616-byuti" &&
									data.slug !== "381-sport" &&
									data.slug !== "387-kosmetika",
							)
							.map((item: any, index: number) => {
								return (
									<React.Fragment key={item?.id}>
										<div
											className={`${s.categoryItemBack} ${
												showMore?.includes(item?.id)
													? s.activeCategoryItemBack
													: ""
											}`}
											onClick={() =>
												handleonClickShow(item?.id)
											}
										>
											<img
												src={
													item?.path ===
													pathFC(paths.brands)
														? brands
														: menuIcons?.[gender]?.[
																index
															]
												}
												alt=""
												className={s.iconCategory}
											/>
											<Link
												to={
													item?.path
														? item?.path
														: pathFC(
																`/${gender}${paths.categories}/${item?.slug}`,
															)
												}
												className={`${s.mainCategory} ${s.categoryItemLink}`}
												onClick={() => {
													setActiveMobileMenu(false);
												}}
											>
												{t(item.name)}
											</Link>
											{item?.children && (
												<span
													dangerouslySetInnerHTML={{
														__html: arrowSvg,
													}}
													className={
														showMore?.includes(
															item?.id,
														)
															? s.arrowActiveSvg
															: s.arrowSvg
													}
												/>
											)}
										</div>
										{item?.children &&
											showMore?.includes(item?.id) && (
												<div
													className={
														s.categorySubRoot
													}
												>
													{item.children.map(
														(categorySub: any) => {
															return (
																categorySub?.name && (
																	<NavLink
																		to={pathFC(
																			`/${gender}${paths.categories}/${categorySub?.slug}`,
																		)}
																		className={
																			s.categorySubItem
																		}
																		onClick={() => {
																			setActiveMobileMenu(
																				false,
																			);
																		}}
																	>
																		{
																			categorySub?.name
																		}
																	</NavLink>
																)
															);
														},
													)}
												</div>
											)}
									</React.Fragment>
								);
							})}
				</div>
			</div>
			{/* <HeaderTop /> */}
		</div>
	);
};

export default HeaderMobile;
