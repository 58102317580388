export const removeProduct = (state: any, productObj: any) => {
  const newBasket: any = [];
  state.basket.forEach((item: any) => {
    let product = item.products.filter(
      (product: any) => product.id !== productObj.id
    );
    if (item.brand.id === productObj.brand.id) {
      if (product?.length > 0) {
        newBasket.push({ ...item, products: product });
      }
    } else {
      newBasket.push(item);
    }
  });
  return newBasket;
};
