// APPROVED
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Modal from "@mui/material/Modal";
import styled from "@mui/system/styled";

export const SDModal = styled(Modal)(() => ({
	outline: "none",
	"& .MuiBackdrop-root": {
		background: "transparent",
		backdropFilter: "blur(5px)",
	},
}));

export const SDWrapper = styled(Box)(({ theme }) => ({
	position: "fixed",
	maxWidth: "90vw",
	maxHeight: "90vh",
	width: "100%",
	height: "100%",
	padding: "10px",
	top: "50%",
	left: "50%",
	transform: "translate(-50%,-50%)",
	background: "#ffffff",
	borderRadius: "5px",
	boxShadow: "4px 5px 30px rgba(212, 212, 235, .4)",
}));

export const SDWrapperBtnClose = styled(IconButton)(() => ({
	position: "absolute",
	right: "5px",
	top: "5px",
	fontSize: "inherit",
	svg: {
		width: "35px",
		height: "35px",
	},
}));
