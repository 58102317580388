import { createSlice } from "@reduxjs/toolkit";

//api
import { postRequest } from "../../API/_api";

//redux
// import { setModalWindowChildrenType, setModalWindowStatus } from "../App/app";

//PostUserForgotPasswordBack
export const postPostUserForgotPasswordBack =
  (data: any) => (dispatch: any) => {
    const { obj, cb, cbError } = data;

    dispatch(setLoadingPostUserForgotPasswordBack(false));
    const success = (data: any) => {
      dispatch(setDataPostUserForgotPasswordBack("success"));
      cb();
      // dispatch(setModalWindowChildrenType("formSuccess"));
      // dispatch(setModalWindowStatus(true));
    };

    const setError = (error: any) => {
      dispatch(setErrorPostUserForgotPasswordBack(error?.response?.data));
      cbError();
    };

    postRequest({
      url: `auth/forgot-password`,
      obj,
      success,
      setError,
    });
  };

const initialState: any = {
  dataPostUserForgotPasswordBack: null,
  loadingPostUserForgotPasswordBack: false,
  errorPostUserForgotPasswordBack: null,
};

export const userForgotPassword = createSlice({
  name: "userForgotPassword",
  initialState,
  reducers: {
    setDataPostUserForgotPasswordBack(state, action) {
      state.dataPostUserForgotPasswordBack = action.payload;
      state.errorPostUserForgotPasswordBack = null;
      state.loadingPostUserForgotPasswordBack = false;
    },
    setLoadingPostUserForgotPasswordBack(state, action) {
      state.loadingPostUserForgotPasswordBack = action.payload;
      state.errorPostUserForgotPasswordBack = null;
      state.dataPostUserForgotPasswordBack = null;
    },
    setErrorPostUserForgotPasswordBack(state, action) {
      state.errorPostUserForgotPasswordBack = action.payload;
    },
  },
});

export const {
  setDataPostUserForgotPasswordBack,
  setLoadingPostUserForgotPasswordBack,
  setErrorPostUserForgotPasswordBack,
} = userForgotPassword.actions;

export default userForgotPassword.reducer;
