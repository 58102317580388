import { useTranslation } from "react-i18next";
//styles
import s from "./ModalText.module.scss";

//components
import ModalClose from "../ModalComponents/ModalClose/ModalClose";

//hooks
import { useAppSelector } from "../../../../hooks/redux";

const ModalText = () => {
  const { t } = useTranslation();
  const { modalWindowInfo } = useAppSelector((state) => state.app);

  const renderContent = (typeTag: string) => {
    let content: any = null;
    switch (typeTag) {
      case "h2":
        return (content = <h2 className={s.h2}>{t(modalWindowInfo?.text)}</h2>);
      default:
        content = (
          <div dangerouslySetInnerHTML={{ __html: modalWindowInfo?.text }} />
        );
    }
    return content;
  };

  return (
    <div className={s.root}>
      <ModalClose />
      {renderContent(modalWindowInfo?.typeTag)}
    </div>
  );
};

export default ModalText;
