import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import styled from "@mui/system/styled";
import { BREAKPOINTS_NAME } from "../../../theme/breakpoints/index";

export const SDWBtn = styled("div")(({ theme }) => ({
	width: "100%",
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
}));

export const SDBtn = styled(Button)(({ theme }) => ({
	cursor: "pointer",
	height: "40px",
	width: "100%",
	textAlign: "center",
	fontWeight: 500,
	fontSize: "16px",
	letterSpacing: "0.1em",
	[theme.breakpoints.down(BREAKPOINTS_NAME.tabletMinus)]: {
		fontSize: "14px",
	},
}));

export const SDBtnText = styled(Typography)(({ theme, is_line_before }) => {
	let styles = {};
	if (is_line_before === "true") {
		styles = {
			"&:before": {
				content: "''",
				background: theme.palette.customColors.bg.bg_2,
				display: "block",
				position: "absolute",
				top: "50%",
				transform: "translateY(-50%)",
				left: "-30px",
				opacity: "0.5",
				borderRadius: "5px",
				width: "21px",
				height: "1px",
			},
		};
	}
	return {
		position: "relative",
		textAlign: "center",
		fontWeight: 500,
		fontSize: "16px",
		letterSpacing: "0.1em",
		...styles,
		[theme.breakpoints.down(BREAKPOINTS_NAME.tabletMinus)]: {
			fontSize: "14px",
		},
	};
});
