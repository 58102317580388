import { NavLink } from "react-router-dom";

//styles
import s from "./ButtonLinkCustomerAdaptive.module.scss";

//hooks
import { useAppDispatch, useAppSelector } from "../../../../../hooks/redux";

//routes
import { paths, pathFC } from "../../../../../App/Routes/paths";

//redux
import {
  setModalWindowChildrenType,
  setModalWindowStatus,
} from "../../../../../redux/App/app";

type ButtonLinkCustomerAdaptiveProps = {
  path: string;
  svg?: string;
};

const ButtonLinkCustomerAdaptive = ({
  path,
  svg,
}: ButtonLinkCustomerAdaptiveProps) => {
  const dispatch = useAppDispatch();

  const basket = useAppSelector((state) => state.basket.basket);

  const { isLogged } = useAppSelector((state) => state.user);

  return (
    <>
      {path === pathFC(paths.profilePersonal) ? (
        !isLogged ? (
          <div
            className={s.buttonItem}
            onClick={() => {
              dispatch(setModalWindowStatus(true));
              dispatch(setModalWindowChildrenType("login"));
            }}
          >
            {svg && (
              <div
                dangerouslySetInnerHTML={{
                  __html: svg,
                }}
                className={s.buttonItemSvg}
              />
            )}
          </div>
        ) : (
          <NavLink
            to={path}
            activeClassName={s.activeButtonItem}
            className={s.buttonItem}
          >
            {svg && (
              <div
                dangerouslySetInnerHTML={{
                  __html: svg,
                }}
                className={s.buttonItemSvg}
              />
            )}
          </NavLink>
        )
      ) : (
        <NavLink
          to={path}
          activeClassName={s.activeButtonItem}
          className={s.buttonItem}
        >
          {path === pathFC(paths.basket) && basket?.length > 0 && (
            <span className={s.counts}>{basket?.length}</span>
          )}
          {svg && (
            <div
              dangerouslySetInnerHTML={{
                __html: svg,
              }}
              className={s.buttonItemSvg}
            />
          )}
        </NavLink>
      )}
    </>
  );
};

export default ButtonLinkCustomerAdaptive;
