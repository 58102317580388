// APPROVED +
import API from "./index";

const getRequestConfig = (args) => {
	if (typeof args === "string") {
		return { url: args };
	}

	return args;
};

export const axiosBaseQuery =
	({ prepareHeaders, meta, transformResponse }) =>
	async (args, api, extraOptions) => {
		try {
			const requestConfig = getRequestConfig(args);

			const { locale = "", ...headers } = prepareHeaders(
				requestConfig.headers || {},
				api,
				args,
			);

			const result = await API({
				...requestConfig,
				headers: {
					...headers,
				},
				signal: api.signal,
				locale,
				...extraOptions,
			});

			return {
				data: transformResponse
					? transformResponse(result.data)
					: result.data,
			};
		} catch (err) {
			console.log(err, "err");
			return {
				error: {
					status: err?.response?.status || err?.data?.status || 0,
					data:
						err.response?.data ||
						err?.message ||
						err?.data ||
						"error",
					statusText: err?.data?.statusText || "Error Server",
				},
				meta,
			};
		}
	};
