/* eslint-disable no-param-reassign */
import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "../../@core/axios/axiosBaseQuery";
import { mainLanguageData } from "../../db/generals/languageData";
import { funPathBackGenerals } from "../../@core/constants/urlBack";
import { getDataSendQuery } from "./helpers";

export const appApi = createApi({
	reducerPath: "appApi",
	tagTypes: [],
	baseQuery: axiosBaseQuery({
		prepareHeaders: (headers, { getState }) => {
			const locale = getState().app?.language || mainLanguageData.key;
			const token = localStorage.getItem("token_shopping");
			// const token = getState().appServer.userMeData?.token;

			let headersCopy = { ...headers };

			headersCopy = Object.assign(headersCopy, {
				Authorization: token ? "Bearer " + JSON.parse(token) : "",
				Locale: locale,
			});

			return {
				...headersCopy,
				locale,
			};
		},
	}),
	endpoints: (builder) => ({
		getTopBrands: builder.query({
			query: (args) =>
				getDataSendQuery({
					url: funPathBackGenerals().topBrands,
					args,
				}),
		}),
		geProductsPath: builder.query({
			query: (args) =>
				getDataSendQuery({
					url: args.path,
					args,
				}),
		}),
		getBrandsSlug: builder.query({
			query: (args) =>
				getDataSendQuery({
					url: funPathBackGenerals({
						slug1: args.slug,
					}).brandsSlug,
					args,
				}),
		}),
		getCategoriesSlug: builder.query({
			query: (args) =>
				getDataSendQuery({
					url: funPathBackGenerals({
						slug1: args.slug,
					}).categoriesSlug,
					args,
				}),
		}),
		getCategories: builder.query({
			query: (args) =>
				getDataSendQuery({
					url: funPathBackGenerals().categories,
					args,
				}),
		}),

		getRawCategories: builder.query({
			query: (args) =>
				getDataSendQuery({
					url: funPathBackGenerals().rawCategories,
					args,
				}),
		}),
		getTopCategoriesSlug: builder.query({
			query: (args) =>
				getDataSendQuery({
					url: funPathBackGenerals({
						slug1: args.slug,
					}).topCategoriesSlug,
					args,
				}),
		}),
		getDiscountImages: builder.query({
			query: (args) =>
				getDataSendQuery({
					url: funPathBackGenerals().discountImages,
					args,
				}),
		}),
		getRecommendedProducts: builder.query({
			query: (args) =>
				getDataSendQuery({
					url: funPathBackGenerals().recommendedProducts,
					args,
				}),
		}),
		getFeedBack: builder.query({
			query: (args) =>
				getDataSendQuery({
					url: funPathBackGenerals().feedBack,
					args,
				}),
		}),
		getStaticInformation: builder.query({
			query: (args) =>
				getDataSendQuery({
					url: funPathBackGenerals().staticInformation,
					args,
				}),
		}),
		getDiscountProductsSlug: builder.query({
			query: (args) =>
				getDataSendQuery({
					url: funPathBackGenerals({
						slug1: args.slug,
					}).discountProductsSlug,
					args,
				}),
		}),
		getNewProductsSlug: builder.query({
			query: (args) =>
				getDataSendQuery({
					url: funPathBackGenerals({
						slug1: args.slug,
					}).newProductsSlug,
					args,
				}),
		}),
		getAsks: builder.query({
			query: (args) =>
				getDataSendQuery({
					url: funPathBackGenerals().asks,
					args,
				}),
		}),
		getBalanceHistory: builder.query({
			query: (args) =>
				getDataSendQuery({
					url: funPathBackGenerals().balanceHistory,
					args,
				}),
		}),
	}),
});
