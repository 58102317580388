import { createSlice, PayloadAction } from "@reduxjs/toolkit";

//api
import { postRequest } from "../../API/_api";

//redux
import { setModalWindowChildrenType, setModalWindowStatus } from "../App/app";

//PostUserResetPasswordBack
export const postPostUserResetPasswordBack = (data: any) => (dispatch: any) => {
  const { obj } = data;

  dispatch(setLoadingPostUserResetPasswordBack(false));
  const success = (data: any) => {
    dispatch(setDataPostUserResetPasswordBack(data.data));
    dispatch(setModalWindowChildrenType("formSuccess"));
    dispatch(setModalWindowStatus(true));
  };

  const setError = (error: any) => {
    dispatch(setErrorPostUserResetPasswordBack(error?.response?.data));
  };

  postRequest({
    url: `auth/reset-password`,
    obj,
    success,
    setError,
  });
};

const initialState: any = {
  dataPostUserResetPasswordBack: null,
  loadingPostUserResetPasswordBack: false,
  errorPostUserResetPasswordBack: null,
};

export const userResetPassword = createSlice({
  name: "userResetPassword",
  initialState,
  reducers: {
    setDataPostUserResetPasswordBack(state, action) {
      state.dataPostUserResetPasswordBack = action.payload;
      state.errorPostUserResetPasswordBack = null;
      state.loadingPostUserResetPasswordBack = false;
    },
    setLoadingPostUserResetPasswordBack(state, action) {
      state.loadingPostUserResetPasswordBack = action.payload;
      state.errorPostUserResetPasswordBack = null;
    },
    setErrorPostUserResetPasswordBack(state, action) {
      state.errorPostUserResetPasswordBack = action.payload;
    },
  },
});

export const {
  setDataPostUserResetPasswordBack,
  setLoadingPostUserResetPasswordBack,
  setErrorPostUserResetPasswordBack,
} = userResetPassword.actions;

export default userResetPassword.reducer;
