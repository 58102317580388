import { createSlice } from "@reduxjs/toolkit";

//api
import { getRequest } from "../../API/_api";

//types
import { TBrand } from "../../TS/types/generals/generals";

//rework

export const getBrandBack = (params: any) => (dispatch: any) => {
  dispatch(setLoadingGetBrandBack(true));

  const success = (data: any) => {
    dispatch(setDataGetBrandBack(data?.data));
  };

  const setError = (error: any) => {
    dispatch(setErrorGetBrandBack(error));
  };

  getRequest({
    url: `brands/${params.slug}`,
    success,
    setError,
  });
};

type TdiscountImagesInitialState = {
  dataGetBrandBack: any | null;
  loadingGetBrandBack: boolean;
  errorGetBrandBack: any;
};

const initialState: TdiscountImagesInitialState = {
  dataGetBrandBack: null,
  loadingGetBrandBack: false,
  errorGetBrandBack: null,
};

export const brandsCategory = createSlice({
  name: "brandsCategory",
  initialState,
  reducers: {
    setDataGetBrandBack(state, action) {
      state.dataGetBrandBack = action.payload;
      state.loadingGetBrandBack = false;
      state.errorGetBrandBack = null;
    },
    setErrorGetBrandBack(state, action) {
      state.errorGetBrandBack = action.payload;
      state.loadingGetBrandBack = false;
    },
    setLoadingGetBrandBack(state, action) {
      state.loadingGetBrandBack = action.payload;
      state.errorGetBrandBack = null;
    },
  },
});

export const {
  setLoadingGetBrandBack,
  setErrorGetBrandBack,
  setDataGetBrandBack,
} = brandsCategory.actions;

export default brandsCategory.reducer;
