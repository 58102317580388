import styled from "@mui/system/styled";
import { BREAKPOINTS_NAME } from "../../theme/breakpoints/index";

export const sx = {
	pMainBtn: {
		wrapper: {
			width: "100%",
		},
		btn: {
			maxWidth: "230px",
			px: "15px",
		},
	},
};

export const SDMain = styled("main")({
	paddingBottom: "100px",
	marginTop: "50px",
});

export const SDBody = styled("div")({
	maxWidth: "362px",
	width: "100%",
	margin: "100px auto 0",
});

export const SDErrorImg = styled("div")({});

export const SDBtns = styled("div")({
	marginTop: "50px",
	display: "flex",
	justifyContent: "space-between",
	columnGap: "15px",
});
