import React, {ReactNode} from "react";

//styles
import s from "./container.module.scss";

interface ContainerProps {
  children: ReactNode;
}

const Container: React.FC<ContainerProps> = ({ children }) => {
  return <div className={s.root}>{children}</div>;
};

export default Container;
