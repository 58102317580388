import { createSlice } from "@reduxjs/toolkit";

//api
import { getRequest, postRequest, putRequest } from "../../../API/_api";

//redux
import {
  setModalWindowChildrenType,
  setModalWindowStatus,
  setModalWindowInfo,
} from "../../App/app";

export const getSellerMessagesSellerReviewsBack =
  ({ params }: any) =>
  (dispatch: any) => {
    dispatch(setLoadingGetSellerMessagesSellerReviewsBack(true));

    const success = (data: any) => {
      dispatch(setDataGetSellerMessagesSellerReviewsBack(data?.data));
      dispatch(setMetaGetSellerMessagesSellerReviewsBack(data?.meta));
    };

    const setError = (error: any) => {
      dispatch(setErrorGetSellerMessagesSellerReviewsBack(error));
    };

    getRequest({
      url: `seller/feed-back`,
      success,
      setError,
      params,
    });
  };

type TPostSellerMessagesReviewsBackProps = {
  obj: any;
};

export const postSellerMessagesReviewsBack =
  (sendData: TPostSellerMessagesReviewsBackProps) => (dispatch: any) => {
    const { obj } = sendData;
    dispatch(setLoadingPostSellerMessagesReviewsBack(true));

    const success = (data: any) => {
      dispatch(setDataPostSellerMessagesReviewsBack(data?.data));
      dispatch(setModalWindowStatus(false));
    };

    const setError = (error: any) => {
      dispatch(setErrorPostSellerMessagesReviewsBack(error?.response?.data));
    };

    postRequest({
      url: `seller/create/feedback`,
      success,
      setError,
      obj,
    });
  };

type TPutSellerMessagesSellerReviewsBackProps = {
  id: any;
};

export const putSellerMessagesSellerReviewsRemoveBack =
  (sendData: TPutSellerMessagesSellerReviewsBackProps) => (dispatch: any) => {
    const { id } = sendData;
    dispatch(setLoadingPutSellerMessagesSellerReviewsRemoveBack(true));

    const success = (data: any) => {
      dispatch(setDataPutSellerMessagesSellerReviewsRemoveBack(data?.data));
      dispatch(setModalWindowChildrenType("formSuccess"));
      dispatch(
        setModalWindowInfo({
          title: "common.modals",
        })
      );
      dispatch(setModalWindowStatus(true));
    };

    const setError = (error: any) => {
      dispatch(setErrorPutSellerMessagesSellerReviewsRemoveBack(error));
      dispatch(setModalWindowChildrenType("formError"));
      dispatch(
        setModalWindowInfo({
          title: "common.modals",
          text: error?.response?.data?.message,
        })
      );
      dispatch(setModalWindowStatus(true));
    };

    putRequest({
      url: `seller/remove-seller-feedback-request/${id}`,
      success,
      setError,
    });
  };

type TPutAnswerSellerMessagesSellerReviewsBackProps = {
  id: number;
  obj: any;
};

export const putAnswerSellerMessagesSellerReviewsBack =
  (sendData: TPutAnswerSellerMessagesSellerReviewsBackProps) =>
  (dispatch: any) => {
    const { id, obj } = sendData;
    dispatch(setLoadingPutAnswerSellerMessagesSellerReviewsBack(true));

    const success = (data: any) => {
      dispatch(setDataPutAnswerSellerMessagesSellerReviewsBack(data?.data));
      dispatch(setModalWindowChildrenType("formSuccess"));
      dispatch(
        setModalWindowInfo({
          title: "common.modals",
        })
      );
      dispatch(setModalWindowStatus(true));
    };

    const setError = (error: any) => {
      dispatch(setErrorPutAnswerSellerMessagesSellerReviewsBack(error));
      dispatch(setModalWindowChildrenType("formError"));
      dispatch(
        setModalWindowInfo({
          title: "common.modals",
          text: error?.response?.data?.message,
        })
      );
      dispatch(setModalWindowStatus(true));
    };

    putRequest({
      url: `seller/feed-back/${id}`,
      success,
      setError,
      obj,
    });
  };

const initialState: any = {
  dataGetSellerMessagesSellerReviewsBack: null,
  metaGetSellerMessagesSellerReviewsBack: null,
  loadingGetSellerMessagesSellerReviewsBack: false,
  errorGetSellerMessagesSellerReviewsBack: null,

  filters: {
    date_from: null,
    date_to: null,
  },

  dataPostSellerMessagesReviewsBack: null,
  loadingPostSellerMessagesReviewsBack: false,
  errorPostSellerMessagesReviewsBack: null,

  dataPutSellerMessagesSellerReviewsRemoveBack: null,
  loadingPutSellerMessagesSellerReviewsRemoveBack: false,
  errorPutSellerMessagesSellerReviewsRemoveBack: null,

  dataPutAnswerSellerMessagesSellerReviewsBack: null,
  loadingPutAnswerSellerMessagesSellerReviewsBack: false,
  errorPutAnswerSellerMessagesSellerReviewsBack: null,
};

export const sellerMessagesSellerReviews = createSlice({
  name: "sellerMessagesSellerReviews",
  initialState,
  reducers: {
    setDataGetSellerMessagesSellerReviewsBack(state, action) {
      state.dataGetSellerMessagesSellerReviewsBack = action.payload;
      state.loadingGetSellerMessagesSellerReviewsBack = false;
      state.errorGetSellerMessagesSellerReviewsBack = null;
    },
    setMetaGetSellerMessagesSellerReviewsBack(state, action) {
      state.metaGetSellerMessagesSellerReviewsBack = action.payload;
      state.loadingGetSellerMessagesSellerReviewsBack = false;
    },
    setErrorGetSellerMessagesSellerReviewsBack(state, action) {
      state.errorGetSellerMessagesSellerReviewsBack = action.payload;
      state.loadingGetSellerMessagesSellerReviewsBack = false;
    },
    setLoadingGetSellerMessagesSellerReviewsBack(state, action) {
      state.loadingGetSellerMessagesSellerReviewsBack = action.payload;
      state.errorGetSellerMessagesSellerReviewsBack = null;
    },
    setFiltersSellerMessagesSellerReviews(state, action) {
      state.filters = {
        ...state.filters,
        [action.payload.name]: action.payload.value,
      };
    },
    setFiltersRemoveSellerMessagesSellerReviews(state, action) {
      state.filters = {
        date_from: "",
        date_to: "",
      };
    },

    setDataPostSellerMessagesReviewsBack(state, action) {
      state.dataPostSellerMessagesReviewsBack = action.payload;
      state.loadingPostSellerMessagesReviewsBack = false;
    },
    setErrorPostSellerMessagesReviewsBack(state, action) {
      state.errorPostSellerMessagesReviewsBack = action.payload;
      state.loadingPostSellerMessagesReviewsBack = false;
    },
    setLoadingPostSellerMessagesReviewsBack(state, action) {
      state.loadingPosttSellerMessagesReviewsBack = action.payload;
      state.errorPostSellerMessagesReviewsBack = null;
    },

    setDataPutSellerMessagesSellerReviewsRemoveBack(state, action) {
      state.dataPutSellerMessagesSellerReviewsRemoveBack = action.payload;
      state.loadingPutSellerMessagesSellerReviewsBack = false;
    },
    setErrorPutSellerMessagesSellerReviewsRemoveBack(state, action) {
      state.errorPutSellerMessagesSellerReviewsRemoveBack = action.payload;
      state.loadingPutSellerMessagesSellerReviewsRemoveBack = false;
    },
    setLoadingPutSellerMessagesSellerReviewsRemoveBack(state, action) {
      state.loadingPutSellerMessagesSellerReviewsRemoveBack = action.payload;
      state.errorPutSellerMessagesSellerReviewsRemoveBack = null;
    },

    setDataPutAnswerSellerMessagesSellerReviewsBack(state, action) {
      state.dataPutAnswerSellerMessagesSellerReviewsBack = action.payload;
      state.loadingPutAnswerSellerMessagesSellerReviewsBack = false;
    },
    setErrorPutAnswerSellerMessagesSellerReviewsBack(state, action) {
      state.errorPutAnswerSellerMessagesSellerReviewsBack = action.payload;
      state.loadingPutAnswerSellerMessagesSellerReviewsBack = false;
    },
    setLoadingPutAnswerSellerMessagesSellerReviewsBack(state, action) {
      state.loadingPutAnswerSellerMessagesSellerReviewsBack = action.payload;
      state.errorPutAnswerSellerMessagesSellerReviewsBack = null;
    },
  },
});

export const {
  setLoadingGetSellerMessagesSellerReviewsBack,
  setMetaGetSellerMessagesSellerReviewsBack,
  setErrorGetSellerMessagesSellerReviewsBack,
  setDataGetSellerMessagesSellerReviewsBack,
  setFiltersSellerMessagesSellerReviews,
  setDataPostSellerMessagesReviewsBack,
  setErrorPostSellerMessagesReviewsBack,
  setLoadingPostSellerMessagesReviewsBack,
  setFiltersRemoveSellerMessagesSellerReviews,
  setDataPutSellerMessagesSellerReviewsRemoveBack,
  setErrorPutSellerMessagesSellerReviewsRemoveBack,
  setLoadingPutSellerMessagesSellerReviewsRemoveBack,
  setDataPutAnswerSellerMessagesSellerReviewsBack,
  setErrorPutAnswerSellerMessagesSellerReviewsBack,
  setLoadingPutAnswerSellerMessagesSellerReviewsBack,
} = sellerMessagesSellerReviews.actions;

export default sellerMessagesSellerReviews.reducer;
