import "./MiniPreloader.scss";

type TMiniPreloaderProps = {
  className?: any;
};

const MiniPreloader = ({ className }: TMiniPreloaderProps): JSX.Element => {
  return (
    <>
      <div className={`blobs ${className ? className : ""}`}>
        <div className="blob-center" />
        <div className="blob" />
        <div className="blob" />
        <div className="blob" />
        <div className="blob" />
        <div className="blob" />
        <div className="blob" />
      </div>
    </>
  );
};

export default MiniPreloader;
