import React from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
//styles
import s from "./FooterTopAdvantages.module.scss";
import breakpoints from "../../../../styles/vars";

//db
import {
  advantagesLink,
  TAdvantageLink,
} from "../../../../db/generals/generalsData";

//hooks
import { useOutside } from "../../../../hooks/useOutside";

const HeaderTopAdvantages: React.FC = () => {
  return (
    <div className={s.headerTopAdvantages}>
      {advantagesLink.map((advantage, index: number) => (
        <HeaderTopAdvantagesItem advantage={advantage} key={index} />
      ))}
    </div>
  );
};

export default HeaderTopAdvantages;

type THeaderTopAdvantagesItemProps = {
  advantage: TAdvantageLink;
};
const HeaderTopAdvantagesItem = ({
  advantage,
}: THeaderTopAdvantagesItemProps) => {
  const { t } = useTranslation();

  const md: boolean = useMediaQuery({ maxWidth: breakpoints.md });

  const { ref, isShow, setIsShow } = useOutside(false);

  return (
    <div
      className={`${s.advantage} `}
      ref={ref}
      onMouseEnter={() => !md && setIsShow(true)}
      onMouseLeave={() => !md && setIsShow(false)}
      onClick={() => md && setIsShow((prev: boolean) => !prev)}
    >
      <div
        dangerouslySetInnerHTML={{ __html: advantage.icon }}
        className={s.svg}
      />
      <div
        className={`${s.advantageText} ${isShow ? s.activeAdvantageText : ""}`}
      >
        {t(advantage.name)}
      </div>
      <div
        className={`${s.advantageDescription} ${
          isShow ? s.activeAdvantageDescription : ""
        } `}
      >
        <span>{t(advantage.description)}</span>
      </div>
    </div>
  );
};
