import React from "react";
import { useTranslation } from "react-i18next";

//styles
import s from "./HeaderTopAdvantages.module.scss";

//db
import { advantagesLink } from "../../../../../db/generals/generalsData";

const HeaderTopAdvantages: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className={s.root}>
      {advantagesLink.map((advantage, index: number) => (
        <div className={s.advantage} key={index}>
          <div
            dangerouslySetInnerHTML={{ __html: advantage.icon }}
            className={s.svg}
          />
          <div className={s.advantageText}>{t(advantage.name)}</div>
          <div className={s.advantageDescription}>
            <span>{t(advantage.description)}</span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default HeaderTopAdvantages;
