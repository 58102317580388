import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Rating from "react-rating";

//styles
import { SDWRaiting, SDRating } from "./styles";

import s from "./ModalProductReview.module.scss";
import styleParent from "../ModalWrapper/ModalWrapper.module.scss";

//assets
import {
	arrowToIco,
	boxIco,
	diamondIco,
	disableStarIco,
	enableStarIco,
	palette,
	arrows,
} from "../../../../assets/icons/iconsConstants";

//components
import ModalClose from "../ModalComponents/ModalClose/ModalClose";

//hooks
import { useAppDispatch } from "../../../../hooks/redux";

//ui
import ButtonFrontBack from "../../Buttons/ButtonFrontBack/ButtonFrontBack";
import TitleWithLine from "../../TitleWithLine/TitleWithLine";
import SelectInput from "../../inputs/SelectInput/SelectInput";
import Textarea from "../../inputs/Textarea/Textarea";
import ErrorText from "../../ErrorText/ErrorText";
import SearchList from "../../inputs/SearchList/SearchList";

//hooks
import { useAppSelector } from "../../../../hooks/redux";

//db
import { reviewData } from "../../../../db/Modals/reviewData";

//utils
import { isBaseURLSingleInImage } from "../../../../utils/generals/isBaseURLSingleInImage";
import { validationFormSchema } from "../../../../utils/forms/validationFormSchema";

//redux
import { setModalWindowStatus } from "../../../../redux/App/app";
import {
	postReviewProductBack,
	setErrorPostReviewProductBack,
} from "../../../../redux/Generals/Reviews/reviewProduct";
import { getProductSlugBack } from "../../../../redux/ProductCard/productCard";

enum EErrorsBack {
	color = "color",
	delivery = "delivery",
	product_id = "product_id",
	quality = "quality",
	score = "score",
	size = "size",
	text = "text",
}

const ModalProductReview = () => {
	const { t } = useTranslation();

	const dispatch = useAppDispatch();

	const [score, setScore] = useState<number | null>(0);

	const { modalWindowInfo } = useAppSelector((state) => state.app);

	const [nameCard, setNameCard] = useState<string>("");

	const {
		dataPostReviewProductBack,
		loadingPostReviewProductBack,
		errorPostReviewProductBack,
	} = useAppSelector((state) => state.reviewProduct);

	const nameFields: any = {
		text: "text",
		sizeReview: "sizeReview",
		qualityReview: "quality",
		deliveryReview: "deliveryReview",
		colorReview: "colorReview",
		score: "score",
	};

	if (modalWindowInfo?.isSelect) {
		nameFields.productId = "productId";
	}

	const {
		register,
		handleSubmit,
		setValue,
		formState: { errors },
	} = useForm({
		mode: "onBlur",
		resolver: yupResolver(validationFormSchema(t, nameFields)),
	});

	useEffect(() => {
		dispatch(setErrorPostReviewProductBack(null));
		if (
			modalWindowInfo?.isSelect &&
			modalWindowInfo?.products?.length > 1
		) {
			setNameCard(modalWindowInfo?.products?.[0]?.product_name);
			setValue(
				nameFields.productId,
				modalWindowInfo?.products?.[0]?.product_id,
			);
		}
	}, []);

	const onSubmit = (data: any) => {
		const sendData = {
			slug: modalWindowInfo?.productSlug,
		};

		console.log(data, "data");
		console.log(modalWindowInfo, "modalWindowInfo");
		console.log(modalWindowInfo?.ids);
		dispatch(
			postReviewProductBack({
				obj: {
					// product_id:
					// 	data?.[nameFields.productId] ||
					// 	modalWindowInfo?.products.map((el) => el.product_id),
					product_id: modalWindowInfo?.ids,
					color: data?.[nameFields.colorReview],
					delivery: data?.[nameFields.deliveryReview],
					quality: data?.[nameFields.qualityReview],
					score: score,
					size: data?.[nameFields.sizeReview],
					text: data?.[nameFields.text],
				},
				getMethodReloadData: modalWindowInfo?.productSlug
					? getProductSlugBack(sendData)
					: false,
			}),
		);
	};

	return (
		<div className={s.root}>
			<ModalClose />
			<div className={styleParent.wrapperClose}>
				<div
					className={styleParent.close}
					onClick={() => dispatch(setModalWindowStatus(false))}
				/>
			</div>
			<div className={s.wrapperTitle}>
				<TitleWithLine
					title={t("modals.productReview.title")}
					fontSize={{ xl: "18px", lg: "14px" }}
					widthLine={{ xl: "60px", lg: "28px" }}
					heightLine={{ xl: "3px", lg: "2px" }}
				/>
			</div>
			{(errorPostReviewProductBack?.message ||
				errorPostReviewProductBack?.product_id?.[0]) && (
				<ErrorText
					text={
						errorPostReviewProductBack?.message ||
						errorPostReviewProductBack?.product_id?.[0]
					}
				/>
			)}
			<div
				className={`${s.rating} ${
					errorPostReviewProductBack?.[EErrorsBack.score]
						? s.errorRating
						: ""
				}`}
			>
				<SDWRaiting>
					<SDRating
						value={score}
						precision={1}
						onChange={(event, newValue) => setScore(newValue)}
					/>
				</SDWRaiting>
			</div>
			<div className={s.wrapper}>
				<form
					className={s.form}
					onSubmit={handleSubmit(onSubmit)}
				>
					{modalWindowInfo?.isSelect &&
						modalWindowInfo?.products?.length > 1 && (
							<SearchList
								maxWidth={{ xl: "200px" }}
								width={{ xl: "calc(50% - 10px)", lg: "100%" }}
								marginRight={{ xl: "auto" }}
								marginLeft={{ xl: "auto" }}
								searchItems={modalWindowInfo?.products || []}
								search={nameCard}
								setSearch={setNameCard}
								placeholder={t("common.enter")}
								name={nameFields?.productId}
								register={register}
								label={`* ${t("common.selectProduct")}`}
								error={errors?.[nameFields?.productId]}
								renderList={modalWindowInfo?.products
									?.filter((item: any) =>
										item?.product_name?.includes(nameCard),
									)
									?.map((item: any) => {
										return (
											<li
												className={s.option}
												onClick={() => {
													setNameCard(
														item?.product_name,
													);
													setValue(
														nameFields.productId,
														item.product_id,
														{
															shouldValidate:
																true,
														},
													);
												}}
											>
												<div className={s.wrapperImg}>
													{item?.image && (
														<img
															src={isBaseURLSingleInImage(
																item?.image,
															)}
															alt={"..."}
														/>
													)}
												</div>

												<p className={s.name}>
													{item?.product_name}
												</p>
											</li>
										);
									})}
							/>
						)}

					<div className={s.wrapperInputs}>
						<SelectInput
							width={{ xl: "calc(50%-10px)" }}
							marginTop={{ xl: "0" }}
							defaultOption={t(reviewData?.sizeReview?.[0].title)}
							options={reviewData?.sizeReview}
							register={register}
							setValue={setValue}
							errorFromBack={
								errorPostReviewProductBack?.[EErrorsBack.size]
							}
							value={t(reviewData?.sizeReview?.[0].title)}
							error={errors?.[nameFields.sizeReview]}
							label={
								<span className={s.lableInner}>
									<img
										src={arrows}
										alt="arrows"
									/>
									{t("modals.productReview.options.0")}
								</span>
							}
							name={nameFields.sizeReview}
							sendTitle
						/>
						<SelectInput
							width={{ xl: "calc(50%-10px)" }}
							marginTop={{ xl: "0" }}
							defaultOption={t(
								reviewData?.colorReview?.[0].title,
							)}
							options={reviewData?.colorReview}
							register={register}
							setValue={setValue}
							errorFromBack={
								errorPostReviewProductBack?.[EErrorsBack.color]
							}
							name={nameFields.colorReview}
							error={errors?.[nameFields.colorReview]}
							label={
								<span className={s.lableInner}>
									<img
										src={palette}
										alt="palette"
									/>
									{t("modals.productReview.options.1")}
								</span>
							}
							value={t(reviewData?.colorReview?.[0].title)}
							sendTitle
						/>
					</div>
					<div className={s.wrapperInputs}>
						<SelectInput
							width={{ xl: "calc(50%-10px)" }}
							marginTop={{ xl: "0" }}
							defaultOption={t(
								reviewData?.qualityReview?.[0].title,
							)}
							options={reviewData?.qualityReview}
							register={register}
							setValue={setValue}
							errorFromBack={
								errorPostReviewProductBack?.[
									EErrorsBack.quality
								]
							}
							name={nameFields.qualityReview}
							error={errors?.[nameFields.qualityReview]}
							label={
								<span className={s.lableInner}>
									<img
										src={diamondIco}
										alt="diamondIco"
									/>
									{t("modals.productReview.options.2")}
								</span>
							}
							value={t(reviewData?.qualityReview?.[0].title)}
							sendTitle
						/>
						<SelectInput
							width={{ xl: "calc(50%-10px)" }}
							marginTop={{ xl: "0" }}
							defaultOption={t(
								reviewData?.deliveryReview?.[0].title,
							)}
							options={reviewData?.deliveryReview}
							register={register}
							setValue={setValue}
							errorFromBack={
								errorPostReviewProductBack?.[
									EErrorsBack.delivery
								]
							}
							name={nameFields.deliveryReview}
							error={errors?.[nameFields.deliveryReview]}
							label={
								<span className={s.lableInner}>
									<img
										src={boxIco}
										alt="boxIco"
									/>
									{t("modals.productReview.options.3")}
								</span>
							}
							value={t(reviewData?.deliveryReview?.[0].title)}
							sendTitle
						/>
					</div>

					<Textarea
						width={{ xl: "100%" }}
						marginTop={{ xl: "20px" }}
						label={""}
						register={register}
						placeholder={t("modals.productReview.review")}
						name={nameFields.text}
						error={errors?.[nameFields.text]}
						errorFromBack={
							errorPostReviewProductBack?.[EErrorsBack.text]
						}
						// defaultValues={defaultValues?.messages}
					/>

					<ButtonFrontBack
						width={{ xl: "230px" }}
						marginTop={{ xl: "25px" }}
						marginRight={{ xl: "auto" }}
						marginLeft={{ xl: "auto" }}
						mode={"red"}
						isButton
						disabledLoading={loadingPostReviewProductBack}
					>
						<span className={s.btnInner}>
							{t("common.button")}
							<img
								src={arrowToIco}
								alt=""
							/>
						</span>
					</ButtonFrontBack>
				</form>
			</div>
		</div>
	);
};

export default ModalProductReview;
