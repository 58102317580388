import { createSlice } from "@reduxjs/toolkit";

//api
import { getRequest, postRequest } from "../../../../API/_api";

//redux
import {
  setModalWindowChildrenType,
  setModalWindowStatus,
  setModalWindowInfo,
} from "../../../App/app";

export const getSellerSettingsCompanyBack = () => (dispatch: any) => {
  dispatch(setLoadingGetSellerSettingsCompanyBack(true));

  const success = (data: any) => {
    dispatch(setDataGetSellerSettingsCompanyBack(data?.data));
  };

  const setError = (error: any) => {
    dispatch(setErrorGetSellerSettingsCompanyBack(error));
  };

  getRequest({
    url: `seller/company-data`,
    success,
    setError,
  });
};

type TPostSellerSettingsCompanyBackProps = {
  obj: any;
};

export const postSellerSettingsCompanyBack =
  (sendData: TPostSellerSettingsCompanyBackProps) => (dispatch: any) => {
    const { obj } = sendData;
    dispatch(setLoadingPostSellerSettingsCompanyBack(true));

    const success = (data: any) => {
      dispatch(setDataPostSellerSettingsCompanyBack(data?.data));
      dispatch(setModalWindowChildrenType("formSuccess"));
      dispatch(
        setModalWindowInfo({
          title: "common.modals",
        })
      );
      dispatch(setModalWindowStatus(true));
      dispatch(getSellerSettingsCompanyBack());
    };

    const setError = (error: any) => {
      dispatch(setErrorPostSellerSettingsCompanyBack(error?.response?.data));
    };

    postRequest({
      url: `seller/company-data`,
      success,
      setError,
      obj,
    });
  };

const initialState: any = {
  dataGetSellerSettingsCompanyBack: null,
  loadingGetSellerSettingsCompanyBack: false,
  errorGetSellerSettingsCompanyBack: null,
  dataPostSellerSettingsCompanyBack: null,
  loadingPostSellerSettingsCompanyBack: false,
  errorPostSellerSettingsCompanyBack: null,
};

export const SellerSettingsCompany = createSlice({
  name: "SellerSettingsCompany",
  initialState,
  reducers: {
    setDataGetSellerSettingsCompanyBack(state, action) {
      state.dataGetSellerSettingsCompanyBack = action.payload;
      state.loadingGetSellerSettingsCompanyBack = false;
    },
    setErrorGetSellerSettingsCompanyBack(state, action) {
      state.errorGetSellerSettingsCompanyBack = action.payload;
      state.loadingGetSellerSettingsCompanyBack = false;
    },
    setLoadingGetSellerSettingsCompanyBack(state, action) {
      state.loadingGetSellerSettingsCompanyBack = action.payload;
      state.errorGetSellerSettingsCompanyBack = null;
    },
    setDataPostSellerSettingsCompanyBack(state, action) {
      state.dataPostSellerSettingsCompanyBack = action.payload;
      state.loadingPostSellerSettingsCompanyBack = false;
    },
    setErrorPostSellerSettingsCompanyBack(state, action) {
      state.errorPostSellerSettingsCompanyBack = action.payload;
      state.loadingPostSellerSettingsCompanyBack = false;
    },
    setLoadingPostSellerSettingsCompanyBack(state, action) {
      state.errorPostSellerSettingsCompanyBack = action.payload;
      state.errorPosttSellerSettingsCompanyBack = null;
    },
  },
});

export const {
  setLoadingGetSellerSettingsCompanyBack,
  setErrorGetSellerSettingsCompanyBack,
  setDataGetSellerSettingsCompanyBack,
  setDataPostSellerSettingsCompanyBack,
  setErrorPostSellerSettingsCompanyBack,
  setLoadingPostSellerSettingsCompanyBack,
} = SellerSettingsCompany.actions;

export default SellerSettingsCompany.reducer;
