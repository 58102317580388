import { useTranslation } from "react-i18next";

//styles
import s from "./ModalFormSuccess.module.scss";

//assets
import { checkGreenIco } from "../../../../assets/icons/iconsConstants";

//components
import ModalClose from "../ModalComponents/ModalClose/ModalClose";

//ui
import TitleWithLine from "../../TitleWithLine/TitleWithLine";

//hooks
import { useAppSelector } from "../../../../hooks/redux";

const ModalFormSuccess = () => {
  const { t } = useTranslation();

  const { modalWindowInfo } = useAppSelector((state) => state.app);

  return (
    <div className={s.root}>
      <ModalClose />
      <div className={s.wrapperTitle}>
        <TitleWithLine
          title={
            modalWindowInfo?.title
              ? t(modalWindowInfo?.title)
              : t("modals.successInfo.title")
          }
          fontSize={{ xl: "18px", lg: "14px" }}
          widthLine={{ xl: "60px", lg: "28px" }}
          heightLine={{ xl: "3px", lg: "2px" }}
        />
      </div>
      <div className={s.content}>
        <p>{t("modals.successInfo.text")}</p>
        <img src={checkGreenIco} alt="checkGreenIco" />
      </div>
    </div>
  );
};

export default ModalFormSuccess;
