import { createSlice } from "@reduxjs/toolkit";

//api
import { getRequest, putRequest } from "../../../API/_api";

//redux
import {
  setModalWindowChildrenType,
  setModalWindowStatus,
  setModalWindowInfo,
} from "../../App/app";

//ts
import {
  TQuestion,
  TParams,
  TFilters,
} from "../../../TS/types/seller/sellerMessages/sellerMessagesQuestion/sellerMessagesQuestion";
import { TMetaBack } from "../../../TS/types/generals/generals";

type TGetSellerMessagesQuestionsBackProps = {
  params: TParams;
  moreProductsActiveButton: boolean;
};

export const getSellerMessagesQuestionsBack =
  (sendData: TGetSellerMessagesQuestionsBackProps) => (dispatch: any) => {
    const { params, moreProductsActiveButton } = sendData;
    dispatch(setLoadingGetSellerMessagesQuestionsBack(true));

    const success = (data: any) => {
      dispatch(
        setDataGetSellerMessagesQuestionsBack({
          data: data?.data,
          meta: data?.meta,
          moreProductsActiveButton: moreProductsActiveButton,
        })
      );
    };

    const setError = (error: any) => {
      dispatch(setErrorGetSellerMessagesQuestionsBack(error));
    };

    getRequest({
      url: `seller/seller-ask`,
      success,
      setError,
      params,
    });
  };

type TPutSellerMessagesQuestionsBackProps = {
  id: any;
};

export const putSellerMessagesQuestionsBack =
  (sendData: TPutSellerMessagesQuestionsBackProps) => (dispatch: any) => {
    const { id } = sendData;
    dispatch(setLoadingPutSellerMessagesQuestionsBack(true));

    const success = (data: any) => {
      dispatch(setDataPutSellerMessagesQuestionsBack(data?.data));
      dispatch(setModalWindowChildrenType("formSuccess"));
      dispatch(
        setModalWindowInfo({
          title: "common.modals",
        })
      );
      dispatch(setModalWindowStatus(true));
    };

    const setError = (error: any) => {
      dispatch(setErrorPutSellerMessagesQuestionsBack(error));
      dispatch(setModalWindowChildrenType("formError"));
      dispatch(
        setModalWindowInfo({
          title: "common.modals",
          text: error?.response?.data?.message,
        })
      );
      dispatch(setModalWindowStatus(true));
    };

    putRequest({
      url: `seller/remove-seller-ask/${id}`,
      success,
      setError,
    });
  };

type TPutAnswerSellerMessagesQuestionsBackProps = {
  id: number;
  obj: any;
};

export const putAnswerSellerMessagesQuestionsBack =
  (sendData: TPutAnswerSellerMessagesQuestionsBackProps) => (dispatch: any) => {
    const { id, obj } = sendData;
    dispatch(setLoadingPutAnswerSellerMessagesQuestionsBack(true));

    const success = (data: any) => {
      dispatch(setDataPutAnswerSellerMessagesQuestionsBack(data?.data));
      dispatch(setModalWindowChildrenType("formSuccess"));
      dispatch(
        setModalWindowInfo({
          title: "common.modals",
        })
      );
      dispatch(setModalWindowStatus(true));
    };

    const setError = (error: any) => {
      dispatch(setErrorPutAnswerSellerMessagesQuestionsBack(error));
      dispatch(setModalWindowChildrenType("formError"));
      dispatch(
        setModalWindowInfo({
          title: "common.modals",
          text: error?.response?.data?.message,
        })
      );
      dispatch(setModalWindowStatus(true));
    };

    putRequest({
      url: `seller/seller-ask/${id}`,
      success,
      setError,
      obj,
    });
  };

type TInitialState = {
  dataGetSellerMessagesQuestionsBack: TQuestion[] | [];
  metaGetSellerMessagesQuestionsBack: TMetaBack | null;
  loadingGetSellerMessagesQuestionsBack: boolean;
  errorGetSellerMessagesQuestionsBack: any;
  filters: TFilters;

  dataPutSellerMessagesQuestionsBack: any;
  loadingPutSellerMessagesQuestionsBack: boolean;
  errorPutSellerMessagesQuestionsBack: any;

  dataPutAnswerSellerMessagesQuestionsBack: any;
  loadingPutAnswerSellerMessagesQuestionsBack: boolean;
  errorPutAnswerSellerMessagesQuestionsBack: any;
};

const initialState: TInitialState = {
  dataGetSellerMessagesQuestionsBack: [],
  metaGetSellerMessagesQuestionsBack: null,
  loadingGetSellerMessagesQuestionsBack: false,
  errorGetSellerMessagesQuestionsBack: null,

  filters: {
    date_from: null,
    date_to: null,
  },

  dataPutSellerMessagesQuestionsBack: null,
  loadingPutSellerMessagesQuestionsBack: false,
  errorPutSellerMessagesQuestionsBack: null,

  dataPutAnswerSellerMessagesQuestionsBack: null,
  loadingPutAnswerSellerMessagesQuestionsBack: false,
  errorPutAnswerSellerMessagesQuestionsBack: null,
};

export const sellerMessagesQuestions = createSlice({
  name: "sellerMessagesQuestions",
  initialState,
  reducers: {
    setDataGetSellerMessagesQuestionsBack(state, action) {
      state.dataGetSellerMessagesQuestionsBack = action.payload
        .moreProductsActiveButton
        ? [...state.dataGetSellerMessagesQuestionsBack, ...action.payload.data]
        : action.payload.data;
      state.metaGetSellerMessagesQuestionsBack = action.payload.meta;
      state.loadingGetSellerMessagesQuestionsBack = false;
    },

    setErrorGetSellerMessagesQuestionsBack(state, action) {
      state.errorGetSellerMessagesQuestionsBack = action.payload;
      state.loadingGetSellerMessagesQuestionsBack = false;
    },
    setLoadingGetSellerMessagesQuestionsBack(state, action) {
      state.loadingGetSellerMessagesQuestionsBack = action.payload;
      state.errorGetSellerMessagesQuestionsBack = null;
    },
    setFiltersSellerMessagesQuestions(state, action) {
      state.filters = {
        ...state.filters,
        [action.payload.name]: action.payload.value,
      };
    },

    setFiltersRemoveSellerMessagesQuestions(state) {
      state.filters = {
        date_from: "",
        date_to: "",
      };
    },

    setDataPutSellerMessagesQuestionsBack(state, action) {
      state.dataPutSellerMessagesQuestionsBack = action.payload;
      state.loadingPutSellerMessagesQuestionsBack = false;
    },
    setErrorPutSellerMessagesQuestionsBack(state, action) {
      state.errorPutSellerMessagesQuestionsBack = action.payload;
      state.loadingPutSellerMessagesQuestionsBack = false;
    },
    setLoadingPutSellerMessagesQuestionsBack(state, action) {
      state.loadingPutSellerMessagesQuestionsBack = action.payload;
      state.errorPutSellerMessagesQuestionsBack = null;
    },
    setDataPutAnswerSellerMessagesQuestionsBack(state, action) {
      state.dataPutAnswerSellerMessagesQuestionsBack = action.payload;
      state.loadingPutAnswerSellerMessagesQuestionsBack = false;
    },
    setErrorPutAnswerSellerMessagesQuestionsBack(state, action) {
      state.errorPutAnswerSellerMessagesQuestionsBack = action.payload;
      state.loadingPutAnswerSellerMessagesQuestionsBack = false;
    },
    setLoadingPutAnswerSellerMessagesQuestionsBack(state, action) {
      state.loadingPutAnswerSellerMessagesQuestionsBack = action.payload;
      state.errorPutAnswerSellerMessagesQuestionsBack = null;
    },
  },
});

export const {
  setLoadingGetSellerMessagesQuestionsBack,
  setErrorGetSellerMessagesQuestionsBack,
  setDataGetSellerMessagesQuestionsBack,
  setFiltersSellerMessagesQuestions,
  setDataPutSellerMessagesQuestionsBack,
  setErrorPutSellerMessagesQuestionsBack,
  setLoadingPutSellerMessagesQuestionsBack,
  setFiltersRemoveSellerMessagesQuestions,
  setDataPutAnswerSellerMessagesQuestionsBack,
  setErrorPutAnswerSellerMessagesQuestionsBack,
  setLoadingPutAnswerSellerMessagesQuestionsBack,
} = sellerMessagesQuestions.actions;

export default sellerMessagesQuestions.reducer;
