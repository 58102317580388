import { createSlice } from "@reduxjs/toolkit";

//api
import { getRequest, postRequest, deleteRequest } from "../../../API/_api";

export const getSellerProductsBack =
  ({ params, moreProductsActiveButton }: any) =>
  (dispatch: any) => {
    dispatch(setLoadingGetSellerProductsBack(true));

    const success = (data: any) => {
      dispatch(
        setDataGetSellerProductsBack({
          data: data?.data,
          meta: data?.meta,
          moreProductsActiveButton: moreProductsActiveButton,
        })
      );
    };

    const setError = (error: any) => {
      dispatch(setErrorGetSellerProductsBack(error));
    };

    getRequest({
      url: `seller/products`,
      success,
      setError,
      params,
    });
  };

type TDeleteSellerProductsBackProps = {
  id: number;
  getProducts: any;
};

export const deleteSellerProductsBack =
  (sendData: TDeleteSellerProductsBackProps) => (dispatch: any) => {
    const { id, getProducts } = sendData;
    dispatch(setLoadingDeleteSellerProductsBack(true));

    const success = (data: any) => {
      dispatch(setDataDeleteSellerProductsBack(data?.data));
      getProducts();
    };

    const setError = (error: any) => {
      dispatch(setErrorDeleteSellerProductsBack(error));
    };

    deleteRequest({
      url: `seller/products/${id}`,
      success,
      setError,
    });
  };

type TPostSellerProductsBackProps = {
  obj: any;
  getProducts: any;
};

export const postSellerProductsBack =
  (sendData: TPostSellerProductsBackProps) => (dispatch: any) => {
    const { obj, getProducts } = sendData;
    dispatch(setLoadingPostSellerProductsBack(true));

    const success = (data: any) => {
      dispatch(setDataPostSellerProductsBack(data?.data));
      getProducts();
    };

    const setError = (error: any) => {
      dispatch(setErrorPostSellerProductsBack(error));
    };

    postRequest({
      url: `seller/products/bulk-action`,
      success,
      setError,
      obj,
    });
  };

type TPostRemovedSellerProductsBackProps = {
  obj: any;
  getProducts: any;
  id: number;
};

export const postRemovedSellerProductsBack =
  (sendData: TPostRemovedSellerProductsBackProps) => (dispatch: any) => {
    const { obj, id, getProducts } = sendData;
    dispatch(setLoadingPostRemovedSellerProductsBack(true));

    const success = (data: any) => {
      dispatch(setDataPostRemovedSellerProductsBack(data?.data));
      getProducts();
    };

    const setError = (error: any) => {
      dispatch(setErrorPostRemovedSellerProductsBack(error));
    };

    postRequest({
      url: `seller/deleted-products/${id}`,
      success,
      setError,
      obj,
    });
  };

const initialState: any = {
  dataGetSellerProductsBack: null,
  metaGetSellerProductsBack: null,
  loadingGetSellerProductsBack: false,
  errorGetSellerProductsBack: null,
  dataPostSellerProductsBack: null,
  loadingPostSellerProductsBack: false,
  errorPostSellerProductsBack: null,
  dataDeleteSellerProductsBack: null,
  loadingDeleteSellerProductsBack: false,
  errorDeleteSellerProductsBack: null,
  dataPostRemovedSellerProductsBack: null,
  loadingPostRemovedSellerProductsBack: false,
  errorPostRemovedSellerProductsBack: null,

  filters: {
    code: null,
    categoryId: null,
    productName: null,
    price_min: null,
    price_max: null,
  },
};

export const sellerProducts = createSlice({
  name: "sellerProducts",
  initialState,
  reducers: {
    setDataGetSellerProductsBack(state, action) {
      state.dataGetSellerProductsBack = action.payload.moreProductsActiveButton
        ? [...state.dataGetSellerProductsBack, ...action.payload.data]
        : action.payload.data;
      state.metaGetSellerProductsBack = action.payload.meta;
      state.loadingGetSellerProductsBack = false;
      state.errorGetSellerProductsBack = null;
    },

    setErrorGetSellerProductsBack(state, action) {
      state.errorGetSellerProductsBack = action.payload;
      state.loadingGetSellerProductsBack = false;
    },
    setLoadingGetSellerProductsBack(state, action) {
      state.loadingGetSellerProductsBack = action.payload;
      state.errorGetSellerProductsBack = null;
    },
    setDataPostSellerProductsBack(state, action) {
      state.dataPostSellerProductsBack = action.payload;
      state.loadingPostSellerProductsBack = false;
    },
    setErrorPostSellerProductsBack(state, action) {
      state.errorPostGetSellerProductsBack = action.payload;
      state.loadingPostSellerProductsBack = false;
    },
    setLoadingPostSellerProductsBack(state, action) {
      state.loadingPosttSellerProductsBack = action.payload;
      state.errorPosttSellerProductsBack = null;
    },
    setDataDeleteSellerProductsBack(state, action) {
      state.dataDeleteSellerProductsBack = action.payload;
      state.loadingDeleteSellerProductsBack = false;
    },
    setErrorDeleteSellerProductsBack(state, action) {
      state.errorDeleteGetSellerProductsBack = action.payload;
      state.loadingDeleteSellerProductsBack = false;
    },
    setLoadingDeleteSellerProductsBack(state, action) {
      state.loadingDeleteSellerProductsBack = action.payload;
      state.errorDeletetSellerProductsBack = null;
    },
    setDataPostRemovedSellerProductsBack(state, action) {
      state.dataPostRemovedSellerProductsBack = action.payload;
      state.loadingPostRemovedSellerProductsBack = false;
      state.errorPostRemovedSellerProductsBack = null;
    },
    setLoadingPostRemovedSellerProductsBack(state, action) {
      state.errorDeleteGetSellerProductsBack = action.payload;
      state.loadingPostRemovedSellerProductsBack = false;
    },
    setErrorPostRemovedSellerProductsBack(state, action) {
      state.loadingPostRemovedSellerProductsBack = action.payload;
      state.errorPostRemovedSellerProductsBack = null;
    },
    setFiltersSellerProducts(state, action) {
      state.filters = {
        ...state.filters,
        [action.payload.name]: action.payload.value,
      };
    },
    setRemoveAllFiltersSellerProducts(state, action) {
      state.filters = {
        code: "",
        categoryId: "",
        productName: "",
        price_min: "",
        price_max: "",
      };
    },
  },
});

export const {
  setLoadingGetSellerProductsBack,
  setErrorGetSellerProductsBack,
  setDataGetSellerProductsBack,
  setDataPostSellerProductsBack,
  setErrorPostSellerProductsBack,
  setLoadingPostSellerProductsBack,
  setFiltersSellerProducts,
  setLoadingDeleteSellerProductsBack,
  setDataDeleteSellerProductsBack,
  setErrorDeleteSellerProductsBack,
  setLoadingPostRemovedSellerProductsBack,
  setDataPostRemovedSellerProductsBack,
  setErrorPostRemovedSellerProductsBack,
  setRemoveAllFiltersSellerProducts,
} = sellerProducts.actions;

export default sellerProducts.reducer;
