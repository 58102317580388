import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import { AsyncPaginate } from "react-select-async-paginate";
import { customStylesSelect } from "./styles";

function SelectAsyncPaginateSearch({
	value,
	onChange,
	item,
	isDarkBackgroundColor,
}) {
	const { t } = useTranslation();
	const theme = useTheme();

	const settings = item?.settings || {};

	// FUNCTIONS
	const loadOptions = async (searchQuery, loadedOptions, { page }) => {
		const returnData = {
			options: [],
			hasMore: false,
			additional: {
				page,
			},
		};

		if (searchQuery.length < 3) {
			return returnData;
		}

		if (!settings?.getSearchRequest) return returnData;

		const payload = await settings.getSearchRequest(searchQuery, page);

		if (!payload?.options || !payload?.count) return returnData;

		return {
			...returnData,
			options: payload?.options || [],
			hasMore: payload?.count
				? loadedOptions.length + payload?.options?.length <
					payload?.count
				: false,
			additional: {
				page: page + 1,
			},
		};
	};

	let settingsAsyncPaginate = {};
	if (settings) {
		const {
			getOptionValue,
			getOptionLabel,
			getSearchRequest,
			...otherSettings
		} = settings;
		if (settings.getOptionValue)
			settingsAsyncPaginate.getOptionValue = settings.getOptionValue;
		if (settings.getOptionLabel)
			settingsAsyncPaginate.getOptionLabel = settings.getOptionLabel;
		if (settings.getSearchRequest)
			settingsAsyncPaginate.loadOptions = loadOptions;
		settingsAsyncPaginate = { ...settingsAsyncPaginate, ...otherSettings };
	}

	return (
		<AsyncPaginate
			debounceTimeout={700}
			styles={customStylesSelect({ theme, isDarkBackgroundColor })}
			value={value}
			onChange={(selected) => {
				onChange({
					val: selected,
					isSetValue: true,
				});
			}}
			placeholder={t(item?.placeholder)}
			additional={{
				page: 1,
			}}
			{...settingsAsyncPaginate}
		/>
	);
}

export default SelectAsyncPaginateSearch;
