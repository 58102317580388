import { createSlice } from "@reduxjs/toolkit";

//api
import { getRequest, putRequest } from "../../../API/_api";

//redux
import {
  setModalWindowChildrenType,
  setModalWindowStatus,
  setModalWindowInfo,
} from "../../App/app";

export const getSellerMessagesProductsReviewsBack =
  ({ params }: any) =>
  (dispatch: any) => {
    dispatch(setLoadingGetSellerMessagesProductsReviewsBack(true));

    const success = (data: any) => {
      dispatch(setDataGetSellerMessagesProductsReviewsBack(data?.data));
      dispatch(setMetaGetSellerMessagesProductsReviewsBack(data?.meta));
    };

    const setError = (error: any) => {
      dispatch(setErrorGetSellerMessagesProductsReviewsBack(error));
    };

    getRequest({
      url: `seller/seller-product-feed-back`,
      success,
      setError,
      params,
    });
  };

type TPutSellerMessagesProductsReviewsBackProps = {
  id: any;
};

export const putSellerMessagesProductsReviewsBack =
  (sendData: TPutSellerMessagesProductsReviewsBackProps) => (dispatch: any) => {
    const { id } = sendData;
    dispatch(setLoadingPutSellerMessagesProductsReviewsBack(true));

    const success = (data: any) => {
      dispatch(setDataPutSellerMessagesProductsReviewsBack(data?.data));
      dispatch(setModalWindowChildrenType("formSuccess"));
      dispatch(
        setModalWindowInfo({
          title: "common.modals",
        })
      );
      dispatch(setModalWindowStatus(true));
    };

    const setError = (error: any) => {
      dispatch(setErrorPutSellerMessagesProductsReviewsBack(error));
      dispatch(setModalWindowChildrenType("formError"));
      dispatch(
        setModalWindowInfo({
          title: "common.modals",
          text: error?.response?.data?.message,
        })
      );
      dispatch(setModalWindowStatus(true));
    };

    putRequest({
      url: `seller/remove-seller-product-feedback-request/${id}`,
      success,
      setError,
    });
  };

type TPutAnswerSellerMessagesProductsReviewsBackProps = {
  id: number;
  obj: any;
};

export const putAnswerSellerMessagesProductsReviewsBack =
  (sendData: TPutAnswerSellerMessagesProductsReviewsBackProps) =>
  (dispatch: any) => {
    const { id, obj } = sendData;
    dispatch(setLoadingPutAnswerSellerMessagesProductsReviewsBack(true));

    const success = (data: any) => {
      dispatch(setDataPutAnswerSellerMessagesProductsReviewsBack(data?.data));
      dispatch(setModalWindowChildrenType("formSuccess"));
      dispatch(
        setModalWindowInfo({
          title: "common.modals",
        })
      );
      dispatch(setModalWindowStatus(true));
    };

    const setError = (error: any) => {
      dispatch(setErrorPutAnswerSellerMessagesProductsReviewsBack(error));
      dispatch(setModalWindowChildrenType("formError"));
      dispatch(
        setModalWindowInfo({
          title: "common.modals",
          text: error?.response?.data?.message,
        })
      );
      dispatch(setModalWindowStatus(true));
    };

    putRequest({
      url: `seller/seller-product-feed-back/${id}`,
      success,
      setError,
      obj,
    });
  };

const initialState: any = {
  dataGetSellerMessagesProductsReviewsBack: null,
  metaGetSellerMessagesProductsReviewsBack: null,
  loadingGetSellerMessagesProductsReviewsBack: false,
  errorGetSellerMessagesProductsReviewsBack: null,

  filters: {
    categoryId: null,
    productName: null,
    price_min: null,
    price_max: null,
    date_from: null,
    date_to: null,
  },

  dataPutSellerMessagesProductsReviewsBack: null,
  loadingPutSellerMessagesProductsReviewsBack: false,
  errorPutSellerMessagesProductsReviewsBack: null,

  dataPutAnswerSellerMessagesProductsReviewsBack: null,
  loadingPutAnswerSellerMessagesProductsReviewsBack: false,
  errorPutAnswerSellerMessagesProductsReviewsBack: null,
};

export const sellerMessagesProductsReviews = createSlice({
  name: "sellerMessagesProductsReviews",
  initialState,
  reducers: {
    setDataGetSellerMessagesProductsReviewsBack(state, action) {
      state.dataGetSellerMessagesProductsReviewsBack = action.payload;
      state.loadingGetSellerMessagesProductsReviewsBack = false;
      state.errorGetSellerMessagesProductsReviewsBack = null;
    },
    setMetaGetSellerMessagesProductsReviewsBack(state, action) {
      state.metaGetSellerMessagesProductsReviewsBack = action.payload;
      state.loadingGetSellerMessagesProductsReviewsBack = false;
    },
    setErrorGetSellerMessagesProductsReviewsBack(state, action) {
      state.errorGetSellerMessagesProductsReviewsBack = action.payload;
      state.loadingGetSellerMessagesProductsReviewsBack = false;
    },
    setLoadingGetSellerMessagesProductsReviewsBack(state, action) {
      state.loadingGetSellerMessagesProductsReviewsBack = action.payload;
      state.errorGetSellerMessagesProductsReviewsBack = null;
    },
    setFiltersSellerMessagesProductsReviews(state, action) {
      state.filters = {
        ...state.filters,
        [action.payload.name]: action.payload.value,
      };
    },
    setFiltersRemoveSellerMessagesProductsReviews(state, action) {
      state.filters = {
        categoryId: "",
        productName: "",
        price_min: "",
        price_max: "",
        date_from: "",
        date_to: "",
      };
    },

    setDataPutSellerMessagesProductsReviewsBack(state, action) {
      state.dataPutSellerMessagesProductsReviewsBack = action.payload;
      state.loadingPutSellerMessagesProductsReviewsBack = false;
    },
    setErrorPutSellerMessagesProductsReviewsBack(state, action) {
      state.errorPutSellerMessagesProductsReviewsBack = action.payload;
      state.loadingPutSellerMessagesProductsReviewsBack = false;
    },
    setLoadingPutSellerMessagesProductsReviewsBack(state, action) {
      state.loadingPutSellerMessagesProductsReviewsBack = action.payload;
      state.errorPutSellerMessagesProductsReviewsBack = null;
    },

    setDataPutAnswerSellerMessagesProductsReviewsBack(state, action) {
      state.dataPutAnswerSellerMessagesProductsReviewsBack = action.payload;
      state.loadingPutAnswerSellerMessagesProductsReviewsBack = false;
    },
    setErrorPutAnswerSellerMessagesProductsReviewsBack(state, action) {
      state.errorPutAnswerSellerMessagesProductsReviewsBack = action.payload;
      state.loadingPutAnswerSellerMessagesProductsReviewsBack = false;
    },
    setLoadingPutAnswerSellerMessagesProductsReviewsBack(state, action) {
      state.loadingPutAnswerSellerMessagesProductsReviewsBack = action.payload;
      state.errorPutAnswerSellerMessagesProductsReviewsBack = null;
    },
  },
});

export const {
  setLoadingGetSellerMessagesProductsReviewsBack,
  setMetaGetSellerMessagesProductsReviewsBack,
  setErrorGetSellerMessagesProductsReviewsBack,
  setDataGetSellerMessagesProductsReviewsBack,
  setFiltersSellerMessagesProductsReviews,
  setFiltersRemoveSellerMessagesProductsReviews,
  setDataPutSellerMessagesProductsReviewsBack,
  setErrorPutSellerMessagesProductsReviewsBack,
  setLoadingPutSellerMessagesProductsReviewsBack,
  setDataPutAnswerSellerMessagesProductsReviewsBack,
  setErrorPutAnswerSellerMessagesProductsReviewsBack,
  setLoadingPutAnswerSellerMessagesProductsReviewsBack,
} = sellerMessagesProductsReviews.actions;

export default sellerMessagesProductsReviews.reducer;
