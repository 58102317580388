export const reasons = [
  { id: 1, title: "Не підійшов колір або розмір", value: 1 },
  { id: 2, title: "Товар не такий, як я очікував(-ла)", value: 2 },
  { id: 3, title: "Невідповідає опису на фото", value: 3 },
  { id: 4, title: "Отримано товар, який я не оплачував(-ла)", value: 4 },
  {
    id: 5,
    title: "Товар неналежної якості, пошкоджений або в неповній комплектації",
    value: 5,
  },
  { id: 6, title: "Інша причина", value: 6 },
];
