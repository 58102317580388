import { createSlice } from "@reduxjs/toolkit";
import { event } from "react-ga";

//api
import { postRequest, getRequest, putRequest } from "../../API/_api";

//redux
import {
	setModalWindowChildrenType,
	setModalWindowStatus,
	setModalWindowInfo,
	setToken,
} from "../App/app";

import { pathFC, paths } from "../../App/Routes/paths";

//ts
import { TUser } from "../../TS/types/user/user";
import { sellerVariantsContent } from "../../TS/enums/generls";

const tokenShopping = localStorage.getItem("token_shopping");

const recentlyProductsLocal = localStorage.getItem("recentlyProducts");

const addRecentlyProduct = (recentlyProducts: any, payload: any) => {
	let itemWithId = false;

	const recently = recentlyProducts;

	recently.forEach((item: any) => {
		if (item.id === payload.id) {
			return (itemWithId = true);
		}
	});

	if (!itemWithId) {
		recently.unshift(payload);
	}
	if (recently?.length > 20) {
		recently.pop();
	}

	return recently;
};

//profileVerifyPhoneCode
export const profileVerifyPhoneCode = (data: any) => (dispatch: any) => {
	const { obj, headers } = data;

	const cb = data?.cb || null;

	dispatch(setLoadingVerifyPhoneCode(false));
	const success = (data: any) => {
		dispatch(setVerifyPhoneCode(data.data));
		dispatch(setModalWindowStatus(false));
		if (cb) {
			cb();
		} else {
			window.location.reload();
		}
	};

	const setError = (error: any) => {
		dispatch(setErrorPostVerifyPhoneCode(error));
	};

	postRequest({
		url: `auth/phone/verify`,
		obj,
		success,
		setError,
		headers,
	});
};

//resendCodeOnPhone
export const resendCodeOnPhone = (type: string, headers?: any) => {
	postRequest({
		url: `auth/${type}/resend_verification_code`,
		headers,
	});
};

//profileLogin
export const profileLogin = (data: any) => (dispatch: any) => {
	const { obj, headers, from } = data;

	const cbUp = data?.cb || null;

	dispatch(setLoadingStatusLogin(false));
	const success = (data: any) => {
		dispatch(setToken(data?.access_token));
		dispatch(setIsLogged(true));

		if (from === "registration") {
			event({ category: "customer_registration", action: "click" });
			if (data?.history) {
				data.history?.push(pathFC(paths.profilePersonal));
			}
		}

		localStorage.setItem(
			"token_shopping",
			JSON.stringify(data?.access_token),
		);
		setModalWindowStatus(false);

		if (from !== "registration") {
			dispatch(
				getUser({
					token: data?.access_token,
					from: "login",
				}),
			);
		}

		// from !== "registration" && window.location.reload();

		if (from === "registration") {
			dispatch(
				getUser({
					token: data?.access_token,
				}),
			);

			dispatch(
				setModalWindowInfo({
					cb: cbUp,
				}),
			);
			dispatch(setModalWindowChildrenType("phoneVerify"));
			dispatch(setModalWindowStatus(true));
		}

		data?.cb || data.cb();
	};

	const setError = (error: any) => {
		dispatch(setErrorPostProfileLogin(error));
	};

	postRequest({
		url: `auth/login`,
		obj,
		success,
		setError,
		headers,
	});
};

//getUser
export const getUser = (sendData?: any) => (dispatch: any) => {
	let headers: any = {};
	if (sendData?.token) {
		headers.Authorization = `Bearer ${sendData?.token}`;
	}

	dispatch(setLoadingGetUser(true));
	const success = (data: any) => {
		dispatch(setUserData(data.data));
		if (sendData?.from === "login" && data.data?.role === "Seller") {
			window.location.href = pathFC(sellerVariantsContent.sellerProfile);
		}
		if (sendData?.from === "login" && data.data?.role !== "Seller") {
			dispatch(setModalWindowInfo({}));
			dispatch(setModalWindowChildrenType(""));
			dispatch(setModalWindowStatus(false));
		}
	};

	const setError = (error: any) => {
		dispatch(setErrorGetUser(error));
	};

	getRequest({
		url: `auth/user`,
		success,
		setError,
		headers,
	});
};

//postUserData
export const postUserData = (data: any) => (dispatch: any) => {
	const { obj, slug } = data;

	dispatch(setLoadingPostUser(true));
	const success = (data: any) => {
		dispatch(setUserPostData(data.data));
		dispatch(setModalWindowChildrenType("formSuccess"));
		dispatch(setModalWindowStatus(true));
		dispatch(getUser());
	};

	const setError = (error: any) => {
		dispatch(setErrorPostUser(error?.response?.data));
		dispatch(setModalWindowChildrenType("formError"));
		dispatch(setModalWindowStatus(true));
	};

	const urlWithSlug = slug ? `user/${slug}` : `user`;

	postRequest({
		url: urlWithSlug,
		obj,
		success,
		setError,
	});
};

//putUserData
export const putUserData = (data: any) => (dispatch: any) => {
	const { obj, slug } = data;

	dispatch(setLoadingPutUser(true));
	const success = (data: any) => {
		dispatch(setUserPutData(data.data));
		dispatch(setModalWindowChildrenType("formSuccess"));
		dispatch(setModalWindowStatus(true));
		dispatch(getUser());
	};

	const setError = (error: any) => {
		dispatch(setErrorPutUser(error?.response?.data));
		dispatch(setModalWindowChildrenType("formError"));
		dispatch(setModalWindowStatus(true));
	};

	const urlWithSlug = slug ? `user/${slug}` : `user`;

	putRequest({
		url: urlWithSlug,
		obj,
		success,
		setError,
	});
};

type TInitialState = {
	loadingStatusLogin: boolean;
	errorLogin: any;
	isLogged: boolean;
	userData: TUser | null;
	errorGetUser: any;
	userPutData: any;
	loadingGetUser: boolean;
	userPostData: boolean;
	errorPostUser: any;
	loadingPostUser: boolean;
	userVerifyPhoneCode: boolean;
	errorVerifyPhoneCode: any;
	loadingVerifyPhoneCode: boolean;
	loadingPutUser: boolean;
	errorPutUser: any;
	recentlyProducts: any | [];
};

const initialState: TInitialState = {
	loadingStatusLogin: false,
	errorLogin: null,
	isLogged: JSON.parse(tokenShopping!) ? true : false,
	userData: null,
	userPutData: null,
	errorGetUser: null,
	loadingGetUser: false,
	userPostData: false,
	errorPostUser: null,
	loadingPostUser: false,
	userVerifyPhoneCode: false,
	errorVerifyPhoneCode: null,
	loadingVerifyPhoneCode: false,
	loadingPutUser: false,
	errorPutUser: null,
	recentlyProducts:
		(recentlyProductsLocal && JSON.parse(recentlyProductsLocal)) || [],
};

export const user = createSlice({
	name: "user",
	initialState,
	reducers: {
		userAddRecentlyProduct(state, action) {
			state.recentlyProducts = addRecentlyProduct(
				state.recentlyProducts,
				action.payload,
			);
		},
		setLoadingStatusLogin(state, action) {
			state.loadingStatusLogin = action.payload;
			state.errorLogin = null;
		},
		setErrorPostProfileLogin(state, action) {
			state.errorLogin = action.payload;
		},
		setUserData(state, action) {
			state.userData = action.payload;
			state.loadingGetUser = false;
			state.errorGetUser = null;
		},
		setLoadingGetUser(state, action) {
			state.loadingGetUser = action.payload;
		},
		setErrorGetUser(state, action) {
			state.errorGetUser = action.payload;
			state.loadingGetUser = false;
		},
		setLoadingPostUser(state, action) {
			state.loadingPostUser = action.payload;
		},
		setErrorPostUser(state, action) {
			state.errorPostUser = action.payload;
			state.loadingPostUser = false;
		},
		setUserPostData(state, action) {
			state.userPostData = action.payload;
			state.loadingPostUser = false;
			state.errorPostUser = null;
		},
		setLoadingPutUser(state, action) {
			state.loadingPutUser = action.payload;
		},
		setErrorPutUser(state, action) {
			state.errorPutUser = action.payload;
			state.loadingPutUser = false;
		},
		setUserPutData(state, action) {
			state.userPutData = action.payload;
			state.loadingPutUser = false;
			state.errorPutUser = null;
		},
		setLoadingVerifyPhoneCode(state, action) {
			state.loadingVerifyPhoneCode = action.payload;
		},
		setErrorPostVerifyPhoneCode(state, action) {
			state.errorVerifyPhoneCode = action.payload;
			state.loadingVerifyPhoneCode = false;
		},
		setVerifyPhoneCode(state, action) {
			state.userVerifyPhoneCode = action.payload;
			state.loadingVerifyPhoneCode = false;
			state.errorVerifyPhoneCode = null;
		},
		setIsLogged(state, action) {
			state.isLogged = action.payload;
		},
	},
});

export const {
	userAddRecentlyProduct,
	setLoadingStatusLogin,
	setErrorPostProfileLogin,
	setUserData,
	setErrorGetUser,
	setLoadingGetUser,
	setUserPostData,
	setErrorPostUser,
	setLoadingPostUser,
	setUserPutData,
	setErrorPutUser,
	setLoadingPutUser,
	setErrorPostVerifyPhoneCode,
	setVerifyPhoneCode,
	setLoadingVerifyPhoneCode,
	setIsLogged,
} = user.actions;

export default user.reducer;
