// APPROVED
import InputAdornment from "@mui/material/InputAdornment";
import { SDInputBase } from "./styles";

const InputBaseComponent = ({
	item,
	value,
	onChange,
	optionsTags = {
		input: {},
		inputWrapper: {},
	},
	idField,
	sxOptions,
	t,
	isDarkBackgroundColor,
}) => <SDInputBase
		id={idField}
		className="WInput"
		name={item.fieldName}
		onChange={(e) => onChange({ e })}
		value={value}
		placeholder={t(item.placeholder)}
		type={item?.typeField || "text"}
		sx={sxOptions.inputWrapper}
		{...item?.inputProps}
		{...optionsTags?.input}
		{...optionsTags?.inputWrapper}
		startAdornment={
			item?.inputAdornments?.start ? (
				<InputAdornment position="start">
					{item?.inputAdornments?.start.icon}
				</InputAdornment>
			) : null
		}
	/>


export default InputBaseComponent;
