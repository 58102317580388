
export const customStylesSelect = ({ theme, isDarkBackgroundColor }) => ({
	container: (provided) => ({
		...provided,
	}),
	valueContainer: (provided) => ({
		...provided,
		overflow: "auto",
		maxHeight: "100px",
	}),
	multiValue: (provided) => ({
		...provided,
		backgroundColor: theme.palette.customColors.bg.bg_2,
	}),

	multiValueLabel: (provided) => ({
		...provided,
		color: theme.palette.customColors.text.t_7,
	}),
	multiValueGeneric: (provided) => ({
		...provided,
		color: theme.palette.customColors.text.t_7,
	}),

	control: (provided) => ({
		...provided,
		boxShadow: "none",
		minWidth: 200,
		width: "100%",
		fontSize: "16px",
		backgroundColor: theme.palette.customColors.bg.bg_2,
		color: theme.palette.customColors.text.t_7,
	}),
	input: (baseStyles) => ({
		...baseStyles,
		caretColor: theme.palette.customColors.text.t_7,
		color: theme.palette.customColors.text.t_7,
	}),
	singleValue: (provided) => {
		const transition = "opacity 300ms";
		return {
			...provided,
			color: theme.palette.customColors.text.t_7,
			transition,
		};
	},
	menu: (provided) => ({
		...provided,
		zIndex: 2,
	}),
	menuList: (provided) => ({
		...provided,
		fontSize: "16px",
		backgroundColor: theme.palette.customColors.bg.bg_2,
		color: theme.palette.customColors.text.t_7,
	}),
	option: (provided) => ({
		...provided,
		backgroundColor: "transparent", // Change background color on hover
		cursor: "pointer", // Change cursor to pointer on hover
		":hover": {
			backgroundColor: theme.palette.customColors.bg.bg_2,
		},
		":click": { backgroundColor: "transparent" },
	}),
	placeholder: (provided) => ({
		...provided,
		color: theme.palette.customColors.text.t_7,
	}),
	indicatorContainer: (provided) => ({
		...provided,
		color: theme.palette.customColors.text.t_7,
	}),
	indicatorSeparator: (provided) => ({
		...provided,
		display: "none",
	}),
});
