const Container = () => ({
	MuiContainer: {
		styleOverrides: {
			root: {
				padding: "0 15px",
			},
		},
	},
});

export default Container;
