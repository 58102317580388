import { createSlice, PayloadAction } from "@reduxjs/toolkit";

//api
import { getRequest } from "../../API/_api";

type sendData = {
  slug: string;
};

type TdiscountImagesInitialState = {
  categoriesGendersBack: any;
  loadingCategoriesGendersBack: boolean;
  errorCategoriesGendersBack: any;
};

const initialState: TdiscountImagesInitialState = {
  categoriesGendersBack: [],
  loadingCategoriesGendersBack: false,
  errorCategoriesGendersBack: null,
};

export const getCategoriesBack = (sendData: sendData) => (dispatch: any) => {
  const { slug } = sendData;
  dispatch(setLoadingCategoriesGendersBack(true));
  const success = (data: any) => {
    dispatch(successCategoriesGendersBack(data.data));
  };

  const setError = (error: any) => {
    dispatch(setErrorCategoriesGendersBack(error));
  };

  getRequest({
    url: `categories/${slug}`,
    success,
    setError,
  });
};

export const categories = createSlice({
  name: "categories",
  initialState,
  reducers: {
    successCategoriesGendersBack(state, action) {
      state.categoriesGendersBack = action.payload;
      state.loadingCategoriesGendersBack = false;
    },
    setLoadingCategoriesGendersBack(state, action) {
      state.loadingCategoriesGendersBack = action.payload;
      state.errorCategoriesGendersBack = null;
    },
    setErrorCategoriesGendersBack(state, action) {
      state.errorCategoriesGendersBack = action.payload;
      state.loadingCategoriesGendersBack = false;
    },
  },
});
export const {
  successCategoriesGendersBack,
  setLoadingCategoriesGendersBack,
  setErrorCategoriesGendersBack,
} = categories.actions;

export default categories.reducer;
