const Avatar = () => ({
	MuiAvatar: {
		styleOverrides: {
			root: {
				width: "41px",
				height: "41px",
			},
		},
	},
});

export default Avatar;
