// APPROVED +
export const pathBackAuth = {
	auth: "/auth",
	authPhoneVerify: "/auth/phone/verify",
	authLogin: "/auth/login",
	authUser: "/auth/user",
	authForgotPassword: "/auth/forgot-password",
	authRegister: "/auth/register",
	authSellerRegister: "/auth/seller/register",
	authResetPassword: "/auth/reset-password",
};

export const funPathBackSeller = (args) => {
	const slug1 = args?.slug1 || null;

	return {
		seller: "/seller",
		sellerProductsId: `/seller/products/${slug1}`, // id
		sellerProductFeedBack: `/seller/seller-product-feed-back`,
		sellerRemoveProductFeedbackRequest: `/seller/remove-seller-product-feedback-request/${slug1}`, // id
		sellerProductFeedBackId: `/seller/seller-product-feed-back/${slug1}`, // id
		sellerAsk: `/seller/seller-ask`,
		sellerRemoveAskId: `seller/remove-seller-ask/${slug1}`, // id
		sellerAskID: `/seller/seller-ask/${slug1}`, // id
		sellerFeedBack: `/seller/feed-back`,
		sellerCreateFeedback: `/seller/create/feedback`,
		sellerRemoveFeedbackRequest: `/seller/remove-seller-feedback-request/${slug1}`, // id
		sellerFeedBackId: `/seller/feed-back/${slug1}`,
		sellerOrders: `/seller/orders`,
		sellerOrdersId: `/seller/orders/${slug1}`,
		sellerProducts: `/seller/products`,
		sellerProductsBulkAction: `/seller/products/bulk-action`,
		sellerDeletedProducts: `/seller/deleted-products/${slug1}`, // id
		sellerSellerpaymentParams: `/seller/sellerpayment/${slug1}/payment?button_text=Оплатить&button__front=auto-click-button`,
		sellerCreatePayment: `/seller/create/payment`,
		sellerCompanyData: `/seller/company-data`,
		sellerRefundData: `/seller/refund-data`,
		sellerDeliveryAvailable: `/seller/delivery-available`,
		sellerDeliverySelect: `/seller/delivery-select`,
		sellerDeliveryData: `/seller/delivery-data`,
		sellerLegalData: `/seller/legal-data`,
		sellerPaymentData: `/seller/payment-data`,
		sellerProfile: `/seller/profile`,
	};
};

export const funPathBackOrders = (args) => {
	const slug1 = args?.slug1 || null;

	return {
		orders: `/orders`,
		ordersIdPayment: `/orders/${slug1}/payment`,
		ordersId: `/orders/${slug1}`,
		ordersIdReturn: `/orders/${slug1}/return`,
	};
};

export const funPathBackUser = (args) => {
	const slug1 = args?.slug1 || null;

	return {
		user: `/user`,
		userPersonsId: `/user/persons/${slug1}`,
		userPersons: `/user/persons`,
	};
};

export const funPathBackProducts = (args) => {
	const slug1 = args?.slug1 || null;

	return {
		products: `/products`,
		productsIdFavorites: `/products/${slug1}/favorites`, // id
		productsIdFavoritesNull: `/products/${slug1}/favorites/null`, // id
		productsId: `/products/${slug1}`,
		productsSearch: `/products-search`,
	};
};

export const funPathBackGenerals = (args) => {
	const slug1 = args?.slug1 || null;

	return {
		topBrands: `/top-brands`,
		brandsSlug: `/brands/${slug1}`, // slug
		categoriesSlug: `/categories/${slug1}`, // slug
		categories: `/categories`,
		rawCategories: `/raw-categories`,
		topCategoriesSlug: `/top-categories/${slug1}`, // slug
		discountImages: `/discount-images`,
		messages: `/messages`,
		messagesID: `/messages/${slug1}`, // id
		recommendedProducts: `/recommended-products`,
		feedBack: `/feed-back`,
		staticInformation: `/static-information`,
		discountProductsSlug: `/discount-products/${slug1}`, // slug gender
		newProductsSlug: `/new-products/${slug1}`, // slug gender
		asks: `/asks`,
		balanceHistory: `/balance_history`,
	};
};

// unknow
// export const funPathBsackseller = (args) => {
// 	return {

// 		sellerFinance: `${categoryOrBrand}/${slug}/products`,
// 		sellerFinance: `auth/${type}/resend_verification_code`,
// 	};
// };
