import React from "react";
import { useTranslation } from "react-i18next";
import MaskedInput from "react-input-mask";

//styles
import s from "./input.module.scss";

//components
import StyledDiv from "../../StyledDiv/StyledDiv";

//ts
import { TMediaQuery } from "../../../../TS/types/generals/generals";

type TCustomMaskedInputProps = {
  width: TMediaQuery;
  maxWidth?: TMediaQuery;
  marginTop?: TMediaQuery;
  label?: string;
  name: string;
  errorFromBack?: string;
  required?: boolean;
  error: any;
  onChange?: any;
  value?: any;
  icon?: any;
  validation?: any;
};

const CustomMaskedInput = React.forwardRef(
  (
    {
      value,
      onChange,
      name,
      width,
      error,
      label,
      required,
      marginTop,
      icon,
      errorFromBack,
      maxWidth,
    }: TCustomMaskedInputProps,
    ref
  ) => {
    const { t } = useTranslation();

    return (
		<StyledDiv
			maxWidth={maxWidth}
			width={width}
			marginTop={marginTop}
		>
			<div className={s.root}>
				{label ? (
					<label htmlFor={label}>
						{label}
						{required ? <span className={s.required}>*</span> : ""}
					</label>
				) : (
					""
				)}
				<div
					className={`${s.wrapperInput} ${
						!!error || errorFromBack ? s.errorInput : ""
					}`}
				>
					<MaskedInput
						name={name}
						mask="+(380)-99-999-99-99"
						value={value}
						alwaysShowMask
						onChange={(e) => {
							e.persist();
							onChange(e.target.value);
						}}
					>
						{(inputProps: any) => (
							<input
								{...inputProps}
								id={label}
								ref={ref}
								placeholder={t("common.forms.phoneNumber")}
							/>
						)}
					</MaskedInput>
					{icon && <div className={s.icon}>{icon}</div>}
				</div>
				{!!error && <div className={s.error}>{error?.message}</div>}
				{!!errorFromBack && (
					<div className={s.error}>
						<span>{errorFromBack}</span>
					</div>
				)}
			</div>
		</StyledDiv>
	);
  }
);

export default CustomMaskedInput;
