/* eslint-disable camelcase */
// +
import styled from "@mui/system/styled";

// STYLES
export const SDTextarea = styled("textarea")(({ theme }) => ({
	backgroundColor: theme.palette.customColors.bg.bg_2,
	padding: "7px 15px 5px",
	width: "100%",
	border: `1px solid ${theme.palette.customColors.border.b_5}`,
	borderRadius: theme.varietyBorderRadius[4],
	resize: "none",
	color: theme.palette.customColors.text.t_7,
	fontFamily: "Proxima Nova",
	fontSize: "16px",
	fontWeight: 400,
	lineHeight: "21px",
	textAlign: "left",
	"&::placeholder": {
		opacity: 0.9,
		color: theme.palette.customColors.text.t_7,
	},
}));
