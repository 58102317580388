import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

//styles
import s from "./ModalProductQuestion.module.scss";

//assets
import { arrowToIco } from "../../../../assets/icons/iconsConstants";

//components
import ModalClose from "../ModalComponents/ModalClose/ModalClose";

//hooks
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux";

//ui
import ButtonFrontBack from "../../Buttons/ButtonFrontBack/ButtonFrontBack";
import TitleWithLine from "../../TitleWithLine/TitleWithLine";
import Textarea from "../../inputs/Textarea/Textarea";

//utils
import { validationFormSchema } from "../../../../utils/forms/validationFormSchema";

//redux
import { postQuestionBack } from "../../../../redux/Generals/Question/question";
import { getProductSlugBack } from "../../../../redux/ProductCard/productCard";

//validation

const nameFields = {
  textarea: "textarea",
};

const ModalProductQuestion = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const { modalWindowInfo } = useAppSelector((state) => state.app);

  const { loadingPostQuestionBack, errorPostQuestionBack } = useAppSelector(
    (state) => state.question
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(validationFormSchema(t, nameFields)),
  });

  const onSubmit = (data: any) => {
    const sendData = {
      slug: modalWindowInfo?.productSlug,
    };

    dispatch(
      postQuestionBack({
        obj: {
          product_id: modalWindowInfo?.id,
          text: data?.textarea,
        },
        getMethodReloadData: getProductSlugBack(sendData),
      })
    );
  };

  return (
    <div className={s.root}>
      <ModalClose />
      <div className={s.wrapperTitle}>
        <TitleWithLine
          title={t("modals.questions.title")}
          fontSize={{ xl: "18px", lg: "14px" }}
          widthLine={{ xl: "60px", lg: "28px" }}
          heightLine={{ xl: "3px", lg: "2px" }}
        />
      </div>
      <div className={s.wrapper}>
        <form className={s.form} onSubmit={handleSubmit(onSubmit)}>
          <Textarea
            width={{ xl: "100%" }}
            marginTop={{ xl: "20px" }}
            label={""}
            register={register}
            placeholder={t("common.forms.question")}
            name={nameFields?.textarea}
            error={errors?.textarea}
            errorFromBack={errorPostQuestionBack?.text}
          />
          <div className={s.groupsBtn}>
            <ButtonFrontBack
              maxWidth={{ xl: "230px" }}
              width={{ xl: "100%" }}
              marginTop={{ xl: "15px" }}
              marginLeft={{ xl: "auto" }}
              marginRight={{ xl: "auto" }}
              mode={"red"}
              isButton
              disabledLoading={loadingPostQuestionBack}
            >
              <span>
                {t("common.button")} <img src={arrowToIco} alt="arrowToIco" />
              </span>
            </ButtonFrontBack>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ModalProductQuestion;
