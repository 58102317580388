import { createSlice } from "@reduxjs/toolkit";

//api
import { postRequest } from "../../../API/_api";

//redux
import { setModalWindowStatus } from "../../App/app";

type TPostReviewProductBackProps = {
  obj: any;
  getMethodReloadData: any;
};

export const postReviewProductBack =
  (sendData: TPostReviewProductBackProps) => (dispatch: any) => {
    const { obj, getMethodReloadData } = sendData;
    dispatch(setLoadingPostReviewProductBack(true));

    const success = (data: any) => {
      dispatch(setDataPostReviewProductBack(data?.data));
      getMethodReloadData && dispatch(getMethodReloadData);
      dispatch(setModalWindowStatus(false));
    };

    const setError = (error: any) => {
      console.dir(error?.response?.data, "error?.response?.data?.message");
      dispatch(setErrorPostReviewProductBack(error?.response?.data));
    };

    postRequest({
      url: `feed-back`,
      success,
      setError,
      obj,
    });
  };

const initialState: any = {
  dataPostReviewProductBack: null,
  loadingPostReviewProductBack: false,
  errorPostReviewProductBack: null,
};

export const reviewProduct = createSlice({
  name: "reviewProduct",
  initialState,
  reducers: {
    setDataPostReviewProductBack(state, action) {
      state.dataPostReviewProductBack = action.payload;
      state.loadingPostReviewProductBack = false;
    },
    setErrorPostReviewProductBack(state, action) {
      state.errorPostReviewProductBack = action.payload;
      state.loadingPostReviewProductBack = false;
    },
    setLoadingPostReviewProductBack(state, action) {
      state.loadingPosttReviewProductBack = action.payload;
      state.errorPosttReviewProductBack = null;
    },
  },
});

export const {
  setDataPostReviewProductBack,
  setErrorPostReviewProductBack,
  setLoadingPostReviewProductBack,
} = reviewProduct.actions;

export default reviewProduct.reducer;
