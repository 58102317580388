import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";

//api
import { getRequest, postRequest } from "../../../API/_api";

export const getMessagesBack = (sendData: any) => (dispatch: any) => {
  const { id } = sendData;
  dispatch(setLoadingGetMessagesBack(true));

  const success = (data: any) => {
    dispatch(setDataGetMessagesBack(data?.data));
  };

  const setError = (error: any) => {
    dispatch(setErrorGetMessagesBack(error));
  };

  getRequest({
    url: `messages/${id}`,
    success,
    setError,
  });
};

type TPostMessagesBackProps = {
  obj: any;
  id?: any;
};

export const postMessagesBack =
  (sendData: TPostMessagesBackProps) => (dispatch: any) => {
    const { obj, id } = sendData;
    dispatch(setLoadingPostMessagesBack(true));

    const success = (data: any) => {
      dispatch(setDataPostMessagesBack(data?.data));

      dispatch(getMessagesBack({ id }));
    };

    const setError = (error: any) => {
      dispatch(setErrorPostMessagesBack(error?.response?.data));
    };

    postRequest({
      url: `messages`,
      success,
      setError,
      obj,
    });
  };

const initialState: any = {
  dataGetMessagesBack: null,
  loadingGetMessagesBack: false,
  errorGetMessagesBack: null,
  dataPostMessagesBack: null,
  loadingPostMessagesBack: false,
  errorPostMessagesBack: null,
  dialogIdMessagesBack: null,
};

export const messages = createSlice({
  name: "messages",
  initialState,
  reducers: {
    setDataGetMessagesBack(state, action) {
      state.dataGetMessagesBack = action.payload?.reverse();
      state.loadingGetMessagesBack = false;
    },
    setErrorGetMessagesBack(state, action) {
      state.errorGetMessagesBack = action.payload;
      state.loadingGetMessagesBack = false;
    },
    setLoadingGetMessagesBack(state, action) {
      state.loadingGetMessagesBack = action.payload;
      state.errorGetMessagesBack = null;
      state.errorPostMessagesBack = null;
    },
    setDataPostMessagesBack(state, action) {
      state.dataPostMessagesBack = action.payload;
      state.loadingPostMessagesBack = false;
    },
    setErrorPostMessagesBack(state, action) {
      state.errorPostMessagesBack = action.payload;
      state.loadingPostMessagesBack = false;
    },
    setLoadingPostMessagesBack(state, action) {
      state.errorPostMessagesBack = action.payload;
      state.errorPosttMessagesBack = null;
    },
    setDialogIdMessagesBack(state, action) {
      state.dialogIdMessagesBack = action.payload;
    },
  },
});

export const {
  setLoadingGetMessagesBack,
  setErrorGetMessagesBack,
  setDataGetMessagesBack,
  setDataPostMessagesBack,
  setErrorPostMessagesBack,
  setLoadingPostMessagesBack,
  setDialogIdMessagesBack,
} = messages.actions;

export default messages.reducer;
