/* eslint-disable no-underscore-dangle */

/* eslint-disable no-param-reassign */
// APPROVED +
import axios from "axios";
import { langsData } from "../../db/generals/languageData";
import { baseURL } from "../../API/_api";

const parseErrorCode = async (error) => {
	const errorResData = error.response?.data;
	const errorResStatus = error.response?.status;

	console.log(error, "error");
	if (error?.response) {
		if (error.response?._response) {
			// Snackbar.error(error.response?._response);
		}
		if (errorResStatus === 401) {
		} else if (errorResStatus === 403) {
		} else if (errorResStatus === 404) {
		}
	}

	console.log({ error, errorResData, errorResStatus }, "errorResStatus");
	return Promise.reject({
		data: {
			message:
				errorResData && Object.keys(errorResData)?.length
					? errorResData.errors
						? { errors: errorResData.errors }
						: errorResData
					: error.response?.statusText || "Error Server",
			errData: errorResData?.message || errorResData,
			status: errorResStatus,
			statusText: error.response?.statusText || "Error Server",
		},
	});
};

const API = axios.create();

const insertLocalInUrl = ({ url, locale }) => {
	if (locale === "uk" || !langsData[locale] || url.includes(`/${locale}/`)) {
		return url;
	}

	const regex = /^(https?:\/\/[^/]+)(.*)$/i;
	const domain = url.replace(regex, "$1");
	const restUrl = url.replace(regex, "$2");
	const newUrl = `${domain}/${locale}${restUrl}`;

	return newUrl;
};

// Request parsing interceptor
API.interceptors.request.use(
	async (config) => {
		if (config.url.includes("http://")) {
			config.baseURL = "";

			const replaceHttps = config.url.replace("http://", "https://");

			config.url = replaceHttps;
		} else {
			config.baseURL = baseURL;
		}

		config.headers = {
			"Content-Type": "application/json",
			accept: "application/json",
			"X-Requested-With": "XMLHttpRequest",
			...config?.headers,
		};

		return config;
	},
	(error) => Promise.reject(error),
);

// Response parsing interceptor
API.interceptors.response.use(
	(response) => response,
	(error) => parseErrorCode(error),
);

export default API;
