import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

//styles
import s from "./ModalReturnOrder.module.scss";

//assets
import {
  arrowToIco,
  clipIco,
  conversationIco,
} from "../../../../assets/icons/iconsConstants";

//components
import ModalClose from "../ModalComponents/ModalClose/ModalClose";

//hooks
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux";

//ui
import ButtonFrontBack from "../../Buttons/ButtonFrontBack/ButtonFrontBack";
import TitleWithLine from "../../TitleWithLine/TitleWithLine";
import SelectInput from "../../inputs/SelectInput/SelectInput";
import Textarea from "../../inputs/Textarea/Textarea";

//utils
import { validationFormSchema } from "../../../../utils/forms/validationFormSchema";
import { isBaseURLSingleInImage } from "../../../../utils/generals/isBaseURLSingleInImage";

//db
import { reasons } from "../../../../db/Modals/returnOrderData";

//api
import { baseURLSingle } from "../../../../API/_api";

//rework
//redux
import { postProfileReturnProductBack } from "../../../../redux/Profile/ProfileOrders/ProfileOrders";

const nameFields = {
  commentRequired: "commentRequired",
  reason: "reason",
  // file: "file",
};

const ModalReturnOrder = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const { modalWindowInfo } = useAppSelector((state) => state.app);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    // watch,
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(validationFormSchema(t, nameFields)),
  });

  const onSubmit = (data: any) => {
    dispatch(
      postProfileReturnProductBack({
        obj: {
          products: modalWindowInfo?.products.map(
            (product: any) => product?.product_id
          ),
          comment: data?.commentRequired,
          reason_id: data?.reason,
          // images: Object.values(data.file),
          images: [],
        },
        orderId: modalWindowInfo.id,
      })
    );
  };

  return (
    <div className={s.root}>
      <ModalClose />
      <div className={s.wrapperTitle}>
        <TitleWithLine
          title={t("modals.productReturn.title")}
          fontSize={{ xl: "18px", lg: "14px" }}
          widthLine={{ xl: "60px", lg: "28px" }}
          heightLine={{ xl: "3px", lg: "2px" }}
        />
      </div>

      <div className={s.wrapper}>
        <h4 className={s.subTitle}>{t("modals.productReturn.products")}?</h4>
        <div className={s.products}>
          {modalWindowInfo?.products &&
            modalWindowInfo.products.map((product: any) => (
              <div className={s.product}>
                <div className={s.productImg}>
                  <img
                    src={isBaseURLSingleInImage(product?.image)}
                    alt="product"
                  />
                </div>
                <div className={s.productName}>{product?.product_name}</div>
              </div>
            ))}
        </div>
        <form className={s.form} onSubmit={handleSubmit(onSubmit)}>
          <SelectInput
            width={{ xl: "calc(50%-10px)" }}
            marginTop={{ xl: "0" }}
            defaultOption={t(reasons?.[0]?.title)}
            options={reasons}
            register={register}
            setValue={setValue}
            name={nameFields?.reason}
            error={errors?.reason}
            label={
              <span className={s.lableInner}>
                <img src={conversationIco} alt="arrows" />
                {t("modals.productReturn.cause")}
              </span>
            }
          />
          <Textarea
            width={{ xl: "100%" }}
            marginTop={{ xl: "20px" }}
            label={""}
            register={register}
            placeholder={"Дополнительные комментарии"}
            name={nameFields?.commentRequired}
            error={errors?.commentRequired}
            // errorFromBack={errorsFromBack}
            // defaultValues={defaultValues?.messages}
          />

          {/* <label className={s.addPhoto}>
            <img src={clipIco} alt="clip" />
            <span>{file ? file?.name : t("common.forms.file")}</span>
            <input
              type="file"
              {...register(nameFields.file)}
              // onChange={(e: any) => {
              //   setFile(e.target.files[0]?.name);
              // }}
            />
          </label> */}

          <ButtonFrontBack
            width={{ xl: "230px" }}
            marginTop={{ xl: "25px" }}
            marginRight={{ xl: "auto" }}
            marginLeft={{ xl: "auto" }}
            mode={"red"}
            isButton
          >
            <span className={s.btnInner}>
              {t("common.button")}
              <img src={arrowToIco} alt="arrowToIco" />
            </span>
          </ButtonFrontBack>
        </form>
      </div>
    </div>
  );
};

export default ModalReturnOrder;
