import {
  setModalWindowChildrenType,
  setModalWindowStatus,
  setModalWindowInfo,
} from "../../redux/App/app";

export const backErrorModal = (error) => (dispatch) => {
  const message = error?.response?.data?.message;
  if (typeof message === "string" && message !== "") {
    dispatch(setModalWindowChildrenType("formError"));
    dispatch(
      setModalWindowInfo({
        title: "common.modals",
        text: message,
      })
    );
    dispatch(setModalWindowStatus(true));
  }
};
