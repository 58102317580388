// +
export const elementTypes = {
	input: "input",
	switch: "switch",
	textarea: "textarea",
	checkbox: "checkbox",
	nativeSelect: "nativeSelect",
	phoneInput: "phoneInput",
	imageInput: "imageInput",
	selectAsyncPaginateSearch: "selectAsyncPaginateSearch",
};
