import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";

//styles
import s from "./HeaderBody.module.scss";

//assets
import zenshhinam from "../../../../assets/icons/header/NewMenu/2-odezda.svg";
import obuv from "../../../../assets/icons/header/NewMenu/145-obuv.svg";
import acc from "../../../../assets/icons/header/NewMenu/547-aksessuary.svg";
import menOdyg from "../../../../assets/icons/header/NewMenu/menOdyg.svg";
import menOdezhda from "../../../../assets/icons/header/NewMenu/menObuv.svg";
import menAcc from "../../../../assets/icons/header/NewMenu/menAcc.svg";
import detMal from "../../../../assets/icons/header/NewMenu/Frame 5-1.svg";
import detDev from "../../../../assets/icons/header/NewMenu/Frame 5.svg";
import kid from "../../../../assets/icons/header/NewMenu/Frame 6.svg";
import logoTwo from "../../../../assets/images/logo/logoTwo.png";

//components
import ButtonsLinkCustomer from "./ButtonsLinkCustomer/ButtonsLinkCustomer";
import ButtonLinkCustomerAdaptive from "./ButtonLinkCustomerAdaptive/ButtonLinkCustomerAdaptive";
import ButtonsLinkSeller from "./ButtonsLinkSeller/ButtonsLinkSeller";
import HeaderMobile from "../HeaderMobile/HeaderMobile";

//ui
import Container from "../../../ui/Containers/container/Contaiter";
import SearchHeaderInput from "../../../ui/inputs/SearchHeaderInput/SearchHeaderInput";

//db
import {
	gendersCategoryLink,
	headerBodybuttonsLink,
	headerBodybuttonsLinkAdaptive,
} from "../../../../db/Header/headerData";

//paths
import { paths, pathFC } from "../../../../App/Routes/paths";

//hooks
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux";

//redux
import { setGender } from "../../../../redux/App/app";

import { BREAKPOINTS_NAME } from "../../../../theme/breakpoints/index";

const HeaderBody: React.FC = () => {
	const { t } = useTranslation();

	const dispatch = useAppDispatch();

	const isUpLaptop = useMediaQuery((theme: any) =>
		theme.breakpoints.up(BREAKPOINTS_NAME.laptop),
	);
	const [activeMobileMenu, setActiveMobileMenu] = useState(false);

	const [activeSearch, setActiveSearch] = useState<boolean>(false);

	const { userData } = useAppSelector((state) => state.user);
	const { gender } = useAppSelector((state) => state.app);

	const { dataRawCategoriesBack } = useAppSelector(
		(state) => state.rawCategories,
	);

	const [categories, setCategories] = useState([
		{
			id: 100,
			name: t("brands.title"),
			path: pathFC(paths.brands),
		},
	]);

	useEffect(() => {
		dataRawCategoriesBack?.[gender]?.children.length > 0 &&
			setCategories([
				...dataRawCategoriesBack?.[gender]?.children,
				{
					id: 100,
					name: t("brands.title"),
					path: pathFC(paths.brands),
				},
			]);
	}, [dataRawCategoriesBack?.[gender]]);

	useEffect(() => {
		if (isUpLaptop) {
			setActiveSearch(false);
			setActiveMobileMenu(false);
		}
	}, [isUpLaptop]);

	const menuIcons: any = {
		women: [zenshhinam, obuv, acc],
		men: [menOdyg, menOdezhda, menAcc],
		kids: [detMal, detDev, kid],
	};

	return (
		<>
			<div className={s.root}>
				<Container>
					<div className={s.wrapper}>
						<div
							className={`${
								activeMobileMenu
									? s.categoryWrapperAvtiveBurger
									: s.categoryWrapper
							}`}
						>
							{isUpLaptop ? (
								gendersCategoryLink?.length > 0 &&
								gendersCategoryLink.map(
									(item, index: number) => {
										const path = item.path;

										return (
											<NavLink
												to={path}
												className={`${s.categoryItem} ${
													item.key === gender
														? s.activeCategoryItem
														: ""
												}`}
												key={index}
												onClick={(e: any) => {
													console.log(
														window.location,
														"window.location",
													);
													if (
														[
															pathFC(
																paths.mainWomen,
															),
															pathFC(
																paths.mainMen,
															),
															pathFC(
																paths.mainKids,
															),
														].includes(
															window.location
																.pathname,
														)
													) {
														e.stopPropagation();
														e.preventDefault();

														window.history.pushState(
															null,
															"",
															path,
														);
													}
													dispatch(
														setGender(item.key),
													);
												}}
											>
												{t(item.title)}
											</NavLink>
										);
									},
								)
							) : (
								<>
									<div
										className={`${s.burgerWrapper} ${
											activeMobileMenu
												? s.activeBurgerWrapper
												: ""
										}`}
									>
										<div
											className={`${s.burger} ${
												activeMobileMenu || activeSearch
													? s.activeBurger
													: ""
											}`}
											onClick={() => {
												setActiveMobileMenu(
													(prev) => !prev,
												);
											}}
										>
											<span />
										</div>
										<SearchHeaderInput
											placeholder={"common.forms.search"}
											className={
												s.searchAdaptiveMobileMenu
											}
											activeSearch={activeSearch}
											setActiveSearch={setActiveSearch}
											classNameDropdown={
												s.searchAdaptiveMobileMenuDropdown
											}
										/>
									</div>
								</>
							)}
						</div>
						<NavLink
							to={pathFC(paths.main)}
							className={s.logoWrapper}
						>
							{!activeMobileMenu && !activeSearch && (
								<img
									src={logoTwo}
									alt="logo"
								/>
							)}
						</NavLink>
						{userData?.role === "Seller" ? (
							<ButtonsLinkSeller />
						) : (
							<div className={s.buttonProfileWrapper}>
								{isUpLaptop
									? headerBodybuttonsLink?.length > 0 &&
										headerBodybuttonsLink.map((item) => {
											return (
												<ButtonsLinkCustomer
													key={item?.id}
													path={item.path}
													svg={item.svg}
													title={item.title}
													subLinks={item?.subLinks}
													onClick={() => {
														setActiveMobileMenu(
															false,
														);
													}}
												/>
											);
										})
									: headerBodybuttonsLinkAdaptive?.length >
											0 &&
										headerBodybuttonsLinkAdaptive.map(
											(item, index: number) => {
												return (
													<ButtonLinkCustomerAdaptive
														path={item.path}
														svg={item.svg}
														key={index}
													/>
												);
											},
										)}
							</div>
						)}
					</div>
				</Container>
			</div>

			{activeMobileMenu && (
				<HeaderMobile
					menuIcons={menuIcons}
					setActiveMobileMenu={setActiveMobileMenu}
					categories={categories}
				/>
			)}
		</>
	);
};

export default HeaderBody;
