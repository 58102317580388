import { useTranslation } from "react-i18next";

//styles
import s from "./Modalloading.module.scss";

//components
import ModalClose from "../ModalComponents/ModalClose/ModalClose";

//ui
import TitleWithLine from "../../TitleWithLine/TitleWithLine";
import MiniPreloader from "../../MiniPreloader/MiniPreloader";

//hooks
import { useAppSelector } from "../../../../hooks/redux";

const Modalloading = () => {
  const { t } = useTranslation();

  const { modalWindowInfo } = useAppSelector((state) => state.app);

  return (
    <div className={s.root}>
      <ModalClose />
      <div className={s.wrapperTitle}>
        <TitleWithLine
          title={
            modalWindowInfo?.title
              ? t(modalWindowInfo?.title)
              : t("generals.sendingData")
          }
          fontSize={{ xl: "18px", lg: "14px" }}
          widthLine={{ xl: "60px", lg: "28px" }}
          heightLine={{ xl: "3px", lg: "2px" }}
        />
      </div>
      <MiniPreloader />
    </div>
  );
};

export default Modalloading;
