import { createSlice } from "@reduxjs/toolkit";

//types
import { TBrand } from "../../TS/types/generals/generals";

type TdiscountImagesInitialState = {
	topBrands: Array<TBrand> | [] | null;
};

const initialState: TdiscountImagesInitialState = {
	topBrands: null,
};

export const brands = createSlice({
	name: "brands",
	initialState,
	reducers: {
		setTopBrands(state, action) {
			state.topBrands = action.payload;
		},
	},
});

export const { setTopBrands } = brands.actions;

export default brands.reducer;
