import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

//styles
import s from "./SliderBtns.module.scss";

const SliderBtnNext = ({
  onClick,
  className,
  classNameSliderBtn,
  ref,
}: any): JSX.Element => {
  return (
    <div
      role={"button"}
      className={`${s.sliderButton} ${s.sliderNext} ${className} ${classNameSliderBtn}`}
      onClick={onClick}
    >
      <FontAwesomeIcon icon={faChevronRight} />
    </div>
  );
};

export default SliderBtnNext;
