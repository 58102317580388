"use client";

/* eslint-disable @typescript-eslint/no-empty-function */
// modules
import Switch from "@mui/material/Switch";
import { useTranslation } from "react-i18next";
import { memo } from "react";
import { Controller, useFormContext } from "react-hook-form";
import InputBaseComponent from "./components/InputBase";
import CheckboxComponent from "./components/checkbox";
import HelperText from "./components/helperText";
// import ImageInputComponent from "./components/imageInput";
import NativeSelectComponent from "./components/nativeSelect";
import SelectAsyncPaginateSearch from "./components/selectAsyncPaginateSearch";
// import PhoneInputComponent from "./components/phoneInput";
import TextareaComponent from "./components/textarea";
import WrapperInput from "./components/wrapperInput";
import { elementTypes } from "./config";
import {
	SDErrorText,
	SDFormControl,
	SDFormControlLabel,
	SDWrapper,
	SDWrapperItem,
} from "./styles";

const ControllersFieldsHookForm = ({
	configFields = [],
	optionsTags = {
		wrapperItem: {},
		wrapperItems: {},
		formControl: {},
		input: {},
		helperText: {},
		nativeSelect: {},
		imageInput: {},
	},
	sxOptions = {
		wrapperItem: {},
		wrapperItems: {},
		formControl: {},
		input: {},
		helperText: {},
		formControlLabel: {},
	},
	errFromBackEnd = {},
	onChangeFieldUp = () => {},
	isProvider = false,
	isDarkBackgroundColor = false,
	handleSubmit = (e) => e?.preventDefault(),
}) => {
	const { t } = useTranslation();

	const { control, formState, reset, setValue } = useFormContext();

	if (!isProvider) return null;

	return (
		<form onSubmit={handleSubmit}>
			<SDWrapper
				sx={sxOptions.root}
				{...optionsTags?.root}
			>
				{configFields.map((el, key) => (
					<Controller
						key={el?.id || key}
						control={control}
						render={({
							field: { ref, onChange: onChangeField, value },
							formState: { errors, touchedFields },
						}) => {
							const idField = `field-${el.fieldName}`;
							const errFront = formState?.errors[el.fieldName];
							const errBack =
								errFromBackEnd?.[el?.errKeyNameBackend];
							const helperText = el?.helperText;

							let errFrontElement = <></>;
							let errBackElement = <></>;
							let helperTextElement = <></>;

							if (errFront?.message) {
								errFrontElement = (
									<SDErrorText>
										{t(errFront?.message)}
									</SDErrorText>
								);
							}

							if (errBack) {
								errBackElement = (
									<SDErrorText>
										{Array.isArray(errBack)
											? errBack.join(", ")
											: errBack}
									</SDErrorText>
								);
							}

							if (helperText) {
								helperTextElement = (
									<HelperText
										helperText={helperText}
										sx={sxOptions.helperText}
										optionsTag={optionsTags?.helperText}
										isDarkBackgroundColor={
											isDarkBackgroundColor
										}
									/>
								);
							}

							const onChange = ({
								e,
								onlyUp = false,
								val = "",
								isSetValue = false,
							}) => {
								if (isSetValue) {
									return setValue(el.fieldName, val);
								}

								onChangeField(e);
								!onlyUp && onChangeField(e);
								onChangeFieldUp({ name: el.fieldName, e });
								return null;
							};

							const element = (() => {
								switch (el.elementType) {
									case elementTypes.input:
										return (
											<InputBaseComponent
												value={value}
												onChange={onChange}
												item={el}
												idField={idField}
												sxOptions={sxOptions}
												optionsTags={optionsTags}
												t={t}
												isDarkBackgroundColor={
													isDarkBackgroundColor
												}
											/>
										);
									case elementTypes.switch:
										return (
											<Switch
												defaultChecked={value}
												name={el.fieldName}
												value={value}
												onChange={(e, val) =>
													onChange({
														e,
														val,
													})
												}
											/>
										);
									case elementTypes.textarea:
										return (
											<TextareaComponent
												value={value}
												onChange={onChange}
												item={el}
												idField={idField}
												optionsTags={optionsTags}
												t={t}
												isDarkBackgroundColor={
													isDarkBackgroundColor
												}
											/>
										);
									case elementTypes.checkbox:
										return (
											<CheckboxComponent
												value={value}
												onChange={onChange}
												item={el}
												idField={idField}
												optionsTags={optionsTags}
												t={t}
												isDarkBackgroundColor={
													isDarkBackgroundColor
												}
											/>
										);
									case elementTypes.nativeSelect:
										return (
											<NativeSelectComponent
												isDarkBackgroundColor={
													isDarkBackgroundColor
												}
												value={value}
												onChange={onChange}
												item={el}
												idField={idField}
												optionsTags={optionsTags}
												t={t}
											/>
										);
									case elementTypes.selectAsyncPaginateSearch:
										return (
											<SelectAsyncPaginateSearch
												isDarkBackgroundColor={
													isDarkBackgroundColor
												}
												value={value}
												onChange={onChange}
												item={el}
												idField={idField}
												optionsTags={optionsTags}
												t={t}
											/>
										);
									// case elementTypes.phoneInput:
									// return (
									// 	<PhoneInputComponent
									// 		isDarkBackgroundColor={
									// 			isDarkBackgroundColor
									// 		}
									// 		onChange={onChange}
									// 		item={el}
									// 		value={value}
									// 		t={t}
									// 		optionsTags={optionsTags}
									// 	/>
									// );
									// case elementTypes.imageInput:
									// 	return (
									// 		<ImageInputComponent
									// 			name={el.fieldName}
									// 			idField={idField}
									// 			onChange={onChange}
									// 			item={el}
									// 			value={value}
									// 			t={t}
									// 			forwardRef={ref}
									// 			touchedFields={touchedFields}
									// 			optionsTags={
									// 				optionsTags?.imageInput
									// 			}
									// 			errors={errors}
									// 			reset={reset}
									// 			renderIsData={
									// 				el?.renderIsData || null
									// 			}
									// 			renderNoData={
									// 				el?.renderNoData || null
									// 			}
									// 		/>
									// 	);
									default:
										return <></>;
								}
							})();

							return (
								<SDWrapperItem
									item
									laptop={1}
									xs={1}
									{...optionsTags?.wrapperItem}
								>
									{(() => {
										if (
											[
												elementTypes.switch,
												elementTypes.checkbox,
											].includes(el.elementType)
										) {
											return (
												<SDFormControlLabel
													control={element}
													label={
														el?.label
															? el.label({ t })
															: ""
													}
													{...optionsTags?.formControlLabel}
												/>
											);
										}

										return (
											<SDFormControl
												sx={sxOptions.formControl}
												{...optionsTags?.formControl}
											>
												<WrapperInput
													isDarkBackgroundColor={
														isDarkBackgroundColor
													}
													optionsTags={optionsTags}
													idField={idField}
													el={el}
												>
													{element}
												</WrapperInput>
												{helperTextElement}
												{errFrontElement}
												{errBackElement}
											</SDFormControl>
										);
									})()}
								</SDWrapperItem>
							);
						}}
						name={el.fieldName}
					/>
				))}
			</SDWrapper>
			<input
				type="submit"
				style={{
					position: "absolute",
					left: "-9999px",
					width: "1px",
					height: "1px",
				}}
				tabIndex="-1"
			/>
		</form>
	);
};

export default memo(ControllersFieldsHookForm);
