import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";

import "./index.scss";
import App from "./App/App";

import { store } from "./redux/store";

import "./i18n";
import reportWebVitals from "./reportWebVitals";

import ScrollToTop from "./components/ScrollToTop";

import ThemeComponent from "./theme/ThemeComponent";
import themeConfig from "./theme/themeConfig";

// ----------------------------------------------------------------------

if ('scrollRestoration' in window.history) {
  window.history.scrollRestoration = 'manual';
}

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement,
);

root.render(
	<React.StrictMode>
		<ThemeComponent settings={themeConfig}>
			<Provider store={store}>
				<BrowserRouter>
					<ScrollToTop />
					<App />
				</BrowserRouter>
			</Provider>
		</ThemeComponent>
	</React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
