import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

//styles
import s from "./ModalBasket.module.scss";

//components
import ModalBasketOrder from "./ModalBasketOrder/ModalBasketOrder";
import EmptyComponent from "../../EmptyComponent/EmptyComponent";
import ModalClose from "../ModalComponents/ModalClose/ModalClose";

//hooks
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux";

//ui
import TitleWithLine from "../../TitleWithLine/TitleWithLine";
import ButtonFrontBack from "../../Buttons/ButtonFrontBack/ButtonFrontBack";

//paths
import { paths, pathFC } from "../../../../App/Routes/paths";

//redux
import {
  setModalWindowChildrenType,
  setModalWindowStatus,
} from "../../../../redux/App/app";

const ModalBasket = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const history = useHistory();

  const { basket } = useAppSelector((state) => state.basket);

  const { isLogged } = useAppSelector((state) => state.user);

  const resolveOrder = () => {
    if (!isLogged) {
      dispatch(setModalWindowChildrenType("login"));
      history.push(pathFC(paths.checkout));
    } else {
      history.push(pathFC(paths.checkout));
      dispatch(setModalWindowStatus(false));
    }
  };

  return (
    <div className={s.root}>
      <ModalClose />
      <div className={s.wrapperTitle}>
        <TitleWithLine
          title={t("basket.title")}
          fontSize={{ xl: "18px", lg: "14px" }}
          widthLine={{ xl: "60px", lg: "28px" }}
          heightLine={{ xl: "3px", lg: "2px" }}
        />
      </div>

      <div className={s.wrapperOrder}>
        {basket?.length > 0 ? (
          basket?.map((order: any, i: number) => (
            <ModalBasketOrder
              key={order.brand?.id || i}
              num={i + 1}
              basket={basket}
              order={order}
            />
          ))
        ) : (
          <EmptyComponent title={t("basket.emptyMessage")} />
        )}
      </div>

      <div className={s.footer}>
        <ButtonFrontBack
          maxWidth={{ xl: "240px" }}
          width={{ xl: "100%" }}
          marginTop={{ xl: "0" }}
          marginLeft={{ md: "auto" }}
          marginRight={{ md: "auto" }}
          onClick={() => resolveOrder()}
          className={s.btn}
          mode={"red"}
          isButton
        >
          <span>{t("basket.button")}</span>
        </ButtonFrontBack>
        <div
          className={s.back}
          onClick={() => dispatch(setModalWindowStatus(false))}
        >
          {t("basket.modals.message")}
        </div>
      </div>
    </div>
  );
};

export default ModalBasket;
