import { createSlice, PayloadAction } from "@reduxjs/toolkit";

//types
import { TDiscountProduct } from "../../TS/types/main/main";

type TInitialState = {
  wishListProducts: any;
};

const favouriteProductsLocal = localStorage.getItem("favouriteProducts");

const initialState: TInitialState = {
  wishListProducts:
    (favouriteProductsLocal && JSON.parse(favouriteProductsLocal)) || [],
};

export const favouriteWishList = createSlice({
  name: "favouriteWishList",
  initialState,
  reducers: {
    removeWishlistProduct(state, action) {
      state.wishListProducts =
        state.wishListProducts.length > 0
          ? state.wishListProducts.filter(
              (item: any) => item.id !== action.payload
            )
          : [];
    },
    addWishlistProduct(state, action) {
      state.wishListProducts = Array.isArray(action.payload)
        ? action.payload
        : [...state.wishListProducts, action.payload];
    },
  },
});

export const { addWishlistProduct, removeWishlistProduct } =
  favouriteWishList.actions;

export default favouriteWishList.reducer;
