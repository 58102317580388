import { createSlice, PayloadAction } from "@reduxjs/toolkit";

//api
import { getRequest } from "../../../API/_api";

import { getSlugOfGender } from "../../../utils/generals/getSlugOfGender";

type sendData = {
  name: string;
  params: { type: string; gender?: string };
};

type TdiscountImagesInitialState = {
  dataRawCategoriesBack: any;
  loadingRawCategoriesBack: boolean;
  errorRawCategoriesBack: any;
};

const initialState: TdiscountImagesInitialState = {
  dataRawCategoriesBack: {
    women: null,
    men: null,
    kids: null,
  },
  loadingRawCategoriesBack: false,
  errorRawCategoriesBack: null,
};

export const getRawCategoriesBack = () => (dispatch: any) => {
  dispatch(setLoadingRawCategoriesBack(true));
  const success = (data: any) => {
    dispatch(setDataRawCategoriesBack(data.data));
  };

  const setError = (error: any) => {
    dispatch(setErrorRawCategoriesBack(error));
  };

  getRequest({
    url: `raw-categories`,
    success,
    setError,
  });
};

const createDataTree = (rawData: any) => {
  const hashTable = Object.create(null);
  rawData?.forEach(
    (aData: any) => (hashTable[aData.id] = { ...aData, children: [] })
  );

  const dataTree: any = [];
  rawData?.forEach((aData: any) => {
    if (aData?.parent_id)
      hashTable?.[aData?.parent_id]?.children?.push(hashTable?.[aData?.id]);
    else dataTree?.push(hashTable?.[aData?.id]);
  });

  return dataTree;
};

export const rawCategories = createSlice({
  name: "rawCategories",
  initialState,
  reducers: {
    setDataRawCategoriesBack(state, action) {
      const genders = createDataTree(action.payload);
      state.dataRawCategoriesBack = {
        women: genders?.find((item: any) => {
          return item.slug === getSlugOfGender("women");
        }),
        men: genders?.find((item: any) => item.slug === getSlugOfGender("men")),
        kids: genders?.find(
          (item: any) => item.slug === getSlugOfGender("kids")
        ),
      };
      state.loadingRawCategoriesBack = false;
    },
    setLoadingRawCategoriesBack(state, action) {
      state.loadingRawCategoriesBack = action.payload;
      state.errorRawCategoriesBack = null;
    },
    setErrorRawCategoriesBack(state, action) {
      state.errorRawCategoriesBack = action.payload;
      state.loadingRawCategoriesBack = false;
    },
  },
});
export const {
  setDataRawCategoriesBack,
  setLoadingRawCategoriesBack,
  setErrorRawCategoriesBack,
} = rawCategories.actions;

export default rawCategories.reducer;
