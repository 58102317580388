import { useTranslation } from "react-i18next";

//styles
import s from "./ModalFormSuccessSellerRegistrion.module.scss";

//assets
import { arrowToIco } from "../../../../assets/icons/iconsConstants";

//components
import ModalClose from "../ModalComponents/ModalClose/ModalClose";

//ui
import TitleWithLine from "../../TitleWithLine/TitleWithLine";
import ButtonFrontBack from "../../Buttons/ButtonFrontBack/ButtonFrontBack";
//hooks
import { useAppDispatch } from "../../../../hooks/redux";

//redux
import { setModalWindowChildrenType } from "../../../../redux/App/app";

const ModalFormSuccessSellerRegistrion = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  return (
    <div className={s.root}>
      <ModalClose />
      <div className={s.wrapperTitle}>
        <TitleWithLine
          title={t("login.success")}
          fontSize={{ xl: "18px", lg: "14px" }}
          widthLine={{ xl: "60px", lg: "28px" }}
          heightLine={{ xl: "3px", lg: "2px" }}
        />
      </div>
      <div className={s.content}>
        <ButtonFrontBack
          width={{ xl: "230px" }}
          marginTop={{ xl: "15px" }}
          marginRight={{ xl: "auto" }}
          marginLeft={{ xl: "auto" }}
          mode={"red"}
          isButton
          onClick={() => dispatch(setModalWindowChildrenType("login"))}
        >
          <span className={s.btnInner}>
            {t("login.buttonSingIn")}
            <img src={arrowToIco} alt="" />
          </span>
        </ButtonFrontBack>
      </div>
    </div>
  );
};

export default ModalFormSuccessSellerRegistrion;
