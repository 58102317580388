const Button = () => ({
	MuiButton: {
		variants: [
			{
				props: { variant: "contained_ee4a2e_ee4a2e_ffffff" },
				style: {
					borderColor: "#ee4a2e",
					color: "#ffffff",
					backgroundColor: "#ee4a2e",
					":hover": {
						backgroundColor: "#ee4a2e",
					},
				},
			},
			{
				props: { variant: "contained_353535_353535_ffffff" },
				style: {
					borderColor: "#353535",
					color: "#ffffff",
					backgroundColor: "#353535",
					":hover": {
						backgroundColor: "#353535",
					},
				},
			},

			{
				props: { variant: "contained_ffffff_3a66e5_ffffff" },
				style: {
					borderColor: "#ffffff",
					color: "#ffffff",
					backgroundColor: "#3a66e5",
					":hover": {
						backgroundColor: "#3a66e5",
					},
				},
			},
			{
				props: { variant: "contained_ffffff_3a66e5_3a66e5" },
				style: {
					borderColor: "#3a66e5",
					color: "#ffffff",
					backgroundColor: "#3a66e5",
					":hover": {
						backgroundColor: "#3a66e5",
					},
				},
			},
			{
				props: { variant: "contained_ffffff_ff3366_ff3366" },
				style: {
					borderColor: "#ff3366",
					color: "#ffffff",
					backgroundColor: "#c",
					":hover": {
						backgroundColor: "#ff3366",
					},
				},
			},
			{
				props: { variant: "contained_ffffff_050505_050505" },
				style: {
					borderColor: "#050505",
					color: "#ffffff",
					backgroundColor: "#050505",
					":hover": {
						backgroundColor: "#050505",
					},
				},
			},
			{
				props: { variant: "contained_050505_ffffff_ffffff" },
				style: {
					borderColor: "#ffffff",
					color: "#050505",
					backgroundColor: "#ffffff",
					":hover": {
						backgroundColor: "#ffffff",
					},
				},
			},
			{
				props: { variant: "outlined_050505_ffffff_050505" },
				style: {
					borderColor: "#050505",
					color: "#050505",
					backgroundColor: "#ffffff",
					":hover": {
						backgroundColor: "#ffffff",
					},
				},
			},

			{
				props: { variant: "contained_2c2c2c_ffffff_ffffff" },
				style: {
					borderColor: "#2c2c2c",
					color: "#ffffff",
					backgroundColor: "#2c2c2c",
					":hover": {
						backgroundColor: "#2c2c2c",
					},
				},
			},
			{
				props: { variant: "contained_ffffff_ff3366_ff3366" },
				style: {
					borderColor: "#ff3366",
					color: "#ffffff",
					backgroundColor: "#ff3366",
					":hover": {
						backgroundColor: "#ff3366",
					},
				},
			},

			{
				props: { variant: "outlined_2c2c2c_transparet_2c2c2c" },
				style: {
					borderColor: "#2c2c2c",
					color: "#2c2c2c",
					backgroundColor: "transparet",
					":hover": {
						backgroundColor: "transparet",
					},
				},
			},

			{
				props: { variant: "outlined_ffffff_transparet_ffffff" },
				style: {
					borderColor: "#ffffff",
					color: "#ffffff",
					backgroundColor: "transparet",
					":hover": {
						backgroundColor: "transparet",
					},
				},
			},
			{
				props: { variant: "outlined_050505_transparet_050505" },
				style: {
					borderColor: "#050505",
					color: "#050505",
					backgroundColor: "transparet",
					":hover": {
						backgroundColor: "transparet",
					},
				},
			},
			{
				props: { variant: "outlined_050505_ffffff_050505" },
				style: {
					borderColor: "#050505",
					color: "#050505",
					backgroundColor: "#ffffff",
					":hover": {
						backgroundColor: "#ffffff",
					},
				},
			},
			{
				props: { variant: "outlined_6f7881_ffffff_d7d7d7" },
				style: {
					borderColor: "#d7d7d7",
					color: "#6f7881",
					backgroundColor: "#ffffff",
					":hover": {
						backgroundColor: "#ffffff",
					},
				},
			},
		],
		styleOverrides: {
			root: () => ({
				fontFamily: "Proxima Nova",
				width: "100%",
				borderRadius: "0",
				fontSize: "20px",
				fontWeight: 600,
				boxShadow: "none",
				border: "2px solid transparent",
				lineHeight: 1.5,
				padding: "8px",
				textTransform: "initial",
			}),
		},
	},
	MuiButtonBase: {
		defaultProps: {
			disableRipple: true,
		},
	},
});

export default Button;
