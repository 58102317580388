import { createSlice } from "@reduxjs/toolkit";

//api
import { postRequest, deleteRequest } from "../../../API/_api";

//redux
import {
  setModalWindowChildrenType,
  setModalWindowStatus,
  setModalWindowInfo,
} from "../../App/app";
import { getUser } from "../../User/user";

//utils
import { backErrorModal } from "../../../utils/generals/BackErrorModal";

type TDeleteProfileOrdersBackProps = {
  id: number;
  getProducts: any;
};

export const deleteProfileOrdersBack =
  (sendData: TDeleteProfileOrdersBackProps) => (dispatch: any) => {
    const { id, getProducts } = sendData;
    dispatch(setLoadingDeleteProfileOrdersBack(true));

    const success = (data: any) => {
      dispatch(setDataDeleteProfileOrdersBack(data?.data));
      dispatch(getProducts());
    };

    const setError = (error: any) => {
      dispatch(setErrorDeleteProfileOrdersBack(error));
      dispatch(backErrorModal(error));
    };

    deleteRequest({
      url: `orders/${id}`,
      success,
      setError,
    });
  };

type TPostProfileReturnProductBackProps = {
  obj: any;
  orderId: number;
};

export const postProfileReturnProductBack =
  (sendData: TPostProfileReturnProductBackProps) => (dispatch: any) => {
    const { obj, orderId } = sendData;
    dispatch(setLoadingPostProfileReturnProductBack(true));

    const success = (data: any) => {
      dispatch(setDataPostProfileReturnProductBack(data?.data));
      dispatch(setModalWindowChildrenType("formSuccess"));
      dispatch(
        setModalWindowInfo({
          title: "common.modals",
        })
      );
      dispatch(setModalWindowStatus(true));
      dispatch(getUser());
    };

    const setError = (error: any) => {
      dispatch(setErrorPostProfileReturnProductBack(error?.response?.data));

      if (typeof error?.response?.data?.message === "string") {
        if (error?.response?.data?.message.includes("sellerRefundData")) {
          dispatch(setModalWindowChildrenType("formSuccess"));
          dispatch(
            setModalWindowInfo({
              title: "common.requestForReturnSucces",
            })
          );
          dispatch(setModalWindowStatus(true));
          dispatch(getUser());
        } else {
          dispatch(setModalWindowChildrenType("formError"));
          dispatch(
            setModalWindowInfo({
              title: "common.modals",
              text: error?.response?.data?.message,
            })
          );
          dispatch(setModalWindowStatus(true));
        }
      }
    };

    postRequest({
      url: `orders/${orderId}/return`,
      success,
      setError,
      obj,
    });
  };

const initialState: any = {
  dataDeleteProfileOrdersBack: null,
  loadingDeleteProfileOrdersBack: false,
  errorDeleteProfileOrdersBack: null,
  dataPostProfileReturnProductBack: null,
  loadingPostProfileReturnProductBack: false,
  errorPostProfileReturnProductBack: null,
};

export const checkoutOrder = createSlice({
  name: "checkoutOrder",
  initialState,
  reducers: {
    setDataDeleteProfileOrdersBack(state, action) {
      state.dataDeleteProfileOrdersBack = action.payload;
      state.loadingDeleteProfileOrdersBack = false;
    },
    setErrorDeleteProfileOrdersBack(state, action) {
      state.errorDeleteProfileOrdersBack = action.payload;
      state.loadingDeleteProfileOrdersBack = false;
    },
    setLoadingDeleteProfileOrdersBack(state, action) {
      state.loadingPosttCheckoutOrderBack = action.payload;
      state.errorPosttCheckoutOrderBack = null;
    },
    setDataPostProfileReturnProductBack(state, action) {
      state.dataPostProfileReturnProductBack = action.payload;
      state.loadingPostProfileReturnProductBack = false;
    },
    setErrorPostProfileReturnProductBack(state, action) {
      state.errorPostProfileReturnProductBack = action.payload;
      state.loadingPostProfileReturnProductBack = false;
    },
    setLoadingPostProfileReturnProductBack(state, action) {
      state.errorPostProfileReturnProductBack = action.payload;
      state.errorPosttProfileReturnProductBack = null;
    },
  },
});

export const {
  setDataDeleteProfileOrdersBack,
  setErrorDeleteProfileOrdersBack,
  setLoadingDeleteProfileOrdersBack,
  setDataPostProfileReturnProductBack,
  setErrorPostProfileReturnProductBack,
  setLoadingPostProfileReturnProductBack,
} = checkoutOrder.actions;

export default checkoutOrder.reducer;
