//styles
import s from "./TitleWithLine.module.scss";

//ui
import StyledDiv from "../StyledDiv/StyledDiv";

//ts
import { TMediaQuery } from "../../../TS/types/generals/generals";

type TTitleWithLineProps = {
  title: any;
  img?: string;
  classNameImg?: string;
  width?: TMediaQuery;
  marginTop?: TMediaQuery;
  marginRight?: TMediaQuery;
  marginLeft?: TMediaQuery;
  fontSize?: TMediaQuery;
  widthLine?: TMediaQuery;
  heightLine?: any;
};

const TitleWithLine = ({
  title,
  img,
  classNameImg,
  width,
  marginTop,
  marginRight,
  marginLeft,
  fontSize,
  widthLine,
  heightLine,
}: TTitleWithLineProps): JSX.Element => {
  return (
    <StyledDiv
      width={width && width}
      marginTop={marginTop && marginTop}
      marginRight={marginRight && marginRight}
      marginLeft={marginLeft && marginLeft}
      fontSize={fontSize && fontSize}
      className={s.title}
    >
      <StyledDiv
        width={widthLine || { xl: "100px" }}
        height={heightLine || "10px"}
        className={s.titleLine}
      />
      <div className={s.titleText}>{title}</div>
      {img && <img src={img} alt="imagee" className={classNameImg} />}
    </StyledDiv>
  );
};
export default TitleWithLine;
