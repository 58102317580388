import { faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";

//styles
import s from "./SearchList.module.scss";
import breakpoints from "../../../../styles/vars";

//components
import StyledDiv from "../../StyledDiv/StyledDiv";

//hooks
import { useOutside } from "../../../../hooks/useOutside";

type TSearchListProps = {
  width?: any;
  marginTop?: any;
  marginRight?: any;
  marginLeft?: any;
  searchItems?: any;
  setSearch?: any;
  search?: any;
  label?: any;
  required?: any;
  renderList?: any;
  placeholder?: any;
  maxWidth?: any;
  validation?: any;
  name: any;
  register: any;
  error?: any;
  errorFromBack?: any;
  readonly?: boolean;
  textForNotIsItems?: any;
};

const SearchList = ({
  searchItems,
  search,
  setSearch,
  marginRight,
  marginTop,
  width,
  required,
  label,
  renderList,
  placeholder,
  maxWidth,
  validation,
  register,
  name,
  error,
  errorFromBack,
  readonly = false,
  textForNotIsItems,
  marginLeft,
}: TSearchListProps) => {
  const { ref, isShow, setIsShow } = useOutside(false);

  const { t } = useTranslation();

  const md: boolean = useMediaQuery({ maxWidth: breakpoints.md });

  return (
    <StyledDiv
      maxWidth={maxWidth ? maxWidth : ""}
      width={width ? width : ""}
      marginTop={marginTop ? marginTop : ""}
      marginRight={marginRight ? marginRight : ""}
      marginLeft={marginLeft ? marginLeft : ""}
      onMouseEnter={() => !md && setIsShow(true)}
      onMouseLeave={() => !md && setIsShow(false)}
      onClick={() => md && setIsShow((prev: boolean) => !prev)}
    >
      {label ? (
        <label htmlFor={label} className={s.label}>
          {label}
          {required ? <span className={s.required}>*</span> : ""}
        </label>
      ) : (
        ""
      )}
      <div className={s.wrapperSearchList} ref={ref}>
        <div
          className={`${s.searchbar} ${
            !!error || errorFromBack ? s.errorInput : ""
          }`}
        >
          <input
            id={name}
            className={s.input}
            type="text"
            placeholder={placeholder}
            value={search}
            name={name}
            readOnly={readonly}
            {...register(name, validation)}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            autoComplete="off"
          />
          <FontAwesomeIcon
            icon={faChevronUp}
            className={`${s.arrow}  ${isShow ? s.reverse : ""}`}
          />
        </div>
        {!!error && (
          <div className={s.error}>
            <span>{error?.message}</span>
          </div>
        )}
        {isShow && (
          <ul
            className={`${s.dropdown}`}
            onClick={() => !md && setIsShow(false)}
          >
            {searchItems?.length > 0 ? (
              renderList
            ) : textForNotIsItems ? (
              textForNotIsItems
            ) : (
              <li className={s.option}>
                {t("generals.nothingFoundHaventSearchedYet")}
              </li>
            )}
          </ul>
        )}
      </div>
    </StyledDiv>
  );
};

export default SearchList;
