import { useTranslation } from "react-i18next";

//styles
import s from "./ModalFormError.module.scss";

//components
import ModalClose from "../ModalComponents/ModalClose/ModalClose";

//ui
import TitleWithLine from "../../TitleWithLine/TitleWithLine";

//hooks
import { useAppSelector } from "../../../../hooks/redux";
const ModalFormError = () => {
  const { t } = useTranslation();

  const { modalWindowInfo } = useAppSelector((state) => state.app);

  return (
    <div className={s.root}>
      <ModalClose />
      <div className={s.wrapperTitle}>
        <TitleWithLine
          title={t("modals.error.title")}
          fontSize={{ xl: "18px", lg: "14px" }}
          widthLine={{ xl: "60px", lg: "28px" }}
          heightLine={{ xl: "3px", lg: "2px" }}
        />
      </div>
      <p className={s.text}>
        {typeof modalWindowInfo?.text === "string"
          ? modalWindowInfo?.text
          : t("modals.error.text")}
      </p>
    </div>
  );
};

export default ModalFormError;
