/* eslint-disable @typescript-eslint/no-unused-vars */

const DefaultPalette = (mode, skin, themeColor) => {
	// ** Vars
	const lightColorsCustom = {
		text: {
			t_1: "#000000",
			t_2: "#ffffff",
			t_3: "#353535",
			t_4: "#a4a4a4",
			t_5: "#6d6d6d",
			t_6: "#ee4a2e",
			t_7: "#eaeaea",
			t_8: "#35cb44",
			t_9: "#9baef0",
			t_10: "#fafafa",
			t_error: " rgb(185, 40, 40)",
		},
		svg: {
			s_1: "#000000",
			s_2: "#ffffff",
			s_3: "#353535",
			s_4: "#a4a4a4",
			s_5: "#6d6d6d",
			s_6: "#ee4a2e",
			s_7: "#eaeaea",
			s_8: "#35cb44",
			s_9: "#9baef0",
			s_10: "#fafafa",
			s_error: " rgb(185, 40, 40)",
		},
		border: {
			b_1: "#000000",
			b_2: "#ffffff",
			b_3: "#353535",
			b_4: "#a4a4a4",
			b_5: "#6d6d6d",
			b_6: "#ee4a2e",
			b_7: "#eaeaea",
			b_8: "#35cb44",
			b_9: "#9baef0",
			b_10: "#fafafa",
			b_error: " rgb(185, 40, 40)",
		},
		bg: {
			bg_1: "#000000",
			bg_2: "#ffffff",
			bg_3: "#353535",
			bg_4: "#a4a4a4",
			bg_5: "#6d6d6d",
			bg_6: "#ee4a2e",
			bg_7: "#eaeaea",
			bg_8: "#35cb44",
			bg_9: "#9baef0",
			bg_10: "#fafafa",
			bg_error: " rgb(185, 40, 40)",
		},
	};

	const darkColorsCustom = {
		text: {
			1: "#000000",
			2: "#050505",
			3: "#030303",
			4: "#070606",
			5: "#1c232b",
			6: "#212529",
			7: "#2d2d2e",
			8: "#3a66e5",
			9: "#43425d",
			10: "#6f7881",
			11: "#9f9f9f",
			12: "#a2a2a2",
			13: "#f7f6f6",
			14: "#fafafa",
			15: "#ffffff",
			//
			16: "#5d5d5d",
			17: "#fbfbfb",
			hover: "#3a66e5",
			hover2: "#e91e63",
			hover3: "#fafafa",
			error: "red",
		},
		svg: {
			1: "#43425d",
			2: "#6f7881",
			3: "#707070",
			4: "#d9d4d4",
			5: "#ff3366",
			6: "#e91e63",
			7: "#ffffff",
			8: "#5d5d5d",
		},
		border: {
			1: "#707070",
			2: "#a2a2a2",
			3: "#d7d7d7",
			4: "#cecece",
			5: "#cccccc",
			6: "#d9d4d4",
			7: "#ffffff",
			//
			8: "#d8d8d8",
			9: "#6f7881",
		},
		bg: {
			1: "#3a66e5",
			2: "#575757",
			3: "#6f7881",
			4: "#e6e6e6",
			5: "#efefef",
			6: "#ffffff",
			//
			7: "#000000",
			8: "#fafafa",
			9: "#2d2d2e",
			10: "#43425d",
			11: "#a2a2a2",
		},
	};

	const mainColor = "#3a66e5";
	const whiteColor = "#FFF";
	const ccTheme = mode === "light" ? lightColorsCustom : darkColorsCustom;

	return {
		mode,
		customColors: {
			...ccTheme,
		},
		badgeCount: {
			main: "#ff3366",
		},
		common: {
			black: "#000",
			white: whiteColor,
		},
		primary: {
			light: "#9E69FD",
			main: "#9155FD",
			dark: "#804BDF",
			contrastText: whiteColor,
		},
		secondary: {
			light: "#9C9FA4",
			main: "#8A8D93",
			dark: "#777B82",
			contrastText: whiteColor,
		},
		error: {
			light: "#FF6166",
			main: "#FF4C51",
			dark: "#E04347",
			contrastText: whiteColor,
		},
		warning: {
			light: "#FFCA64",
			main: "#FFB400",
			dark: "#E09E00",
			contrastText: whiteColor,
		},
		info: {
			light: "#32BAFF",
			main: "#16B1FF",
			dark: "#139CE0",
			contrastText: whiteColor,
		},
		success: {
			light: "#6AD01F",
			main: "#56CA00",
			dark: "#4CB200",
			contrastText: whiteColor,
		},

		black050505: {
			main: "#050505",
			contrastText: whiteColor,
		},

		grey: {
			50: "#FAFAFA",
			100: "#F5F5F5",
			200: "#EEEEEE",
			300: "#E0E0E0",
			400: "#BDBDBD",
			500: "#9E9E9E",
			600: "#757575",
			700: "#616161",
			800: "#424242",
			900: "#212121",
			A100: "#F5F5F5",
			A200: "#EEEEEE",
			A400: "#BDBDBD",
			A700: "#616161",
		},
		background: {
			paper: mode === "light" ? whiteColor : "#312D4B",
		},
		action: {
			active: `rgba(${mainColor}, 0.54)`,
			hover: "none",
			selected: `rgba(${mainColor}, 0.08)`,
			disabled: `rgba(${mainColor}, 0.26)`,
			disabledBackground: `rgba(${mainColor}, 0.12)`,
			focus: `rgba(${mainColor}, 0.12)`,
		},
	};
};

export default DefaultPalette;
