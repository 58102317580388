/* eslint-disable camelcase */
// +
import InputBase from "@mui/material/InputBase";
import styled from "@mui/system/styled";

// STYLES
export const SDInputBase = styled(InputBase)(({ theme }) => ({
	border: "1px solid #a4a4a4",
	input: {
		fontSize: "14px",
		color: "#6d6d6d",
		"&::placeholder": {
			opacity: 0.9,
			color: "#6d6d6d",
		},
	},
}));
