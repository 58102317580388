// APPROVED
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import styled from "@mui/system/styled";
import { BREAKPOINTS_NAME } from "../../../../theme/breakpoints/index";

export const sx = {
	pMainBtn: {
		wrapper: {
			mt: "15px",
			width: "100%",
		},
		btn: {
			maxWidth: "230px",
			px: "15px",
		},
	},
};

export const SDRoot = styled(Box)(({ theme }) => ({
	position: "relative",
	backgroundColor: theme.palette.customColors.bg.bg_2,
	width: "100%",
	borderRadius: theme.varietyBorderRadius[6],
	".MuiAutocomplete-popper": {
		marginTop: "17px",
	},
	[theme.breakpoints.down(BREAKPOINTS_NAME.tabletPlus)]: {
		maxWidth: "100%",
	},
}));

export const SDRepeat = styled(Typography)({
	width: "auto",
	textAlign: "center",
	marginTop: "20px",
	fontSize: "14px",
	letterSpacing: "0.05em",
	color: "#353535",
	paddingBottom: "1px",
	cursor: "pointer",
	borderBottom: "1px solid #353535",
});

export const SDGroupsBtn = styled("div")({
	width: "100%",
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
});

export const SDWrapper = styled("div")({
	maxWidth: "362px",
	width: "100%",
});

export const SDTextDone = styled(Typography)({
	marginTop: "5px",
	fontWeight: 500,
	letterSpacing: "0.05em",
	color: "#35cb44",
	fontSize: "13px",
	textAlign: "center",
	display: "flex",
	"& img": {
		marginRight: "6px",
		minWidth: "11px",
		filter: "invert(57%) sepia(101%) saturate(505%) hue-rotate(56deg) brightness(99%) contrast(186%)",
	},
});

export const SDError = styled(Typography)({
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
	gridColumnGap: "5px",
	columnGap: "5px",
	marginTop: "3px",
	"& span": {
		fontWeight: 500,
		fontSize: "14px",
		lineHeight: "20px",
		letterSpacing: "0.05em",
		color: "#ee4a2e", // $colorBackGroundRed
	},
});

export const SDDescription = styled(Typography)({
	fontWeight: 500,
	fontSize: "12px",
	lineHeight: "20px",
	letterSpacing: "0.05em",
	color: "#6d6d6d",
	marginBottom: "20px",
	marginTop: "10px",
});
