import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useForm } from "react-hook-form";
import Rating from "react-rating";

//styles
import { SDWRaiting, SDRating } from './styles';
import s from "./ModalSellerReview.module.scss";

// //assets
import {
  arrowToIco,
  boxIco,
  diamondIco,
  disableStarIco,
  enableStarIco,
  clockIco,
  conversationIco,
} from "../../../../assets/icons/iconsConstants";

//components
import ModalClose from "../ModalComponents/ModalClose/ModalClose";

//hooks
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux";

//ui
import ButtonFrontBack from "../../Buttons/ButtonFrontBack/ButtonFrontBack";
import TitleWithLine from "../../TitleWithLine/TitleWithLine";
import SelectInput from "../../inputs/SelectInput/SelectInput";
import Textarea from "../../inputs/Textarea/Textarea";
import ErrorText from "../../ErrorText/ErrorText";

//db
import { reviewData } from "../../../../db/Modals/reviewData";

//redux
import { postSellerMessagesReviewsBack } from "../../../../redux/Seller/SellerMessages/SellerMessagesSellerReviews";

enum EnameFields {
  communication = "communication",
  text = "text",
  delivery = "delivery",
  connection = "connection",
  qualityReview = "qualityReview",
  score = "score",
}

enum EErrorsBack {
  color = "color",
  delivery = "delivery",
  product_id = "product_id",
  quality = "quality",
  score = "score",
  size = "size",
  text = "text",
}

const ModalSellerReview = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const [score, setScore] = useState<number | null>(0);

  const { modalWindowInfo } = useAppSelector((state) => state.app);

  const {
    loadingPostSellerMessagesReviewsBack,
    errorPostSellerMessagesReviewsBack,
  } = useAppSelector((state) => state.sellerMessagesSellerReviews);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<any>({
    mode: "onBlur",
    defaultValues: {
      [EnameFields?.communication]: t(reviewData?.communication?.[0]?.title),
      [EnameFields?.connection]: t(reviewData?.connection?.[0]?.title),
      [EnameFields?.qualityReview]: t(reviewData?.qualityReview?.[0]?.title),
      [EnameFields?.delivery]: t(reviewData?.deliveryReview?.[0]?.title),
    },
  });

  const onSubmit = (data: any) => {
    console.log(data, "data");

    dispatch(
      postSellerMessagesReviewsBack({
        obj: {
          seller_id: modalWindowInfo?.sellerId,
          text: data?.[EnameFields?.text],
          skills: data?.[EnameFields?.communication],
          response_time: data?.[EnameFields?.connection],
          quality: data?.[EnameFields?.qualityReview],
          delivery: data?.[EnameFields?.delivery],
          score,
        },
      })
    );
  };

  return (
    <div className={s.root}>
      <ModalClose />
      <div className={s.wrapperTitle}>
        <TitleWithLine
          title={t("modals.sellerReview.title")}
          fontSize={{ xl: "18px", lg: "14px" }}
          widthLine={{ xl: "60px", lg: "28px" }}
          heightLine={{ xl: "3px", lg: "2px" }}
        />
      </div>
      {errorPostSellerMessagesReviewsBack?.message && (
        <ErrorText text={errorPostSellerMessagesReviewsBack?.message} />
      )}
      <div
        className={`${s.rating} ${
          errorPostSellerMessagesReviewsBack?.score ? s.errorRating : ""
        }`}
      >
        <SDWRaiting>
          <SDRating
            value={score}
            precision={1}
            onChange={(event, newValue) => setScore(newValue)}
          />
        </SDWRaiting>

      </div>
      <div className={s.wrapper}>
        <form className={s.form} onSubmit={handleSubmit(onSubmit)}>
          <div className={s.wrapperInputs}>
            <SelectInput
              width={{ xl: "calc(50% - 5px)", smPlus: "100%" }}
              marginTop={{ xl: "0" }}
              defaultOption={t(reviewData?.communication?.[0].title)}
              options={reviewData?.communication}
              name={EnameFields?.communication}
              register={register}
              setValue={setValue}
              error={errors?.[EnameFields.communication]}
              label={
                <span className={s.lableInner}>
                  <img src={conversationIco} alt="arrows" />
                  {t("common.forms.sellerReview.options.0")}
                </span>
              }
              errorFromBack={errorPostSellerMessagesReviewsBack?.skills}
              sendTitle
            />
            <SelectInput
              width={{ xl: "calc(50% - 5px)", smPlus: "100%" }}
              marginTop={{ xl: "0", smPlus: "15px" }}
              defaultOption={t(reviewData?.connection?.[0].title)}
              options={reviewData?.connection}
              register={register}
              setValue={setValue}
              name={EnameFields?.connection}
              error={errors?.[EnameFields.connection]}
              label={
                <span className={s.lableInner}>
                  <img src={clockIco} alt="palette" />
                  {t("common.forms.sellerReview.options.1")}
                </span>
              }
              errorFromBack={errorPostSellerMessagesReviewsBack?.response_time}
              sendTitle
            />
          </div>
          <div className={s.wrapperInputs}>
            <SelectInput
              width={{ xl: "calc(50% - 5px)", smPlus: "100%" }}
              marginTop={{ xl: "0", smPlus: "15px" }}
              defaultOption={t(reviewData?.qualityReview?.[0].title)}
              options={reviewData?.qualityReview}
              register={register}
              setValue={setValue}
              name={EnameFields?.qualityReview}
              error={errors?.[EnameFields.qualityReview]}
              label={
                <span className={s.lableInner}>
                  <img src={diamondIco} alt="diamondIco" />
                  {t("common.forms.sellerReview.options.2")}
                </span>
              }
              errorFromBack={errorPostSellerMessagesReviewsBack?.quality}
              sendTitle
            />
            <SelectInput
              width={{ xl: "calc(50% - 5px)", smPlus: "100%" }}
              marginTop={{ xl: "0", smPlus: "15px" }}
              defaultOption={t(reviewData?.deliveryReview?.[0].title)}
              options={reviewData?.deliveryReview}
              register={register}
              setValue={setValue}
              name={EnameFields?.delivery}
              error={errors?.[EnameFields.delivery]}
              label={
                <span className={s.lableInner}>
                  <img src={boxIco} alt="boxIco" />
                  {t("common.forms.sellerReview.options.3")}
                </span>
              }
              errorFromBack={errorPostSellerMessagesReviewsBack?.delivery}
              sendTitle
            />
          </div>
          <Textarea
            width={{ xl: "100%" }}
            marginTop={{ xl: "20px" }}
            label={""}
            register={register}
            placeholder={t("modals.productReview.review")}
            name={EnameFields?.text}
            error={errors?.[EnameFields.text]}
            errorFromBack={errorPostSellerMessagesReviewsBack?.text}
            validation={{
              required: t("generals.yupValidation.isRequired"),
              maxLength: {
                value: 328,
                message: `${t(`generals.yupValidation.maxSymbols`)} 328 ${t(
                  `generals.yupValidation.symbolss`
                )}`,
              },
            }}
          />
          <ButtonFrontBack
            width={{ xl: "230px" }}
            marginTop={{ xl: "25px" }}
            marginRight={{ xl: "auto" }}
            marginLeft={{ xl: "auto" }}
            mode={"red"}
            isButton
            disabledLoading={loadingPostSellerMessagesReviewsBack}
          >
            <span className={s.btnInner}>
              {t("common.button")}
              <img src={arrowToIco} alt="arrowToIco" />
            </span>
          </ButtonFrontBack>
        </form>
      </div>
    </div>
  );
};

export default ModalSellerReview;
