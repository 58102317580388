// +
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { SDNativeSelect } from "./styles";

const NativeSelectComponent = ({
	item,
	value,
	onChange,
	optionsTags = {
		input: {},
		inputWrapper: {},
	},
	idField,
	isDarkBackgroundColor,
	t,
}) => (
	<SDNativeSelect
		id={idField}
		name={item.fieldName}
		onChange={(e) => onChange({ e })}
		value={value}
		placeholder={t(item.placeholder)}
		inputProps={{
			IconComponent: KeyboardArrowDownIcon,
			name: item.fieldName,
			id: idField,
		}}
		sx={{
			"label+.-MuiInputBase-root-MuiInput-root": {
				margin: 0,
			},
		}}
		{...item?.inputProps}
		{...optionsTags?.nativeSelect}
	>
		{item?.arrayContent?.map((el) => (
			<option
				key={el.id}
				value={el.value}
			>
				{t(el.title)}
			</option>
		))}
	</SDNativeSelect>
);

export default NativeSelectComponent;
