import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Link } from 'react-router-dom';

//styles
import s from './ModalLogin.module.scss';

//assets
import { registrationIco } from '../../../../assets/icons/iconsConstants';

//components
import ModalClose from '../ModalComponents/ModalClose/ModalClose';

//ui
import ButtonFrontBack from '../../Buttons/ButtonFrontBack/ButtonFrontBack';
import TitleWithLine from '../../TitleWithLine/TitleWithLine';
import Input from '../../inputs/Input/Input';
import ErrorText from '../../ErrorText/ErrorText';

//hooks
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';

//utils
import { validationFormSchema } from '../../../../utils/forms/validationFormSchema';
import { formatedCallbackUrl } from '../../../../utils/formatedCallbackUrl';

//redux
import { setModalWindowChildrenType, setModalWindowStatus } from '../../../../redux/App/app';
import { profileLogin } from '../../../../redux/User/user';

//routes
import { paths, pathFC } from '../../../../App/Routes/paths';

const nameFields = {
  email: 'email',
  password: 'password',
};

const ModalLogin = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const { errorLogin } = useAppSelector((state) => state.user);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(validationFormSchema(t, nameFields)),
  });

  const onSubmit = (data: any) => {
    dispatch(
      profileLogin({
        obj: {
          email: data.email,
          password: data.password,
        },
      })
    );
  };

  const errorsFromBack = errorLogin?.response?.data?.message;

  return (
    <div className={s.root}>
      <ModalClose />

      <div className={s.wrapperTitle}>
        <TitleWithLine
          title={t('login.title')}
          fontSize={{ xl: '18px', lg: '14px' }}
          widthLine={{ xl: '60px', lg: '28px' }}
          heightLine={{ xl: '3px', lg: '2px' }}
        />
      </div>

      {errorsFromBack && <ErrorText text={t('login.error.0')} />}
      <form className={s.form} onSubmit={handleSubmit(onSubmit)}>
        <Input
          width={{ xl: '100%' }}
          marginTop={{ xl: '20px' }}
          id={nameFields.email}
          name={nameFields.email}
          type='text'
          placeholder={'E-mail'}
          required
          error={errors?.[nameFields.email]}
          errorFromBack={errorsFromBack}
          register={register}
        />

        <Input
          width={{ xl: '100%' }}
          marginTop={{ xl: '10px' }}
          id={nameFields.password}
          name={nameFields.password}
          type='password'
          placeholder={t('common.forms.password')}
          required
          error={errors?.[nameFields.password]}
          errorFromBack={errorsFromBack}
          register={register}
        />

        <div className={s.Btns}>
          <ButtonFrontBack
            width={{ xl: 'calc(50% - 10px)' }}
            marginTop={{ xl: '10px' }}
            mode={'red'}
            isButton
          >
            <span>{t('login.buttonSingIn')}</span>
          </ButtonFrontBack>
          <ButtonFrontBack
            width={{ xl: 'calc(50% - 10px)' }}
            marginTop={{ xl: '10px' }}
            onClick={() => {
              dispatch(setModalWindowChildrenType('forgotPassword'));
            }}
            mode={'whiteGray'}
          >
            <span>{t('login.buttonPassword')}</span>
          </ButtonFrontBack>
        </div>
      </form>
      <div className={s.reg}>
        <img src={registrationIco} alt='registrationIco' />
        <Link className={s.linkRegistration} to={pathFC(formatedCallbackUrl({
              includesArray: [paths.registration],
              path: paths.registration
            }))} onClick={() =>  dispatch(setModalWindowStatus(false))}>
          <p>{t('login.createAcc.0')}</p>
          <span>{t('login.createAcc.1')}</span>
        </Link>
      </div>
      <div className={s.regText}>{t('modals.registration.promo')}</div>
    </div>
  );
};

export default ModalLogin;
