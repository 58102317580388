import { useState, useEffect } from "react";

//styles
import s from "./checkbox.module.scss";

//assets
import { checkIco } from "../../../../assets/icons/iconsConstants";

//ui
import StyledDiv from "../../StyledDiv/StyledDiv";

//ts
import { TMediaQuery } from "../../../../TS/types/generals/generals";

type TCheckboxProps = {
  width: TMediaQuery;
  marginTop?: TMediaQuery;
  marginRight?: TMediaQuery;
  marginLeft?: TMediaQuery;
  label?: any;
  name: string;
  className?: string;
  errorFromBack?: string;
  required?: boolean;
  error: any;
  value?: any;
  setValue: any;
};

const Checkbox = ({
  width,
  label,
  name,
  className,
  marginTop,
  marginRight,
  marginLeft,
  required,
  error,
  value,
  setValue,
}: TCheckboxProps) => {
  const [checked, setChecked] = useState(value ? value : false);

  useEffect(() => {
    setValue && setValue(name, checked);
  }, [checked]);

  useEffect(() => {
    setChecked(value);
  }, [value]);

  return (
    <StyledDiv
      width={width && width}
      marginTop={marginTop && marginTop}
      marginRight={marginRight && marginRight}
      marginLeft={marginLeft && marginLeft}
    >
      <div
        className={`${s.checkboxItem} ${className} ${!!error ? s.error : ""}`}
      >
        <div
          className={`${s.checkbox} ${checked ? s.active : ""}`}
          onClick={() => setChecked((prev: any) => !prev)}
        >
          <input id={name} name={name} type={"checkbox"} />
          <img src={checkIco} alt="check" />
        </div>

        <label htmlFor={name} className={error ? s.error : ""}>
          {label}
          {required ? <span className={s.required}>*</span> : ""}
        </label>
      </div>
    </StyledDiv>
  );
};

export default Checkbox;
