// +
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import styled from "@mui/system/styled";

// STYLES
export const SDCheckbox = styled(Checkbox)(() => ({
	padding: 0,
	fontSize: "24px",
	".MuiCheckbox-root": {
		padding: 0,
	},
}));

export const SDBGNonActiveIcon = styled(Box)(({ theme }) => ({
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	width: "24px",
	height: "25px",
	borderRadius: "50%",
	border: `1px solid ${theme.palette.customColors.border.b_2}`,
}));

export const SDBGActiveIcon = styled(Box)(({ theme }) => ({
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	backgroundColor: theme.palette.customColors.bg.bg_13,
	width: "24px",
	height: "25px",
	borderRadius: "50%",
	svg: {
		path: {
			fill: theme.palette.customColors.svg.s_2,
		},
	},
}));
