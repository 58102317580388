import { useState } from "react";
import { useTranslation } from "react-i18next";

//styles
import s from "./input.module.scss";

//components
import StyledDiv from "../../StyledDiv/StyledDiv";

//ts
import { TMediaQuery } from "../../../../TS/types/generals/generals";

type TInputProps = {
  width: TMediaQuery;
  marginTop?: TMediaQuery;
  marginLeft?: TMediaQuery;
  label?: any;
  id: string;
  type: string;
  name: string;
  placeholder: string;
  className?: string;
  errorFromBack?: any;
  required?: boolean;
  error?: any;
  register?: any;
  onChange?: any;
  maxWidth?: TMediaQuery;
  validation?: any;
  icon?: any;
  value?: any;
};

const Input = ({
  width,
  label,
  type,
  name,
  placeholder,
  className,
  marginTop,
  errorFromBack,
  required,
  error,
  register,
  marginLeft,
  onChange,
  maxWidth,
  validation,

  icon,
  value,
}: TInputProps) => {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const [typefieldPasword, setTypefieldPasword] = useState("password");

  const showHidePasswoedHandler = () => {
    setShowPassword((prev) => !prev);
    showPassword
      ? setTypefieldPasword("password")
      : setTypefieldPasword("text");
  };

  return (
		<StyledDiv
			maxWidth={maxWidth && maxWidth}
			width={width && width}
			marginTop={marginTop && marginTop}
			marginLeft={marginLeft && marginLeft}
		>
			<div className={`${s.root}  ${className ? className : ""}`}>
				{label ? (
					<label htmlFor={label}>
						{label}
						{required ? <span className={s.required}>*</span> : ""}
					</label>
				) : (
					""
				)}
				{register && name ? (
					<div
						className={`${s.wrapperInput} ${
							!!error || errorFromBack ? s.errorInput : ""
						}`}
					>
						<input
							id={label}
							placeholder={placeholder}
							name={name}
							type={type === "password" ? typefieldPasword : type}
							{...register(name, validation)}
						/>
						{type === "password" && (
							<div
								className={s.showHide}
								onClick={() => showHidePasswoedHandler()}
							>
								{showPassword
									? t("generals.hide")
									: t("generals.show")}
							</div>
						)}
						{icon && <div className={s.icon}>{icon}</div>}
					</div>
				) : (
					<div
						className={`${s.wrapperInput} ${
							!!error || errorFromBack ? s.errorInput : ""
						}`}
					>
						<input
							id={label}
							placeholder={placeholder}
							name={name}
							value={value}
							type={type === "password" ? typefieldPasword : type}
							onChange={(e: any) => onChange(e.target.value)}
						/>
						{type === "password" && (
							<div
								className={s.showHide}
								onClick={() => showHidePasswoedHandler()}
							>
								{showPassword
									? t("generals.hide")
									: t("generals.show")}
							</div>
						)}
					</div>
				)}
				{!!error && (
					<div className={s.error}>
						<span>{error?.message}</span>
					</div>
				)}
				{!!errorFromBack && (
					<div className={s.error}>
						<span>{errorFromBack}</span>
					</div>
				)}
			</div>
		</StyledDiv>
  );
};

export default Input;
