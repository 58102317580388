export const addProduct = (state: any, product: any) => {
  let itemWithId = false;
  let isBrand = false;
  const newBasket: any = [];
  state.basket.forEach((order: any) => {
    const products: any = [];
    if (order.brand?.id === product.brand?.id) {
      isBrand = true;
      order.products.forEach((item: any) => {
        if (item.id === product.id) {
          itemWithId = true;
          products.push(item);
        } else {
          products.push(item);
        }
      });
      if (!itemWithId) {
        products.push(product);
      }
      newBasket.push({ ...order, products: products });
    } else {
      newBasket.push(order);
    }
  });
  if (!isBrand) {
    newBasket.push({ brand: product.brand, products: [product] });
  }
  return newBasket;
};
