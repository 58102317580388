// APPROVED
export const modalType = {
	phoneVerify: "phoneVerify",
};

export const configRen = {
	[modalType.phoneVerify]: {
		sx: {
			wrapper: {
				maxWidth: "442px",
				maxHeight: "max-content",
				height: "max-content",
				padding: "50px 40px",
			},
		},
		// iconClose: {
		// 	styles: { display: "none" },
		// },
	},
};
