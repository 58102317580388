import styled from "@mui/system/styled";
import { BREAKPOINTS_NAME } from "../../theme/breakpoints/index";

export const sx = {
	pMainBtn: {
		wrapper: {
			marginTop: "20px",
		},
		btn: {
			maxWidth: "245px",
			m: "0 auto",
		},
	},
};

export const Banner = styled("section")(({ theme }) => ({
	width: "100%",
	padding: "80px 0",
	background: "#f9f1eb",
	position: "relative",
	overflow: "hidden",
	margin: "0 0 30px",

	[theme.breakpoints.down(BREAKPOINTS_NAME.laptop)]: {
		padding: "30px 0",
	},

	[theme.breakpoints.down(BREAKPOINTS_NAME.tabletPlus)]: {
		padding: "30px 0",
	},
}));

export const Images = styled("div")(({ theme }) => ({
	position: "absolute",
	top: 0,
	width: "100%",
	zIndex: 0,

	"& img": {
		position: "absolute",
		top: 0,
		"&:nth-child(1)": {
			left: "-4%",
		},
		"&:nth-child(2)": {
			right: "-4%",
		},
		[theme.breakpoints.down(BREAKPOINTS_NAME.container1440)]: {
			"&:nth-child(1)": {
				left: "-12%",
			},
			"&:nth-child(2)": {
				right: "-12%",
			},
		},

		[theme.breakpoints.down(BREAKPOINTS_NAME.laptop)]: {
			"&:nth-child(1)": {
				left: 0,
			},
			"&:nth-child(2)": {
				right: 0,
			},
		},
	},
}));

export const WrapperContent = styled("div")(({ theme }) => ({}));

export const Title = styled("div")(({ theme }) => ({
	display: "flex",
	alignItems: "center",
	flexDirection: "column",
	[theme.breakpoints.down(925)]: {
		"& span": {
			fontSize: "12px",
			lineHeight: "14px",
			paddingLeft: "39px",
			textAlign: "left",
			"&::before": {
				width: "28px",
				height: "1px",
			},
		},
		"& p": {
			paddingLeft: 0,
			fontSize: "14px",
			lineHeight: "18px",
		},
	},
}));

export const TitleSpan = styled("span")(({ theme }) => ({
	fontWeight: "bold",
	fontSize: "18px",
	lineHeight: "21px",
	letterSpacing: "0.1em",
	textTransform: "uppercase",
	color: theme.palette.text.darkgrey,
	position: "relative",
	paddingLeft: "80px",
	"&::before": {
		content: '""',
		position: "absolute",
		top: "50%",
		transform: "translateY(-50%)",
		width: "60px",
		height: "3px",
		background: "#353535",
		borderRadius: "5px",
		left: 0,
	},
}));

export const TitleParagraph = styled("p")(({ theme }) => ({
	marginTop: "10px",
	textAlign: "center",
	zIndex: 1,
}));
