// APPROVED

const Badge = () => ({
	MuiBadge: {
		styleOverrides: {
			badge: {
				padding: "4px",
				zIndex: 6,
			},
		},
	},
});

export default Badge;
