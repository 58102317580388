import * as Yup from "yup";

import { passwordRegExp } from "../../utils/forms/regExp";

export const validationFormSchema = (
  t: any,
  nameFields: any,
  objectBool?: any
) => {
  return Yup.object().shape({
    fio:
      nameFields?.fio &&
      Yup.string()
        .required(t("generals.yupValidation.isRequired"))
        .min(
          3,
          `${t(`generals.yupValidation.minSymbols`)} 3 ${t(
            `generals.yupValidation.symbols`
          )}`
        )
        .max(
          190,
          `${t(`generals.yupValidation.maxSymbols`)} 190 ${t(
            `generals.yupValidation.symbolss`
          )}`
        )
        .matches(/^([^0-9]*)$/, t("generals.yupValidation.notContainNumbers")),
    firstName:
      nameFields?.firstName &&
      Yup.string()
        .required(t("generals.yupValidation.isRequired"))
        .min(
          3,
          `${t(`generals.yupValidation.minSymbols`)} 3 ${t(
            `generals.yupValidation.symbols`
          )}`
        )
        .max(
          190,
          `${t(`generals.yupValidation.maxSymbols`)} 190 ${t(
            `generals.yupValidation.symbolss`
          )}`
        )
        .matches(/^([^0-9]*)$/, t("generals.yupValidation.notContainNumbers")),

    lastName:
      nameFields?.lastName &&
      Yup.string()
        .required(t("generals.yupValidation.isRequired"))
        .min(
          3,
          `${t(`generals.yupValidation.minSymbols`)} 3 ${t(
            `generals.yupValidation.symbols`
          )}`
        )
        .max(
          190,
          `${t(`generals.yupValidation.maxSymbols`)} 190 ${t(
            `generals.yupValidation.symbolss`
          )}`
        )
        .matches(/^([^0-9]*)$/, t("generals.yupValidation.notContainNumbers")),
    brandName:
      nameFields?.brandName &&
      Yup.string()
        .required(t("generals.yupValidation.isRequired"))
        .min(
          3,
          `${t(`generals.yupValidation.minSymbols`)} 3 ${t(
            `generals.yupValidation.symbols`
          )}`
        )
        .max(
          190,
          `${t(`generals.yupValidation.maxSymbols`)} 190 ${t(
            `generals.yupValidation.symbolss`
          )}`
        ),

    email:
      nameFields?.email &&
      Yup.string()
        .email(t("generals.yupValidation.email"))
        .required(t("generals.yupValidation.isRequired")),
    password:
      nameFields?.password &&
      Yup.string()
        .required(t("generals.yupValidation.isRequired"))
        .matches(
          passwordRegExp,
          `${t(`generals.yupValidation.minSymbols`)} 6 ${t(
            `generals.yupValidation.symbols`
          )}`
        ),
    passwordRegister:
      nameFields?.passwordRegister &&
      Yup.string()
        .required(t("generals.yupValidation.isRequired"))
        .matches(
          passwordRegExp,
          `${t(`generals.yupValidation.minSymbols`)} 6 ${t(
            `generals.yupValidation.symbols`
          )}`
        ),
    passwordRegisterConfirmation:
      nameFields?.passwordRegisterConfirmation &&
      Yup.string()
        .required(t("generals.yupValidation.isRequired"))
        .matches(
          passwordRegExp,
          `${t(`generals.yupValidation.minSymbols`)} 6 ${t(
            `generals.yupValidation.symbols`
          )}`
        )
        .test(
          "passwords-match",
          t("generals.yupValidation.passwordsDontEqual"),
          function (value) {
            return this.parent.passwordRegister === value;
          }
        ),
    controlledMaskedInputNumberPhone:
      nameFields?.controlledMaskedInputNumberPhone &&
      Yup.mixed()
        .required(t("generals.yupValidation.isRequired"))
        .test("test_length", "", function (value: any) {
          const clearTel = (tel: any) => tel.replace(/[^0-9]/g, "");
          const clearedTel = (value && clearTel(value)) || 0;
          if (value && clearedTel?.length < 12) {
            return this.createError({
              message: t("generals.yupValidation.phoneTest"),
            });
          }
          return true;
        }),
    checkboxConfirm:
      nameFields?.checkboxConfirm &&
      Yup.bool().test("bool", "", function (value) {
        if (!value) {
          return this.createError({
            message: t("generals.yupValidation.phoneTest"),
          });
        }
        return true;
      }),
    office:
      nameFields?.office &&
      Yup.string()
        .required(t("generals.yupValidation.isRequired"))
        .min(
          2,
          `${t(`generals.yupValidation.minSymbols`)} 2 ${t(
            `generals.yupValidation.symbols`
          )}`
        )
        .max(
          190,
          `${t(`generals.yupValidation.maxSymbols`)} 190 ${t(
            `generals.yupValidation.symbolss`
          )}`
        ),
    city:
      nameFields?.city &&
      Yup.string()
        .required(t("generals.yupValidation.isRequired"))
        .min(
          2,
          `${t(`generals.yupValidation.minSymbols`)} 2 ${t(
            `generals.yupValidation.symbols`
          )}`
        )
        .max(
          190,
          `${t(`generals.yupValidation.maxSymbols`)} 190 ${t(
            `generals.yupValidation.symbolss`
          )}`
        ),
    comment:
      nameFields?.comment &&
      Yup.string().max(
        700,
        `${t(`generals.yupValidation.maxSymbols`)} 700 ${t(
          `generals.yupValidation.symbolss`
        )}`
      ),

    commentRequired:
      nameFields?.commentRequired &&
      Yup.string()
        .required(t("generals.yupValidation.isRequired"))
        .max(
          700,
          `${t(`generals.yupValidation.maxSymbols`)} 700 ${t(
            `generals.yupValidation.symbolss`
          )}`
        ),
    street:
      nameFields?.street &&
      Yup.string()
        .required(t("generals.yupValidation.isRequired"))
        .min(
          2,
          `${t(`generals.yupValidation.minSymbols`)} 2 ${t(
            `generals.yupValidation.symbols`
          )}`
        )
        .max(
          190,
          `${t(`generals.yupValidation.maxSymbols`)} 190 ${t(
            `generals.yupValidation.symbolss`
          )}`
        ),
    house:
      nameFields?.house &&
      Yup.string()
        .required(t("generals.yupValidation.isRequired"))

        .max(
          190,
          `${t(`generals.yupValidation.maxSymbols`)} 190 ${t(
            `generals.yupValidation.symbolss`
          )}`
        ),
    at:
      nameFields?.at &&
      Yup.string()
        .required(t("generals.yupValidation.isRequired"))

        .max(
          190,
          `${t(`generals.yupValidation.maxSymbols`)} 190 ${t(
            `generals.yupValidation.symbolss`
          )}`
        ),
    text:
      nameFields?.text &&
      Yup.string()
        .required(t("generals.yupValidation.isRequired"))
        .max(
          700,
          `${t(`generals.yupValidation.maxSymbols`)} 700 ${t(
            `generals.yupValidation.symbolss`
          )}`
        ),
  });
};
