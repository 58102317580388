import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

//api
import { baseURL } from "../../API/_api";

const favouriteProductsLocal = localStorage.getItem("favouriteProducts");

export const addFavouriteProduct = createAsyncThunk(
  "addFavouriteProduct",
  async (data: any, thunkAPI) => {
    try {
      const response = await axios.post(`${baseURL}discount-images`, { data });
      return response.data.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const removeFavouriteProduct = createAsyncThunk(
  "removeFavouriteProduct",
  async (data: any, thunkAPI) => {
    try {
      const response = await axios.delete(`${baseURL}discount-images`, {
        data,
      });
      return response.data.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

type TFavouriteProduct = {
  id: number;
};
type TinitialState = {
  favouriteProducts: any;
  statusAddFavouriteProduct: boolean;
  errorAddFavouriteProduct: string | object;
  statusRemoveFavouriteProduct: boolean;
  errorRemoveFavouriteProduct: string | object;
};

const initialState: TinitialState = {
  favouriteProducts:
    (favouriteProductsLocal && JSON.parse(favouriteProductsLocal)) || null,
  statusAddFavouriteProduct: false,
  errorAddFavouriteProduct: "",
  statusRemoveFavouriteProduct: false,
  errorRemoveFavouriteProduct: "",
};

export const favourite = createSlice({
  name: "favourite",
  initialState,
  reducers: {},
  extraReducers: {
    [addFavouriteProduct.fulfilled.type]: (state, action) => {
      state.statusAddFavouriteProduct = false;
      state.errorAddFavouriteProduct = "";
    },
    [addFavouriteProduct.pending.type]: (state) => {
      state.statusAddFavouriteProduct = true;
    },
    [addFavouriteProduct.rejected.type]: (
      state,
      action: PayloadAction<string | object>
    ) => {
      state.statusAddFavouriteProduct = false;
      state.errorAddFavouriteProduct = action.payload;
    },
    [removeFavouriteProduct.fulfilled.type]: (state, action) => {
      state.statusRemoveFavouriteProduct = false;
      state.errorRemoveFavouriteProduct = "";
    },
    [removeFavouriteProduct.pending.type]: (state) => {
      state.statusRemoveFavouriteProduct = true;
    },
    [removeFavouriteProduct.rejected.type]: (
      state,
      action: PayloadAction<string | object>
    ) => {
      state.statusRemoveFavouriteProduct = false;
      state.errorRemoveFavouriteProduct = action.payload;
    },
  },
});

export const {} = favourite.actions;

export default favourite.reducer;
