import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

//styles
import s from "./ButtonsLinkCustomer.module.scss";

//hooks
import { useAppDispatch, useAppSelector } from "../../../../../hooks/redux";

//routes
import { paths, pathFC } from "../../../../../App/Routes/paths";

//redux
import {
  setModalWindowChildrenType,
  setModalWindowStatus,
} from "../../../../../redux/App/app";

type ButtonsLinkCustomerProps = {
  path: string;
  svg?: string;
  title: string;
  subLinks?: any;
  onClick?: any;
};

const ButtonsLinkCustomer = ({
  path,
  svg,
  title,
  subLinks,
  onClick,
}: ButtonsLinkCustomerProps) => {
  const { t } = useTranslation();

  const history = useHistory();

  const dispatch = useAppDispatch();

  const wishListProducts = useAppSelector(
    (state) => state.favouriteWishList.wishListProducts
  );

  const basket = useAppSelector((state) => state.basket.basket);

  const { isLogged, userData } = useAppSelector((state) => state.user);

  const logout = () => {
    localStorage.removeItem("token_shopping");
    history.push(pathFC(paths.main));
    window.location.reload();
  };

  if (isLogged && userData?.role === "Customer") {
    return (
      <div className={s.wrapperProfile}>
        <NavLink
          to={path}
          activeClassName={s.activeButtonItem}
          className={s.burgerButtonItem}
          onClick={onClick}
        >
          {svg && (
            <div
              dangerouslySetInnerHTML={{
                __html: svg,
              }}
              className={s.burgerButtonItemSvg}
            />
          )}

          <span className={s.burgerButtonItemTitle}>
            {t(title)}
            {path === pathFC(paths.favorites) &&
              wishListProducts?.length > 0 && (
                <span className={s.counts}>{wishListProducts?.length}</span>
              )}
            {path === pathFC(paths.basket) && basket?.length > 0 && (
              <span className={s.counts}>{basket?.length}</span>
            )}
            {path === pathFC(paths.profilePromoCodesCertificates) &&
              userData?.promo_codes?.length > 0 && (
                <span className={s.counts}>
                  {userData?.promo_codes?.length}
                </span>
              )}
          </span>
        </NavLink>
        {subLinks && (
          <div className={s.subLinks}>
            {subLinks?.map((item: any) => {
              return (
                <NavLink
                  to={item.path}
                  className={s.subLinkItem}
                  key={item?.id}
                >
                  {t(item.title)}
                </NavLink>
              );
            })}
            <span className={s.subLinkItemOut} onClick={logout}>
              {t("header.logOut")}
            </span>
          </div>
        )}
      </div>
    );
  } else {
    if (
      pathFC(paths.profilePersonal) === path ||
      pathFC(paths.profilePromoCodesCertificates) === path
    ) {
      return (
        <div
          className={s.burgerButtonItem}
          onClick={() => {
            dispatch(setModalWindowStatus(true));
            dispatch(setModalWindowChildrenType("login"));
            onClick();
          }}
        >
          {svg && (
            <div
              dangerouslySetInnerHTML={{
                __html: svg,
              }}
              className={s.burgerButtonItemSvg}
            />
          )}

          <span className={s.burgerButtonItemTitle}>{t(title)}</span>
        </div>
      );
    } else {
      return (
        <NavLink
          to={path}
          activeClassName={s.activeButtonItem}
          className={s.burgerButtonItem}
          onClick={onClick}
        >
          {/* {path === pathFC(paths.favorites) && wishListProducts?.length > 0 && (
            <span className={s.counts}>{wishListProducts?.length}</span>
          )}
          {path === pathFC(paths.basket) && basket?.length > 0 && (
            <span className={s.counts}>{basket?.length}</span>
          )}
          {path === pathFC(paths.profilePromoCodesCertificates) &&
            userData &&
            userData?.promo_codes?.length > 0 && (
              <span className={s.counts}>{userData?.promo_codes?.length}</span>
            )} */}

          {svg && (
            <div
              dangerouslySetInnerHTML={{
                __html: svg,
              }}
              className={s.burgerButtonItemSvg}
            />
          )}

          <span className={s.burgerButtonItemTitle}>{t(title)}</span>
        </NavLink>
      );
    }
  }
};

export default ButtonsLinkCustomer;
