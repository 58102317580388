export const guarenteeSvg: string = `
    <svg
      width="15"
      height="17"
      viewBox="0 0 15 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.0476 10.6404L13.0477 10.6403C13.7724 8.67556 13.7383 6.49219 13.703 4.628L13.7029 4.62545C13.7029 4.62485 13.7028 4.62426 13.7028 4.62367C13.6957 4.466 13.691 4.30061 13.6882 4.11876L13.6882 4.11833C13.68 3.56658 13.2386 3.10609 12.6848 3.07511L13.0476 10.6404ZM13.0476 10.6404C12.6546 11.7063 12.0628 12.6258 11.2908 13.3798C10.4103 14.2397 9.24077 14.9388 7.79658 15.4438C7.77378 15.4518 7.74909 15.4586 7.72368 15.4637C7.68896 15.4706 7.65441 15.4739 7.6203 15.4739C7.58613 15.4739 7.55148 15.4706 7.51627 15.4635L7.39897 16.052M13.0476 10.6404L7.44408 15.4439M7.44408 15.4439L7.44413 15.4439C7.46675 15.4518 7.49124 15.4585 7.51691 15.4637L7.39897 16.052M7.44408 15.4439C5.9982 14.9397 4.82748 14.241 3.94631 13.3811M7.44408 15.4439L11.71 13.8091C10.7554 14.7413 9.5054 15.4819 7.99465 16.0102C7.9452 16.0275 7.89371 16.0415 7.84162 16.052C7.76829 16.0666 7.69423 16.0739 7.6203 16.0739C7.54636 16.0739 7.47243 16.0666 7.39897 16.052M7.39897 16.052C7.34688 16.0415 7.29575 16.0276 7.24654 16.0104C5.734 15.4831 4.48263 14.7429 3.52723 13.8105M3.52723 13.8105L3.94631 13.3811M3.52723 13.8105L3.94629 13.3811C3.94629 13.3811 3.9463 13.3811 3.94631 13.3811M3.52723 13.8105C2.68838 12.9918 2.0489 11.9959 1.62665 10.8501L3.94631 13.3811M3.94631 13.3811C3.174 12.6274 2.58233 11.7082 2.18964 10.6426L2.18963 10.6426C1.46723 8.68262 1.50196 6.50178 1.53745 4.64007L1.53797 4.61931C1.54497 4.46225 1.54972 4.29821 1.55241 4.11854L1.55241 4.11845C1.56058 3.56655 2.00216 3.1061 2.5558 3.07523L2.55586 3.07523C4.49478 2.96689 6.03432 2.32585 7.34227 1.05207M3.94631 13.3811L7.34227 1.05207M7.34227 1.05207L7.34499 1.04948C7.5045 0.906272 7.73627 0.906361 7.89556 1.04944L7.89832 1.05207C9.20627 2.32584 10.7458 2.96689 12.6847 3.07511L7.34227 1.05207Z"
        stroke="#ee4a2e"
        stroke-width="1.2"
      />
      <path
        d="M4.07305 8.20831C4.07305 10.1649 5.66392 11.7558 7.62054 11.7558C9.57705 11.7558 11.1679 10.1649 11.1679 8.20831C11.1679 6.25171 9.57705 4.66082 7.62054 4.66082C5.66392 4.66082 4.07305 6.25169 4.07305 8.20831Z"
        stroke="#ee4a2e"
        stroke-width="1.1"
      />
      <path
        d="M8.65238 6.91585L8.65231 6.91579L8.64958 6.91894L6.7731 9.07615L6.11122 8.33154L6.11126 8.33151L6.10914 8.32927C6.06243 8.27987 5.97711 8.2182 5.86895 8.20485C5.75239 8.19047 5.62844 8.2346 5.5209 8.36511C5.32319 8.60504 5.43003 8.86977 5.52542 8.97062L5.52542 8.97062L6.50593 10.0073C6.50593 10.0073 6.50594 10.0073 6.50594 10.0073C6.53989 10.0432 6.58425 10.0646 6.62513 10.0771C6.66707 10.0899 6.71255 10.0957 6.7564 10.0957C6.80024 10.0957 6.84572 10.0899 6.88765 10.0771C6.92852 10.0645 6.97287 10.0432 7.00681 10.0073L7.00683 10.0073L7.00821 10.0058L9.25491 7.53043C9.30268 7.47959 9.35548 7.39164 9.37437 7.28942C9.39416 7.18227 9.37671 7.05525 9.27394 6.94659C9.1604 6.82653 9.02938 6.789 8.91286 6.79859C8.80206 6.8077 8.70589 6.85928 8.65238 6.91585Z"
        fill="#ee4a2e"
        stroke="#ee4a2e"
        stroke-width="0.2"
      />
    </svg>
  `;
