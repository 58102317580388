export const reviewData = {
  communication: [
    { id: 3, title: "modals.sellerReview.communication.2" },
    { id: 2, title: "modals.sellerReview.communication.1" },
    { id: 1, title: "modals.sellerReview.communication.0" },
  ],
  connection: [
    { id: 3, title: "modals.sellerReview.connection.2" },
    { id: 2, title: "modals.sellerReview.connection.1" },
    { id: 1, title: "modals.sellerReview.connection.0" },
  ],
  sizeReview: [
    { id: 4, title: "modals.productReview.size.3" },
    { id: 2, title: "modals.productReview.size.1" },
    { id: 3, title: "modals.productReview.size.2" },
    { id: 1, title: "modals.productReview.size.0" },
  ],
  colorReview: [
    { id: 4, title: "modals.productReview.color.3" },
    { id: 2, title: "modals.productReview.color.1" },
    { id: 3, title: "modals.productReview.color.2" },
    { id: 1, title: "modals.productReview.color.0" },
  ],
  qualityReview: [
    { id: 3, title: "modals.sellerReview.quality.2" },
    { id: 2, title: "modals.sellerReview.quality.1" },
    { id: 1, title: "modals.sellerReview.quality.0" },
  ],
  deliveryReview: [
    { id: 3, title: "modals.sellerReview.delivery.2" },
    { id: 2, title: "modals.sellerReview.delivery.1" },
    { id: 1, title: "modals.sellerReview.delivery.0" },
  ],
};
