const IconButton = () => ({
	MuiIconButton: {
		styleOverrides: {
			root: {
				padding: 0,
			},
		},
	},
});

export default IconButton;
