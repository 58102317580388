import { createSlice } from "@reduxjs/toolkit";

//api
import { getRequest, putRequest } from "../../../../API/_api";

//redux
import {
  setModalWindowChildrenType,
  setModalWindowStatus,
  setModalWindowInfo,
} from "../../../App/app";

export const getSellerSettingsDocumentsBack = () => (dispatch: any) => {
  dispatch(setLoadingGetSellerSettingsDocumentsBack(true));

  const success = (data: any) => {
    dispatch(setDataGetSellerSettingsDocumentsBack(data?.data));
  };

  const setError = (error: any) => {
    dispatch(setErrorGetSellerSettingsDocumentsBack(error));
  };

  getRequest({
    url: `seller/legal-data`,
    success,
    setError,
  });
};

type TPutSellerSettingsDocumentsBackProps = {
  obj: any;
};

export const putSellerSettingsDocumentsBack =
  (sendData: TPutSellerSettingsDocumentsBackProps) => (dispatch: any) => {
    const { obj } = sendData;
    dispatch(setLoadingPutSellerSettingsDocumentsBack(true));

    const success = (data: any) => {
      dispatch(setDataPutSellerSettingsDocumentsBack(data?.data));
      dispatch(setModalWindowChildrenType("formSuccess"));
      dispatch(
        setModalWindowInfo({
          title: "common.modals",
        })
      );
      dispatch(setModalWindowStatus(true));
    };

    const setError = (error: any) => {
      dispatch(setErrorPutSellerSettingsDocumentsBack(error?.response?.data));
    };

    putRequest({
      url: `seller/legal-data`,
      success,
      setError,
      obj,
    });
  };

const initialState: any = {
  dataGetSellerSettingsDocumentsBack: null,
  loadingGetSellerSettingsDocumentsBack: false,
  errorGetSellerSettingsDocumentsBack: null,
  dataPutSellerSettingsDocumentsBack: null,
  loadingPutSellerSettingsDocumentsBack: false,
  errorPutSellerSettingsDocumentsBack: null,
};

export const sellerSettingsDocuments = createSlice({
  name: "sellerSettingsDocuments",
  initialState,
  reducers: {
    setDataGetSellerSettingsDocumentsBack(state, action) {
      state.dataGetSellerSettingsDocumentsBack = action.payload;
      state.errorPutSellerSettingsDocumentsBack = null;
      state.loadingGetSellerSettingsDocumentsBack = false;
    },
    setErrorGetSellerSettingsDocumentsBack(state, action) {
      state.errorGetSellerSettingsDocumentsBack = action.payload;
      state.loadingGetSellerSettingsDocumentsBack = false;
    },
    setLoadingGetSellerSettingsDocumentsBack(state, action) {
      state.loadingGetSellerSettingsDocumentsBack = action.payload;
      state.errorGetSellerSettingsDocumentsBack = null;
    },
    setDataPutSellerSettingsDocumentsBack(state, action) {
      state.dataPutSellerSettingsDocumentsBack = action.payload;
      state.loadingPutSellerSettingsDocumentsBack = false;
      state.errorPutSellerSettingsDocumentsBack = null;
    },
    setErrorPutSellerSettingsDocumentsBack(state, action) {
      state.errorPutSellerSettingsDocumentsBack = action.payload;
      state.loadingPutSellerSettingsDocumentsBack = false;
    },
    setLoadingPutSellerSettingsDocumentsBack(state, action) {
      state.loadingPutSellerSettingsDocumentsBack = action.payload;
      state.errorPutSellerSettingsDocumentsBack = null;
    },
  },
});

export const {
  setLoadingGetSellerSettingsDocumentsBack,
  setErrorGetSellerSettingsDocumentsBack,
  setDataGetSellerSettingsDocumentsBack,
  setDataPutSellerSettingsDocumentsBack,
  setErrorPutSellerSettingsDocumentsBack,
  setLoadingPutSellerSettingsDocumentsBack,
} = sellerSettingsDocuments.actions;

export default sellerSettingsDocuments.reducer;
