import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { type } from "os";

//api
import { baseURL } from "../../API/_api";

//types
import { TBrand } from "../../TS/types/generals/generals";

type sendData = {
  email: string;
  gender: string;
};
export const postSubscription = createAsyncThunk(
  "postSubscription",
  async (data: sendData, thunkAPI) => {
    try {
      const response = await axios.get(`${baseURL}subscription`, { data });
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

type TSubscriptionState = {
  response: any;
  status: boolean;
  error: string | object;
};

const initialState: TSubscriptionState = {
  response: [],
  status: false,
  error: "",
};

export const subscription = createSlice({
  name: "subscription",
  initialState,
  reducers: {},
  extraReducers: {
    [postSubscription.fulfilled.type]: (state, action) => {
      state.status = false;
      state.error = "";
      state.response = action.payload.data;
    },
    [postSubscription.pending.type]: (state) => {
      state.status = true;
    },
    [postSubscription.rejected.type]: (
      state,
      action: PayloadAction<string | object>
    ) => {
      state.status = false;
      state.error = action.payload;
    },
  },
});

export const {} = subscription.actions;

export default subscription.reducer;
