import { createSlice } from "@reduxjs/toolkit";

//api
import { getRequest, putRequest } from "../../../API/_api";

//TS
import { TSellerOrder } from "../../../TS/types/seller/sellerOrder/sellerOrder";

//redux
import {
  setModalWindowChildrenType,
  setModalWindowStatus,
  setModalWindowInfo,
} from "../../App/app";

type TGetSellerOrdersProps = {
  orderId: any;
};

export const getSellerOrderBack =
  (sendData: TGetSellerOrdersProps) => (dispatch: any) => {
    const { orderId } = sendData;
    dispatch(setLoadingGetSellerOrderBack(true));

    const success = (data: any) => {
      dispatch(setDataGetSellerOrderBack(data?.data));
    };

    const setError = (error: any) => {
      dispatch(setErrorGetSellerOrderBack(error));
    };

    getRequest({
      url: `orders/${orderId}`,
      success,
      setError,
    });
  };

type TputSellerOrderBackProps = {
  orderId: number;
  obj: {
    status: number;
  };
};

export const putSellerOrderBack =
  (sendData: TputSellerOrderBackProps) => (dispatch: any) => {
    const { obj, orderId } = sendData;
    dispatch(setLoadingPutSellerOrderBack(true));

    const success = (data: any) => {
      dispatch(setDataPutSellerOrderBack(data?.data));
      dispatch(
        getSellerOrderBack({
          orderId,
        })
      );
    };

    const setError = (error: any) => {
      dispatch(setErrorPutSellerOrderBack(error));
      dispatch(setModalWindowChildrenType("formError"));
      dispatch(
        setModalWindowInfo({
          title: "common.modals",
          text: error?.response?.data?.message,
        })
      );
      dispatch(setModalWindowStatus(true));
    };

    putRequest({
      url: `seller/orders/${orderId}`,
      success,
      setError,
      obj,
    });
  };

type TInitialState = {
  dataGetSellerOrderBack: TSellerOrder | null;
  loadingGetSellerOrderBack: boolean;
  errorGetSellerOrderBack: any;

  dataPutSellerOrderBack: any;
  loadingPutSellerOrderBack: boolean;
  errorPutSellerOrderBack: any;
};

const initialState: TInitialState = {
  dataGetSellerOrderBack: null,
  loadingGetSellerOrderBack: false,
  errorGetSellerOrderBack: null,

  dataPutSellerOrderBack: null,
  loadingPutSellerOrderBack: false,
  errorPutSellerOrderBack: null,
};

export const sellerOrder = createSlice({
  name: "sellerOrder",
  initialState,
  reducers: {
    setDataGetSellerOrderBack(state, action) {
      state.dataGetSellerOrderBack = action.payload;
      state.loadingGetSellerOrderBack = false;
    },
    setLoadingGetSellerOrderBack(state, action) {
      state.loadingGetSellerOrderBack = action.payload;
      state.errorGetSellerOrderBack = null;
    },
    setErrorGetSellerOrderBack(state, action) {
      state.errorGetSellerOrderBack = action.payload;
      state.loadingGetSellerOrderBack = false;
    },
    setDataPutSellerOrderBack(state, action) {
      state.dataPutSellerOrderBack = action.payload;
      state.loadingPutSellerOrderBack = false;
    },
    setErrorPutSellerOrderBack(state, action) {
      state.errorPutSellerOrderBack = action.payload;
      state.loadingPutSellerOrderBack = false;
    },
    setLoadingPutSellerOrderBack(state, action) {
      state.loadingPutSellerOrderBack = action.payload;
      state.errorPutSellerOrderBack = null;
    },
  },
});

export const {
  setDataGetSellerOrderBack,
  setLoadingGetSellerOrderBack,
  setErrorGetSellerOrderBack,
  setDataPutSellerOrderBack,
  setErrorPutSellerOrderBack,
  setLoadingPutSellerOrderBack,
} = sellerOrder.actions;

export default sellerOrder.reducer;
