import { createSlice } from "@reduxjs/toolkit";

//api
import { getRequest } from "../../../API/_api";

//types
import { TDiscountProduct } from "../../../TS/types/main/main";

//utils
import { getSlugOfGender } from "../../../utils/generals/getSlugOfGender";

type TData = {
  params: {
    page: number;
    perPage: number;
    direction: string;
    sortBy: string;
  };
  gender: string;
};


export const getMainDiscountProductsNewBack =
  (sendData: TData) => (dispatch: any) => {
    const { params, gender } = sendData;

    dispatch(setLoadingGetMainDiscountProductsNewBack(true));

    const success = (data: any) => {
      dispatch(setDataGetMainDiscountProductsNewBack(data));
    };

    const setError = (error: any) => {
      dispatch(setErrorGetMainDiscountProductsNewBack(error?.response?.data));
    };

    getRequest({
      url: `new-products/${getSlugOfGender(gender)}`,
      success,
      setError,
      params,
    });
  };

type TdiscountImagesInitialState = {
  dataGetMainDiscountProductsSellOutBack: Array<TDiscountProduct> | [];
  loadingGetMainDiscountProductsSellOutBack: boolean;
  errorGetMainDiscountProductsSellOutBack: any;
  metaGetMainDiscountProductsSellOutBack: any;

  dataGetMainDiscountProductsNewBack: Array<TDiscountProduct> | [];
  loadingGetMainDiscountProductsNewBack: boolean;
  errorGetMainDiscountProductsNewBack: any;
  metaGetMainDiscountProductsNewBack: any;
};

const initialState: TdiscountImagesInitialState = {
  dataGetMainDiscountProductsSellOutBack: [],
  loadingGetMainDiscountProductsSellOutBack: false,
  errorGetMainDiscountProductsSellOutBack: null,
  metaGetMainDiscountProductsSellOutBack: null,

  dataGetMainDiscountProductsNewBack: [],
  loadingGetMainDiscountProductsNewBack: false,
  errorGetMainDiscountProductsNewBack: null,
  metaGetMainDiscountProductsNewBack: null,
};

export const mainDiscountProducts = createSlice({
  name: "mainDiscountProducts",
  initialState,
  reducers: {
    setDataGetMainDiscountProductsSellOutBack(state, action) {
      state.dataGetMainDiscountProductsSellOutBack = action.payload.data;
      state.metaGetMainDiscountProductsSellOutBack = action.payload.meta;
      state.loadingGetMainDiscountProductsSellOutBack = false;
      state.errorGetMainDiscountProductsSellOutBack = null;
    },

    setErrorGetMainDiscountProductsSellOutBack(state, action) {
      state.errorGetMainDiscountProductsSellOutBack = action.payload;
      state.loadingGetMainDiscountProductsSellOutBack = false;
    },
    setLoadingGetMainDiscountProductsSellOutBack(state, action) {
      state.loadingGetMainDiscountProductsSellOutBack = action.payload;
      state.errorGetMainDiscountProductsSellOutBack = null;
    },
    setDataGetMainDiscountProductsNewBack(state, action) {
      state.dataGetMainDiscountProductsNewBack = action.payload.data;
      state.metaGetMainDiscountProductsNewBack = action.payload.meta;
      state.loadingGetMainDiscountProductsNewBack = false;
      state.errorGetMainDiscountProductsNewBack = null;
    },
    setErrorGetMainDiscountProductsNewBack(state, action) {
      state.errorGetMainDiscountProductsNewBack = action.payload;
      state.loadingGetMainDiscountProductsNewBack = false;
    },
    setLoadingGetMainDiscountProductsNewBack(state, action) {
      state.loadingGetMainDiscountProductsNewBack = action.payload;
      state.errorGetMainDiscountProductsNewBack = null;
    },
  },
});

export const {
  setLoadingGetMainDiscountProductsSellOutBack,
  setErrorGetMainDiscountProductsSellOutBack,
  setDataGetMainDiscountProductsSellOutBack,
  setDataGetMainDiscountProductsNewBack,
  setErrorGetMainDiscountProductsNewBack,
  setLoadingGetMainDiscountProductsNewBack,
} = mainDiscountProducts.actions;

export default mainDiscountProducts.reducer;
