import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { event } from "react-ga";

//api
import { postRequest } from "../../API/_api";

//redux
import { profileLogin } from "./user";
import { setModalWindowChildrenType, setModalWindowStatus } from "../App/app";

//ProfileRegistrationBack
export const postProfileRegistrationBack = (data: any) => (dispatch: any) => {
  const { obj } = data;

  dispatch(setLoadingProfileRegistrationBack(false));
  const success = (data: any) => {
    dispatch(setDataProfileRegistrationBack(data.data));

    dispatch(
      profileLogin({
        obj: {
          email: obj.email,
          password: obj.password,
        },
        from: "registration",
        history: data?.history || null
      })
    );
  };

  const setError = (error: any) => {
    dispatch(setErrorProfileRegistrationBack(error?.response?.data));
  };

  postRequest({
    url: `auth/register`,
    obj,
    success,
    setError,
  });
};

//postSellerRegistrationBack
export const postSellerRegistrationBack = (data: any) => (dispatch: any) => {
  const { obj } = data;

  dispatch(setLoadingSellerRegistrationBack(false));
  const success = (data: any) => {
    event({
      category: "seller_registration",
      action: "click",
    });
    dispatch(setDataSellerRegistrationBack(data.data));
    dispatch(setModalWindowChildrenType("formSuccessSellerRegistrion"));
    dispatch(setModalWindowStatus(true));
  };

  const setError = (error: any) => {
    dispatch(setErrorSellerRegistrationBack(error));
  };

  postRequest({
    url: `auth/seller/register`,
    obj,
    success,
    setError,
  });
};

const initialState: any = {
  dataProfileRegistrationBack: null,
  loadingProfileRegistrationBack: false,
  errorProfileRegistrationBack: null,
  dataSellerRegistrationBack: null,
  loadingSellerRegistrationBack: false,
  errorSellerRegistrationBack: null,
};

export const userRegister = createSlice({
  name: "userRegister",
  initialState,
  reducers: {
    setDataProfileRegistrationBack(state, action) {
      state.dataProfileRegistrationBack = action.payload;
      state.errorProfileRegistrationBack = null;
      state.loadingProfileRegistrationBack = false;
    },
    setLoadingProfileRegistrationBack(state, action) {
      state.loadingProfileRegistrationBack = action.payload;
      state.errorProfileRegistrationBack = null;
    },
    setErrorProfileRegistrationBack(state, action) {
      state.errorProfileRegistrationBack = action.payload;
    },
    setLoadingSellerRegistrationBack(state, action) {
      state.loadingSellerRegistrationBack = action.payload;
      state.errorSellerRegistrationBack = null;
    },
    setErrorSellerRegistrationBack(state, action) {
      state.errorSellerRegistrationBack = action.payload;
    },
    setDataSellerRegistrationBack(state, action) {
      state.dataSellerRegistrationBack = action.payload;
      state.errorSellerRegistrationBack = null;
      state.loadingSellerRegistrationBack = false;
    },
  },
});

export const {
  setDataProfileRegistrationBack,
  setLoadingProfileRegistrationBack,
  setErrorProfileRegistrationBack,
  setLoadingSellerRegistrationBack,
  setErrorSellerRegistrationBack,
  setDataSellerRegistrationBack,
} = userRegister.actions;

export default userRegister.reducer;
