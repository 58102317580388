import { createSlice } from "@reduxjs/toolkit";

//api
import { getRequest } from "../../../API/_api";

//types
import { TTopCategories } from "../../../TS/types/main/main";

type sendData = {
  gender: string;
  params: any;
};

type TdiscountImagesInitialState = {
  topCategoriesBack: Array<TTopCategories> | [];
  loadingTopCategoriesBack: boolean;
  errorTopCategoriesBack: any;
};

const initialState: TdiscountImagesInitialState = {
  topCategoriesBack: [],
  loadingTopCategoriesBack: false,
  errorTopCategoriesBack: null,
};

export const getTopCategoriesBack = (sendData: sendData) => (dispatch: any) => {
  const { gender, params } = sendData;
  dispatch(setLoadingTopCategoriesBack(true));
  const success = (data: any) => {
    dispatch(successTopCategoriesBack(data.data));
  };

  const setError = (error: any) => {
    dispatch(setErrorTopCategoriesBack(error));
  };

  getRequest({
    url: `top-categories/${gender}`,
    success,
    setError,
    params,
  });
};

export const categoriesTop = createSlice({
  name: "categoriesTop",
  initialState,
  reducers: {
    successTopCategoriesBack(state, action) {
      state.topCategoriesBack = action.payload;
      state.loadingTopCategoriesBack = false;
    },
    setLoadingTopCategoriesBack(state, action) {
      state.loadingTopCategoriesBack = action.payload;
      state.errorTopCategoriesBack = null;
    },
    setErrorTopCategoriesBack(state, action) {
      state.errorTopCategoriesBack = action.payload;
      state.loadingTopCategoriesBack = false;
    },
  },
});
export const {
  successTopCategoriesBack,
  setLoadingTopCategoriesBack,
  setErrorTopCategoriesBack,
} = categoriesTop.actions;

export default categoriesTop.reducer;
