//styles
import s from "./ModalUserSellerChatMessage.module.scss";

type TModalUserSellerChatMessageProps = {
  data: any;
};

const ModalUserSellerChatMessage = ({
  data,
}: TModalUserSellerChatMessageProps) => {
  return (
    <div className={s.item}>
      <div className={s.wrapperTitle}>
        <span className={s.name}>{data?.sender}</span>
        <span className={s.date}>{data?.created_date}</span>
      </div>
      <p className={s.text}>{data.text}</p>
    </div>
  );
};

export default ModalUserSellerChatMessage;
