import React, { lazy } from "react";
import { paths, pathFC } from "./paths";

const Main: LazyComponentType = lazy(() => import("../../pages/Main/Main"));
const Profile: LazyComponentType = lazy(
	() => import("../../pages/Profile/Profile"),
);

const Basket: LazyComponentType = lazy(
	() => import("../../pages/Basket/Basket"),
);
const Favorites: LazyComponentType = lazy(
	() => import("../../pages/Favorites/Favorites"),
);
const ProductCard: LazyComponentType = lazy(
	() => import("../../pages/ProductCard/ProductCard"),
);

const Contacts: LazyComponentType = lazy(
	() => import("../../pages/Contacts/Contacts"),
);

const Cooperation: LazyComponentType = lazy(
	() => import("../../pages/Cooperation/Cooperation"),
);

const About: LazyComponentType = lazy(() => import("../../pages/About/About"));

const Faq: LazyComponentType = lazy(() => import("../../pages/Faq/Faq"));

const Warranty: LazyComponentType = lazy(
	() => import("../../pages/Warranty/Warranty"),
);
const Partnership: LazyComponentType = lazy(
	() => import("../../pages/Partnership/Partnership"),
);

const Delivery: LazyComponentType = lazy(
	() => import("../../pages/Delivery/Delivery"),
);

const Categories: LazyComponentType = lazy(
	() => import("../../pages/Categories/Categories"),
);

const Brands: LazyComponentType = lazy(
	() => import("../../pages/Brands/Brands"),
);

const BrandsCategory: LazyComponentType = lazy(
	() => import("../../pages/BrandsCategory/BrandsCategory"),
);

const PayThank: LazyComponentType = lazy(
	() => import("../../pages/PayThank/PayThank"),
);

const Checkout: LazyComponentType = lazy(
	() => import("../../pages/Checkout/Checkout"),
);

const Seller: LazyComponentType = lazy(
	() => import("../../pages/Seller/Seller"),
);

const SearchCatalog: LazyComponentType = lazy(
	() => import("../../pages/SearchCatalog/SearchCatalog"),
);

const DiscountOrNewProducts: LazyComponentType = lazy(
	() => import("../../pages/DiscountOrNewProducts/DiscountOrNewProducts"),
);

const Returns: LazyComponentType = lazy(
	() => import("../../pages/Returns/Returns"),
);

const Registration: LazyComponentType = lazy(
	() => import("../../pages/Registration/Registration"),
);

type LazyComponentType = React.LazyExoticComponent<React.ComponentType<any>>;

type TRoute = {
	path: string;
	component: LazyComponentType;
};

type TRoutes = Array<TRoute>;

export const publicRoutes: TRoutes = [
	{
		path: pathFC(paths?.main),
		component: Main,
	},
	{
		path: pathFC(paths?.mainWomen),
		component: Main,
	},
	{
		path: pathFC(paths?.mainMen),
		component: Main,
	},
	{
		path: pathFC(paths?.mainKids),
		component: Main,
	},
	{
		path: pathFC(paths?.brands),
		component: Brands,
	},
	{
		path: pathFC(paths?.registration),
		component: Registration,
	},
	{
		path: `${pathFC(paths?.brandsCategory)}/:id`,
		component: BrandsCategory,
	},

	{
		path: pathFC(paths?.favorites),
		component: Favorites,
	},
	{
		path: pathFC(paths?.basket),
		component: Basket,
	},
	{
		path: `${pathFC(paths?.productCart)}/:slug`,
		component: ProductCard,
	},
	{
		path: `${pathFC(paths?.contacts)}`,
		component: Contacts,
	},
	{
		path: `${pathFC(paths?.cooperation)}`,
		component: Cooperation,
	},
	{
		path: `${pathFC(paths?.about)}`,
		component: About,
	},
	{
		path: `${pathFC(paths?.faq)}`,
		component: Faq,
	},
	{
		path: `${pathFC(paths?.return)}`,
		component: Returns,
	},
	{
		path: `${pathFC(paths?.warranty)}`,
		component: Warranty,
	},
	{
		path: `${pathFC(paths?.partnership)}`,
		component: Partnership,
	},
	{
		path: `${pathFC(paths?.delivery)}`,
		component: Delivery,
	},
	{
		path: pathFC(`/:gender${paths?.categories}/:slug`),
		component: Categories,
	},
	{
		path: `${pathFC(paths?.payThankCard)}`,
		component: PayThank,
	},
	{
		path: `${pathFC(paths?.payThankCash)}`,
		component: PayThank,
	},
	{
		path: `${pathFC(paths?.payThankError)}`,
		component: PayThank,
	},
	{
		path: `${pathFC(paths?.checkout)}`,
		component: Checkout,
	},

	{
		path: `${pathFC(paths?.search)}/:slug`,
		component: SearchCatalog,
	},

	{
		path: pathFC(`/:gender${paths?.newProducts}/:slug?`),
		component: DiscountOrNewProducts,
	},
	{
		path: pathFC(`/:gender${paths?.discountProducts}/:slug?`),
		component: DiscountOrNewProducts,
	},
];

export const privateCustomerRoutes: TRoutes = [
	{
		path: pathFC(paths?.profilePromoCodesCertificates),
		component: Profile,
	},
	{
		path: pathFC(paths?.profilePersonal),
		component: Profile,
	},
	{
		path: pathFC(paths?.profileQuestionReviews),
		component: Profile,
	},
	{
		path: pathFC(paths?.profileOrders),
		component: Profile,
	},
];

export const privateSellerRoutes: TRoutes = [
	{
		path: `${pathFC(paths?.seller)}/:slug?/:subSlug`,
		component: Seller,
	},
];
