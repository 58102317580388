import { createSlice } from "@reduxjs/toolkit";

//api
import { getRequest } from "../../../API/_api";

//ts
import {
  TSellerOrder,
  TSellerOrdesAppended,
  TFilters,
  TParams,
} from "../../../TS/types/seller/sellerOrder/sellerOrder";
import { TMetaBack } from "../../../TS/types/generals/generals";

type TGetSellerOrdersProps = {
  params: TParams;
  moreProductsActiveButton?: boolean;
};

export const getSellerOrders =
  (sendData: TGetSellerOrdersProps) => (dispatch: any) => {
    const { params, moreProductsActiveButton } = sendData;
    dispatch(setLoadingSellerOrders(true));

    const success = (data: any) => {
      dispatch(
        setSellerOrdersData({
          data: data?.data,
          meta: data?.meta,
          appended: data?.appended,
          moreProductsActiveButton: moreProductsActiveButton,
        })
      );
    };

    const setError = (error: any) => {
      dispatch(setErrorSellerOrders(error));
    };

    getRequest({
      url: `seller/orders`,
      success,
      setError,
      params,
    });
  };

type TInitialState = {
  sellerOrdersData: TSellerOrder[] | [] | null;
  sellerOrdersAppended: TSellerOrdesAppended | null;
  sellerOrdersMeta: TMetaBack | null;
  loadingSellerOrders: boolean;
  errorSellerOrders: any;
  filters: TFilters;
};

const initialState: TInitialState = {
  sellerOrdersData: null,
  sellerOrdersAppended: null,
  sellerOrdersMeta: null,
  loadingSellerOrders: false,
  errorSellerOrders: null,
  filters: {
    status: "",
    date_from: "",
    date_to: "",
    price_min: "",
    price_max: "",
  },
};

export const sellerOrders = createSlice({
  name: "sellerOrders",
  initialState,
  reducers: {
    setSellerOrdersData(state, action) {
      state.sellerOrdersData = action.payload.moreProductsActiveButton
        ? state.sellerOrdersData && state.sellerOrdersData?.length > 0
          ? [...state.sellerOrdersData, ...action.payload.data]
          : action.payload.data
        : action.payload.data;
      state.sellerOrdersMeta = action.payload.meta;
      state.sellerOrdersAppended = action.payload.appended;
      state.loadingSellerOrders = false;
    },

    setErrorSellerOrders(state, action) {
      state.errorSellerOrders = action.payload;
      state.loadingSellerOrders = false;
    },
    setLoadingSellerOrders(state, action) {
      state.loadingSellerOrders = action.payload;
      state.errorSellerOrders = null;
    },
    setFiltersSellerOrders(state, action) {
      state.filters = {
        ...state.filters,
        [action.payload.name]: action.payload.value,
      };
    },
    setRemoveAllFiltersSellerOrders(state) {
      state.filters = {
        status: "",
        date_from: "",
        date_to: "",
        price_min: "",
        price_max: "",
      };
    },
  },
});

export const {
  setSellerOrdersData,
  setErrorSellerOrders,
  setLoadingSellerOrders,
  setFiltersSellerOrders,
  setRemoveAllFiltersSellerOrders,
} = sellerOrders.actions;

export default sellerOrders.reducer;
