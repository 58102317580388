import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { NavLink } from "react-router-dom";

//styles
import s from "./LogoAndLanguage.module.scss";
import breakpoints from "../../../../../styles/vars";

//components
import { SwitchLanguage } from "../../../SwitchLanguage/SwitchLanguage";

//svg
import { mainLogoSvg } from "../../../../ui/Svgs/mainLogoSvg";
import { arrowSvg } from "../../../../ui/Svgs/arrowSvg";

//paths
import { paths, pathFC } from "../../../../../App/Routes/paths";

//hooks
import { useAppSelector } from "../../../../../hooks/redux";

//db
import { soicialsLink } from "../../../../../db/generals/generalsData";

const LogoAndLanguage: React.FC = () => {
  const { t } = useTranslation();

  const lg = useMediaQuery({ maxWidth: breakpoints.lg });

  const newDate = new Date();

  const language = useAppSelector((state) => state.app.language);

  const [stateDropdownLanguage, setStateDropdownLanguage] = useState(false);

  return (
    <div className={s.logoAndLanguage}>
      <NavLink to={pathFC(paths.main)} className={s.wrapperLogo}>
        <div
          dangerouslySetInnerHTML={{ __html: mainLogoSvg }}
          className={s.mainLogoSvg}
        />
      </NavLink>

      <div className={s.languagesWrapper}>
        {!lg && <span className={s.languageText}>{t("footer.language")}:</span>}

        <div
          className={s.selectedLanguage}
          onMouseEnter={() => setStateDropdownLanguage(true)}
          onMouseLeave={() => setStateDropdownLanguage(false)}
          onClick={() => setStateDropdownLanguage((prev) => !prev)}
        >
          <span
            className={`${stateDropdownLanguage ? s.langActive : ""} ${s.lang}`}
          >
            {language}
          </span>
          <span
            dangerouslySetInnerHTML={{ __html: arrowSvg }}
            className={`${
              stateDropdownLanguage ? s.arrowSvgActive : s.arrowSvg
            }`}
          />

          <div
            className={
              stateDropdownLanguage
                ? s.activeDropdownLanguage
                : s.dropdownLanguage
            }
          >
            <SwitchLanguage
              className={s.languageBtn}
              classNameActive={s.activeBtn}
              filterLang={true}
            />
          </div>
        </div>
      </div>
      {!lg && (
        <>
          <div className={s.savingRights}>
            © {newDate.getFullYear()}
            <span>{t("footer.copyright")}</span>
          </div>

          <div className={s.socialsWrapper}>
            {soicialsLink?.length > 0 &&
              soicialsLink.map((soicialLink, index: number) => {
                return (
                  <a
                    href={soicialLink.src}
                    target="_blank"
                    rel="noreferrer"
                    key={index}
                    className={s.socialLink}
                  >
                    <span
                      dangerouslySetInnerHTML={{ __html: soicialLink.svg }}
                      className={s.socialSvg}
                    />
                  </a>
                );
              })}
          </div>
        </>
      )}
    </div>
  );
};

export default LogoAndLanguage;
