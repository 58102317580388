import React from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Assets
import error from "../../assets/images/generals/error.png";

// Global Components
import BottomBanner from "../../componentsNEW/bottomBanner";

// UI
import Container from "../../ui/containers/container";
import TitleWithLine from "../../ui/titleWithLine/TitleWithLine";
import MainBtn from "../../ui/buttons/mainBtn";

// Routes
import { paths, pathFC } from "../../App/Routes/paths";

// Styles
import { sx, SDMain, SDBody, SDErrorImg, SDBtns } from "./styles";

const Page404: React.FC = () => {
	const { t } = useTranslation();
	const history = useHistory();

	// FUNCTIONS
	const handleMainClick = () => {
		history.push(pathFC(paths.main));
	};

	const handleBackClick = () => {
		history.goBack();
	};

	return (
		<>
			<Container>
				<SDMain>
					<TitleWithLine title={t("page404.title")} />
					<SDBody>
						<SDErrorImg>
							<img
								src={error}
								alt="error"
							/>
						</SDErrorImg>
						<SDBtns>
							<MainBtn
								text={t("page404.buttonMain")}
								onClick={handleMainClick}
								optionsTags={{
									wrapper: { sx: sx.pMainBtn.wrapper },
									btn: {
										variant:
											"contained_ee4a2e_ee4a2e_ffffff",
										sx: sx.pMainBtn.btn,
									},
								}}
							/>
							<MainBtn
								text={t("page404.buttonBack")}
								onClick={handleBackClick}
								optionsTags={{
									wrapper: { sx: sx.pMainBtn.wrapper },
									btn: {
										variant:
											"contained_ee4a2e_ee4a2e_ffffff",
										sx: sx.pMainBtn.btn,
									},
								}}
							/>
						</SDBtns>
					</SDBody>
				</SDMain>
			</Container>
			<BottomBanner />
		</>
	);
};

export default Page404;
