/* eslint-disable camelcase */
// +
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import styled from "@mui/system/styled";

// STYLES
export const SDFormControl = styled(FormControl)(() => ({
	width: "100%",
}));

export const SDWrapper = styled(Grid)(() => ({
	display: "grid",
	gridTemplateColumns: "1fr",
	gridRowGap: "31px", // not
}));

export const SDWrapperItem = styled(Grid)(() => ({}));

export const SDErrorText = styled(Typography)(({ theme }) => ({
	marginTop: "5px",
	color: theme.palette.customColors.text.error,
	fontSize: "16px",
}));

export const SDFormControlLabel = styled(FormControlLabel)(
	({ theme, is_dark_background_color }) => ({
		margin: 0,
		"& span:nth-of-type(2)": {
			color: theme.palette.customColors.text.t_7,
		},
	}),
);
