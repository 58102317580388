import { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//styles
import s from "./MinimalHeader.module.scss";

//assets
import logo from "../../../assets/images/logo/logo-whith.png";

//ui common
import { SwitchLanguage } from "../SwitchLanguage/SwitchLanguage";

//ui
import Container from "../../ui/Containers/container/Contaiter";

//svg
import { arrowSvg } from "../../ui/Svgs/arrowSvg";

//routes
import { paths, pathFC } from "../../../App/Routes/paths";

//hooks
import { useAppSelector } from "../../../hooks/redux";

const MinimalHeader = () => {
	const { t } = useTranslation();

	const history = useHistory();

	const language = useAppSelector((state) => state.app.language);

	const [stateDropdownLanguage, setStateDropdownLanguage] = useState(false);

	return (
		<header className={s.root}>
			<Container>
				<div className={s.wrapper}>
					<div
						className={s.btnBack}
						onClick={() => history.goBack()}
					>
						<FontAwesomeIcon icon={faChevronLeft} />
						{t("minHeader.button")}
					</div>
					<Link
						className={s.wrapperLogo}
						to={pathFC(paths.main)}
					>
						<img
							src={logo}
							alt="logo"
						/>
					</Link>
					<div className={s.languagesWrapper}>
						<span className={s.languageText}>
							{t("footer.language")}:
						</span>
						<div
							className={s.selectedLanguage}
							onMouseEnter={() => setStateDropdownLanguage(true)}
							onMouseLeave={() => setStateDropdownLanguage(false)}
							onClick={() =>
								setStateDropdownLanguage((prev) => !prev)
							}
						>
							<span
								className={`${stateDropdownLanguage ? s.langActive : ""} ${
									s.lang
								}`}
							>
								{language}
							</span>
							<span
								dangerouslySetInnerHTML={{ __html: arrowSvg }}
								className={`${
									stateDropdownLanguage
										? s.arrowSvgActive
										: s.arrowSvg
								}`}
							/>
							<div
								className={
									stateDropdownLanguage
										? s.activeDropdownLanguage
										: s.dropdownLanguage
								}
							>
								<SwitchLanguage
									className={s.languageBtn}
									classNameActive={s.activeBtn}
									filterLang={true}
								/>
							</div>
						</div>
					</div>
				</div>
			</Container>
		</header>
	);
};

export default MinimalHeader;
