import { createSlice } from "@reduxjs/toolkit";

//api
import { getRequest } from "../../../API/_api";

//ts
import {
  TSellerOrder,
  TSellerBalanceHistoryTotals,
  TFilters,
} from "../../../TS/types/seller/sellerOrder/sellerOrder";
import { TMetaBack } from "../../../TS/types/generals/generals";

export type TParams = {
  page: number;
  perPage: number;
  sortColumn: string;
  sortDirection: string;
  filters?: {
    id?: string[];
    order_id?: string[];
    operation_type?: string[];
    created_at?: string[];
  };
};

type TGetSellerFinanceProps = {
  params: TParams;
  moreProductsActiveButton?: boolean;
};

export const getSellerFinanceBack =
  (sendData: TGetSellerFinanceProps) => (dispatch: any) => {
    const { params, moreProductsActiveButton } = sendData;
    dispatch(setLoadingSellerFinance(true));

    const success = (data: any) => {
      dispatch(
        setSellerFinanceData({
          data: data?.data,
          meta: data?.meta,
          totals: data?.totals,
          moreProductsActiveButton: moreProductsActiveButton,
        })
      );
    };

    const setError = (error: any) => {
      dispatch(setErrorSellerFinance(error));
    };

    getRequest({
      url: `balance_history`,
      success,
      setError,
      params,
    });
  };

type TInitialState = {
  dataSellerFinanceBack: TSellerOrder[] | [] | null;
  totalsSellerFinanceBack: TSellerBalanceHistoryTotals | null;
  metaSellerFinanceBack: TMetaBack | null;
  loadingSellerFinanceBack: boolean;
  errorSellerFinanceBack: any;
  filters: {
    id: any;
    order_id: any;
    operation_type: any;
    date_from: any;
    date_to: any;
  };
};

const initialState: TInitialState = {
  dataSellerFinanceBack: null,
  totalsSellerFinanceBack: null,
  metaSellerFinanceBack: null,
  loadingSellerFinanceBack: false,
  errorSellerFinanceBack: null,
  filters: {
    id: null,
    order_id: null,
    operation_type: null,
    date_from: null,
    date_to: null,
  },
};

export const sellerFinance = createSlice({
  name: "sellerFinance",
  initialState,
  reducers: {
    setSellerFinanceData(state, action) {
      state.dataSellerFinanceBack = action.payload.moreProductsActiveButton
        ? state.dataSellerFinanceBack?.length
          ? [...state.dataSellerFinanceBack, ...action.payload.data]
          : action.payload.data
        : action.payload.data;
      state.metaSellerFinanceBack = action.payload.meta;
      state.totalsSellerFinanceBack = action.payload.totals;
      state.loadingSellerFinanceBack = false;
    },

    setErrorSellerFinance(state, action) {
      state.errorSellerFinanceBack = action.payload;
      state.loadingSellerFinanceBack = false;
    },
    setLoadingSellerFinance(state, action) {
      state.loadingSellerFinanceBack = action.payload;
      state.errorSellerFinanceBack = null;
    },
    setFiltersSellerFinance(state, action) {
      state.filters = {
        ...state.filters,
        [action.payload.name]: action.payload.value,
      };
    },
    setRemoveAllFiltersSellerFinance(state) {
      state.filters = {
        id: "",
        order_id: "",
        operation_type: "",
        date_from: "",
        date_to: "",
      };
    },
  },
});

export const {
  setSellerFinanceData,
  setErrorSellerFinance,
  setLoadingSellerFinance,
  setFiltersSellerFinance,
  setRemoveAllFiltersSellerFinance,
} = sellerFinance.actions;

export default sellerFinance.reducer;
