const breakpoints: any = {
  xm: "1520px",
  xl: "1440px",
  lg: "1100px",
  md: "768px",
  mobile: "600px",
  mdMinus: "576px",
  smPlus: "480px",
  sm: "320px",
};

export default breakpoints;
