export const IncludeMassElement = (
  checked: number,
  isChecked: number[],
  setIsChecked: any
) => {
  if (!isChecked?.includes(checked)) {
    isChecked
      ? setIsChecked(() => [...isChecked, checked])
      : setIsChecked(() => [checked]);
  } else {
    let index = isChecked?.findIndex((item) => item === checked);
    setIsChecked(() => [
      ...isChecked.slice(0, index),
      ...isChecked.slice(index + 1),
    ]);
  }
  return { isChecked };
};
