import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useMediaQuery from "@mui/material/useMediaQuery";
import { NavLink, useLocation } from "react-router-dom";

//styles
import s from "./HeaderBottom.module.scss";

//assets
import zenshhinam from "../../../../assets/icons/header/NewMenu/2-odezda.svg";
import obuv from "../../../../assets/icons/header/NewMenu/145-obuv.svg";
import acc from "../../../../assets/icons/header/NewMenu/547-aksessuary.svg";
import brands from "../../../../assets/icons/header/NewMenu/brands.svg";
import menOdyg from "../../../../assets/icons/header/NewMenu/menOdyg.svg";
import menOdezhda from "../../../../assets/icons/header/NewMenu/menObuv.svg";
import menAcc from "../../../../assets/icons/header/NewMenu/menAcc.svg";
import detMal from "../../../../assets/icons/header/NewMenu/Frame 5-1.svg";
import detDev from "../../../../assets/icons/header/NewMenu/Frame 5.svg";
import kid from "../../../../assets/icons/header/NewMenu/Frame 6.svg";

//ui
import Container from "../../../ui/Containers/container/Contaiter";
import SearchHeaderInput from "../../../ui/inputs/SearchHeaderInput/SearchHeaderInput";

//hooks
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux";

//db
import {
	gendersCategoryLink,
	genderChildren,
} from "../../../../db/Header/headerData";

//routes
import { paths, pathFC } from "../../../../App/Routes/paths";

import { BREAKPOINTS_NAME } from "../../../../theme/breakpoints/index";
import { setGender } from "../../../../redux/App/app";

const HeaderBottom: React.FC = () => {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();

	const location = useLocation();

	const isUpLaptop = useMediaQuery((theme: any) =>
		theme.breakpoints.up(BREAKPOINTS_NAME.laptop),
	);

	const [activeCategoryItem, setActiveCategoryItem] = useState<number | null>(
		null,
	);

	const { dataRawCategoriesBack } = useAppSelector(
		(state) => state.rawCategories,
	);

	const gender = useAppSelector((state) => state.app.gender);

	const [categories, setCategories] = useState([
		{
			id: 100,
			name: t("brands.title"),
			path: pathFC(paths.brands),
		},
	]);

	useEffect(() => {
		const newCategories = [
			...(dataRawCategoriesBack?.[gender]?.children || []),
			...(gender !== "kids"
				? [
						{
							id: 100,
							name: t("brands.title"),
							path: pathFC(paths.brands),
						},
					]
				: []),
			{
				id: 101,
				name: t("main.new.title"),
				path: pathFC(
					`/${gender}${paths.newProducts}/${dataRawCategoriesBack?.[gender]?.slug}`,
				),
			},
			{
				id: 102,
				name: t("main.sale.title"),
				path: pathFC(
					`/${gender}${paths.discountProducts}/${dataRawCategoriesBack?.[gender]?.slug}`,
				),
			},
		];

		if (gender === "women") {
			newCategories.splice(2, 0, {
				id: 103,
				name: t("header.byuti"),
				path: pathFC(`/women/categories/616-byuti`),
			});
		}

		setCategories(newCategories);
	}, [dataRawCategoriesBack?.[gender], gender]);

	const menuIcons: any = {
		women: [zenshhinam, obuv, acc],
		men: [menOdyg, menOdezhda, menAcc],
		kids: [detMal, detDev, kid],
	};

	const isAllowUrlForMenuLg = (url: string) => {
		switch (url) {
			case pathFC(paths.main):
				return true;
			case pathFC(paths.mainWomen):
				return true;
			case pathFC(paths.mainMen):
				return true;
			case pathFC(paths.mainKids):
				return true;
			default:
				return false;
		}
	};

	return (
		<>
			<div className={s.root}>
				<Container>
					<div className={s.wrapper}>
						{isUpLaptop ? (
							<>
								<div className={s.categoryWrapper}>
									{categories?.length > 0 &&
										categories
											.filter(
												(data: any) =>
													data.slug !== "590-sport" &&
													data.slug !== "616-byuti" &&
													data.slug !== "381-sport" &&
													data.slug !==
														"387-kosmetika",
											)
											.map((item: any, i: number) => {
												return (
													<div
														className={`${s.categoryItem} `}
														key={i}
													>
														<NavLink
															to={
																item?.path
																	? item?.path
																	: pathFC(
																			`/${gender}${paths.categories}/${item?.slug}`,
																		)
															}
															className={`${s.mainCategory} ${
																item?.id ===
																activeCategoryItem
																	? s.activeCategoryItem
																	: ""
															} ${item.news ? s.categoryItemNews : ""} ${
																item.action
																	? s.categoryItemAction
																	: ""
															} ${s.categoryItemLink}`}
															onClick={() =>
																setActiveCategoryItem(
																	item?.id,
																)
															}
														>
															{t(item.name)}
														</NavLink>{" "}
														{item?.children && (
															<div
																className={
																	s.categorySubRoot
																}
															>
																<div
																	className={
																		s.rootcategorySub
																	}
																>
																	<div
																		className={
																			s.wrapperCategorySub
																		}
																	>
																		<span
																			className={
																				s.titleGeneral
																			}
																		>
																			{t(
																				"header.footerItem.categories",
																			)}
																		</span>
																		<div
																			className={
																				s.innerCategorySub
																			}
																		>
																			{item.children.map(
																				(
																					categorySub: any,
																					i: number,
																				) => {
																					return (
																						categorySub?.name && (
																							<NavLink
																								to={pathFC(
																									`/${gender}${paths.categories}/${categorySub?.slug}`,
																								)}
																								className={
																									s.categorySubItem
																								}
																								key={
																									i
																								}
																							>
																								{
																									categorySub?.name
																								}
																							</NavLink>
																						)
																					);
																				},
																			)}
																		</div>
																	</div>
																</div>
															</div>
														)}
													</div>
												);
											})}
								</div>
								<div className={s.wrapperSearchInput}>
									<SearchHeaderInput
										placeholder={"common.forms.search"}
										activeSearch={true}
										lupaIsLink
									/>
								</div>
							</>
						) : (
							<>
								{(location.pathname === pathFC(paths.main) ||
									location.pathname ===
										pathFC(paths.mainMen) ||
									location.pathname ===
										pathFC(paths.mainKids) ||
									location.pathname ===
										pathFC(paths.mainWomen)) &&
									gendersCategoryLink?.length > 0 &&
									gendersCategoryLink.map(
										(item, i: number) => {
											const path = item.path;

											return (
												<NavLink
													to={path}
													className={`${s.categoryItem} ${
														item.key === gender
															? s.activeGendersCategoryItem
															: ""
													}`}
													key={i}
													onClick={(e: any) => {
														if (
															[
																pathFC(
																	paths.mainWomen,
																),
																pathFC(
																	paths.mainMen,
																),
																pathFC(
																	paths.mainKids,
																),
															].includes(
																window.location
																	.pathname,
															)
														) {
															e.stopPropagation();
															e.preventDefault();

															window.history.pushState(
																null,
																"",
																path,
															);
														}

														dispatch(
															setGender(item.key),
														);
													}}
												>
													{t(item.title)}
												</NavLink>
											);
										},
									)}
							</>
						)}
					</div>
				</Container>
			</div>
			{!isUpLaptop && isAllowUrlForMenuLg(window.location.pathname) && (
				<div className={s.menuNew}>
					{genderChildren(gender).map((item: any, index: number) => {
						return (
							<NavLink
								to={
									item?.path
										? item?.path
										: pathFC(
												`/${gender}${paths.categories}/${item?.slug}`,
											)
								}
								className={s.menuNewItem}
								key={index}
							>
								<img
									src={
										item?.path === pathFC(paths.brands)
											? brands
											: menuIcons?.[gender]?.[index]
									}
									alt={t(item.name)}
								/>
								<span>{t(item.name)}</span>
							</NavLink>
						);
					})}
				</div>
			)}
		</>
	);
};

export default HeaderBottom;
