const Card = () => ({
	MuiCardContent: {
		styleOverrides: {
			root: {},
		},
	},
	MuiContainer: {
		styleOverrides: {
			root: {
				padding: "0 10px",
			},
		},
	},
});

export default Card;
