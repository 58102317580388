import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

//styles
import s from "./EmptyComponent.module.scss";

//assets
import thank from "../../../assets/images/generals/thank.png";

//ui
import ButtonFrontBack from "../Buttons/ButtonFrontBack/ButtonFrontBack";

//routes
import { paths, pathFC } from "../../../App/Routes/paths";

type TEmptyComponentProps = {
  title: string;
  button?: boolean;
  maxWidth?: string;
};

const EmptyComponent = ({ title, button, maxWidth }: TEmptyComponentProps) => {
  const { t } = useTranslation();

  const history = useHistory();

  return (
    <div className={s.rootThank}>
      <span className={s.title}>{title}</span>

      <div
        className={s.wrapperImg}
        style={{ maxWidth: maxWidth ? maxWidth : "" }}
      >
        <img className={s.img} src={thank} alt="thank" />
      </div>
      {button && (
        <ButtonFrontBack
          marginTop={{ xl: "50px" }}
          width={{ xl: "200px" }}
          onClick={() => {
            history.push(pathFC(paths.main));
          }}
          mode={"red"}
          className={s.btn}
          isButton
        >
          <span>{t("empty.button")}</span>
        </ButtonFrontBack>
      )}
    </div>
  );
};

export default EmptyComponent;
