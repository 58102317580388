import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useForm } from "react-hook-form";

//styles
import s from "./ModalSellerReviewAnswer.module.scss";
//assets
import { arrowToIco } from "../../../../assets/icons/iconsConstants";

//components
import ModalClose from "../ModalComponents/ModalClose/ModalClose";

//hooks
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux";

//ui
import ButtonFrontBack from "../../Buttons/ButtonFrontBack/ButtonFrontBack";
import TitleWithLine from "../../TitleWithLine/TitleWithLine";
import Textarea from "../../inputs/Textarea/Textarea";
import ErrorText from "../../ErrorText/ErrorText";

//redux
import { putAnswerSellerMessagesProductsReviewsBack } from "../../../../redux/Seller/SellerMessages/SellerMessagesProductsReviews";

enum EnameFields {
  communication = "communication",
  text = "text",
  delivery = "delivery",
  connection = "connection",
  qualityReview = "qualityReview",
  score = "score",
}

enum EErrorsBack {
  text = "text",
}

const ModalSellerReviewAnswer = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const { modalWindowInfo } = useAppSelector((state) => state.app);

  const {
    loadingPutAnswerSellerMessagesProductsReviewsBack,
    errorPutAnswerSellerMessagesProductsReviewsBack,
  } = useAppSelector((state) => state.sellerMessagesProductsReviews);

  const {
    loadingPutAnswerSellerMessagesSellerReviewsBack,
    errorPutAnswerSellerMessagesSellerReviewsBack,
  } = useAppSelector((state) => state.sellerMessagesProductsReviews);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<any>({
    mode: "onBlur",
  });

  const onSubmit = (data: any) => {
    dispatch(
      modalWindowInfo?.methodSend({
        obj: {
          text: data?.[EnameFields?.text],
        },
        id: modalWindowInfo?.id,
      })
    );
  };

  return (
    <div className={s.root}>
      <ModalClose />
      <div className={s.wrapperTitle}>
        <TitleWithLine
          title={t("seller.button")}
          fontSize={{ xl: "18px", lg: "14px" }}
          widthLine={{ xl: "60px", lg: "28px" }}
          heightLine={{ xl: "3px", lg: "2px" }}
        />
      </div>
      {(errorPutAnswerSellerMessagesProductsReviewsBack?.message ||
        errorPutAnswerSellerMessagesSellerReviewsBack?.message) && (
        <ErrorText
          text={
            errorPutAnswerSellerMessagesProductsReviewsBack?.message ||
            errorPutAnswerSellerMessagesSellerReviewsBack?.message
          }
        />
      )}

      <div className={s.wrapper}>
        <form className={s.form} onSubmit={handleSubmit(onSubmit)}>
          <Textarea
            width={{ xl: "100%" }}
            marginTop={{ xl: "20px" }}
            label={""}
            register={register}
            placeholder={t("common.forms.answer")}
            name={EnameFields?.text}
            error={errors?.[EnameFields.text]}
            errorFromBack={
              errorPutAnswerSellerMessagesProductsReviewsBack?.text
            }
            validation={{
              required: t("generals.yupValidation.isRequired"),
              maxLength: {
                value: 328,
                message: `${t(`generals.yupValidation.maxSymbols`)} 328 ${t(
                  `generals.yupValidation.symbolss`
                )}`,
              },
            }}
          />
          <ButtonFrontBack
            width={{ xl: "230px" }}
            marginTop={{ xl: "25px" }}
            marginRight={{ xl: "auto" }}
            marginLeft={{ xl: "auto" }}
            mode={"red"}
            isButton
            disabledLoading={
              loadingPutAnswerSellerMessagesProductsReviewsBack ||
              loadingPutAnswerSellerMessagesSellerReviewsBack
            }
          >
            <span className={s.btnInner}>
              {t("common.button")}
              <img src={arrowToIco} alt="arrowToIco" />
            </span>
          </ButtonFrontBack>
        </form>
      </div>
    </div>
  );
};

export default ModalSellerReviewAnswer;
