//assets
import image from "../../assets/images/modal-size/modal-size__img_1.png";

export const gendersClothesWomen = {
  title: "common.gendersClothes.women",
  image: image,
  titlesColumns: [
    {
      id: 0,
      text: "clothingSize.ukrSize",
    },
    {
      id: 1,
      text: "common.sizeOptions.chest",
      additional: ", см",
    },
    {
      id: 2,
      text: "common.sizeOptions.waist",
      additional: ", см",
    },
    {
      id: 3,
      text: "common.sizeOptions.thigh",
      additional: ", см",
    },
  ],
  sizesMass: [
    {
      id: 0,
      sizes: ["40", "78-82", "60-64", "86-90"],
    },
    {
      id: 1,
      sizes: ["42", "82-86", "64-68", "90-94"],
    },
    {
      id: 2,
      sizes: ["44", "86-90", "68-72", "94-98"],
    },
    {
      id: 3,
      sizes: ["46", "90-94", "72-76", "98-102"],
    },
    {
      id: 4,
      sizes: ["48", "94-98", "76-80", "102-106"],
    },
    {
      id: 5,
      sizes: ["50", "98-102", "80-84", "106-110"],
    },
    {
      id: 6,
      sizes: ["52", "102-106", "84-88", "110-114"],
    },
    {
      id: 7,
      sizes: ["54", "106-110", "88-92", "114-118"],
    },
    {
      id: 8,
      sizes: ["56", "110-114", "92-96", "118-122"],
    },
    {
      id: 9,
      sizes: ["58", "114-118", "96-100", "122-126"],
    },
    {
      id: 10,
      sizes: ["60", "118-122", "100-104", "126-130"],
    },
  ],
  text: `
          Вам понадобится провести измерения с помощью сантиметровой ленты. При
          измерении обхвата груди лента должна плотно прилегать к телу, спереди
          проходить по наиболее выступающим точкам, сбоку через подмышечные
          впадины, сзади обхватывая лопатки. Обхват талии измеряется строго
          горизонтально по самой узкой части тела, проходя через самую
          выступающую точку живота. При измерении обхвата бедер лента должна
          находиться горизонтально, проходя посредине бедра и сзади по наиболее
          выступающим точкам ягодиц.
        `,
};
