"use client";

import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import PhoneVerify from "./components/phoneVerify";
import { configRen, modalType } from "./config";
import { SDModal, SDWrapper, SDWrapperBtnClose } from "./styles";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { allActionsStore } from "../../redux/rootActions";

const ModalComponent = () => {
	// HOOKS
	const dispatch = useAppDispatch();

	// SELECTORS
	const modalConfig = useAppSelector((state) => state.app.modalConfig);

	// useDisableEnableScroll({ locked: modalConfig.open });

	// FUNCTIONS
	const handleClose = () => {
		dispatch(allActionsStore.setModalClearConfigAction());
		modalConfig?.props?.onClose && modalConfig?.props?.onClose();
	};

	return (
		<SDModal
			open={modalConfig.open}
			onClose={handleClose}
			closeAfterTransition
		>
			<SDWrapper
				sx={{
					...configRen?.[modalConfig.type]?.sx?.wrapper,
					...modalConfig?.sx?.container,
				}}
			>
				<SDWrapperBtnClose
					aria-label="close"
					onClick={handleClose}
					// style={{
					// 	color: "black",
					// 	...configRen?.[modalConfig.type]?.iconClose?.sx,
					// }}
				>
					<CloseIcon fontSize="17.15px" />
				</SDWrapperBtnClose>
				{(() => {
					switch (modalConfig.type) {
						case modalType.phoneVerify:
							return (
								<PhoneVerify
									handleClose={handleClose}
									{...modalConfig?.props}
								/>
							);
						default:
							return (
								<Typography
									id="spring-modal-title"
									variant="h6"
								>
									No such modal
								</Typography>
							);
					}
				})()}
			</SDWrapper>
		</SDModal>
	);
};

export default ModalComponent;
