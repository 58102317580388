import { createSlice } from "@reduxjs/toolkit";

//api
import { getRequest } from "../../API/_api";

export const getSearchCatalogBack =
  ({ params, moreProductsActiveButton }: any) =>
  (dispatch: any) => {
    dispatch(setLoadingGetSearchCatalogBack(true));

    const success = (data: any) => {
      dispatch(
        setDataGetSearchCatalogBack({
          data: data?.data,
          meta: data?.meta,
          filters: data?.filters,
          moreProductsActiveButton: moreProductsActiveButton,
        })
      );
    };

    const setError = (error: any) => {
      dispatch(setErrorGetSearchCatalogBack(error));
    };

    getRequest({
      url: `products-search`,
      success,
      setError,
      params,
    });
  };

const initialState: any = {
  dataGetSearchCatalogBack: null,
  metaGetSearchCatalogBack: null,
  filtersGetSearchCatalogBack: null,
  loadingGetSearchCatalogBack: false,
  errorGetSearchCatalogBack: null,
};

export const searchCatalog = createSlice({
  name: "searchCatalog",
  initialState,
  reducers: {
    setDataGetSearchCatalogBack(state, action) {
      state.dataGetSearchCatalogBack = action.payload.moreProductsActiveButton
        ? [...state.dataGetSearchCatalogBack, ...action.payload.data]
        : action.payload.data;

      state.metaGetSearchCatalogBack = action.payload?.meta;
      state.filtersGetSearchCatalogBack = action.payload?.filters;
      state.loadingGetSearchCatalogBack = false;
      state.errorGetSearchCatalogBack = null;
    },

    setErrorGetSearchCatalogBack(state, action) {
      state.errorGetSearchCatalogBack = action.payload;
      state.loadingGetSearchCatalogBack = false;
    },
    setLoadingGetSearchCatalogBack(state, action) {
      state.loadingGetSearchCatalogBack = action.payload;
      state.errorGetSearchCatalogBack = null;
    },
  },
});

export const {
  setLoadingGetSearchCatalogBack,
  setErrorGetSearchCatalogBack,
  setDataGetSearchCatalogBack,
} = searchCatalog.actions;

export default searchCatalog.reducer;
