import { createSlice } from "@reduxjs/toolkit";

//api
import { getRequest } from "../../../API/_api";

//types
import { TProduct } from "../../../TS/types/productCard/productCard";

type sendData = {
	params: any;
	path: string;
	moreProductsActiveButton: boolean;
};

export const getProductsBack = (sendData: sendData) => (dispatch: any) => {
	const { params, path, moreProductsActiveButton } = sendData;
	dispatch(setLoadingproductsBack(true));
	const success = (data: any) => {
		dispatch(
			successproductsBack({
				data: data?.data,
				meta: data?.meta,
				filters: data?.filters,
				moreProductsActiveButton: moreProductsActiveButton,
			}),
		);
	};

	const setError = (error: any) => {
		dispatch(setErrorproductsBack(error));
	};

	getRequest({
		url: path,
		success,
		setError,
		params,
	});
};

type TProductsState = {
	productsBack: Array<TProduct> | [];
	loadingProductsBack: boolean;
	errorProductsBack: any;
	productsMetaBack: any;
	productsFiltersBack: object | null;
};

const initialState: TProductsState = {
	productsBack: [],
	loadingProductsBack: false,
	errorProductsBack: null,
	productsMetaBack: null,
	productsFiltersBack: null,
};

export const products = createSlice({
	name: "products",
	initialState,
	reducers: {
		successproductsBack(state, action) {
			state.productsBack = action.payload.moreProductsActiveButton
				? [...state.productsBack, ...action.payload.data]
				: action.payload.data;
			state.productsMetaBack = action.payload.meta;
			state.productsFiltersBack = action.payload.filters;
			state.loadingProductsBack = false;
		},
		setLoadingproductsBack(state, action) {
			state.loadingProductsBack = action.payload;
			state.errorProductsBack = null;
		},
		setErrorproductsBack(state, action) {
			state.errorProductsBack = action.payload;
			state.loadingProductsBack = false;
		},
	},
});

export const {
	successproductsBack,
	setLoadingproductsBack,
	setErrorproductsBack,
} = products.actions;

export default products.reducer;
