import { createSlice, PayloadAction } from "@reduxjs/toolkit";

//api
import { getRequest } from "../../../API/_api";

type TdiscountImagesInitialState = {
  recommendedProductsBack: any;
  loadingRecommendedProductsBack: boolean;
  errorRecommendedProductsBack: any;
};

const initialState: TdiscountImagesInitialState = {
  recommendedProductsBack: [],
  loadingRecommendedProductsBack: false,
  errorRecommendedProductsBack: null,
};

export const getRecommendedProductsBack = () => (dispatch: any) => {
  dispatch(setLoadingRecommendedProductsBack(true));
  const success = (data: any) => {
    dispatch(successRecommendedProductsBack(data.data));
  };

  const setError = (error: any) => {
    dispatch(setErrorRecommendedProductsBack(error));
  };

  getRequest({
    url: `recommended-products`,
    success,
    setError,
  });
};

export const recommendedProducts = createSlice({
  name: "recommendedProducts",
  initialState,
  reducers: {
    successRecommendedProductsBack(state, action) {
      state.recommendedProductsBack = action.payload;
      state.loadingRecommendedProductsBack = false;
    },
    setLoadingRecommendedProductsBack(state, action) {
      state.loadingRecommendedProductsBack = action.payload;
      state.errorRecommendedProductsBack = null;
    },
    setErrorRecommendedProductsBack(state, action) {
      state.errorRecommendedProductsBack = action.payload;
      state.loadingRecommendedProductsBack = false;
    },
  },
});
export const {
  successRecommendedProductsBack,
  setLoadingRecommendedProductsBack,
  setErrorRecommendedProductsBack,
} = recommendedProducts.actions;

export default recommendedProducts.reducer;
