import { slugLangObj } from "../../db/generals/languageData";

export const languageInitialization = (
  language: any,
  currentLocation: any,
  currentHistory: any,
  reload?: boolean
) => {
  const url = `${currentLocation.pathname}${
    currentLocation?.search?.length > 1 ? currentLocation?.search : ""
  }`;

  const excludedVariants = ["/ru", "/ua"];
  const regex = new RegExp(`^(${excludedVariants.join("|")})`);

  const selectedLang = slugLangObj?.[language]?.slugLang || "";
  const withoutLang = url.replace(regex, "");

  const finalPathname = `${
    selectedLang ? `/${selectedLang}` : ""
  }${withoutLang}`;

  if (reload) {
    window.location.href = finalPathname || "/";
  } else {
    currentHistory.push(finalPathname);
  }

  return true;
};
