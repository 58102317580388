import { useEffect, useRef } from "react";
import { withRouter } from "react-router-dom";

function ScrollToTop({ history }) {
	const currentPathname = useRef(history.location.pathname);

	console.log(history, "history");
	useEffect(() => {
		// window.scrollTo(0, 0);

		const unlisten = history.listen((location) => {
			console.log(location, "location");
			// if (location.pathname !== currentPathname.current) {
			window.scrollTo(0, 0);
			currentPathname.current = location.pathname;
			// }
		});

		return () => {
			unlisten();
		};
	}, [history]);

	return null;
}

export default withRouter(ScrollToTop);
