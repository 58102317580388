import styled from "@mui/system/styled";
import { BREAKPOINTS_NAME } from "../../../theme/breakpoints/index";

export const SDContainer = styled("div")(({ theme }) => ({
	maxWidth: "1660px",
	margin: "0 auto",
	padding: "0 20px",
	[theme.breakpoints.down(BREAKPOINTS_NAME.container1440)]: {
		maxWidth: "100%",
		padding: "0 20px",
	},
	[theme.breakpoints.down(BREAKPOINTS_NAME.laptop)]: {
		padding: "0 10px",
	},
}));
