//styles
import s from "../Textarea/textarea.module.scss";

//components
import StyledDiv from "../../StyledDiv/StyledDiv";

type TTextareaProps = {
  width?: any;
  maxWidth?: any;
  marginTop?: any;
  marginLeft?: any;
  marginRight?: any;
  label?: any;
  name: string;
  placeholder?: string;
  className?: string;
  errorFromBack?: any;
  required?: boolean;
  error: any;
  register?: any;
  setErrorsFromBack?: any;
  subRender?: any;
  validation?: any;
};

const Textarea = ({
  width,
  marginTop,
  label,
  name,
  register,
  error,
  required,
  placeholder,
  className,
  subRender,
  maxWidth,
  marginRight,
  marginLeft,
  validation,
  errorFromBack,
}: TTextareaProps) => {
  return (
    <StyledDiv
      width={width ? width : ""}
      maxWidth={maxWidth ? maxWidth : ""}
      marginTop={marginTop ? marginTop : ""}
      marginRight={marginRight ? marginRight : ""}
      marginLeft={marginLeft ? marginLeft : ""}
    >
      {register ? (
        <div className={`${s.root} ${className ? className : ""}`}>
          <label>
            {label}
            {required ? <span className={s.required}>*</span> : ""}
          </label>
          <textarea
            className={`${!!error || errorFromBack ? s.errorTextarea : ""}`}
            placeholder={placeholder}
            name={name}
            {...register(name, validation)}
          />
          {!!error && <div className={s.error}>{error?.message}</div>}
        </div>
      ) : (
        <div className={`${s.root} ${className ? className : ""}`}>
          <label htmlFor={label}>
            {label}
            {required ? "*" : ""}
          </label>
          <textarea id={label} placeholder={placeholder} name={name} />
        </div>
      )}
      {subRender}
    </StyledDiv>
  );
};

Textarea.propTypes = {};

export default Textarea;
