import React, {ReactNode} from "react";

//styles
import s from "./containerFooter.module.scss";

interface ContainerProps {
  children: ReactNode;
}


const ContainerFooter:  React.FC<ContainerProps> = ({ children }) => {
  return <div className={s.root}>{children}</div>;
};

export default ContainerFooter;
