import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

//api
import { baseURL } from "../../API/_api";

export const getBrandsAlphabet = createAsyncThunk(
  "getBrandsAlphabet",
  async (_, thunkAPI) => {
    try {
      const response = await axios.get(`${baseURL}brand-list`);
      const sortedBrands = Object.keys(response.data)
        .sort()
        .reduce((acc: any, item) => {
          acc[item] = response.data[item];
          return acc;
        }, {});
      return sortedBrands;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

type TdiscountImagesInitialState = {
  alphabetBrands: any;
  status: boolean;
  error: string | object;
};

const initialState: TdiscountImagesInitialState = {
  alphabetBrands: [],
  status: false,
  error: "",
};

export const brandsAlphabet = createSlice({
  name: "brandsAlphabet",
  initialState,
  reducers: {},
  extraReducers: {
    [getBrandsAlphabet.fulfilled.type]: (state, action) => {
      state.status = false;
      state.error = "";
      state.alphabetBrands = action.payload;
    },
    [getBrandsAlphabet.pending.type]: (state) => {
      state.status = true;
    },
    [getBrandsAlphabet.rejected.type]: (
      state,
      action: PayloadAction<string | object>
    ) => {
      state.status = false;
      state.error = action.payload;
    },
  },
});

export const {} = brandsAlphabet.actions;

export default brandsAlphabet.reducer;
