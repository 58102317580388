/* eslint-disable camelcase */
// +
import Typography from "@mui/material/Typography";
import styled from "@mui/system/styled";

// STYLES
export const SDHelperText = styled(Typography)(
	({ theme, is_dark_background_color }) => ({
		marginTop: "11px",
		color: theme.palette.customColors.text.t_2,
		fontSize: "16px",
	}),
);
