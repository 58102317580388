import { createSlice } from "@reduxjs/toolkit";
import { event } from "react-ga";

//api
import { getRequest, postRequest } from "../../API/_api";

//redux
import { basketRemoveProduct } from "../Basket/basket";
import {
	setModalWindowChildrenType,
	setModalWindowStatus,
	setModalWindowInfo,
} from "../App/app";
import { getUser } from "../User/user";

const getPayment = (id: number, buttonText: string) => (dispatch: any) => {
	const params = {
		button_text: buttonText,
		button__front: "hover-button__front",
	};
	const success = (data: any) => {
		dispatch(setModalWindowChildrenType("orderPay"));
		dispatch(
			setModalWindowInfo({
				title: "modals.pay.card",
				data: data.data,
			}),
		);
		dispatch(setModalWindowStatus(true));
	};

	const setError = (error: any) => {
		if (typeof error?.response?.data?.message === "string") {
			dispatch(setModalWindowChildrenType("formError"));
			dispatch(
				setModalWindowInfo({
					title: "common.modals",
					text: error?.response?.data?.message,
				}),
			);
			dispatch(setModalWindowStatus(true));
		}
	};

	getRequest({
		url: `orders/${id}/payment`,
		success,
		setError,
		params,
	});
};

type TPostCheckoutOrderBackProps = {
	obj: any;
	needGetPayment: boolean;
	t: any;
	forwardToPageSuccess: any;
	history: any;
};

export const postCheckoutOrderBack =
	(sendData: TPostCheckoutOrderBackProps) => (dispatch: any) => {
		const { obj, needGetPayment, t, forwardToPageSuccess, history } =
			sendData;
		dispatch(setLoadingPostCheckoutOrderBack(true));

		const productsAllData = obj.products;
		obj.products = obj?.products?.map((product: any) => {
			return {
				id: product.id,
				quantity: product.quantity,
			};
		});

		const success = (data: any) => {
			dispatch(getUser());
			dispatch(setDataPostCheckoutOrderBack(data));
			needGetPayment &&
				dispatch(getPayment(data.data.id, t("modals.pay.button")));
			event({
				category: "buy",
				action: "click",
			});
			forwardToPageSuccess &&
				!needGetPayment &&
				history.push(forwardToPageSuccess);

			productsAllData.map((product: any) =>
				dispatch(
					basketRemoveProduct({
						id: product?.id,
						brand: product?.brand,
					}),
				),
			);
		};

		const setError = (error: any) => {
			console.dir(error?.response?.data?.message, "error");
			dispatch(
				setErrorPostCheckoutOrderBack(error?.response?.data?.message),
			);

			if (typeof error?.response?.data?.message === "string") {
				dispatch(setModalWindowChildrenType("formError"));
				dispatch(
					setModalWindowInfo({
						title: "common.modals",
						text: error?.response?.data?.message,
					}),
				);
				dispatch(setModalWindowStatus(true));
			}
		};

		postRequest({
			url: `orders`,
			success,
			setError,
			obj,
		});
	};

const initialState: any = {
	dataPostCheckoutOrderBack: null,
	loadingPostCheckoutOrderBack: false,
	errorPostCheckoutOrderBack: null,
};

export const checkoutOrder = createSlice({
	name: "checkoutOrder",
	initialState,
	reducers: {
		setDataPostCheckoutOrderBack(state, action) {
			state.dataPostCheckoutOrderBack = action.payload;
			state.loadingPostCheckoutOrderBack = false;
		},
		setErrorPostCheckoutOrderBack(state, action) {
			state.errorPostCheckoutOrderBack = action.payload;
			state.loadingPostCheckoutOrderBack = false;
		},
		setLoadingPostCheckoutOrderBack(state, action) {
			state.loadingPostCheckoutOrderBack = action.payload;
			state.errorPosttCheckoutOrderBack = null;
		},
	},
});

export const {
	setDataPostCheckoutOrderBack,
	setErrorPostCheckoutOrderBack,
	setLoadingPostCheckoutOrderBack,
} = checkoutOrder.actions;

export default checkoutOrder.reducer;
