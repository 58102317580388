import React from "react";
import { SDRoot, SDLine, SDTitle, SDImg } from "./styles";

interface TitleWithLineProps {
	img?: string;
	title: string;
	variant?: string;
	optionsTags?: {
		root?: React.HTMLAttributes<HTMLDivElement>;
		line?: React.HTMLAttributes<HTMLDivElement>;
		title?: React.HTMLAttributes<HTMLHeadingElement>;
	};
}

const TitleWithLine: React.FC<TitleWithLineProps> = ({
	img,
	title,
	variant = "h6",
	optionsTags = {},
}): JSX.Element => {
	return (
		<SDRoot {...optionsTags.root}>
			<SDLine {...optionsTags.line} />
			<SDTitle
				variant={variant}
				{...optionsTags.title}
			>
				{title}
			</SDTitle>
			{img && (
				<SDImg
					src={img}
					alt="image"
				/>
			)}
		</SDRoot>
	);
};

export default TitleWithLine;
