import ModalBasket from "./ModalBasket/ModalBasket";
import ModalLogin from "./ModalLogin/ModalLogin";
import ModalForgotPassword from "./ModalForgotPassword/ModalForgotPassword";
import ModalRegistration from "./ModalRegistration/ModalRegistration";
import ModalClothingSize from "./ModalClothingSize/ModalClothingSize";
import ModalUserSellerChat from "./ModalUserSellerChat/ModalUserSellerChat";
import ModalProductReview from "./ModalProductReview/ModalProductReview";
import ModalSellerReview from "./ModalSellerReview/ModalSellerReview";
import ModalReturnOrder from "./ModalReturnOrder/ModalReturnOrder";
import ModalFormError from "./ModalFormError/ModalFormError";
import ModalFormSuccess from "./ModalFormSuccess/ModalFormSuccess";
import ModalPhoneVerify from "./ModalPhoneVerify/ModalPhoneVerify";
import ModalProductQuestion from "./ModalProductQuestion/ModalProductQuestion";
import ModalFormSuccessSellerRegistrion from "./ModalFormSuccessSellerRegistrion/ModalFormSuccessSellerRegistrion";

import ModalOrderPay from "./ModalOrderPay/ModalOrderPay";
import ModalZoomImg from "./ModalZoomImg/ModalZoomImg";
import ModalResetPassword from "./ModalResetPassword/ModalResetPassword";
import ModalSellerReviewAnswer from "./ModalSellerReviewAnswer/ModalSellerReviewAnswer";
import Modalloading from "./Modalloading/Modalloading";
import ModalText from "./ModalText/ModalText";

export const selectModal = (type) => {
  switch (type) {
    case "basket":
      return <ModalBasket />;
    case "login":
      return <ModalLogin />;
    case "forgotPassword":
      return <ModalForgotPassword />;
    case "registration":
      return <ModalRegistration />;
    case "clothingSize":
      return <ModalClothingSize />;
    case "userSellerChat":
      return <ModalUserSellerChat />;
    case "productReview":
      return <ModalProductReview />;
    case "productQuestion":
      return <ModalProductQuestion />;
    case "sellerReview":
      return <ModalSellerReview />;
    case "sellerReviewAnswer":
      return <ModalSellerReviewAnswer />;

    case "returnOrder":
      return <ModalReturnOrder />;
    case "formError":
      return <ModalFormError />;
    case "formSuccess":
      return <ModalFormSuccess />;
    case "phoneVerify":
      return <ModalPhoneVerify />;
    case "formSuccessSellerRegistrion":
      return <ModalFormSuccessSellerRegistrion />;
    case "orderPay":
      return <ModalOrderPay />;
    case "zoomImg":
      return <ModalZoomImg />;
    case "resetPassword":
      return <ModalResetPassword />;
    case "loading":
      return <Modalloading />;
    case "text":
      return <ModalText />;
    default:
      throw new Error("No one modals type didn`t select");
  }
};
