export default {
	MuiSelect: {
		styleOverrides: {
			root: {
				padding: "10.5px 15px 10.5px 17px",
			},
			select: {
				padding: "0",
				lineHeight: "1rem",
				fontSize: "inherit",
				p: { fontWeight: 400 },
			},
		},
	},
};
