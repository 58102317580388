/* eslint-disable camelcase */
// +
import Box from "@mui/material/Box";
import styled from "@mui/system/styled";

export const SDLabel = styled("label")(({ theme, is_dark_background_color }) => ({
	color: theme.palette.customColors.text.t_7,
	fontFamily: "Proxima Nova",
	fontSize: "22px",
}));

export const SDWInput = styled(Box)(() => ({
	width: "100%",
}));
