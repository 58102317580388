"use client";

// +
import { useTranslation } from "react-i18next";
// import Link from "next/link";
import { SDHelperText } from "./styles";

const HelperText = ({ helperText, optionsTag = {}, isDarkBackgroundColor }) => {
	const { t } = useTranslation("common");

	// VARIABLES
	const type = helperText?.type || "";

	// RENDERS
	const text = (
		<SDHelperText
			onClick={helperText?.onClick}
			{...optionsTag}
		>
			{t(helperText?.text || "")}
		</SDHelperText>
	);

	// if (type === "link") {
	// 	return <Link href={helperText?.href || ""}>{text}</Link>;
	// }

	return text;
};

export default HelperText;
