import { guarenteeSvg } from "../../components/ui/Svgs/guarenteeSvg";
import { truckSvg } from "../../components/ui/Svgs/truckSvg";
import { returnSvg } from "../../components/ui/Svgs/returnSvg";
import { priceSvg } from "../../components/ui/Svgs/priceSvg";

//svg social
import { facebookSvg } from "../../components/ui/Svgs/facebookSvg";
import { instagramSvg } from "../../components/ui/Svgs/instagramSvg";
import { telegramSvg } from "../../components/ui/Svgs/telegramSvg";

//paytmentsMethod
// import { visaSvg } from "../../components/ui/Svgs/visaSvg";
// import { masterCardSvg } from "../../components/ui/Svgs/masterCardSvg";
// import { NPSvg } from "../../components/ui/Svgs/NPSvg";

import visaSvg from "../../assets/icons/footer/visa.svg";
import masterCardSvg from "../../assets/icons/footer/masterCard.svg";
import NPSvg from "../../assets/icons/footer/NP.svg";

const instagramLink: string = "https://www.instagram.com/shopping.ua.official/";
const facebookLink: string = "https://www.facebook.com/shopping.ua.official";
const telegramLink: string = "https://t.me/shoppinguaofficial";

export type TAdvantageLink = {
  name: string;
  description: string;
  icon: string;
};

type TAdvantagesLink = Array<TAdvantageLink>;

export const advantagesLink: TAdvantagesLink = [
  {
    name: "header.links.delivery.name",
    description: "header.links.delivery.description",
    icon: truckSvg,
  },
  {
    name: "header.links.warranty.name",
    description: "header.links.warranty.description",
    icon: guarenteeSvg,
  },
  {
    name: "header.links.return.name",
    description: "header.links.return.description",
    icon: returnSvg,
  },
  {
    name: "header.links.prices.name",
    description: "header.links.prices.description",
    icon: priceSvg,
  },
];

type TSoicialLink = {
  svg: string;
  src: string;
};

type TSoicialsLink = Array<TSoicialLink>;

export const soicialsLink: TSoicialsLink = [
  {
    svg: instagramSvg,
    src: instagramLink,
  },
  {
    svg: facebookSvg,
    src: facebookLink,
  },
  {
    svg: telegramSvg,
    src: telegramLink,
  },
];

type TPaytmentMethod = {
  svg: string;
};

type TPaytmentsMethod = Array<TPaytmentMethod>;

export const paytmentsMethod: TPaytmentsMethod = [
  {
    svg: visaSvg,
  },
  {
    svg: masterCardSvg,
  },
  {
    svg: NPSvg,
  },
];

export const alphabetFirst: Array<{ name: string; id: number }> = [
  { name: "Все", id: 1 },
  { name: "0-9", id: 2 },
  { name: "A", id: 3 },
  { name: "B", id: 4 },
  { name: "C", id: 5 },
  { name: "D", id: 6 },
  { name: "E", id: 7 },
  { name: "F", id: 8 },
  { name: "G", id: 9 },
  { name: "H", id: 10 },
  { name: "I", id: 11 },
  { name: "J", id: 12 },
  { name: "K", id: 13 },
  { name: "L", id: 14 },
  { name: "M", id: 15 },
];

export const alphabetSecond: Array<{ name: string; id: number }> = [
  { name: "N", id: 16 },
  { name: "O", id: 17 },
  { name: "P", id: 18 },
  { name: "Q", id: 19 },
  { name: "R", id: 20 },
  { name: "S", id: 21 },
  { name: "T", id: 22 },
  { name: "U", id: 23 },
  { name: "V", id: 24 },
  { name: "W", id: 25 },
  { name: "X", id: 26 },
  { name: "Y", id: 27 },
  { name: "Z", id: 28 },
  { name: "А-Я", id: 29 },
];

//BottomBanner component
export const bottomBannerDiscount = "10 %";
