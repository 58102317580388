import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

//styles
import s from "./ModalBasketOrderItem.module.scss";

//hooks
import { useAppDispatch } from "../../../../../../hooks/redux";

//utils
import { isBaseURLSingleInImage } from "../../../../../../utils/generals/isBaseURLSingleInImage";

//paths
import { paths, pathFC } from "../../../../../../App/Routes/paths";

//redux
import {
  basketRemoveProduct,
  basketProductIncrement,
  basketProductDecrement,
} from "../../../../../../redux/Basket/basket";

type TModalBasketOrderItemProps = {
  data: any;
  basket: any;
  key: number;
};

const ModalBasketOrderItem = ({ data, basket }: TModalBasketOrderItemProps) => {
  const { t } = useTranslation();

  const history = useHistory();

  const dispatch = useAppDispatch();

  return (
    <div className={s.root}>
      <div
        className={s.wrapperImg}
        onClick={() => {
          history.push(`${pathFC(paths.productCart)}/${data.slug}`);
        }}
      >
        {data?.images?.[0] && (
          <img src={isBaseURLSingleInImage(data.images[0])} alt={data.name} />
        )}
      </div>
      <div className={s.body}>
        <div className={s.wrapperNameRemove}>
          <p className={s.name}>{data.name}</p>{" "}
          <div
            className={s.remove}
            onClick={() => {
              if (basket?.length <= 1) {
                dispatch(
                  basketRemoveProduct({ id: data.id, brand: data.brand })
                );
                localStorage.removeItem("basketProducts");
              } else {
                dispatch(
                  basketRemoveProduct({ id: data.id, brand: data.brand })
                );
              }
            }}
          />
        </div>
        <div className={s.info}>
          <div className={s.sizeCounter}>
            <div className={s.size}>
              <p className={s.sizeItem}>{t("common.size")} (UKR):</p>
              <p className={s.sizePoint}>{data?.size?.ukrainian}</p>
            </div>

            <div className={s.counter}>
              <div
                className={s.minus}
                onClick={() => {
                  dispatch(
                    basketProductDecrement({ id: data.id, brand: data.brand })
                  );
                }}
              >
                -
              </div>
              <div className={s.quantity}>{data.quantity}</div>
              <div
                className={s.plus}
                onClick={() => {
                  dispatch(
                    basketProductIncrement({ id: data.id, brand: data.brand })
                  );
                }}
              >
                +
              </div>
            </div>
          </div>
          <div className={s.cost}>{((data.final_price * data.quantity) || 0).toFixed(0)} грн</div>
        </div>
      </div>
    </div>
  );
};

export default ModalBasketOrderItem;
