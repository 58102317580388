// APPROVED ++
import Box from '@mui/material/Box';
import Rating from '@mui/material/Rating';
import { styled } from '@mui/material/styles';

export const SDWRaiting = styled(Box)(() => ({
  display: 'flex',
}));

export const SDRating = styled(Rating)(() => ({
  display: 'flex',
}));
