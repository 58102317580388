import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type TProductsFilersInitialState = {
  chooseFilters: any;
  directionProducts: string;
  sortByProducts: string;
  pricesMinMax: {
    min: any;
    max: any;
  };
};

const initialState: TProductsFilersInitialState = {
  chooseFilters: {},
  directionProducts: "desc",
  sortByProducts: "rating",
  pricesMinMax: {
    min: null,
    max: null,
  },
};

export const productsFilers = createSlice({
  name: "productsFilers",
  initialState,
  reducers: {
    setAddFilter(state, action) {
      if (action.payload.value?.length) {
        state.chooseFilters = {
          ...state.chooseFilters,
          [action.payload.name]: action.payload.value,
        };
      } else {
        delete state.chooseFilters?.[action.payload.name];
      }
    },
    setDirection(state, action) {
      state.directionProducts = action.payload;
    },
    setSortBy(state, action) {
      state.sortByProducts = action.payload;
    },
    removeAllFilters(state) {
      state.chooseFilters = {};
      state.directionProducts = "desc";
      state.sortByProducts = "rating";
      state.pricesMinMax = {
        min: 0,
        max: 9999999,
      };
    },
    setPricesMinMax(state, action) {
      state.pricesMinMax = {
        min: action.payload.min,
        max: action.payload.max,
      };
    },
  },
});
export const {
  setAddFilter,
  setDirection,
  setSortBy,
  removeAllFilters,
  setPricesMinMax,
} = productsFilers.actions;

export default productsFilers.reducer;
