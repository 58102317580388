import { paths, pathFC } from "../../App/Routes/paths";

type TFooterMenuCategoryItem = {
  path: string;
  title: string;
  isLg?: boolean;
  attention?: boolean;
  news?: boolean;
  additionalPath?: {
    path: string;
    title: string;
  };
};

type TFooterMenuCategoryLink = {
  title: string;
  items: Array<TFooterMenuCategoryItem>;
  isLg?: boolean;
};

export const footerMenuCategoryLink: Array<TFooterMenuCategoryLink> = [
  {
    title: "footer.menuFirst.title",
    items: [
      // {
      //   path: pathFC(paths.mainWomen,
      //   title: "header.footerItem.promotions",
      //   action: true,
      //   additionalPath: {
      //     path: pathFC(paths.mainWomen,
      //     title: "common.gendersClothes.women",
      //   },
      // },
      // {
      //   path: pathFC(paths.mainWomen,
      //   title: "header.menubuttonsLink.closses",
      //   additionalPath: {
      //     path: pathFC(paths.mainWomen,
      //     title: "common.gendersClothes.men",
      //   },
      // },
      // {
      //   path: pathFC(paths.mainWomen,
      //   title: "header.menubuttonsLink.shoes",
      //   additionalPath: {
      //     path: pathFC(paths.mainWomen,
      //     title: "common.gendersClothes.kids",
      //   },
      // },
      // {
      //   path: pathFC(paths.mainWomen,
      //   title: "header.menubuttonsLink.accessories",
      // },
      // {
      //   path: pathFC(paths.mainWomen,
      //   title: "header.menubuttonsLink.sport",
      // },
      // {
      //   path: pathFC(paths.mainWomen,
      //   title: "header.menubuttonsLink.beauty",
      // },
      {
        path: pathFC(paths.brands),
        title: "header.footerItem.brands",
      },
      {
        path: pathFC(paths.mainWomen),
        title: "common.gendersClothes.women",
      },
      {
        path: pathFC(paths.mainMen),
        title: "common.gendersClothes.men",
      },
      {
        path: pathFC(paths.mainKids),
        title: "common.gendersClothes.kids",
      },
      // {
      //   path: pathFC(paths.mainWomen,
      //   title: "header.footerItem.discount",
      // },
      // {
      //   path: pathFC(paths.mainWomen,
      //   title: "header.footerItem.new",
      //   news: true,
      // },
      // {
      //   path: pathFC(paths.mainWomen,
      //   title: "header.footerItem.blog",
      // },
    ],
  },
  {
    title: "",
    items: [
      {
        path: pathFC(paths.mainWomen),
        title: "common.gendersClothes.women",
      },
      {
        path: pathFC(paths.mainMen),
        title: "common.gendersClothes.men",
      },
      {
        path: pathFC(paths.mainKids),
        title: "common.gendersClothes.kids",
      },
    ],
  },
  {
    title: "footer.menuThird.title",
    items: [
      {
        path: pathFC(paths.basket),
        title: "footer.menuThird.basket",
      },
      {
        path: pathFC(paths.favorites),
        title: "footer.menuThird.favourite",
      },
      {
        path: pathFC(paths.return),
        title: "footer.menuThird.return",
      },
      {
        path: pathFC(paths.faq),
        title: "footer.menuThird.faq",
      },
      {
        path: pathFC(paths.delivery),
        title: "footer.menuThird.delivery",
      },
      {
        path: pathFC(paths.warranty),
        title: "footer.menuThird.warranty",
        news: true,
      },
      {
        path: pathFC(paths.about),
        title: "footer.menuThird.about",
      },
    ],
  },
  {
    title: "footer.menuFourth.title",
    items: [
      {
        path: pathFC(paths.cooperation),
        title: "footer.menuFourth.cooperation",
        attention: true,
      },
      // {
      //   path: pathFC(paths.mainWomen,
      //   title: "footer.menuFourth.partnership",
      // },
      // {
      //   path: pathFC(paths.mainWomen,
      //   title: "footer.menuFourth.news",
      // },
      {
        path: pathFC(paths.contacts),
        title: "footer.menuFourth.contacts",
      },
    ],
  },
];
