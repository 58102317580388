import { createSlice } from "@reduxjs/toolkit";

//api
import { getRequest, postRequest } from "../../../API/_api";

export const getSellerProfileMoneyBack = (id: any) => (dispatch: any) => {
  dispatch(setLoadingGetSellerProfileMoneyBack(true));

  const success = (data: any) => {
    dispatch(setDataGetSellerProfileMoneyBack(data?.data));
  };

  const setError = (error: any) => {
    dispatch(setErrorGetSellerProfileMoneyBack(error));
  };

  getRequest({
    url: `seller/sellerpayment/${id}/payment?button_text=Оплатить&button__front=auto-click-button`,
    success,
    setError,
  });
};

type TPostSellerProfileMoneyBackProps = {
  obj: any;
};

export const postSellerProfileMoneyBack =
  (sendData: TPostSellerProfileMoneyBackProps) => (dispatch: any) => {
    const { obj } = sendData;
    dispatch(setLoadingPostSellerProfileMoneyBack(true));

    const success = (data: any) => {
      dispatch(setDataPostSellerProfileMoneyBack(data));
      dispatch(getSellerProfileMoneyBack(data?.data?.id));
    };

    const setError = (error: any) => {
      dispatch(setErrorPostSellerProfileMoneyBack(error));
    };

    postRequest({
      url: `seller/create/payment`,
      success,
      setError,
      obj,
    });
  };

const initialState: any = {
  dataGetSellerProfileMoneyBack: null,
  loadingGetSellerProfileMoneyBack: false,
  errorGetSellerProfileMoneyBack: null,
  dataPostSellerProfileMoneyBack: null,
  loadingPostSellerProfileMoneyBack: false,
  errorPostSellerProfileMoneyBack: null,
};

export const sellerProfileMoney = createSlice({
  name: "sellerProfileMoney",
  initialState,
  reducers: {
    setDataGetSellerProfileMoneyBack(state, action) {
      state.dataGetSellerProfileMoneyBack = action.payload;
      state.loadingGetSellerProfileMoneyBack = false;
      state.errorGetSellerProfileMoneyBack = null;
    },
    setMetaGetSellerProfileMoneyBack(state, action) {
      state.metaGetSellerProfileMoneyBack = action.payload;
      state.loadingGetSellerProfileMoneyBack = false;
    },
    setErrorGetSellerProfileMoneyBack(state, action) {
      state.errorGetSellerProfileMoneyBack = action.payload;
      state.loadingGetSellerProfileMoneyBack = false;
    },
    setLoadingGetSellerProfileMoneyBack(state, action) {
      state.loadingGetSellerProfileMoneyBack = action.payload;
      state.errorGetSellerProfileMoneyBack = null;
    },
    setDataPostSellerProfileMoneyBack(state, action) {
      state.dataPostSellerProfileMoneyBack = action.payload;
      state.loadingPostSellerProfileMoneyBack = false;
    },
    setErrorPostSellerProfileMoneyBack(state, action) {
      state.errorPostGetSellerProfileMoneyBack = action.payload;
      state.loadingPostSellerProfileMoneyBack = false;
    },
    setLoadingPostSellerProfileMoneyBack(state, action) {
      state.loadingPostSellerProfileMoneyBack = action.payload;
      state.errorPosttSellerProfileMoneyBack = null;
    },
  },
});

export const {
  setLoadingGetSellerProfileMoneyBack,
  setErrorGetSellerProfileMoneyBack,
  setDataGetSellerProfileMoneyBack,
  setDataPostSellerProfileMoneyBack,
  setErrorPostSellerProfileMoneyBack,
  setLoadingPostSellerProfileMoneyBack,
} = sellerProfileMoney.actions;

export default sellerProfileMoney.reducer;
