import { slugLangObj } from "../../db/generals/languageData";

export const getSelectedLang = () => {
  const pathName = window.location.pathname;
  let lang = "";
  const regex = /\/([^/]+)\//;
  const match = pathName.match(regex);
  if(match) {
    const extracted = match ? match[0] : "";
    lang = extracted.replace(/\//g, "");
  } else {
    lang = pathName.replace(/\//g, "");
  }

  const selectedLang = slugLangObj?.[lang] || "";

  return selectedLang;
};
