import styles from "./ErrorText.module.scss";
import { errorIco } from "../../../assets/icons/iconsConstants";

const ErrorText = ({ text }) => (
  <div className={styles.error}>
    <img src={errorIco} alt="" />
    <span>{text}</span>
  </div>
);

export default ErrorText;
