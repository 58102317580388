import { createSlice } from "@reduxjs/toolkit";

//utils
import { addProduct } from "../../utils/basket/addProduct";
import { decrement } from "../../utils/basket/decrement";
import { increment } from "../../utils/basket/increment";
import { removeProduct } from "../../utils/basket/removeProduct";

type TbasketState = {
  basket: any;
  status: boolean;
  error: string | object;
};

const basketProductsLocal = localStorage.getItem("basketProducts");

const initialState: TbasketState = {
  basket: (basketProductsLocal && JSON.parse(basketProductsLocal)) || [],
  status: false,
  error: "",
};

export const basket = createSlice({
  name: "basket",
  initialState,
  reducers: {
    addProductBasket(state, action) {
      state.basket = addProduct(state, action.payload);
    },
    basketRemoveProduct(state, action) {
      state.basket = removeProduct(state, action.payload);
    },
    basketProductIncrement(state, action) {
      state.basket = increment(state, action.payload);
    },
    basketProductDecrement(state, action) {
      state.basket = decrement(state, action.payload);
    },
  },
});

export const {
  addProductBasket,
  basketRemoveProduct,
  basketProductIncrement,
  basketProductDecrement,
} = basket.actions;

export default basket.reducer;
