type TSlugs = {
  [key: string]: string;
};

export const getSlugOfGender = (gender: string) => {
  const slugs: TSlugs = {
    women: "1-zenshhinam",
    men: "176-muzcinam",
    kids: "417-detyam",
  };

  return slugs[gender];
};

export const getGenderOfSlug = (slug: string) => {
  const slugs: TSlugs = {
    ["1-zenshhinam"]: "women",
    ["176-muzcinam"]: "men",
    ["417-detyam"]: "kids",
  };

  return slugs[slug];
};
