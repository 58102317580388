export const decrement = (state: any, productObj: any) => {
  const newBasket: any = [];
  state.basket.forEach((item: any) => {
    if (item.brand.id === productObj.brand.id) {
      const products: any = [];
      item.products.forEach((product: any) => {
        if (product.id === productObj.id) {
          if (product.quantity > 1) {
            products.push({ ...product, quantity: product.quantity - 1 });
          } else {
            products.push(product);
          }
        } else {
          products.push(product);
        }
      });
      newBasket.push({ ...item, products: products });
    } else {
      newBasket.push(item);
    }
  });
  return newBasket;
};
