//routes
import { pathFC } from "../../App/Routes/paths";

//assets
import { messagesSvg } from "../../components/ui/Svgs/messagesSvg";
import { profilePanelSvg } from "../../components/ui/Svgs/profilePanelSvg";
import { productsSvg } from "../../components/ui/Svgs/productsSvg";
import { ordersSvg } from "../../components/ui/Svgs/ordersSvg";
import { settingsSvg } from "../../components/ui/Svgs/settingsSvg";
import { financeSvg } from "../../components/ui/Svgs/financeSvg";

//enums
import { sellerVariantsContent } from "../../TS/enums/generls";

export enum sellerOrdersSuffix {
  onSale = "on_sale",
  removed = "removed",
  addProduct = "add_product",
  product = "product",
}

export const sellerSlugs = {
  sellerProfile: `${sellerVariantsContent.sellerProfile}`,

  sellerFinance: `${sellerVariantsContent.sellerFinance}`,

  sellerOrdersAll: `${sellerVariantsContent.sellerOrders}/all`,
  sellerOrdersConfirmation: `${sellerVariantsContent.sellerOrders}/confirmation`,
  sellerOrdersProcessing: `${sellerVariantsContent.sellerOrders}/processing`,
  sellerOrdersInProgressDelivery: `${sellerVariantsContent.sellerOrders}/in_progress_delivery`,
  sellerOrdersCompleted: `${sellerVariantsContent.sellerOrders}/completed`,
  sellerOrdersRejected: `${sellerVariantsContent.sellerOrders}/rejected`,

  sellerOrdersOrder: `${sellerVariantsContent.sellerOrder}`,

  sellerProductsOnSale: `${sellerVariantsContent.sellerProducts}/${sellerOrdersSuffix.onSale}`,
  sellerProductsRemoved: `${sellerVariantsContent.sellerProducts}/${sellerOrdersSuffix.removed}`,
  sellerProductsAddProduct: `${sellerVariantsContent.sellerProducts}/${sellerOrdersSuffix.addProduct}`,
  sellerProductsProduct: `${sellerVariantsContent.sellerProducts}/${sellerOrdersSuffix.product}`,

  sellerMessages: `${sellerVariantsContent.sellerMessages}/all`,
  sellerMessagesProductReviews: `${sellerVariantsContent.sellerMessages}/product_reviews`,
  sellerMessagesSellerReviews: `${sellerVariantsContent.sellerMessages}/seller_Reviews`,
  sellerMessagesQuestions: `${sellerVariantsContent.sellerMessages}/questions`,

  sellerDialogues: `${sellerVariantsContent.sellerDialogues}`,

  sellerSettingsCompany: `${sellerVariantsContent.sellerSettings}/company`,
  sellerSettingsRegistrationDocuments: `${sellerVariantsContent.sellerSettings}/registration_documents`,
  sellerSettingsPayment: `${sellerVariantsContent.sellerSettings}/payment`,
  sellerSettingsDelivery: `${sellerVariantsContent.sellerSettings}/delivery`,
  sellerSettingsDataReturn: `${sellerVariantsContent.sellerSettings}/data_to_return`,
};

export const sellerMenuData = [
  {
    id: 1,
    icon: profilePanelSvg,
    url: pathFC(sellerSlugs.sellerProfile),
    label: "seller.lists.menu.panel",
  },
  {
    id: 2,
    icon: ordersSvg,
    label: "seller.lists.menu.orders",
    subMenu: [
      {
        id: 1,
        url: pathFC(sellerSlugs.sellerOrdersAll),
        label: "seller.lists.orders.0",
      },
      {
        id: 2,
        url: pathFC(sellerSlugs.sellerOrdersConfirmation),
        label: "seller.lists.orders.1",
      },
      {
        id: 3,
        url: pathFC(sellerSlugs.sellerOrdersProcessing),
        label: "seller.lists.orders.2",
      },
      {
        id: 4,
        url: pathFC(sellerSlugs.sellerOrdersInProgressDelivery),
        label: "seller.lists.orders.3",
      },
      {
        id: 5,
        url: pathFC(sellerSlugs.sellerOrdersCompleted),
        label: "seller.lists.orders.4",
      },
      {
        id: 6,
        url: pathFC(sellerSlugs.sellerOrdersRejected),
        label: "seller.lists.orders.5",
      },
    ],
  },
  {
    id: 3,
    icon: productsSvg,
    label: "seller.lists.menu.products",
    subMenu: [
      {
        id: 1,
        url: pathFC(sellerSlugs.sellerProductsOnSale),
        label: "seller.lists.products.0",
      },
      {
        id: 2,
        url: pathFC(sellerSlugs.sellerProductsRemoved),
        label: "seller.lists.products.1",
      },
    ],
  },
  {
    id: 4,
    icon: messagesSvg,
    label: "seller.lists.menu.messages",
    subMenu: [
      {
        id: 1,
        url: pathFC(sellerSlugs.sellerMessages),
        label: "seller.lists.message.0",
      },
      {
        id: 2,
        url: pathFC(sellerSlugs.sellerMessagesProductReviews),
        label: "seller.lists.message.1",
      },
      {
        id: 3,
        url: pathFC(sellerSlugs.sellerMessagesSellerReviews),
        label: "seller.lists.message.2",
      },
      {
        id: 4,
        url: pathFC(sellerSlugs.sellerMessagesQuestions),
        label: "seller.lists.message.3",
      },
    ],
  },
  {
    id: 5,
    icon: financeSvg,
    url: pathFC(sellerSlugs.sellerFinance),
    label: "seller.lists.menu.finance",
  },
  {
    id: 6,
    icon: settingsSvg,
    label: "seller.lists.menu.settings",
    subMenu: [
      {
        id: 1,
        url: pathFC(sellerSlugs.sellerSettingsCompany),
        label: "seller.lists.settings.0",
      },
      {
        id: 2,
        url: pathFC(sellerSlugs.sellerSettingsRegistrationDocuments),
        label: "seller.lists.settings.1",
      },
      {
        id: 3,
        url: pathFC(sellerSlugs.sellerSettingsPayment),
        label: "seller.lists.settings.2",
      },
      {
        id: 4,
        url: pathFC(sellerSlugs.sellerSettingsDelivery),
        label: "seller.lists.settings.4",
      },
      {
        id: 5,
        url: pathFC(sellerSlugs.sellerSettingsDataReturn),
        label: "seller.lists.settings.3",
      },
    ],
  },
];
