// +
import { SDTextarea } from "./styles";

const TextareaComponent = ({
	item,
	value,
	onChange,
	optionsTags = {
		input: {},
		inputWrapper: {},
		textarea: {},
	},
	idField,
	isDarkBackgroundColor,
	t,
}) => (
	<SDTextarea
		id={idField}
		name={item.fieldName}
		onChange={(e) => onChange({ e })}
		value={value}
		placeholder={t(item.placeholder)}
		resize={item?.resize || "none"}
		{...optionsTags?.input}
		sx={{
			...optionsTags?.textarea?.sx,
			...(item?.sx || {}),
		}}
	/>
);

export default TextareaComponent;
