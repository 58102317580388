import React from "react";
import { useTranslation } from "react-i18next";
import { SDWBtn, SDBtn, SDBtnText } from "./styles";

interface MainBtnProps {
	text: string;
	onClick?: () => void;
	optionsTags?: {
		wrapper?: any;
		btn?: any;
	};
	isLineBefore?: boolean;
}

const MainBtn: React.FC<MainBtnProps> = ({
	text,
	onClick,
	optionsTags = {},
	isLineBefore = false,
}) => {
	const { t } = useTranslation();

	return (
		<SDWBtn {...optionsTags.wrapper}>
			<SDBtn
				variant="contained_353535_353535_ffffff"
				{...optionsTags.btn}
				onClick={onClick}
			>
				<SDBtnText is_line_before={isLineBefore.toString()}>
					{t(text)}
				</SDBtnText>
			</SDBtn>
		</SDWBtn>
	);
};

export default MainBtn;
