export const breakpoints = {
	values: {
		xs: 0,
		smMinus: 425,
		container484: 484,
		tabletMinus: 568,
		tablet: 640,
		tabletPlus: 768,
		laptop: 1100, // 1024
		desktop: 1200,
		sectionContainer: 1196,
		sectionContainer1091: 1091,
		container1220: 1220,
		container1440: 1440,
		sectionContainer949: 949,
		container1712: 1712,
		container1663: 1663,
	},
};

export const BREAKPOINTS_NAME = {
	xs: "xs",
	smMinus: "smMinus",
	tabletMinus: "tabletMinus",
	tablet: "tablet",
	tabletPlus: "tabletPlus",
	laptop: "laptop",
	desktop: "desktop",
	sectionContainer: "sectionContainer",
	sectionContainer1091: "sectionContainer1091",
	sectionContainer949: "sectionContainer949",
	container1712: "container1712",
	container1663: "container1663",
	container484: "container484",
	container1220: "container1220",
	container1440: "container1440",
};
