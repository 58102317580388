import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

//styles
import s from "./ModalPhoneVerify.module.scss";

//assets
import { checkIco, errorIco } from "../../../../assets/icons/iconsConstants";

//components
import ModalClose from "../ModalComponents/ModalClose/ModalClose";

//ui
import ButtonFrontBack from "../../Buttons/ButtonFrontBack/ButtonFrontBack";
import TitleWithLine from "../../TitleWithLine/TitleWithLine";
import Input from "../../inputs/Input/Input";

//hooks
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux";

//utils
import { validationFormSchema } from "../../../../utils/forms/validationFormSchema";

//redux
import {
	resendCodeOnPhone,
	profileVerifyPhoneCode,
} from "../../../../redux/User/user";

const nameFields = {
	code: "code",
};

const ModalPhoneVerify = () => {
	const { t } = useTranslation();

	const dispatch = useAppDispatch();

	const { errorVerifyPhoneCode } = useAppSelector((state) => state.user);
	const modalWindowInfo = useAppSelector(
		(state) => state.app.modalWindowInfo,
	);

	const { token } = useAppSelector((state) => state.app);

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		mode: "onBlur",
		resolver: yupResolver(validationFormSchema(t, nameFields)),
	});

	const onSubmit = (data: any) => {
		dispatch(
			profileVerifyPhoneCode({
				obj: {
					phone_verify_code: data.code,
				},
				cb: modalWindowInfo?.cb || null,
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}),
		);

		// setTimeout(() => {
		// 	window.location.reload();
		// }, 1000);
	};

	return (
		<div className={s.root}>
			<ModalClose onClick={modalWindowInfo?.cb || null} />
			<div className={s.wrapperTitle}>
				<TitleWithLine
					title={t("profilePersonal.verifyNumber")}
					fontSize={{ xl: "18px", lg: "14px" }}
					widthLine={{ xl: "60px", lg: "28px" }}
					heightLine={{ xl: "3px", lg: "2px" }}
				/>
			</div>
			<div className={s.wrapper}>
				{errorVerifyPhoneCode && (
					<div className={s.error}>
						<img
							src={errorIco}
							alt="error"
						/>
						<span>{t("verify.messageError")}</span>
					</div>
				)}
				<p className={s.done}>
					<img
						src={checkIco}
						alt="checkIco"
					/>
					<span>{t("modals.verify.success")}</span>
				</p>

				<p className={s.description}>{t("modals.verify.repeat")}</p>

				<form
					className={s.form}
					onSubmit={handleSubmit(onSubmit)}
				>
					<Input
						width={{ xl: "100%" }}
						marginTop={{ xl: "20px" }}
						id={nameFields.code}
						name={nameFields.code}
						type="text"
						placeholder={t("common.forms.code")}
						required
						error={errors?.code}
						// errorsFromBack={errorsFromBack}
						register={register}
					/>
					<div className={s.groupsBtn}>
						<div
							className={s.btnRepeat}
							onClick={() =>
								resendCodeOnPhone("phone", {
									Authorization: `Bearer ${token}`,
								})
							}
						>
							<span>{t("modals.password.repeatCode")}</span>
						</div>
						<ButtonFrontBack
							width={{ xl: "230px" }}
							marginTop={{ xl: "15px" }}
							mode={"red"}
							isButton
						>
							<span>{t("common.button")}</span>
						</ButtonFrontBack>
					</div>
				</form>
			</div>
		</div>
	);
};

export default ModalPhoneVerify;
