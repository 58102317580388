import { createSlice } from "@reduxjs/toolkit";

//api
import { getRequest } from "../../../API/_api";

export const getSellerProductBack =
  ({ id }: any) =>
  (dispatch: any) => {
    dispatch(setLoadingGetSellerProductBack(true));

    const success = (data: any) => {
      dispatch(setDataGetSellerProductBack(data?.data));
    };

    const setError = (error: any) => {
      dispatch(setErrorGetSellerProductBack(error));
    };

    getRequest({
      url: `seller/products/${id}`,
      success,
      setError,
    });
  };

const initialState: any = {
  dataGetSellerProductBack: null,
  loadingGetSellerProductBack: false,
  errorGetSellerProductBack: null,
};

export const SellerProduct = createSlice({
  name: "SellerProduct",
  initialState,
  reducers: {
    setDataGetSellerProductBack(state, action) {
      state.dataGetSellerProductBack = action.payload;
      state.loadingGetSellerProductBack = false;
      state.errorGetSellerProductBack = null;
    },
    setErrorGetSellerProductBack(state, action) {
      state.errorGetSellerProductBack = action.payload;
      state.loadingGetSellerProductBack = false;
    },
    setLoadingGetSellerProductBack(state, action) {
      state.loadingGetSellerProductBack = action.payload;
      state.errorGetSellerProductBack = null;
    },
  },
});

export const {
  setLoadingGetSellerProductBack,
  setErrorGetSellerProductBack,
  setDataGetSellerProductBack,
} = SellerProduct.actions;

export default SellerProduct.reducer;
