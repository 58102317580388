import styled from "styled-components";

//ts
import { TMediaQuery } from "../../../TS/types/generals/generals";

type TStyledInput = {
  width?: TMediaQuery;
  height?: TMediaQuery;
  marginLeft?: TMediaQuery;
  marginRight?: TMediaQuery;
  marginTop?: TMediaQuery;
  fontSize?: TMediaQuery;
  maxWidth?: TMediaQuery;
};

const StyledInput = styled.div`
  width: ${({ width }: TStyledInput) => width?.xl}; !important;
  max-width: ${({ maxWidth }: TStyledInput) => maxWidth?.xl}; !important;
  height: ${({ height }: TStyledInput) => height?.xl}; !important;
  margin-left: ${({ marginLeft }: TStyledInput) => marginLeft?.xl} !important;
  margin-right: ${({ marginRight }: TStyledInput) =>
    marginRight?.xl} !important;
  margin-top: ${({ marginTop }: TStyledInput) => marginTop?.xl} !important;
  font-size: ${({ fontSize }: TStyledInput) => fontSize?.xl} !important;
  @media (max-width: 1100px) {
    width: ${({ width }: TStyledInput) => width?.lg} !important;
    max-width: ${({ maxWidth }: TStyledInput) => maxWidth?.lg}; !important;
    height: ${({ height }: TStyledInput) => height?.lg}; !important;
    margin-top: ${({ marginTop }: TStyledInput) => marginTop?.lg} !important;
    margin-left: ${({ marginLeft }: TStyledInput) => marginLeft?.lg} !important;
    margin-right: ${({ marginRight }: TStyledInput) =>
      marginRight?.lg} !important;
    font-size: ${({ fontSize }: TStyledInput) => fontSize?.lg} !important;
  }
  @media (max-width: 768px) {
    width: ${({ width }: TStyledInput) => width?.md} !important;
    max-width: ${({ maxWidth }: TStyledInput) => maxWidth?.md}; !important;
    height: ${({ height }: TStyledInput) => height?.md}; !important;
    margin-top: ${({ marginTop }: TStyledInput) => marginTop?.md} !important;
    margin-left: ${({ marginLeft }: TStyledInput) => marginLeft?.md} !important;
    margin-right: ${({ marginRight }: TStyledInput) =>
      marginRight?.md} !important;
    font-size: ${({ fontSize }: TStyledInput) => fontSize?.md} !important;
  }
  @media (max-width: 580px) {
    width: ${({ width }: TStyledInput) => width?.mdMinus} !important;
    max-width: ${({ maxWidth }: TStyledInput) => maxWidth?.mdMinus}; !important;
    height: ${({ height }: TStyledInput) => height?.mdMinus}; !important;

    margin-top: ${({ marginTop }: TStyledInput) =>
      marginTop?.mdMinus} !important;
    margin-left: ${({ marginLeft }: TStyledInput) =>
      marginLeft?.mdMinus} !important;
    margin-right: ${({ marginRight }: TStyledInput) =>
      marginRight?.mdMinus} !important;
    font-size: ${({ fontSize }: TStyledInput) => fontSize?.mdMinus} !important;
  }
  @media (max-width: 480px) {
    width: ${({ width }: TStyledInput) => width?.smPlus} !important;
    max-width: ${({ maxWidth }: TStyledInput) => maxWidth?.smPlus}; !important;
    height: ${({ height }: TStyledInput) => height?.smPlus}; !important;
    margin-top: ${({ marginTop }: TStyledInput) =>
      marginTop?.smPlus} !important;
    margin-left: ${({ marginLeft }: TStyledInput) =>
      marginLeft?.smPlus} !important;
    margin-right: ${({ marginRight }: TStyledInput) =>
      marginRight?.smPlus} !important;
    font-size: ${({ fontSize }: TStyledInput) => fontSize?.smPlus} !important;
  }
  @media (max-width: 320px) {
    width: ${({ width }: TStyledInput) => width?.sm} !important;
    height: ${({ height }: TStyledInput) => height?.sm}; !important;
    margin-top: ${({ marginTop }: TStyledInput) => marginTop?.sm} !important;
    margin-left: ${({ marginLeft }: TStyledInput) => marginLeft?.sm} !important;
    margin-right: ${({ marginRight }: TStyledInput) =>
      marginRight?.sm} !important;
    font-size: ${({ fontSize }: TStyledInput) => fontSize?.sm} !important;
  }
`;

export default StyledInput;
