//styles
import s from "./ModalClose.module.scss";

//hooks
import { useAppDispatch } from "../../../../../hooks/redux";

//redux
import { setModalWindowStatus } from "../../../../../redux/App/app";
import { useEffect } from "react";

const ModalClose = ({ onClick }: any) => {
	const dispatch = useAppDispatch();

	useEffect(() => {
		const handleEsc = (event: any) => {
			if (event.keyCode === 27) {
				dispatch(setModalWindowStatus(false));
			}
		};
		window.addEventListener("keydown", handleEsc);

		return () => {
			window.removeEventListener("keydown", handleEsc);
		};
	}, []);

	return (
		<div
			className={s.wrapperClose}
			onClick={(e: any) => {
				dispatch(setModalWindowStatus(false));
				onClick && onClick();
			}}
		>
			<div className={s.close} />
		</div>
	);
};

export default ModalClose;
