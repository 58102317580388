import { v4 as uuidv4 } from "uuid";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Lazy, Manipulation } from "swiper";

//styles
import s from "./ModalZoomImg.module.scss";

//components
import ModalClose from "../ModalComponents/ModalClose/ModalClose";
import ProductCardSlide from "../../../ProductCard/ProductCardSlider/ProductCardSlide";

//ui
import SliderBtnPrev from "../../../ui/Buttons/SliderBtns/SliderBtnPrev";
import SliderBtnNext from "../../../ui/Buttons/SliderBtns/SliderBtnNext";

//hooks
import { useAppSelector } from "../../../../hooks/redux";

const ModalZoomImg = () => {
  const { modalWindowInfo } = useAppSelector((state) => state.app);

  const classNameSliderBtnNext = `swiper-button-next_${uuidv4()}`;
  const classNameSliderBtnPrev = `swiper-button-prev_${uuidv4()}`;

  const settings = {
    mousewheel: true,
    loop: true,
    spacebetween: 0,
    speed: 1000,
    slidesPerView: 1,
    initialSlide: modalWindowInfo.index,
    navigation: {
      nextEl: `.${classNameSliderBtnNext}`,
      prevEl: `.${classNameSliderBtnPrev}`,
    },
  };

  return (
    <div className={s.root}>
      <ModalClose />
      {modalWindowInfo?.images?.length > 0 && (
        <Swiper
          className={s.slider}
          {...settings}
          modules={[Navigation, Pagination, Lazy, Manipulation]}
        >
          <SliderBtnPrev
            classNameSliderBtn={classNameSliderBtnPrev}
            className={s.btnPrev}
          />
          <SliderBtnNext
            classNameSliderBtn={classNameSliderBtnNext}
            className={s.btnNext}
          />

          {modalWindowInfo?.images?.map((data: string, i: number) => {
            return (
              <SwiperSlide key={i}>
                <ProductCardSlide
                  data={data}
                  key={i}
                  i={i}
                  media={modalWindowInfo?.media || null}
                  images={modalWindowInfo?.images}
                  alt={modalWindowInfo?.alt}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
      )}
    </div>
  );
};

export default ModalZoomImg;
