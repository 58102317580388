import { useTranslation } from 'react-i18next';
import ReCAPTCHA from 'react-google-recaptcha';
import { useRef, useState, Component, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
//styles
import s from './ModalRegistration.module.scss';

//components
import ModalClose from '../ModalComponents/ModalClose/ModalClose';

//hooks
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';

//ui
import ButtonFrontBack from '../../Buttons/ButtonFrontBack/ButtonFrontBack';
import TitleWithLine from '../../TitleWithLine/TitleWithLine';
import ErrorText from '../../ErrorText/ErrorText';
import Input from '../../inputs/Input/Input';
import Checkbox from '../../inputs/Checkbox/Checkbox';
import CustomMaskedInput from '../../inputs/MaskedInput/MaskedInput';

//utils
import { validationFormSchema } from '../../../../utils/forms/validationFormSchema';

//redux
import { postProfileRegistrationBack } from '../../../../redux/User/userRegister';

const nameFields = {
  firstName: 'firstName',
  lastName: 'lastName',
  email: 'email',
  controlledMaskedInputNumberPhone: 'controlledMaskedInputNumberPhone',
  passwordRegister: 'passwordRegister',
  passwordRegisterConfirmation: 'passwordRegisterConfirmation',
  checkboxConfirm: 'checkboxConfirm',
};

const ModalRegistration = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const reCaptchaRef = useRef<any>(null);
  const [captchaValue, setCaptchaValue] = useState<string | null>(null);

  const { loadingProfileRegistrationBack, errorProfileRegistrationBack } =
    useAppSelector((state) => state.userRegister);

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(validationFormSchema(t, nameFields)),
  });

  const onSubmit = (data: any) => {
    if (!captchaValue) return;
    reCaptchaRef.current.reset();
    setCaptchaValue(null);

    dispatch(
      postProfileRegistrationBack({
        obj: {
          name: data?.firstName,
          last_name: data?.lastName,
          email: data.email,
          password: data.passwordRegister,
          phone: data.controlledMaskedInputNumberPhone,
        },
      })
    );
  };

  const handleChange = (value: string | null) => {
    setCaptchaValue(value);
  };

  return (
    <div className={s.root}>
      <ModalClose />
      <div className={s.wrapperTitle}>
        <TitleWithLine
          title={t('modals.registration.title')}
          fontSize={{ xl: '18px', lg: '14px' }}
          widthLine={{ xl: '60px', lg: '28px' }}
          heightLine={{ xl: '3px', lg: '2px' }}
        />
      </div>
      {errorProfileRegistrationBack?.message?.email?.[0]?.includes(
        'unique'
      ) && <ErrorText text={t('modals.registration.error.email.1')} />}
      <div className={s.wrapper}>
        <form className={s.form} onSubmit={handleSubmit(onSubmit)}>
          <div className={s.wrapperInputs}>
            <Input
              width={{ xl: 'calc(50% - 5px)' }}
              marginTop={{ xl: '20px' }}
              id={nameFields.firstName}
              name={nameFields.firstName}
              type='text'
              placeholder={t('common.forms.name')}
              required
              error={errors?.firstName}
              errorFromBack={errorProfileRegistrationBack?.message?.name}
              register={register}
            />
            <Input
              width={{ xl: 'calc(50% - 5px)' }}
              marginTop={{ xl: '20px' }}
              id={nameFields.lastName}
              name={nameFields.lastName}
              type='text'
              placeholder={t('common.forms.lastName')}
              required
              error={errors?.lastName}
              // errorsFromBack={errorsFromBack}
              register={register}
            />
          </div>
          <Input
            width={{ xl: '100%' }}
            marginTop={{ xl: '10px' }}
            id={nameFields.email}
            name={nameFields.email}
            type='text'
            placeholder={'E-mail'}
            required
            error={errors?.email}
            errorFromBack={errorProfileRegistrationBack?.message?.email}
            register={register}
          />

          <Controller
            render={({ field }) => (
              <CustomMaskedInput
                {...field}
                width={{ xl: '100%' }}
                marginTop={{ xl: '10px' }}
                name={nameFields.controlledMaskedInputNumberPhone}
                // label={t("form.inputTitle.phoneNumber")}
                required
                error={errors?.controlledMaskedInputNumberPhone}
              />
            )}
            name={nameFields?.controlledMaskedInputNumberPhone}
            control={control}
          />
          <Input
            width={{ xl: '100%' }}
            marginTop={{ xl: '10px' }}
            id={nameFields.passwordRegister}
            name={nameFields.passwordRegister}
            type='password'
            placeholder={t('common.forms.password')}
            required
            error={errors?.passwordRegister}
            errorFromBack={errorProfileRegistrationBack?.message?.password}
            register={register}
          />
          <Input
            width={{ xl: '100%' }}
            marginTop={{ xl: '10px' }}
            id={nameFields.passwordRegisterConfirmation}
            name={nameFields.passwordRegisterConfirmation}
            type='password'
            placeholder={t('common.forms.repeatPassword')}
            required
            error={errors?.passwordRegisterConfirmation}
            errorFromBack={errorProfileRegistrationBack?.message?.password}
            register={register}
          />

          <p className={s.passwordText}>{t('login.password')}</p>

          <Checkbox
            width={{ xl: '100%' }}
            marginTop={{ xl: '10px' }}
            name={nameFields.checkboxConfirm}
            error={errors?.checkboxConfirm}
            // errorsFromBack={errorsFromBack}
            value={getValues(nameFields?.checkboxConfirm)}
            setValue={setValue}
            label={t('common.forms.agreement')}
          />

          <div className={s.wCaptcha}>
            <ReCAPTCHA
              type="image"
              size="normal"
              ref={reCaptchaRef}
              sitekey={process.env.REACT_APP_SITE_KEY_RECAPTCHA || ""}
              onChange={handleChange}
            />
          </div>
          <ButtonFrontBack
            width={{ xl: '230px' }}
            marginTop={{ xl: '15px' }}
            marginRight={{ xl: 'auto' }}
            marginLeft={{ xl: 'auto' }}
            mode={'red'}
            isButton
            disabledLoading={loadingProfileRegistrationBack}
          >
            <span>{t('modals.registration.title')}</span>
          </ButtonFrontBack>
        </form>
      </div>
    </div>
  );
};

export default ModalRegistration;
