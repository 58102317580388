import { createSlice } from "@reduxjs/toolkit";

//api
import { getRequest } from "../../../API/_api";

//types
import { TProduct } from "../../../TS/types/productCard/productCard";

type sendData = {
  params: any;
  slug: string;
  categoryOrBrand: string;
  moreProductsActiveButton: boolean;
};

export const getCategoryOrBrandProductsBack =
  (sendData: sendData) => (dispatch: any) => {
    const { params, slug, categoryOrBrand, moreProductsActiveButton } =
      sendData;
    dispatch(setLoadingCategoryOrBrandProductsBack(true));
    const success = (data: any) => {
      dispatch(
        successCategoryOrBrandProductsBack({
          data: data?.data,
          meta: data?.meta,
          filters: data?.filters,
          moreProductsActiveButton: moreProductsActiveButton,
        })
      );
    };

    const setError = (error: any) => {
      dispatch(setErrorCategoryOrBrandProductsBack(error));
    };

    getRequest({
      url: `${categoryOrBrand}/${slug}/products`,
      success,
      setError,
      params,
    });
  };

type TProductsState = {
  categoryOrBrandProductsBack: Array<TProduct> | [];
  loadingCategoryOrBrandProductsBack: boolean;
  errorCategoryOrBrandProductsBack: any;
  categoryOrBrandProductsMetaBack: any;
  categoryOrBrandProductsFiltersBack: object | null;
};

const initialState: TProductsState = {
  categoryOrBrandProductsBack: [],
  loadingCategoryOrBrandProductsBack: false,
  errorCategoryOrBrandProductsBack: null,
  categoryOrBrandProductsMetaBack: null,
  categoryOrBrandProductsFiltersBack: null,
};

export const categoryOrBrandProducts = createSlice({
  name: "categoryProducts",
  initialState,
  reducers: {
    successCategoryOrBrandProductsBack(state, action) {
      state.categoryOrBrandProductsBack = action.payload
        .moreProductsActiveButton
        ? [...state.categoryOrBrandProductsBack, ...action.payload.data]
        : action.payload.data;
      state.categoryOrBrandProductsMetaBack = action.payload.meta;
      state.categoryOrBrandProductsFiltersBack = action.payload.filters;
      state.loadingCategoryOrBrandProductsBack = false;
    },
    setLoadingCategoryOrBrandProductsBack(state, action) {
      state.loadingCategoryOrBrandProductsBack = action.payload;
      state.errorCategoryOrBrandProductsBack = null;
    },
    setErrorCategoryOrBrandProductsBack(state, action) {
      state.errorCategoryOrBrandProductsBack = action.payload;
      state.loadingCategoryOrBrandProductsBack = false;
    },
  },
});

export const {
  successCategoryOrBrandProductsBack,
  setLoadingCategoryOrBrandProductsBack,
  setErrorCategoryOrBrandProductsBack,
} = categoryOrBrandProducts.actions;

export default categoryOrBrandProducts.reducer;
