import i18n from "i18next";
import { initReactI18next } from "react-i18next";

//db
import { mainLanguageData } from "./db/generals/languageData";

//Rus
import AboutRus from "./locales/RUS/About.json";
import BasketRus from "./locales/RUS/Basket.json";
import BlogRus from "./locales/RUS/Blog.json";
import BrandsRus from "./locales/RUS/Brands.json";
import BrandsCategoryRus from "./locales/RUS/BrandsCategory.json";
import CartProductRus from "./locales/RUS/CartProduct.json";
import CategoriesRus from "./locales/RUS/Categories.json";
import CheckoutRus from "./locales/RUS/Checkout.json";
import commonRus from "./locales/RUS/common.json";
import ContactsRus from "./locales/RUS/Contacts.json";
import CooperationRus from "./locales/RUS/Cooperation.json";
import DeliveryRus from "./locales/RUS/Delivery.json";
import DiscountProductsRus from "./locales/RUS/DiscountProducts.json";
import EmailThankRus from "./locales/RUS/EmailThank.json";
import FaqRus from "./locales/RUS/Faq.json";
import FavouriteRus from "./locales/RUS/Favourite.json";
import FooterRus from "./locales/RUS/Footer.json";
import GuaranteeRus from "./locales/RUS/Guarantee.json";
import HeaderRus from "./locales/RUS/Header.json";
import MainRus from "./locales/RUS/Main.json";
import NewProductsRus from "./locales/RUS/NewProducts.json";
import NewsRus from "./locales/RUS/News.json";
import Page404Rus from "./locales/RUS/Page404.json";
import PartnershipRus from "./locales/RUS/Partnership.json";
import PayThankRus from "./locales/RUS/PayThank.json";
import ProductCardRus from "./locales/RUS/ProductCard.json";
import ProfileRus from "./locales/RUS/Profile.json";
import ProfilePersonalRus from "./locales/RUS/ProfilePersonal.json";
import ReturnRus from "./locales/RUS/Return.json";
import SearchRus from "./locales/RUS/Search.json";
import GeneralsRus from "./locales/RUS/Generals.json";

//global
import ArticleSliderRus from "./locales/RUS/globalComponents/ArticleSlider.json";
import BottomDiscountRus from "./locales/RUS/globalComponents/BottomDiscount.json";
import CostFilterRus from "./locales/RUS/globalComponents/CostFilter.json";
import EmptyComponentRus from "./locales/RUS/globalComponents/EmptyComponent.json";
import MinHeaderRus from "./locales/RUS/globalComponents/MinimalHeader.json";
import PaginationRus from "./locales/RUS/globalComponents/Pagination.json";
import ProductFilterRus from "./locales/RUS/globalComponents/ProductFilter.json";
import ReviewRus from "./locales/RUS/globalComponents/Review.json";
//modals
import AddRus from "./locales/RUS/globalComponents/Modals/Add.json";
import BrandTopRus from "./locales/RUS/globalComponents/Modals/BrandTop.json";
import ClothingSizeRus from "./locales/RUS/globalComponents/Modals/ClothingSize.json";
import LoginRus from "./locales/RUS/globalComponents/Modals/Login.json";
import ModalsRus from "./locales/RUS/globalComponents/Modals/Modals.json";
import PasswordRus from "./locales/RUS/globalComponents/Modals/Password.json";
import PromoCodeRus from "./locales/RUS/globalComponents/Modals/PromoCode.json";
import VerifyRus from "./locales/RUS/globalComponents/Modals/Verify.json";
//seller
import SellerRus from "./locales/RUS/Seller/Seller.json";
import SellerProductRus from "./locales/RUS/Seller/Product/Product.json";
import SellerAddProductRus from "./locales/RUS/Seller/Product/AddProduct.json";
import SellerProductCardRus from "./locales/RUS/Seller/Product/ProductCard.json";
import SellerAdRus from "./locales/RUS/Seller/SellerAd.json";
import SellerProfileRus from "./locales/RUS/Seller/SellerProfile.json";
import SellerSettingsRus from "./locales/RUS/Seller/SellerSettings.json";

//Ukr
import AboutUkr from "./locales/UKR/About.json";
import BasketUkr from "./locales/UKR/Basket.json";
import BlogUkr from "./locales/UKR/Blog.json";
import BrandsUkr from "./locales/UKR/Brands.json";
import BrandsCategoryUkr from "./locales/UKR/BrandsCategory.json";
import CartProductUkr from "./locales/UKR/CartProduct.json";
import CategoriesUkr from "./locales/UKR/Categories.json";
import CheckoutUkr from "./locales/UKR/Checkout.json";
import commonUkr from "./locales/UKR/common.json";
import ContactsUkr from "./locales/UKR/Contacts.json";
import CooperationUkr from "./locales/UKR/Cooperation.json";
import DeliveryUkr from "./locales/UKR/Delivery.json";
import DiscountProductsUkr from "./locales/UKR/DiscountProducts.json";
import EmailThankUkr from "./locales/UKR/EmailThank.json";
import FaqUkr from "./locales/UKR/Faq.json";
import FavouriteUkr from "./locales/UKR/Favourite.json";
import FooterUkr from "./locales/UKR/Footer.json";
import GuaranteeUkr from "./locales/UKR/Guarantee.json";
import HeaderUkr from "./locales/UKR/Header.json";
import MainUkr from "./locales/UKR/Main.json";
import NewProductsUkr from "./locales/UKR/NewProducts.json";
import NewsUkr from "./locales/UKR/News.json";
import Page404Ukr from "./locales/UKR/Page404.json";
import PartnershipUkr from "./locales/UKR/Partnership.json";
import PayThankUkr from "./locales/UKR/PayThank.json";
import ProductCardUkr from "./locales/UKR/ProductCard.json";
import ProfileUkr from "./locales/UKR/Profile.json";
import ProfilePersonalUkr from "./locales/UKR/ProfilePersonal.json";
import ReturnUkr from "./locales/UKR/Return.json";
import SearchUkr from "./locales/UKR/Search.json";
import GeneralsUkr from "./locales/UKR/Generals.json";
//global
import ArticleSliderUkr from "./locales/UKR/globalComponents/ArticleSlider.json";
import BottomDiscountUkr from "./locales/UKR/globalComponents/BottomDiscount.json";
import CostFilterUkr from "./locales/UKR/globalComponents/CostFilter.json";
import EmptyComponentUkr from "./locales/UKR/globalComponents/EmptyComponent.json";
import MinHeaderUkr from "./locales/UKR/globalComponents/MinimalHeader.json";
import PaginationUkr from "./locales/UKR/globalComponents/Pagination.json";
import ReviewUkr from "./locales/UKR/globalComponents/Review.json";
//modals
import AddUkr from "./locales/UKR/globalComponents/Modals/Add.json";
import BrandTopUkr from "./locales/UKR/globalComponents/Modals/BrandTop.json";
import ClothingSizeUkr from "./locales/UKR/globalComponents/Modals/ClothingSize.json";
import LoginUkr from "./locales/UKR/globalComponents/Modals/Login.json";
import ModalsUkr from "./locales/UKR/globalComponents/Modals/Modals.json";
import PasswordUkr from "./locales/UKR/globalComponents/Modals/Password.json";
import PromoCodeUkr from "./locales/UKR/globalComponents/Modals/PromoCode.json";
import VerifyUkr from "./locales/UKR/globalComponents/Modals/Verify.json";
//seller
import SellerUkr from "./locales/UKR/Seller/Seller.json";
import SellerProductUkr from "./locales/UKR/Seller/Product/Product.json";
import SellerAddProductUkr from "./locales/UKR/Seller/Product/AddProduct.json";
import SellerProductCardUkr from "./locales/UKR/Seller/Product/ProductCard.json";
import SellerAdUkr from "./locales/UKR/Seller/SellerAd.json";
import SellerProfileUkr from "./locales/UKR/Seller/SellerProfile.json";
import SellerSettingsUkr from "./locales/UKR/Seller/SellerSettings.json";

const resources = {
  ru: {
    translation: {
      about: AboutRus,
      basket: BasketRus,
      blog: BlogRus,
      brands: BrandsRus,
      brandsCategory: BrandsCategoryRus,
      cartProduct: CartProductRus,
      categories: CategoriesRus,
      checkout: CheckoutRus,
      common: commonRus,
      contacts: ContactsRus,
      cooperation: CooperationRus,
      delivery: DeliveryRus,
      discountProducts: DiscountProductsRus,
      emailThank: EmailThankRus,
      faq: FaqRus,
      favourite: FavouriteRus,
      footer: FooterRus,
      guarantee: GuaranteeRus,
      header: HeaderRus,
      main: MainRus,
      newProducts: NewProductsRus,
      news: NewsRus,
      page404: Page404Rus,
      partnership: PartnershipRus,
      payThank: PayThankRus,
      productCard: ProductCardRus,
      profile: ProfileRus,
      profilePersonal: ProfilePersonalRus,
      return: ReturnRus,
      search: SearchRus,
      generals: GeneralsRus,
      //global
      articleSlider: ArticleSliderRus,
      bottomDiscount: BottomDiscountRus,
      costFilter: CostFilterRus,
      empty: EmptyComponentRus,
      minHeader: MinHeaderRus,
      pagination: PaginationRus,
      productFilter: ProductFilterRus,
      review: ReviewRus,
      //modals
      add: AddRus,
      brandTop: BrandTopRus,
      clothingSize: ClothingSizeRus,
      login: LoginRus,
      modals: ModalsRus,
      password: PasswordRus,
      promoCode: PromoCodeRus,
      verify: VerifyRus,
      //seller
      seller: SellerRus,
      sellerProduct: SellerProductRus,
      sellerAddProduct: SellerAddProductRus,
      sellerProductCard: SellerProductCardRus,
      sellerAd: SellerAdRus,
      sellerProfile: SellerProfileRus,
      sellerSettings: SellerSettingsRus,
    },
  },
  uk: {
    translation: {
      about: AboutUkr,
      basket: BasketUkr,
      blog: BlogUkr,
      brands: BrandsUkr,
      brandsCategory: BrandsCategoryUkr,
      cartProduct: CartProductUkr,
      categories: CategoriesUkr,
      checkout: CheckoutUkr,
      common: commonUkr,
      contacts: ContactsUkr,
      cooperation: CooperationUkr,
      delivery: DeliveryUkr,
      discountProducts: DiscountProductsUkr,
      emailThank: EmailThankUkr,
      faq: FaqUkr,
      favourite: FavouriteUkr,
      footer: FooterUkr,
      guarantee: GuaranteeUkr,
      header: HeaderUkr,
      main: MainUkr,
      newProducts: NewProductsUkr,
      news: NewsUkr,
      page404: Page404Ukr,
      partnership: PartnershipUkr,
      payThank: PayThankUkr,
      productCard: ProductCardUkr,
      profile: ProfileUkr,
      profilePersonal: ProfilePersonalUkr,
      return: ReturnUkr,
      search: SearchUkr,
      generals: GeneralsUkr,
      //global
      articleSlider: ArticleSliderUkr,
      bottomDiscount: BottomDiscountUkr,
      costFilter: CostFilterUkr,
      empty: EmptyComponentUkr,
      minHeader: MinHeaderUkr,
      pagination: PaginationUkr,
      review: ReviewUkr,
      //modals
      add: AddUkr,
      brandTop: BrandTopUkr,
      clothingSize: ClothingSizeUkr,
      login: LoginUkr,
      modals: ModalsUkr,
      password: PasswordUkr,
      promoCode: PromoCodeUkr,
      verify: VerifyUkr,
      //seller
      seller: SellerUkr,
      sellerProduct: SellerProductUkr,
      sellerAddProduct: SellerAddProductUkr,
      sellerProductCard: SellerProductCardUkr,
      sellerAd: SellerAdUkr,
      sellerProfile: SellerProfileUkr,
      sellerSettings: SellerSettingsUkr,
    },
  },
};

i18n.use(initReactI18next).init({
  resources: resources,
  lng: mainLanguageData?.key,
  fallbackLng: mainLanguageData?.key,
  interpolation: {
    escapeValue: false,
  },
  react: {
    wait: true,
  },
});

export default i18n;
