import { createSlice } from "@reduxjs/toolkit";

//api
import { getRequest, postRequest } from "../../../API/_api";

export const getSellerMessagesDialoguesBack = () => (dispatch: any) => {
  dispatch(setLoadingGetSellerMessagesDialoguesBack(true));

  const success = (data: any) => {
    dispatch(setDataGetSellerMessagesDialoguesBack(data));
  };

  const setError = (error: any) => {
    dispatch(setErrorGetSellerMessagesDialoguesBack(error));
  };

  getRequest({
    url: `messages`,
    success,
    setError,
  });
};

const initialState: any = {
  dataGetSellerMessagesDialoguesBack: null,
  loadingGetSellerMessagesDialoguesBack: false,
  errorGetSellerMessagesDialoguesBack: null,
};

export const sellerMessagesDialogues = createSlice({
  name: "sellerMessagesDialogues",
  initialState,
  reducers: {
    setDataGetSellerMessagesDialoguesBack(state, action) {
      state.dataGetSellerMessagesDialoguesBack = action.payload;
      state.loadingGetSellerMessagesDialoguesBack = false;
      state.errorGetSellerMessagesDialoguesBack = null;
    },

    setErrorGetSellerMessagesDialoguesBack(state, action) {
      state.errorGetSellerMessagesDialoguesBack = action.payload;
      state.loadingGetSellerMessagesDialoguesBack = false;
    },
    setLoadingGetSellerMessagesDialoguesBack(state, action) {
      state.loadingGetSellerMessagesDialoguesBack = action.payload;
      state.errorGetSellerMessagesDialoguesBack = null;
    },
  },
});

export const {
  setLoadingGetSellerMessagesDialoguesBack,
  setErrorGetSellerMessagesDialoguesBack,
  setDataGetSellerMessagesDialoguesBack,
} = sellerMessagesDialogues.actions;

export default sellerMessagesDialogues.reducer;
