import { createSlice, PayloadAction } from "@reduxjs/toolkit";

//api
import { getRequest } from "../../API/_api";

//types
import { TProductCard } from "../../TS/types/productCard/productCard";

//redux
import { userAddRecentlyProduct } from "../User/user";

type sendData = {
  slug: any;
  ReactGA?: any;
};

export const getProductSlugBack = (sendData: sendData) => (dispatch: any) => {
  const { slug, ReactGA } = sendData;
  dispatch(setLoadingProductBack(true));
  const success = (data: any) => {
    ReactGA && ReactGA.set("dimension1", data.data.name);
    ReactGA && ReactGA.send("send", "pageview");
    dispatch(successProductBack(data.data));
    dispatch(userAddRecentlyProduct(data.data));
  };

  const setError = (error: any) => {
    dispatch(setErrorProductBack(error));
  };

  getRequest({
    url: `products/${slug}`,
    success,
    setError,
  });
};

type TdiscountImagesInitialState = {
  productBack: TProductCard | null;
  loadingProductBack: boolean;
  errorProductBack: any;
};

const initialState: TdiscountImagesInitialState = {
  productBack: null,
  loadingProductBack: false,
  errorProductBack: null,
};

export const productCard = createSlice({
  name: "productCard",
  initialState,
  reducers: {
    successProductBack(state, action) {
      state.productBack = action.payload;
      state.loadingProductBack = false;
    },
    setLoadingProductBack(state, action) {
      state.loadingProductBack = action.payload;
      state.errorProductBack = null;
    },
    setErrorProductBack(state, action) {
      state.errorProductBack = action.payload;
      state.loadingProductBack = false;
    },
  },
});

export const {
  successProductBack,
  setLoadingProductBack,
  setErrorProductBack,
} = productCard.actions;

export default productCard.reducer;
