import { createSlice, PayloadAction } from "@reduxjs/toolkit";

//api
import { getRequest } from "../../../API/_api";

type sendData = {
  nameKeyForStaticInformation: string;
  params: { type: string; gender?: string };
};

type TdiscountImagesInitialState = {
  staticInformationBack: any;
  loadingBack: any;
  errorBack: any;
};

const initialState: TdiscountImagesInitialState = {
  staticInformationBack: {
    contactsBack: null,
  },
  loadingBack: {
    contactsBack: false,
  },
  errorBack: {
    contactsBack: null,
  },
};

export const getStaticInformationBack =
  (sendData: sendData) => (dispatch: any) => {
    const { nameKeyForStaticInformation, params } = sendData;
    dispatch(
      setLoadingStaticInformationBack({
        name: nameKeyForStaticInformation,
        status: true,
      })
    );
    const success = (data: any) => {
      dispatch(
        successStaticInformationBack({
          name: nameKeyForStaticInformation,
          data: data,
        })
      );
    };

    const setError = (error: any) => {
      dispatch(
        setErrorStaticInformationBack({
          name: nameKeyForStaticInformation,
          error: error,
        })
      );
    };

    getRequest({
      url: `static-information`,
      success,
      setError,
      params,
    });
  };

export const staticInformation = createSlice({
  name: "staticInformation",
  initialState,
  reducers: {
    successStaticInformationBack(state, action) {
      state.loadingBack = {
        ...state.loadingBack,
        [action.payload.name]: false,
      };
      state.errorBack = {
        ...state.errorBack,
        [action.payload.name]: null,
      };
      state.staticInformationBack = {
        ...state.staticInformationBack,
        [action.payload.name]: action.payload.data,
      };
    },
    setLoadingStaticInformationBack(state, action) {
      state.loadingBack = {
        ...state.loadingBack,
        [action.payload.name]: action.payload.status,
      };
    },
    setErrorStaticInformationBack(state, action) {
      state.errorBack = {
        ...state.errorBack,
        [action.payload.name]: null,
      };
    },
  },
});
export const {
  successStaticInformationBack,
  setLoadingStaticInformationBack,
  setErrorStaticInformationBack,
} = staticInformation.actions;

export default staticInformation.reducer;
