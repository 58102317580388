import React from "react";

//styles
import s from "./Footer.module.scss";

//components
import FooterMainInfo from "./FooterMainInfo/FooterMainInfo";
import FooterBottom from "./FooterBottom/FooterBottom";
import FooterTopAdvantages from "./FooterTopAdvantages/FooterTopAdvantages";

const Footer: React.FC = () => {
  return (
    <footer className={s.root}>
      <FooterTopAdvantages />
      <FooterMainInfo />
      <FooterBottom />
    </footer>
  );
};

export default Footer;
