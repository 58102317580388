//assets
import noImageAvailable from "../../../assets/images/generals/NoImageAvailable.png";

//types
import { TMedia } from "../../../TS/types/generals/generals";

import LazyImage from "./LazyImageObserver";

type TImageComponentUIProps = {
  src: string;
  alt: string | null;
  media: TMedia | any;
  index: number;
  className?: string;
  size: string;
  categories?: boolean;
  images?: Array<string> | null;
  product?: boolean;
  reservImg?: any;
};

const ImageComponentUI = ({
  src,
  alt,
  media,
  index,
  className,
  size,
  categories = false,
  images,
  product = false,
}: TImageComponentUIProps): JSX.Element => {
  const checkSrcForDisplay = () => {
    if (media && media?.length > 0) {
      if (images && images?.length > 0 && media?.length === images?.length) {
        return media?.[index]?.versions[size]
          ? media[index].versions[size]
          : media[index].versions.original;
      } else if (
        images &&
        images?.length > 0 &&
        media?.length !== images?.length
      ) {
        if (!media[index]) {
          return images[index];
        } else {
          return media[index].versions[size];
        }
      }
    }
    return src;
  };

  const checkSrcForDisplayProducts = () => {
    if (media?.versions) {
      return media?.versions?.[size];
    } else if (media?.versions === null) {
      return media?.[0]?.versions?.[size];
    }
    return src;
  };

  function imgError(image: any) {
    image.onerror = "";
    image.src = noImageAvailable;
    return true;
  }

  return categories ? (
    <LazyImage
      src={checkSrcForDisplayProducts()}
      alt={alt || ""}
      height={100}
      width={100}
      className={`${className ? className : ""}`}
    />
  ) : product ? (
    <>
      <LazyImage
        src={checkSrcForDisplay()}
        alt={alt || ""}
        height={100}
        width={100}
        className={`${className ? className : ""}`}
      />
    </>
  ) : (
    <img
      src={checkSrcForDisplay()}
      alt={alt || ""}
      className={`${className ? className : ""}`}
      // srcSet={`${media?.[index]?.versions["sm"] || src} 300w, ${
      //   media?.[index]?.versions["md"] || src
      // } 768w, ${media?.[index]?.versions["lg"] || src}   1280w`}
      onError={({ currentTarget }) => imgError(currentTarget)}
    />
  );
};
export default ImageComponentUI;
