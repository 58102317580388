const Controls = () => ({
	MuiFormControlLabel: {
		styleOverrides: {
			root: () => ({
				columnGap: "8px",
			}),
		},
	},
});

export default Controls;
