import { useTranslation } from "react-i18next";

//styles
import s from "./ModalOrderPay.module.scss";

//components
import ModalClose from "../ModalComponents/ModalClose/ModalClose";

//ui
import TitleWithLine from "../../TitleWithLine/TitleWithLine";
import ButtonFrontBack from "../../Buttons/ButtonFrontBack/ButtonFrontBack";

//hooks
import { useAppSelector } from "../../../../hooks/redux";

const ModalOrderPay = () => {
  const { t } = useTranslation();

  const { modalWindowInfo } = useAppSelector((state) => state.app);

  return (
    <div className={s.root}>
      <ModalClose />
      <div className={s.wrapperTitle}>
        <TitleWithLine
          title={
            modalWindowInfo?.title
              ? t(modalWindowInfo?.title)
              : t("modals.successInfo.title")
          }
          fontSize={{ xl: "18px", lg: "14px" }}
          widthLine={{ xl: "60px", lg: "28px" }}
          heightLine={{ xl: "3px", lg: "2px" }}
        />
      </div>
      {modalWindowInfo?.data && (
        <>
          {modalWindowInfo?.data?.includes("form") ? (
            <ButtonFrontBack
              maxWidth={{ xl: "230px" }}
              width={{ xl: "100%" }}
              marginTop={{ xl: "20px" }}
              marginLeft={{ xl: "auto" }}
              marginRight={{ xl: "auto" }}
              mode={"red"}
            >
              <div
                className={s.content}
                dangerouslySetInnerHTML={{ __html: modalWindowInfo?.data }}
              />
            </ButtonFrontBack>
          ) : (
            <div
              className={s.content}
              dangerouslySetInnerHTML={{ __html: modalWindowInfo?.data }}
            />
          )}
        </>
      )}
    </div>
  );
};

export default ModalOrderPay;
